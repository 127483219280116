import React from "react";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
import Footer from "../../../../components/footer";

const GettingPaidPage = () => {
  return (
    <div className="">
      <BookingNavbar />
      <div className="w-[90%] mx-auto mt-10">
        <h1 className="text-[25px] font-kd mb-8 font-bold text-[#484848] opacity-80">
          Getting Paid: A Guide for Partner
        </h1>
        {/* Image */}
        <div className="mb-8">
          <img
            src={"/images/HelpPage/gettingpaid.jpg"}
            alt="Payout"
            className="w-3/4 h-auto rounded-lg shadow-md"
          />
          <p className="text-gray-500 text-lg py-3">
            Congrats! You’ve earned it (literally). Now, when will you get it?
            Here’s important info on payouts, <br /> and how to make sure you
            get paid the way you want to.
          </p>
        </div>
        {/* Payout Setup */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">Payout Setup</h2>
          <p>
            When you first become a Host, it's crucial to set up your payout
            method promptly. Your payout currency is determined by the country
            and method you selected during the initial setup. Learn more about
            managing your payout preferences.
          </p>
        </section>

        {/* Verification Process */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Verification Process
          </h2>
          <p>
            Wondering when your payout method will be verified? We'll guide you
            through the verification timeline to ensure your method is ready to
            receive payouts.
          </p>
        </section>

        {/* Managing Payout Methods */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Managing Payout Methods
          </h2>
          <p>
            Need to make changes to your payout method? Learn how to add, edit,
            or remove payout methods in your account settings.
          </p>
        </section>

        {/* Default Payout Method */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Default Payout Method
          </h2>
          <p>
            Choose a default payout method to streamline your payout process.
            This ensures that any upcoming payouts are released automatically.
          </p>
        </section>

        {/* Choosing a Payout Currency */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Choosing a Payout Currency
          </h2>
          <p>
            Decide which currency you prefer to be paid in and explore options
            for adding alternative payout methods.
          </p>
        </section>

        {/* Minimum Payout Amount */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Minimum Payout Amount
          </h2>
          <p>
            Discover how to set a minimum payout amount to optimize transaction
            fees and streamline your earnings.
          </p>
        </section>

        {/* Payout Amount and Timing */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Payout Amount and Timing
          </h2>
          <p>
            Once Airbnb releases your payout, the timing of when you receive
            your money depends on various factors, including your payout
            method's processing time, bank holidays, and the length of the
            guest's stay.
          </p>
        </section>

        {/* Tracking Your Payouts */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Tracking Your Payouts
          </h2>
          <p>
            Use your earnings dashboard to track the status of your payouts and
            access detailed information about your earnings.
          </p>
        </section>

        {/* Understanding Your Payout */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Understanding Your Payout
          </h2>
          <p>
            Gain insights into how your payout is calculated, including your
            nightly rate, optional extra charges, and details about the Host
            service fee.
          </p>
        </section>

        {/* Guest Cancellations and Payouts */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Guest Cancellations and Payouts
          </h2>
          <p>
            Find out how guest cancellations affect your payout and whether
            guests are refunded in such cases.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default GettingPaidPage;
