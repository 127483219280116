import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          King Fahad Garden in Taif: A Serene Oasis in Saudi Arabia
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction to King Fahad Garden
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                Located in the picturesque city of Taif, Saudi Arabia, King
                Fahad Garden (also known as Al Hada Park) stands as a lush oasis
                amidst the arid landscapes of the region. This expansive garden
                is renowned for its natural beauty, diverse flora, recreational
                facilities, and panoramic views, making it a popular destination
                for locals and tourists alike seeking respite from the desert
                heat.
                <br />
                Geographical Location and Access
                <br />
                <p className="lg:hidden xl:block">
                  {" "}
                  King Fahad Garden is situated in the Al Hada district of Taif,
                  nestled at an elevation of approximately 2,200 meters (7,218
                  feet) above sea level in the Sarawat Mountains. Its elevated
                  location offers visitors cooler temperatures and breathtaking
                  vistas of the surrounding valleys and mountain ranges. The
                  garden is easily accessible by road, making it a convenient
                  day trip from both Taif and nearby cities like Mecca and
                  Jeddah.
                </p>
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/king-garden/image3.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 font-cairo font-medium text-[15px]">
            {" "}
            King Fahad Garden is situated in the Al Hada district of Taif,
            nestled at an elevation of approximately 2,200 meters (7,218 feet)
            above sea level in the Sarawat Mountains. Its elevated location
            offers visitors cooler temperatures and breathtaking vistas of the
            surrounding valleys and mountain ranges. The garden is easily
            accessible by road, making it a convenient day trip from both Taif
            and nearby cities like Mecca and Jeddah.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Background and Development
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The garden was established during the reign of King Fahad bin
              Abdulaziz Al Saud, after whom it is named. Its development aimed
              to create a recreational space that would not only showcase the
              natural beauty of Taif's mountainous terrain but also provide
              amenities for leisure and relaxation. Over the years, King Fahad
              Garden has evolved into a well-maintained park featuring a blend
              of native and exotic plant species, along with facilities for
              families, tourists, and nature enthusiasts.
              <br />
              Natural and Architectural Features
              <br />
              King Fahad Garden spans a vast area, offering visitors a variety
              of experiences amid its manicured lawns, flower beds, and shaded
              pathways. The garden is adorned with numerous fountains, water
              features, and sculptures that enhance its aesthetic appeal and
              create a tranquil ambiance. Towering trees provide shade, while
              benches and picnic areas invite visitors to unwind and enjoy the
              peaceful surroundings.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/king-garden/image5.webp"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural and Educational Significance
          </h2>
          <div className="mt-4">
            Beyond its recreational offerings, King Fahad Garden serves as a hub
            for cultural events, exhibitions, and educational programs that
            promote environmental awareness and appreciation for Saudi Arabia's
            natural heritage. The garden's visitor center provides information
            about local flora and fauna, as well as the region's ecological
            significance within the broader context of conservation efforts in
            Saudi Arabia. Visitor Experience and Tourism A visit to King Fahad
            Garden promises a memorable experience for visitors of all ages.
            Whether you're seeking a peaceful retreat amidst nature,
            opportunities for outdoor recreation, or insights into Saudi
            Arabia's rich biodiversity, the garden offers something for
            everyone. Its proximity to Taif's other attractions, such as the Al
            Rudaf Park and the Taif Zoo, makes it an ideal stop for those
            exploring the city's cultural and natural landmarks.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
