import React from "react";

const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          The distance between Medina and Quba Mosque is 5 km.
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Masjid Quba
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="w-full">
              <p className="text-[#484848]  font-cairo font-medium text-[15px]">
                Masjid Quba, known as the Quba Mosque, holds significant
                historical and religious importance in Islam. It is located in
                the city of Medina in Saudi Arabia, approximately 3 kilometers
                (about 2 miles) south of the Prophet's Mosque (Masjid
                an-Nabawi). Geographical Coordinates: The mosque's precise
                geographical coordinates are 24.4483° N latitude and 39.6171° E
                longitude. It is situated in the Quba district, which is an area
                renowned for its rich Islamic heritage. Accessibility: The
                mosque is easily accessible by various means of transport. For
                pilgrims and visitors staying in Medina, it can be reached via a
                short taxi ride, local buses, or even a pleasant walk if they
                are close to the city center.
                <p className="lg:hidden xl:block text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                  The area around Masjid Quba is well-developed, featuring
                  numerous amenities including restaurants, shops, and hotels,
                  making it convenient for visitors. Surrounding Landmarks:
                  Nearby landmarks include other historic mosques, residential
                  areas, and markets that offer a glimpse into the daily life
                  and culture of Medina. The Prophet's Mosque, one of the most
                  significant sites in Islam, is within close proximity, making
                  it convenient for visitors to include both mosques in their
                  itinerary.
                </p>
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src="/images/MasjidQuba1.svg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
            The area around Masjid Quba is well-developed, featuring numerous
            amenities including restaurants, shops, and hotels, making it
            convenient for visitors. Surrounding Landmarks: Nearby landmarks
            include other historic mosques, residential areas, and markets that
            offer a glimpse into the daily life and culture of Medina. The
            Prophet's Mosque, one of the most significant sites in Islam, is
            within close proximity, making it convenient for visitors to include
            both mosques in their itinerary.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Masjid Quba in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Masjid Quba holds a special place in Islamic history and
              tradition. It is considered the first mosque ever built in Islam,
              with its foundation laid by Prophet Muhammad (PBUH) himself.
              Historical Significance: The construction of Masjid Quba began
              during the Hijra (migration) of Prophet Muhammad (PBUH) from Mecca
              to Medina in 622 CE. Upon arriving in Quba, a village near Medina,
              the Prophet and his companions erected the mosque, which became a
              central place for worship and community gatherings. Religious
              Importance: The mosque is highly revered because of its
              association with the Prophet Muhammad (PBUH). It is mentioned in
              the Quran in Surah At-Tawbah (9:108), which highlights the
              mosque's foundation on piety and devotion to Allah. Hadith
              References: Numerous hadiths emphasize the virtue of praying at
              Masjid Quba. One such hadith states that performing two rak'ahs
              (units of prayer) at Masjid Quba is equivalent to performing an
              Umrah (lesser pilgrimage) in terms of spiritual rewards. This
              hadith underscores the mosque’s significance and the blessings
              associated with it.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/MasjidQuba2.svg"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              Masjid Quba Mosque In saudi Arabia
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-2xl font-bold mb-4">
            How to Explore Masjid Quba
          </h2>
          <ul className="list-none list-inside space-y-2">
            <li>
              <strong>Location and Accessibility:</strong> Masjid Al Jummah is
              located in Quba, near Medina. It is easily accessible by car,
              taxi, or public transport.
            </li>
            <li>
              <strong>Historical Significance:</strong> The mosque is known for
              being the site where the Prophet Muhammad led the first Friday
              prayer (Jummah) after his migration to Medina.
            </li>
            <li>
              <strong>Best Visiting Times:</strong> Early morning or late
              evening visits are recommended for a serene experience. Fridays
              are particularly significant due to the historical importance of
              Jummah prayers.
            </li>
            <li>
              <strong>Activities:</strong> Engage in prayer, especially the
              Jummah prayer if visiting on a Friday. Spend time in quiet
              reflection and read Islamic texts to deepen your understanding of
              the mosque’s significance.
            </li>
            <li>
              <strong>Cultural Etiquette:</strong> Dress modestly and maintain
              respectful behavior. Ensure cleanliness and engage with locals or
              guides to learn more about the mosque’s history and significance.
            </li>
            <li>
              <strong>Nearby Landmarks:</strong> Combine your visit with trips
              to nearby significant sites such as the Quba Mosque and Masjid
              Al-Qiblatain for a comprehensive understanding of Medina’s rich
              Islamic heritage.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Page;
