import React, { useContext, useEffect, useState } from "react";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { NumericFormat } from "react-number-format";
import { CurrencyContext } from "../../../../utility/CurrencyContext";

function Modal({
  totalAdults,
  totalChildren,
  totalInfants,
  roomType,
  onSave,
  show,
  onClose,
  packageDetail,
}) {
  const [adults, setAdults] = useState(totalAdults || 1);
  const [children, setChildren] = useState(totalChildren || 0);
  const [infants, setInfants] = useState(totalInfants || 0);
  const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);
  const [selectedRoomType, setSelectedRoomType] = useState(
    roomType || "Sharing"
  );
  const [totalCost, setTotalCost] = useState(0);

  const incrementAdults = () => setAdults(adults + 1);
  const decrementAdults = () => adults > 0 && setAdults(adults - 1);

  const incrementChildren = () => setChildren(children + 1);
  const decrementChildren = () => children > 0 && setChildren(children - 1);

  const incrementInfants = () => setInfants(infants + 1);
  const decrementInfants = () => infants > 0 && setInfants(infants - 1);

  const handleSave = () => {
    onSave(adults, children, infants, selectedRoomType);
    onClose();
  };

  const convertedCost =
    packageDetail && exchangeRates[selectedCurrency]
      ? (packageDetail.package_base_cost / exchangeRates["PKR"]) *
        exchangeRates[selectedCurrency]
      : packageDetail
      ? packageDetail.package_base_cost
      : 0; // Default value or handle the case where packageDetail is null
  const calculateConvertedCost = (cost) => {
    return packageDetail && exchangeRates[selectedCurrency]
      ? (cost / exchangeRates["PKR"]) * exchangeRates[selectedCurrency]
      : packageDetail && packageDetail.package_base_cost;
  };
  const calculateTotalCost = () => {
    if (!packageDetail) {
      setTotalCost((packageDetail && packageDetail.package_base_cost) || 0);
      return;
    }

    const roomCostMap = {
      Single: calculateConvertedCost(packageDetail.cost_for_single || 0),
      Double: calculateConvertedCost(packageDetail.cost_for_double || 0),
      Triple: calculateConvertedCost(packageDetail.cost_for_triple || 0),
      Quad: calculateConvertedCost(packageDetail.cost_for_quad || 0),
      Sharing: calculateConvertedCost(packageDetail.cost_for_sharing || 0),
    };
    const baseCost = calculateConvertedCost(
      packageDetail.package_base_cost || 0
    );
    const roomCost = roomCostMap[selectedRoomType] || 0;
    const adultCost = (packageDetail.cost_per_adult || baseCost) * adults;
    const childrenCost = (packageDetail.cost_for_child || baseCost) * children;
    const infantsCost = (packageDetail.cost_for_infants || baseCost) * infants;

    setTotalCost(roomCost + adultCost + childrenCost + infantsCost);
  };
  const primaryTotal_Cost =
    packageDetail &&
    packageDetail.package_base_cost + packageDetail.cost_for_sharing;
  useEffect(() => {
    calculateTotalCost();
  }, [adults, children, infants, selectedRoomType, selectedCurrency]);

  if (!show) return null;
  return (
    <div className="App">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center px-10 md:px-0 items-center">
        <div className="bg-white rounded-xl lg:w-[35%] md:w-1/2 h-5/6 overflow-hidden relative">
          <div
            style={{
              position: "relative",
              display: "inline-block",
              height: "40%",
            }}
            className="bg-[#00936c] w-full"
          >
            <img
              src="/images/ReserveModal/Modal.png"
              alt=""
              className="h-full w-full"
              style={{ display: "block" }}
            />
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                border: "none",
                color: "gray",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              ✕
            </button>
          </div>

          <div className="overflow-auto h-[calc(60%-5rem)] py-3 shadow-lg px-5 mb-12">
            <div className="flex justify-between items-center mb-1.5">
              <h2 className="text-xl font-semibold">
                {packageDetail && packageDetail.package_name}
              </h2>
            </div>
            <p className="text-sm text-gray-500 mb-1.5">
              {packageDetail && packageDetail.description}
            </p>{" "}
            <p className="text-sm text-gray-500 mb-1.5">
              <NumericFormat
                value={convertedCost}
                displayType={"text"}
                thousandSeparator
                prefix={`${selectedCurrency} `}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            <hr className="mb-1.5" />
            <div className="mb-4 space-y-4 ">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <p className="font-semibold">Adults</p>
                  <p className="text-sm text-gray-500">Age 13+</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={decrementAdults}
                    className={` ${
                      adults === 1
                        ? "text-gray-300"
                        : "text-[#484848] opacity-80"
                    } size-[25px]`}
                    disabled={adults === 1}
                  >
                    <CiCircleMinus className="size-[25px]" />
                  </button>
                  <span className="mx-2">{adults}</span>
                  <button
                    onClick={incrementAdults}
                    className="text-[#484848] opacity-80 size-[25px]"
                  >
                    <CiCirclePlus className="size-[25px]" />
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-semibold">Children</p>
                  <p className="text-sm text-gray-500">Under 12</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={decrementChildren}
                    className={` ${
                      children === 0
                        ? "text-gray-300"
                        : "text-[#484848] opacity-80"
                    } `}
                    disabled={children === 0}
                  >
                    <CiCircleMinus className="size-[25px]" />
                  </button>
                  <span className="mx-2">{children}</span>
                  <button
                    onClick={incrementChildren}
                    className="text-[#484848] opacity-80 size-[25px]"
                  >
                    <CiCirclePlus className="size-[25px]" />
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-semibold">Infants</p>
                  <p className="text-sm text-gray-500">
                    Children 2 years or less
                  </p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={decrementInfants}
                    className={` ${
                      infants === 0
                        ? "text-gray-300"
                        : "text-[#484848] opacity-80"
                    } `}
                    disabled={infants === 0}
                  >
                    <CiCircleMinus className="size-[25px]" />
                  </button>
                  <span className="mx-2">{infants}</span>
                  <button
                    onClick={incrementInfants}
                    className="text-[#484848] opacity-80 size-[25px]"
                  >
                    <CiCirclePlus className="size-[25px]" />
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <p className="font-semibold">Room Type</p>
                <div className="w-full mt-2">
                  {[
                    {
                      type: "Single",
                      price: calculateConvertedCost(
                        packageDetail && packageDetail.cost_for_single
                      ),
                    },
                    {
                      type: "Double",
                      price: calculateConvertedCost(
                        packageDetail && packageDetail.cost_for_double
                      ),
                    },
                    {
                      type: "Triple",
                      price: calculateConvertedCost(
                        packageDetail && packageDetail.cost_for_triple
                      ),
                    },
                    {
                      type: "Quad",
                      price: calculateConvertedCost(
                        packageDetail && packageDetail.cost_for_quad
                      ),
                    },
                    {
                      type: "Sharing",
                      price: calculateConvertedCost(
                        packageDetail && packageDetail.cost_for_sharing
                      ),
                    },
                  ].map(({ type, price }) => (
                    <div
                      key={type}
                      className="mb-2 flex items-center justify-between"
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id={type}
                          name="roomType"
                          value={type}
                          checked={selectedRoomType === type}
                          onChange={(e) => setSelectedRoomType(e.target.value)}
                          className="mr-2"
                        />
                        <label htmlFor={type} className="mr-2">
                          {type}
                        </label>
                      </div>
                      <NumericFormat
                        value={price}
                        displayType={"text"}
                        thousandSeparator
                        prefix={`${selectedCurrency} `}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 px-5 py-3 bg-white flex justify-between items-center border-t border-gray-300">
            <div>
              <p className="font-semibold">Total Cost</p>
              <NumericFormat
                value={totalCost || primaryTotal_Cost}
                displayType={"text"}
                thousandSeparator
                prefix={`${selectedCurrency} `}
                decimalScale={2}
                fixedDecimalScale={true}
                className="font-bold text-[22px]"
              />
            </div>
            <button
              onClick={handleSave}
              className="px-5 py-2 w-1/2 bg-[#00936C] text-white rounded-md"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
