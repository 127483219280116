import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // import useNavigate for navigation
import MobileSearchBar from "./searchbarmobile.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { format, addDays } from "date-fns";
import Modal from "./Modal.jsx";


const SearchBar = () => {
  const navigate = useNavigate(); // initialize navigation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infant, setInfant] = useState(0);
  const [checkinDate, setCheckinDate] = useState(addDays(new Date(), 1));
  const [showDateModal, setShowDateModal] = useState(false);
  const datepickerRef = useRef(null);
  const guestsRef = useRef(null);
  const formattedCheckinDate = format(checkinDate, "MM/dd/yyyy");


  const handleSearch = () => {
    sessionStorage.removeItem("refreshed"); // Clear the flag before navigating
    navigate("/umrah", {
      state: {
        fromHomepage: true, // Set this flag when navigating from homepage
        adults,
        children,
        infant,
        start_date: checkinDate.toISOString().split("T")[0],
        flight_from: "Multan",
        page: 1,
        page_size: 10,
      },
    });
  };  


  // Close modal if clicked outside of date picker
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target)
      ) {
        setShowDateModal(false);
      }
      if (guestsRef.current && !guestsRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [datepickerRef, guestsRef]);

  const handleSelect = (date) => {
    setCheckinDate(date);
  };

  const toggleDateModal = () => {
    setShowDateModal(!showDateModal);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    closeModal(); // Close the modal after saving
  };

  return (
    <>
      {/* Mobile Search Bar */}
      <MobileSearchBar />

      <div className="hidden lg:flex justify-center relative z-50">
        <div className="flex bg-white shadow rounded-full px-4 py-2 items-center w-[75%]">
          {/* Location Input */}
          <div className="flex-grow pr-7 pl-4 ">
            <span className="text-black text-sm px-3">Flight from</span>
            <input
              type="text"
              placeholder="Where are you traveling from?"
              className="border-0 text-sm bg-transparent placeholder:text-[#C2C6CC] outline-none text-[#484848] opacity-80 flex-grow w-full focus:outline-none focus:ring-0"
            />
          </div>
          <span className="mx-2 text-gray-300">|</span>
          {/* Date Selection */}
          <div
            className="flex-grow cursor-pointer w-[25%]"
            onClick={toggleDateModal}
          >
            <span className="text-black text-sm px-3">
              When are you planning to go?
            </span>
            <input
              type="text"
              readOnly
              className="border-0 text-sm bg-transparent placeholder:text-[#C2C6CC] outline-none text-[#484848] opacity-80 w-full focus:outline-none focus:ring-0"
              value={formattedCheckinDate}
              placeholder="Add Date"
            />
          </div>
          <span className="mx-2 text-gray-300">|</span>
          {/* Guest Number Input */}
          <div className="relative flex-grow w-[20%] -mt-2.5" ref={guestsRef}>
            <span className="text-black text-sm px-3">Guests</span>
            <div
              className="cursor-pointer text-sm w-full ml-2.5 placeholder:text-[#C2C6CC] outline-none text-[#484848] opacity-80"
              onClick={openModal}
            >
              {adults} Adults, {children} Children, {infant} Infants
  </div>
            {isModalOpen && (
              <Modal
                show={isModalOpen}
                onClose={closeModal}
                adults={adults}
                setAdults={setAdults}
                children={children}
                setChildren={setChildren}
                infant={infant}
                setInfant={setInfant}
                onSave={handleSave}
              />
            )}
          </div>

          {/* Search Button */}
          <button onClick={handleSearch} className="bg-[#00936C] text-white rounded-full p-4 shadow-md">            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
          </button>
        </div>

        {showDateModal && (
          <div
            ref={datepickerRef}
            className="absolute mt-2 bg-white z-50"
            style={{ left: "50%", transform: "translateX(-50%)" }}
          >
            <Calendar
              date={checkinDate}
              onChange={handleSelect}
              minDate={addDays(new Date(), 1)} // Prevent selection of today or any previous date
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
