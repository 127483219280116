import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { MasjidalKhayfimages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const MasjidalKhayf = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${MasjidalKhayfimages.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] text-center lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl text-center">
            Masjid al-Khayf
          </h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          "Discover the serene legacy of Masjid al-Khayf: A spiritual oasis
          nestled in the heart of Mina, echoing centuries of devotion and
          history."
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Masjid al-Khayf: A Sacred Confluence of Geography and Spirituality
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Nestled in the valley of Mina, Masjid al-Khayf stands as a
                testament to both geographic marvel and spiritual reverence.
                This sacred mosque, steeped in history, has witnessed countless
                pilgrims' prayers and supplications during the annual Hajj.
                Known as the "Mosque of the Prophets," it is believed to be the
                site where numerous prophets, including Prophet Muhammad (peace
                be upon him), offered their prayers.
              </p>
              <p>
                Its strategic location, surrounded by the stony hills of Mina,
                not only provides a serene retreat for worship but also
                symbolizes the deep connection between the natural landscape and
                Islamic spirituality. Join us as we delve into the rich heritage
                and enduring significance of Masjid al-Khayf, exploring its role
                in the spiritual journey of millions.
              </p>
              <p className="lg:hidden xl:block">
                Masjid al-Khayf is strategically located in the valley of Mina,
                surrounded by the stony hills that characterize the region.
                Mina, situated approximately 7 kilometers east of Mecca, serves
                as a vital location during the Hajj pilgrimage. The mosque's
                location amidst the rugged terrain provides a serene and natural
                retreat for worshippers, fostering a profound connection between
                the natural landscape and the spiritual practices of Islam. The
                geographic setting of Masjid al-Khayf not only offers a peaceful
                environment for reflection and prayer but also symbolizes the
                harmony between nature and spirituality that is central to
                Islamic teachings.
              </p>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={MasjidalKhayfimages.image2}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full rounded-lg"
              />
              <p>
                <em className="text-[10px] md:text-[16px] ">
                  Interior of Masjid al-Khayf
                </em>
              </p>
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
            Masjid al-Khayf is strategically located in the valley of Mina,
            surrounded by the stony hills that characterize the region. Mina,
            situated approximately 7 kilometers east of Mecca, serves as a vital
            location during the Hajj pilgrimage. The mosque's location amidst
            the rugged terrain provides a serene and natural retreat for
            worshippers, fostering a profound connection between the natural
            landscape and the spiritual practices of Islam. The geographic
            setting of Masjid al-Khayf not only offers a peaceful environment
            for reflection and prayer but also symbolizes the harmony between
            nature and spirituality that is central to Islamic teachings.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Understanding Masjid al-Khayf: Historical and Spiritual Dimensions
          </h1>
          <div className="pt-3">
            <p className="font-cairo font-medium text-[15px]">
              The term "Arafat" has deep significance in the Islamic tradition.
              The word "Arafat" comes from the Arabic root عَرَفَ (ʿarafa),
              which means "to know" or "to recognize." In the context of Islamic
              history and theology, Arafat refers to several key concepts and
              events:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  Recognition and Repentance:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Masjid al-Khayf holds a profound place in Islamic tradition,
                  associated with themes of recognition and repentance.
                  According to Islamic teachings, this mosque is located in
                  Mina, a key area where pilgrims gather during Hajj. It is
                  believed that numerous prophets, including Prophet Muhammad
                  (peace be upon him), prayed at this site, seeking forgiveness
                  and divine guidance. This makes Masjid al-Khayf a symbol of
                  penitence and spiritual renewal, echoing the stories of past
                  prophets who turned to God in humility and devotion.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  The Day of Hajj
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  During the annual Hajj pilgrimage, Masjid al-Khayf becomes a
                  focal point for millions of Muslims. Pilgrims visit the mosque
                  during the days of Hajj, particularly during the first three
                  days of the event. It is a time of intense spiritual activity,
                  where worshippers engage in prayers, reflection, and rituals
                  such as the symbolic stoning of the devil. The mosque's
                  significance is amplified as it serves as a place for pilgrims
                  to gather, pray, and seek mercy and forgiveness from God,
                  recognizing their sins and striving for spiritual
                  purification.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  A Place of Prophetic Legacy:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Masjid al-Khayf is also historically significant as a site
                  where Prophet Muhammad (peace be upon him) and other prophets
                  are believed to have prayed. This legacy is a powerful
                  reminder of the unity and continuity of the Islamic faith,
                  linking contemporary believers with their spiritual forebears.
                  The mosque stands as a testament to the enduring principles of
                  Islam, emphasizing humility, devotion, and the pursuit of
                  divine forgiveness and guidance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Meaning of ‘al-Khayf’
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              In Arabic, the word ‘Al-Khayf’ means what has risen above the
              stream of water and inclined the width of the mountain.
            </p>
            <div className="space-y-1.5 py-2">
              <h4 className="font-medium text-[25px]">Points of reflection:</h4>
              <div className=" font-medium text-[15px] space-y-2">
                <p>
                  This is a place where the pilgrim makes a firm resolution that
                  from this day forward all they will do will be for the sake of
                  Allah and to acquire benefit in the Akhirah.
                </p>
                <p>
                  This was the emphasis of the Prophet (ﷺ) at this very place.
                  Our mind and hearts should ponder and reflect: Is our concern
                  the Akhirah or the Dunya?
                </p>
              </div>
            </div>
          </div>
          <img
            src={MasjidalKhayfimages.image3}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
          >
            <SwiperSlide>
              <img
                src={MasjidalKhayfimages.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MasjidalKhayfimages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MasjidalKhayfimages.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MasjidalKhayfimages.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default MasjidalKhayf;
