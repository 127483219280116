import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../App";
import country_data from "../../UserSetup/Login/country_data.json";
import { checkUserExistence } from "../../../utility/AuthApis";
import { createBookingRequest, getPackagesDetail } from "../../../utility/Api";
import SideBar from "./SideBar";
import Modal from "./Modal/Modal";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import Loader from "../../../components/loader";

const RegistrationForm = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { state: locationState } = useLocation();
  const {
    huz_token,
    partner_session_token,
    bookingFormData: initialFormData,
  } = locationState || {};

  const packageId = new URLSearchParams(window.location.search).get("packageId");
  const { state: authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const inputRef = useRef();

  const defaultCountry = country_data[0].dial_code;

  const [formData, setFormData] = useState({
    phone_number: "",
    country: defaultCountry,
    totalAdults: 1,
    totalChild: 0,
    totalInfants: 0,
    specialRequest: "",
    roomType: "Sharing",
  });

  const [showModal, setShowModal] = useState(false);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [packageDetail, setPackageDetail] = useState(null);
  const [payment_type, setPayment_type] = useState(1);
  const [errors, setErrors] = useState({});
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (initialFormData) {
      setFormData((prevData) => ({ ...prevData, ...initialFormData }));
      setPayment_type(initialFormData.payment_type || 1);
    }
  }, [initialFormData]);

  useEffect(() => {
    (async () => {
      try {
        const { data, error } = await getPackagesDetail(packageId);
        if (error) throw new Error(error);
        const [packageData] = data;
        setPackageDetail(packageData);
        setCheckInDate(packageData.start_date?.split("T")[0] || "");
        setCheckOutDate(packageData.end_date?.split("T")[0] || "");
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    })();
  }, [packageId]);

  useEffect(() => {
    if (packageDetail) calculateTotalCost();
  }, [formData, packageDetail]);

  const calculateTotalCost = () => {
    const { totalAdults, totalChild, totalInfants, roomType } = formData;
    const roomCostKey = `cost_for_${roomType.toLowerCase()}`;
    const roomCost = packageDetail[roomCostKey] || 0;

    const totalCost =
      totalAdults * packageDetail.package_base_cost +
      totalChild * packageDetail.cost_for_child +
      totalInfants * packageDetail.cost_for_infants +
      roomCost * totalAdults;

    setTotalCost(totalCost);
    localStorage.setItem("Total-Price", JSON.stringify(totalCost));
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (/^\d*$/.test(value) && value.length <= 11) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSpecialRequestChange = ({ target: { value } }) => {
    setFormData((prevData) => ({ ...prevData, specialRequest: value }));
  };

  const handleContinueButton = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setApiError("");

    try {
      let session_token = "";
      if (authState) {
        const loginProfile = JSON.parse(localStorage.getItem("Login-User-Profile") || "{}");
        const newUserProfile = JSON.parse(localStorage.getItem("New-User-Profile") || "{}");
        session_token = loginProfile.session_token || newUserProfile.session_token || "";
      }

      const paymentTypes = ["Bank", "Cheque", "Voucher"];

      const payload = {
        session_token,
        partner_session_token: packageDetail.partner_session_token,
        huz_token: packageDetail.huz_token,
        adults: formData.totalAdults,
        child: formData.totalChild,
        infants: formData.totalInfants,
        room_type: formData.roomType,
        start_date: checkInDate,
        end_date: checkOutDate,
        total_price: totalCost,
        special_request: formData.specialRequest || "Null",
        payment_type: paymentTypes[payment_type - 1],
      };

      if (!authState) {
        const phoneNumber = `${formData.country}${formData.phone_number}`;
        const response = await checkUserExistence(phoneNumber);
        localStorage.setItem("Login-User-Profile", JSON.stringify(response));
        localStorage.setItem("User-Phone-Number", JSON.stringify(phoneNumber));
        navigate("/otpverification", {
          state: { userFromBooking: true, huz_token, formData },
        });
      } else {
        const bookingResponse = await createBookingRequest(
          payload.session_token,
          payload.partner_session_token,
          payload.huz_token,
          payload.adults,
          payload.child,
          payload.infants,
          payload.room_type,
          payload.start_date,
          payload.end_date,
          payload.total_price,
          payload.special_request,
          payload.payment_type
        );

        navigate("/passportDetails", {
          state: {
            huz_token,
            partner_session_token,
            ...formData,
            totalCost,
            bookingResponse: bookingResponse.data,
            booking_number: bookingResponse.data.booking_number,
            user_session_token: bookingResponse.data.user_session_token,
            payment_type,
            returnDate: packageDetail.end_date,
          },
        });
      }
    } catch (error) {
      console.error("Error during continue button click:", error);
      setApiError(error.response?.data?.message || "An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSave = (adults, children, infants, roomType) => {
    setFormData((prevData) => ({
      ...prevData,
      totalAdults: adults,
      totalChild: children,
      totalInfants: infants,
      roomType,
    }));
  };

  const handleEditClick = (inputField) => {
    document.getElementById(inputField)?.focus();
  };

  return (
    <div className="text-[#484848] font-kd">
      <BookingNavbar />
      <div className="space-y-5 font-kd w-[85%] mx-auto py-10">
        <div className="md:flex w-full justify-between">
          <div className="xl:w-full justify-end">
            <div className="md:max-w-[75%] bg-white space-y-5">
              <h1 className="text-sm md:text-2xl font-semibold">Request to book</h1>

              <div className="space-y-4">
                <div className="space-y-1.5">
                  <strong className="text-sm md:text-lg">Your Trip</strong>
                  {packageDetail?.is_package_open_for_other_date ? (
                    <div className="space-y-1.5">
                      {/* Start Date Input */}
                      <div className="text-sm flex justify-between items-center">
                        <div className="flex flex-col space-y-2">
                          <strong className="opacity-80">Start Date</strong>
                          <input
                            id="startDateInput"
                            type="date"
                            className="text-sm border-none opacity-80 pl-2 p-4 rounded-md py-2 outline-none"
                            value={checkInDate}
                            min={packageDetail.start_date?.split("T")[0]}
                            max={checkOutDate || packageDetail.end_date?.split("T")[0]}
                            onChange={(e) => {
                              const newStartDateStr = e.target.value;
                              const newStartDate = new Date(newStartDateStr);
                              const minDate = new Date(
                                packageDetail.start_date?.split("T")[0]
                              );
                              const maxDate = new Date(
                                checkOutDate || packageDetail.end_date?.split("T")[0]
                              );

                              if (newStartDate < minDate) {
                                setCheckInDate(minDate.toISOString().split("T")[0]);
                              } else if (newStartDate > maxDate) {
                                setCheckInDate(maxDate.toISOString().split("T")[0]);
                              } else {
                                setCheckInDate(newStartDateStr);
                              }
                            }}
                            ref={inputRef}
                          />
                        </div>
                        <button
                          className="underline font-bold cursor-pointer"
                          onClick={() => handleEditClick("startDateInput")}
                        >
                          Edit
                        </button>
                      </div>

                      {/* End Date Input */}
                      <div className="text-sm flex justify-between items-center">
                        <div className="flex flex-col space-y-2">
                          <strong className="opacity-80">End Date</strong>
                          <input
                            id="endDateInput"
                            type="date"
                            className="text-sm border-none opacity-80 pl-2 p-4 rounded-md py-2 outline-none"
                            value={checkOutDate}
                            min={checkInDate || packageDetail.start_date?.split("T")[0]}
                            max={packageDetail.end_date?.split("T")[0]}
                            onChange={(e) => {
                              const newEndDateStr = e.target.value;
                              const newEndDate = new Date(newEndDateStr);
                              const minDate = new Date(
                                checkInDate || packageDetail.start_date?.split("T")[0]
                              );
                              const maxDate = new Date(
                                packageDetail.end_date?.split("T")[0]
                              );

                              if (newEndDate < minDate) {
                                setCheckOutDate(minDate.toISOString().split("T")[0]);
                              } else if (newEndDate > maxDate) {
                                setCheckOutDate(maxDate.toISOString().split("T")[0]);
                              } else {
                                setCheckOutDate(newEndDateStr);
                              }
                            }}
                            ref={inputRef}
                          />
                        </div>
                        <button
                          className="underline font-bold cursor-pointer"
                          onClick={() => handleEditClick("endDateInput")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-1.5">
                      <div className="text-sm flex justify-between items-center">
                        <div className="flex flex-col">
                          <strong className="opacity-80">Start Date</strong>
                          <p className="text-lg font-semibold text-[#4b465c] opacity-80">
                            {packageDetail &&
                              new Date(packageDetail.start_date).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="text-sm flex justify-between items-center">
                        <div className="flex flex-col">
                          <strong className="opacity-80">End Date</strong>
                          <p className="text-lg font-semibold text-[#4b465c] opacity-80">
                            {packageDetail &&
                              new Date(packageDetail.end_date).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-sm flex justify-between items-center">
                    <div className="space-y-0.5">
                      <strong className="opacity-80">Total Adults</strong>
                      <p className="opacity-80">{formData.totalAdults}</p>
                    </div>
                    <button
                      className="underline font-bold cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="text-sm flex justify-between items-center">
                    <div className="space-y-0.5">
                      <strong className="opacity-80">Total Childs</strong>
                      <p className="opacity-80">{formData.totalChild}</p>
                    </div>
                    <button
                      className="underline font-bold cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="text-sm flex justify-between items-center">
                    <div className="space-y-0.5">
                      <strong className="opacity-80">Total Infants</strong>
                      <p className="opacity-80">{formData.totalInfants}</p>
                    </div>
                    <button
                      className="underline font-bold cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="text-sm flex justify-between items-center">
                    <div className="space-y-0.5">
                      <strong className="opacity-80">Room Type</strong>
                      <p className="opacity-80">{formData.roomType}</p>
                    </div>
                    <button
                      className="underline font-bold cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <div>
                <div className="mb-4">
                  <strong className="opacity-80">Choose how to pay</strong>
                </div>
                {["Bank Transfer", "By Cheque", "By Voucher"].map(
                  (option, index) => (
                    <div
                      key={index}
                      className={`flex justify-between p-3 text-sm font-semibold border-[1px] ${index === 0 ? "rounded-t-md" : ""
                        } ${index === 2 ? "rounded-b-md" : ""} ${payment_type === index + 1 ? "border-black" : ""
                        }`}
                      onClick={() => setPayment_type(index + 1)}
                    >
                      <p className="opacity-80">{option}</p>
                      <input
                        type="radio"
                        checked={payment_type === index + 1}
                        readOnly
                      />
                    </div>
                  )
                )}
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold opacity-50">Special requests</h1>
                <p className="text-xs opacity-80">
                  Special requests cannot be guaranteed – but the organization will
                  do its best to meet your needs. You can always make a special
                  request after your booking is complete!
                </p>
                <textarea
                  className="w-full h-32 text-sm rounded-md mt-2 text-[#4b465c] opacity-80"
                  placeholder="Write a special request."
                  value={formData.specialRequest}
                  onChange={handleSpecialRequestChange}
                />
              </div>
              <hr className="my-5" />
              <div className="space-y-1">
                <h2 className="text-xl font-semibold opacity-50">
                  Cancellation Policy
                </h2>
                <p className="text-xs opacity-80">
                  Free cancellation before Jun 15. After the reservation is
                  non-refundable. Learn More
                </p>
              </div>
              <hr className="my-5" />
              <div>
                <p className="text-xs opacity-80">
                  By selecting the button below, I agree to the Host’s House
                  Rules, Ground rules for guests, Huz’s Rebooking and Refund
                  policy, and that Huz can charge my payment method if I’m
                  responsible for damage.
                </p>
              </div>
              {authState ? (
                <div className="space-y-4 mt-4">
                  <div className="mx-auto my-5">
                    <button
                      className={`w-full text-sm py-2 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      type="button"
                      onClick={handleContinueButton}
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Continue"}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="space-y-1 mt-5">
                  <h2 className="text-xl font-semibold">Log in or sign up</h2>
                  <div className="text-xs">
                    <select
                      className="w-full rounded-t-md outline-none border-[#B2ABAB] border-b-none"
                      value={formData.country}
                      onChange={(e) =>
                        setFormData({ ...formData, country: e.target.value })
                      }
                    >
                      {country_data.map((item) => (
                        <option key={item.dial_code} value={item.dial_code}>
                          {item.name} {item.dial_code}
                        </option>

                      ))}
                    </select>
                  </div>
                  <div className="flex items-center border-[1px] border-[#B2ABAB] rounded-b-md p-2 py-0 text-xs">
                    <p className="text-sm w-1/3">Phone Number</p>
                    <input
                      type="text"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      className="border-none outline-none w-full text-sm no-border bg-white"
                    />
                  </div>
                  {errors.phone_number && (
                    <p className="text-red-500 text-xs">{errors.phone_number}</p>
                  )}
                  {apiError && (
                    <div className="text-red-500 text-xs mt-2">{apiError}</div>
                  )}
                  <p className="text-xs my-2">
                    We’ll call or text you to confirm your number. Standard message
                    and data rates apply. Privacy Policy
                  </p>
                  <div className="space-y-4 mt-4">
                    <div className="mx-auto my-5">
                      <button
                        className={`w-full text-sm py-2 mb-3 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                        type="button"
                        onClick={handleContinueButton}
                        disabled={isLoading}
                      >
                        {isLoading ? <Loader /> : "Continue"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="xl:w-[40%] lg:w-2/3 md:w-full">
            <SideBar
              pkg={packageDetail}
              input_Ref={inputRef}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              totalAdults={formData.totalAdults}
              totalChildren={formData.totalChild}
              totalInfants={formData.totalInfants}
              totalCost={totalCost}
            />
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        totalAdults={formData.totalAdults}
        totalChildren={formData.totalChild}
        totalInfants={formData.totalInfants}
        roomType={formData.roomType}
        onSave={handleModalSave}
        show={showModal}
        onClose={() => setShowModal(false)}
        packageDetail={packageDetail}
        totalCost={totalCost}
      />
    </div>
  );
};

export default RegistrationForm;
