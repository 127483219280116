import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css/navigation";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Array of image URLs from the public folder
const images = [
  process.env.PUBLIC_URL + "/images/Makkah/AlHudaybiyah/hudaibiyah4.jpeg",
  process.env.PUBLIC_URL + "/images/Makkah/AlHudaybiyah/hudaibiyah5.webp",
  process.env.PUBLIC_URL + "/images/Makkah/AlHudaybiyah/hudaibiyah6.jpeg",
];

const Slider = () => {
  const [swiper, setSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const updateButtonVisibility = () => {
    if (!swiper || !prevButtonRef.current || !nextButtonRef.current) return;

    prevButtonRef.current.classList.toggle(
      "hidden",
      currentIndex <= 0 && !swiper.loop
    );
    nextButtonRef.current.classList.toggle(
      "hidden",
      currentIndex >= images.length - 1
    );
  };

  useEffect(() => {
    if (swiper && prevButtonRef.current && nextButtonRef.current) {
      updateButtonVisibility();
    }
  }, [swiper, currentIndex]);

  return (
    <div className="w-[85%] mx-auto pt-8">
      <div className="slider-container relative hidden md:block">
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          loop={true}
          navigation={false} // Disable built-in navigation
          modules={[Navigation]}
          slidesPerView={1} // Default for mobile
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {" "}
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                className="w-full h-[300px] object-cover rounded-t-lg"
                src={image}
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={handlePrev}
          className="absolute top-1/2 -left-4 transform -translate-y-2/3 z-10" // Adjusted CSS
        >
          <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 -right-5 transform -translate-y-2/3 z-10" // Adjusted CSS
        >
          <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
      </div>
      <div className="md:hidden block">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          className="mySwiper swiper-container"
          breakpoints={{
            1440: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                className="w-full h-[300px] object-cover rounded-t-lg"
                src={image}
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
