import React from "react";
import { useNavigate } from "react-router-dom";
import bgimagesm from "../assets/bgimgForNewsLetter.svg";

const Subscription = () => {
  const navigate = useNavigate();

  return (
    <div className=" ">
      <div
        className="bg-[#d9f1ea] py-10 font-kd text-center"
        style={{
          backgroundImage: `url(${bgimagesm})`,
          backgroundPosition: "center",
          objectFit: "cover",
        }}
      >
        <div className="mx-auto w-[85%]">
          <h3 className="text-lg sm:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold mb-4 text-[#4B465C] fonmt-cairo text-[34px]">
            Subscribe to Our Newsletter
          </h3>
          <p className="text-xs sm:text-sm xl:text-base 2xl:text-lg font-medium mb-4 font-cairo text-[#4B465C]">
            Sign up for our newsletter and you'll be among the first to find out
            about new features.
          </p>
          <form className="flex flex-col sm:flex-row justify-center items-center mb-4">
            <input
              type="email"
              placeholder="Your email address..."
              required
              className="md:w-[380px] w-full  px-4 py-2 mb-2 sm:mb-0 sm:mr-2 rounded-md border border-[#D7D7D7] focus:outline-none focus:ring-0 outline-none"
            />
            <button
              type="submit"
              className="w-full sm:w-auto px-6 py-2 bg-[#00936c] text-white font-medium rounded-md hover:bg-[#007f5b] transition duration-200"
            >
              Subscribe
            </button>
          </form>
          <div className="text-sm font-normal text-[#4B465C]">
            By Subscribing, you agree with HajjUmrah.com{" "}
            <a href="#" className="text-[#4B465C] font-bold">
              Terms of Services
            </a>{" "}
            and{" "}
            <a href="#" className="text-[#4B465C] font-bold">
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
      {/* <div className="bg-[#00936c] h-[107px] text-white py-3 w-full justify-center text-center align-middle mx-auto">
        <button
          className="text-center border-[1px] border-white rounded-lg px-5 py-2 my-5 font-kd font-semibold text-[18px]"
          onClick={() => navigate("/umrah")}
        >
          List your package
        </button>
      </div> */}
      <div className="bg-[#00936c] text-white">
        <div className="py-5 w-full flex justify-center items-center">
          <button
            className="text-center border-[1px] border-white rounded-md px-4 py-1.5 font-medium text-[13px]"
            onClick={() => navigate("/umrah")}
          >
            List your package
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
