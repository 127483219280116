// data.js

export const gharslider = {
  image1: "/images/Makkah/Ghar-e-Hira/gharslider1.jpg",
  image2: "/images/Makkah/Ghar-e-Hira/gharslider2.jpg",
  image3: "/images/Makkah/Ghar-e-Hira/gharslider3.jpg",
  image4: "/images/Makkah/Ghar-e-Hira/gharslider4.jpg",
  image5: "/images/Makkah/Ghar-e-Hira/gharslider5.jpg",
};

export const tabicon = {
  image: "/images/holysites.png",
  image2: "/images/grandmosque.png",
  image3: "/images/mosqueservices.svg",
  image4: "/images/attractionlogo.svg",
  image5: "/images/accom.png",
  image6: "/images/shopping.png",
  image7: "/images/cafes.png",
  image8: "/images/mecca.png",
};

export const pageOneContent = {
  hero: {
    image: "/images/Makkah/Ghar-e-Hira/hira.svg",
    heading: "Ghar e Hira",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/Makkah/Ghar-e-Sur/surone.svg",
  },
  why: {
    image: "/images/Makkah/Ghar-e-Sur/surtwo.svg",
  },
  pilgrimge: {
    image: "/images/Makkah/Ghar-e-Sur/surthree.svg",
  },

  pilgrimge2: {
    image: "/images/Makkah/Ghar-e-Sur/surfour.svg",
  },
};
export const gharSur = {
  hero: {
    image: "/images/Makkah/Ghar-e-Sur/gharsur.svg",
    heading: "Ghar e Sur",
  },
  history: {
    image: "/images/Makkah/Ghar-e-Sur/surone.svg",
  },
  why: {
    image: "/images/Makkah/Ghar-e-Sur/surtwo.svg",
  },
  pilgrimage: {
    image: "/images/Makkah/Ghar-e-Sur/surthree.svg",
  },
  pilgrimage2: {
    image: "/images/Makkah/Ghar-e-Sur/surfour.svg",
  },
};

export const muhammad = {
  hero: {
    image: "/images/Makkah/ProphetHouse/muhammad1.svg",
    subHeading: "Home of Hazrat",
    heading: "Holy Prophet Hazrat Muhammad (S.A.W) House",
    subHeading2: "(SAW)",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/Makkah/ProphetHouse/muhammad2.svg",
  },
  why: {
    image: "/images/Makkah/ProphetHouse/muhammad3.svg",
  },
  pilgrimge: {
    image: "/images/Makkah/ProphetHouse/prophet1.jpg",
  },
  pilgrimge2: {
    image: "/images/Makkah/ProphetHouse/prophet2.jpg",
  },
};

export const taif = {
  hero: {
    image: "/images/taif1.svg",
    heading: "Taif",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/taif2.png",
  },
  why: {
    image: "/images/taif3.svg",
  },
  pilgrimge: {
    image: "/images/taif4.svg",
  },
  pilgrimge2: {
    image: "/images/taif5.svg",
  },
};

export const alHudaybiyah = {
  hero: {
    image: "/images/Makkah/AlHudaybiyah/huda1.svg",
    subHeading: "Treaty of",
    heading: "Al-Hudaybiyah",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/Makkah/AlHudaybiyah/huda2.svg",
  },
  why: {
    image: "/images/Makkah/AlHudaybiyah/huda3.png",
  },
  pilgrimge: {
    image: "/static/media/taif4.9d470df2907f7b97250fe2b74e144ac1.svg",
  },

  pilgrimge2: {
    image: "/static/media/taif5.48409f7d2b82604c87103ec6a709cdbc.svg",
  },
};

export const uhud = {
  hero: {
    image: "/images/uhud1.svg",
    subHeading: "Battle of",
    heading: "Uhud",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/uhud2.svg",
  },
  why: {
    image: "/images/uhud3.svg",
  },
  pilgrimge: {
    image: "/images/uhud4.webp",
  },
};

export const bigghars = {
  hero: {
    image: "/images/ghar1.svg",
    heading: "Bir Ghars  ",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/ghar2.svg",
  },
  why: {
    image: "/images/ghar3.svg",
  },
  pilgrimge: {
    image: "/static/media/taif4.9d470df2907f7b97250fe2b74e144ac1.svg",
  },

  pilgrimge2: {
    image: "/static/media/taif5.48409f7d2b82604c87103ec6a709cdbc.svg",
  },
  pilgrimge3: {
    image: "/images/ghars4.jpg",
  },
  pilgrimge4: {
    image: "/images/ghars5.jpg",
  },
};

export const MasjidQiblatain = {
  hero: {
    image: "/images/qibla.svg",
    heading: "Masjid al-Qiblatain",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/qibla1.png",
  },
  why: {
    image: "/images/qibla2.svg",
  },
  pilgrimge: {
    image: "/images/qibla3.svg",
  },
  pilgrimge2: {
    image: "/static/media/taif5.48409f7d2b82604c87103ec6a709cdbc.svg",
  },
};

export const Wadijin = {
  hero: {
    image: "/images/Wadijin1.svg",
    heading: "Wadi-e-Jinn",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/Wadijin2.svg",
  },
  why: {
    image: "/images/Wadijin3.svg",
  },
  pilgrimge: {
    image: "/images/Wadijin4.svg",
  },
  pilgrimge2: {
    image: "/images/Wadijin5.svg",
  },
  pilgrimge3: {
    image: "/images/Wadijin6.jpeg",
  },
};

export const MasjidMiqat = {
  hero: {
    image: "/images/miqat1.svg",
    heading: "Masjid Miqat",
    subHeading2: "(Dhul Hulaifah)",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/miqat2.svg",
  },
  why: {
    image: "/images/miqat3.svg",
  },
  pilgrimge: {
    image: "/images/miqat5.jpg",
  },
  pilgrimge2: {
    image: "/images/miqat6.webp",
  },
};

export const MasjidGhamama = {
  hero: {
    image: "/images/MasjidGhamama1.svg",
    heading: "Masjid al-Ghamama",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/MasjidGhamama2.svg",
  },
  why: {
    image: "/images/MasjidGhamama3.svg",
  },
  pilgrimge: {
    image: "/images/MasjidGhamama4.svg",
  },
  pilgrimge2: {
    image: "/images/MasjidGhamama5.jpg",
  },
  pilgrimge3: {
    image: "/images/MasjidGhamama6.webp",
  },
};

export const MasjidJummah = {
  hero: {
    image: "/images/masjidjummah1.svg",
    heading: "Masjid-al-Jummah",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/masjidjummah2.svg",
  },
  why: {
    image: "/images/masjidjummah3.svg",
  },
  pilgrimge: {
    image: "/images/masjidjummah4.svg",
  },
  pilgrimge2: {
    image: "/images/masjidjummah5.jpg",
  },
};

export const MasjidMustarah = {
  hero: {
    image: "/images/MasjidMustarah1.svg",
    heading: "Masjid al-Mustarah",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/MasjidMustarah2.svg",
  },
  why: {
    image: "/images/MasjidMustarah3.svg",
  },
  pilgrimge: {
    image: "/images/MasjidMustarah4.svg",
  },
  pilgrimge2: {
    image: "/images/MasjidMustarah5.jpg",
  },
  pilgrimge3: {
    image: "/images/MasjidMustarah6.jpg",
  },
};

export const MasjidAbubakar = {
  hero: {
    image: "/images/abubakar1.svg",
    heading: "Masjid Abu Bakr",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/abubakar2.svg",
  },
  why: {
    image: "/images/abubakar3.svg",
  },
  pilgrimge: {
    image: "/images/abubakar4.jpg",
  },
  pilgrimge2: {
    image: "/images/abubakar5.jpg",
  },
  pilgrimge3: {
    image: "/images/abubakar6.jpg",
  },
};

export const MasjidEjabah = {
  hero: {
    image: "/images/MasjidEjabah1.jpg",
    heading: "Masjid Ejabah",
    subHeading2: "(رضي الله عنه)",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/MasjidEjabah2.svg",
  },
  why: {
    image: "/images/MasjidEjabah3.svg",
  },
  pilgrimge: {
    image: "/images/ejabah2.jpg",
  },
  pilgrimge2: {
    image: "/images/ejabah3.jpg",
  },
  pilgrimge3: {
    image: "/images/ejabah4.jpg",
  },
};

export const MasjidJinn = {
  hero: {
    image: "/images/Makkah/MasjidJinn/MasjidJinn.webp",
    heading: "Masjid al-Jinn",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/Makkah/MasjidJinn/MasjidJinn1.svg",
  },
  why: {
    image: "/images/Makkah/MasjidJinn/MasjidJinn2.svg",
  },
  masjidjinn1: {
    image: "/images/Makkah/MasjidJinn/MasjidJinn3.svg",
  },

  masjidjinn3: {
    image: "/images/Makkah/MasjidJinn/MasjidJinn5.webp",
  },
};

export const MasjidNimra = {
  hero: {
    image: "/images/nimra1.svg",
    heading: " Masjid-e-Nimra",
    description: "5 minutes read - April 2024",
  },
  history: {
    image: "/images/nimra2.svg",
  },
  why: {
    image: "/images/nimra3.svg",
  },
  pilgrimge: {
    image: "/images/nimra4.svg",
  },
  nimra1: {
    image: "/images/nimra5.jpg",
  },
  nimra2: {
    image: "/images/nimra6.jpg",
  },
  nimra3: {
    image: "/images/nimra7.jpg",
  },
  nimra4: {
    image: "/images/nimra8.jpg",
  },
  nimra5: {
    image: "/images/nimra9.jpg",
  },
};
