import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../../assets/offer_img/medicalimg.png";
import image2 from "../../../assets/offer_img/insurance.png";
import { NavLink } from "react-router-dom";

const offers = [
  {
    imageUrl: image1,
    title: "Get Free Personal & Medical Assistance",
    subtitle: "Promotions, deals and special offers for you",
  },
  {
    imageUrl: image2,
    title: "Get Free Insurance",
    subtitle: "Promotions, deals and special offers for you",
  },
];

const OffersSlider = () => (
  <div className="mt-10">
    <div className="w-[85%] mx-auto">
      <h2 className="text-2xl font-bold text-[#484848] mb-1 font-cairo">
        Offers
      </h2>
      <p className="text-base font-medium text-[#484848] mb-5 font-cairo">
        Exclusive Benefits for Your Sacred Journey
      </p>
      <div className="grid grid-cols-1 md:gap-[20px] md:grid-cols-2 space-y-[20px] md:space-y-0">
        {offers.map(({ imageUrl, title, subtitle }, index) => (
          <div key={index} className="outline-none flex items-stretch h-full">
            <div
              className={`${
                index === 0 ? "bg-cover" : "bg-white"
              } rounded-lg shadow-lg border-2 border-gray-500 border-opacity-10 overflow-hidden p-6 flex justify-between items-center w-full`}
              style={
                index === 0
                  ? {
                      backgroundImage: `url(${imageUrl})`,
                      backgroundRepeat: `no-repeat`,
                    }
                  : {}
              }
            >
              <div>
                <h3 className="lg:text-xl text-sm font-bold text-[#484848] font-cairo">
                  {title}
                </h3>
                <p className="text-gray-600 text-xs md:text-sm font-cairo font-medium">
                  {subtitle}
                </p>
                <NavLink to={"/error"}>
                  <button className="text-white bg-[#00936c] opacity-90 hover:bg-green-800 text-sm p-2 rounded-md mt-4">
                    Explore More
                  </button>
                </NavLink>
              </div>
              {index !== 0 && (
                <div className="flex-shrink-0 ml-4">
                  <img
                    src={imageUrl}
                    alt={`Offer ${index + 1}`}
                    className="lg:h-24 lg:w-24 xl:w-32 xl:h-28 object-cover rounded-md"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default OffersSlider;
