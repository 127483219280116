import React from "react";
import icon from "../assets/NoBookingIcon.svg";

const NoBookings = () => {
  return (
    <div className="flex flex-col space-y-4 font-kd justify-center w-[50%] items-center text-center min-h-screen bg-white mx-auto w-full">
      <img src={icon} alt="No Bookings Icon" className="w-24 h-24" />
      <p className="text-xl text-[#4b465c] font-bold">Your bookings show here
      </p>
      <p className=" text-sm text-[#4b465c] ">
        This page shows all your bookings. If you made a booking but don't see it here, you can import it using your booking confirmation number and PIN.  </p>
    </div>
  );
};

export default NoBookings;
