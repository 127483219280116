import React, { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { muhammad } from "../../../../../../data";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Page = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <h1 className="font-cairo bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        The distance of Prophet (S.A.W) house between Mecca and Medina is about
        340 kilometers (211 miles) by road.
      </h1>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
          <h1 className="font-cairo text-[25px] font-bold pb-1">History</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
              <p>
                The history of the Prophet Muhammad's (peace be upon him) home
                in Medina, known as the Prophet's House, is intertwined with the
                migration (Hijrah) from Mecca to Medina. The distance between
                Mecca and Medina played a significant role in this historical
                event. <br />
                In 622 CE, facing persecution and hostility from the Quraysh
                tribe in Mecca, the Prophet Muhammad and his followers sought
                refuge in Medina, then known as Yathrib. The journey from Mecca
                to Medina marked the beginning of a new phase in Islamic history
                and the establishment of the first Islamic community. <br />
                The distance between Mecca and Medina, approximately 340
                kilometers (around 211 miles) by road, was covered by the
                Prophet Muhammad and his companion Abu Bakr over several days,
                taking a route that bypassed major trade routes to avoid
                detection by the Quraysh who were pursuing them.
              </p>
              <p>
                We strive so much to be successful in this dunya until we work
                hard to get the big house, big cars, good successful careers.
                However, have we ever noticed how our beloved Prophet Muhammad
                lives?
              </p>
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              <img
                src={muhammad.history.image}
                alt=""
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
        </div>

        <div className="py-10 ">
          <h1 className="font-cairo text-[25px] font-bold pb-1">
            {" "}
            Reasons why people visit the Home of HAZRAT MUHAMMAD (SAW)
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              <img
                src={muhammad.pilgrimge.image}
                alt=""
                className="w-full rounded-lg"
              />
            </div>
            <div className="lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
              <p>
                People visit the home of the Prophet Muhammad (peace be upon
                him) in Medina, known as the Prophet's House, for several
                purposes, each deeply rooted in religious devotion and spiritual
                significance:
              </p>
              <p>
                People visit the home of Hazrat Muhammad (SAW) to connect
                spiritually, honor the historical significance of Islam, perform
                religious duties during Hajj or Umrah, seek blessings, educate
                themselves, show respect, reflect personally, and engage in
                cultural exchange with fellow Muslims. These visits deepen their
                faith and understanding of the Prophet's life and teachings.
              </p>
              <p>
                Additionally, these visits offer a chance to experience the
                tranquility and reverence of a place that holds immense
                historical and religious importance. It provides an opportunity
                to step into the very footsteps of the Prophet, enriching their
                spiritual journey.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h1 className="font-cairo text-[25px] font-bold pb-1">
            {" "}
            Spiritual Pilgrimage:
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
              <p>
                Many Muslims visit the Prophet's Mosque (Masjid an-Nabawi) in
                Medina as part of their religious pilgrimage, known as Umrah or
                Ziyarah. They seek to pay their respects to Prophet Muhammad
                (SAW) and offer prayers at his mosque, which includes his home
                as a sacred site. This pilgrimage allows them to connect deeply
                with their faith, reflect on the Prophet's life and teachings,
                and seek spiritual blessings. The mosque, being the second
                holiest site in Islam, provides a profound sense of peace and
                devotion for those who visit.
              </p>
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              <img
                src={muhammad.hero.image}
                alt=""
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="py-10">
          <h2 className=" text-[25px] font-bold pb-1">Religious Observance:</h2>
          <p className="font-cairo font-normal text-[15px] py-4">
            Jabal ar-Rahmah holds significant importance in Islam for several
            reasons. It is believed to be the place where Prophet Adam (peace be
            upon him) and Eve were reunited after being expelled from Paradise
            and where they sought forgiveness from Allah, marking a moment of
            profound repentance and mercy. Additionally, Jabal ar-Rahmah is
            historically significant as the site where Prophet Muhammad (peace
            be upon him) delivered his farewell sermon during his final
            pilgrimage, emphasizing key tenets of Islam and unity among Muslims.
            Pilgrims visit this mountain to reflect on these pivotal events,
            seek spiritual blessings, and strengthen their faith. The mountain's
            historical and religious importance makes it a focal point for
            prayer, reflection, and connection to the Islamic heritage.
          </p>
        </div>

        <div>
          <h2 className="font-cairo text-[25px] font-bold">
            Connection to Islamic History:
          </h2>
          <p className="font-cairo font-normal text-[15px] pt-2.5">
            The Prophet's House holds immense historical significance as the
            residence of the Prophet Muhammad during his time in Medina.
            Visitors are drawn to the site to learn about the life and teachings
            of the Prophet and to connect with the rich history of Islam.
          </p>
        </div>
        <div className="slider-container relative py-10">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={muhammad.hero.image}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={muhammad.history.image}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={muhammad.pilgrimge.image}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={muhammad.pilgrimge2.image}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page;
