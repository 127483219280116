import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          The Geographical Location of the Globe Roundabout
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction to the Globe Roundabout
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                The Globe Roundabout, also known as the Globe Roundabout
                Monument, is located at a prominent intersection in the bustling
                city of Jeddah, Saudi Arabia. This iconic landmark is situated
                at the junction of Al-Malek Road and Al-Andalus Street, two
                major thoroughfares in the city. The roundabout is a key point
                of reference for both locals and visitors, acting as a gateway
                to Jeddah’s commercial and residential districts.
                <br />
                Introduction to the Globe Roundabout
                <br />
                The Globe Roundabout is one of Jeddah’s most recognizable
                landmarks. It features a massive globe sculpture, symbolizing
                the city's status as a major international hub and its
                historical role as a gateway to the holy cities of Mecca and
                Medina. The roundabout is not just a traffic junction but a
                symbolic representation of Jeddah’s modernity and global
                connections.
                {/*  */}
                <br />
                <p className="lg:hidden xl:block">
                  The design of the Globe Roundabout is both striking and
                  symbolic. The globe itself is an impressive piece of art,
                  meticulously crafted to represent the Earth. It stands as a
                  testament to Jeddah’s modernity and its aspirations on the
                  global stage. The surrounding landscaping and urban design
                  elements enhance the aesthetic appeal of the roundabout,
                  making it a visually captivating landmark that attracts
                  photographers and tourists alike.
                </p>
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/globe/image2.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            The design of the Globe Roundabout is both striking and symbolic.
            The globe itself is an impressive piece of art, meticulously crafted
            to represent the Earth. It stands as a testament to Jeddah’s
            modernity and its aspirations on the global stage. The surrounding
            landscaping and urban design elements enhance the aesthetic appeal
            of the roundabout, making it a visually captivating landmark that
            attracts photographers and tourists alike.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Background
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The Globe Roundabout was designed by the Spanish architect Julio
              Lafuente, who is known for his contributions to urban design in
              various parts of the world. The construction of the monument was
              part of Jeddah’s urban development initiatives aimed at
              beautifying the city and enhancing its infrastructure. Since its
              completion, the Globe Roundabout has become an integral part of
              Jeddah’s cityscape, reflecting the city’s aspirations and growth.
              Architectural Features The centerpiece of the Globe Roundabout is
              a large globe sculpture, which is elevated on a pedestal. The
              globe is crafted from metal and features detailed representations
              of the continents, highlighting the interconnectedness of the
              world. Surrounding the globe are well-manicured gardens and green
              spaces, adding to the aesthetic appeal of the roundabout. The
              design is both functional and artistic, facilitating smooth
              traffic flow while serving as a visual delight for passersby.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/globe/image4.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Architectural Features
          </h2>
          <div className="mt-4">
            <p>
              The centerpiece of the Globe Roundabout is a large globe
              sculpture, which is elevated on a pedestal. The globe is crafted
              from metal and features detailed representations of the
              continents, highlighting the interconnectedness of the world.
              Surrounding the globe are well-manicured gardens and green spaces,
              adding to the aesthetic appeal of the roundabout. The design is
              both functional and artistic, facilitating smooth traffic flow
              while serving as a visual delight for passersby. Symbolism and
              Cultural Significance The Globe Roundabout symbolizes Jeddah’s
              international character and its historical significance as a port
              city. For centuries, Jeddah has been a melting pot of cultures,
              attracting pilgrims, traders, and travelers from around the world.
              The globe represents this rich cultural tapestry and the city’s
              role in connecting different parts of the world. It also reflects
              Saudi Arabia’s vision of modernization and its efforts to foster
              global partnerships. The Role of the Globe Roundabout Today Today,
              the Globe Roundabout serves as a landmark for navigation and a
              symbol of pride for the residents of Jeddah. It is a popular spot
              for photography, especially at night when the monument is
              beautifully illuminated. The roundabout is also a testament to
              Jeddah’s commitment to urban development and its vision of
              becoming a leading global city.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
