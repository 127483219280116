import React from 'react';

const EmailNotificationsPage = () => {
  const emailPreferences = [
    {
      label: 'Email preferences',
      value: 'ht.coc147@gmail.com',
      description: "This is the email address you use to sign in. It's also where we send your booking confirmations.",
      action: 'Manage'
    }
  ];

  return (
    <div className="w-full">
      <h1 className="text-3xl font-bold mb-4">Email notifications</h1>
      <p className="text-base text-gray-600 mb-6">Decide what you want to be notified about, and unsubscribe from what you don't.</p>
      <div className="space-y-4">
        {emailPreferences.map((preference, index) => (
          <div key={index} className="flex justify-between items-center border-b pb-4">
            <div>
              <p className="">{preference.label}</p>
              <p className="text-sm font-light text-gray-600">{preference.value}</p>
              <p className="text-sm text-gray-500">{preference.description}</p>
            </div>
            <a href="#" className="text-sm font-light text-[#00936C]">{preference.action}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailNotificationsPage;
