import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Footer from "../../../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { createBookingRequestbyTransactionPhoto } from "../../../utility/Api";
import voucher_img from "../../../assets/assets/img/Voucher.svg";
import { NumericFormat } from "react-number-format";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Loader from "../../../components/loader";
const VoucherDetail = ({ pkg }) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    bookingResponse,
    totalAdults,
    totalChild,
    totalInfants,
    roomType,
    totalCost,
  } = location.state || {};
  console.log(bookingResponse);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [errors, setErrors] = useState({});
  const [previewFile, setPreviewFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const session_token =
    JSON.parse(localStorage.getItem("Login-User-Profile"))?.session_token ||
    JSON.parse(localStorage.getItem("New-User-Profile"))?.session_token;
  const user_name = JSON.parse(
    localStorage.getItem("Login-User-Profile")
  )?.name;
  const handleFileChange = (e) => {
    const inputSelectedFile = e.target.files[0];
    setSelectedFiles(inputSelectedFile);
    setPreviewFile(URL.createObjectURL(inputSelectedFile));
    validateFile(inputSelectedFile);
  };

  const validateFile = (file) => {
    if (!file) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: "Please upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    validateFile(selectedFiles);

    if (errors.file) {
      return;
    }

    try {
      if (selectedFiles) {
        const formData = new FormData();
        formData.append("session_token", session_token);
        formData.append("booking_number", bookingResponse.booking_number);
        formData.append("transaction_photo", selectedFiles);
        formData.append("transaction_amount", totalCost);
        await createBookingRequestbyTransactionPhoto(formData);
        navigate("/allbookingsinfo", {
          state: { transaction_image: selectedFiles },
        });
      }
    } catch (error) {
      console.error("Error creating booking request:", error);
    }
    setIsLoading(false);
  };

  const sanitizedBookingResponse = {
    ...bookingResponse,
    mecca_nights: Number(bookingResponse.mecca_nights),
    madinah_nights: Number(bookingResponse.madinah_nights),
    total_price: totalCost,
    adults: totalAdults,
    child: totalChild,
    infants: totalInfants,
    room_type: roomType,
  };

  return (
    <div className="text-[#484848] border-[#B2ABAB]">
      <BookingNavbar />
      <div className="space-y-5 font-kd w-[85%] mx-auto py-10">
        <div className="md:flex w-full justify-between">
          <div className="xl:w-full justify-end">
            <div className="md:max-w-[75%] bg-white space-y-5">
              <h1 className="text-sm md:text-xl font-bold opacity-80">
                Thank You {user_name}!
              </h1>
              <div>
                <div className="space-y-2 text-sm opacity-80">
                  <p>Your order has been submitted.</p>
                  <p>Your order number is {bookingResponse.booking_number}.</p>
                  <p>
                    Total due amount is{" "}
                    <NumericFormat
                      className="font-bold text-[16px]"
                      value={sanitizedBookingResponse.total_price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"PKR "}
                    />
                  </p>
                  <p className="pt-10">Download Voucher and Pay</p>
                  <div className="flex gap-4 border-[1px] p-2 rounded-md">
                    <img src={voucher_img} alt="" />
                  </div>

                  <label htmlFor="fileInput">
                    <div
                      className={`h-24 w-full border border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center cursor-pointer relative`}
                    >
                      <img
                        src="/images/uploadlogo.png"
                        alt="Custom Icon"
                        className="h-6 w-6 text-gray-500 mb-2"
                      />
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />

                      <p className="text-gray-600 font-light">
                        Drop files here or click to upload
                      </p>
                    </div>
                  </label>
                  {previewFile && (
                    <img src={previewFile} alt="Preview" className="w-[20%]" />
                  )}
                  {errors.file && (
                    <p className="text-red-500 text-xs">{errors.file}</p>
                  )}

                  <div className="space-y-4 mt-4">
                    <div className=" mx-auto my-5">
                      <button
                        className={`w-full text-sm py-2 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
                          isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[40%] md:w-1/2">
            <SideBar
              pkg={{
                package_name: sanitizedBookingResponse.package_name,
                package_description:
                  sanitizedBookingResponse.package_description,
                mecca_nights: sanitizedBookingResponse.mecca_nights,
                madinah_nights: sanitizedBookingResponse.madinah_nights,
                package_cost: sanitizedBookingResponse.package_cost,
                start_date: sanitizedBookingResponse.start_date,
                end_date: sanitizedBookingResponse.end_date,
                is_package_open_for_other_date:
                  sanitizedBookingResponse.is_package_open_for_other_date,
              }}
              checkInDate={sanitizedBookingResponse.start_date}
              checkOutDate={sanitizedBookingResponse.end_date}
              totalAdults={sanitizedBookingResponse.adults}
              totalChildren={sanitizedBookingResponse.child}
              totalInfants={sanitizedBookingResponse.infants}
              roomType={sanitizedBookingResponse.room_type}
              totalCost={sanitizedBookingResponse.total_price}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VoucherDetail;
