import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/MasjidQuba1.svg",
    process.env.PUBLIC_URL + "/images/MasjidQuba2.svg",
    process.env.PUBLIC_URL + "/images/MasjidQuba3.svg",
    process.env.PUBLIC_URL + "/images/MasjidQuba4.svg",
    process.env.PUBLIC_URL + "/images/MasjidQuba6.jpg",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner image={"/images/MasjidQuba.svg"} title={"Masjid Quba"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
