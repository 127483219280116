import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Discovering Al Balad: Jeddah's Historic Heart
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction to Al Balad
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Al Balad, meaning "The City" in Arabic, is the historic heart of
                Jeddah, Saudi Arabia. This vibrant district is renowned for its
                rich cultural heritage, traditional architecture, and bustling
                souqs (markets). As a UNESCO World Heritage site, Al Balad
                attracts visitors seeking to explore its labyrinthine alleyways,
                ancient buildings, and authentic Arabian charm.
                <br />
                Geographical Location and Layout
                <br />
                Situated along the Red Sea coast, Al Balad occupies a strategic
                location that has been pivotal to Jeddah's development as a
                trading port and cultural crossroads. The district extends from
                the waterfront inland, encompassing a maze of narrow streets
                lined with coral stone houses adorned with intricately carved
                wooden balconies known as rawasheen. Al Balad's layout reflects
                its historical function as a commercial hub and residential
                area, offering a glimpse into traditional Arabian urban
                planning.
              </p>
              <p className="text-[#484848] lg:hidden xl:block mb-4 font-cairo font-medium text-[15px]">
                One of the defining features of Al Balad is its distinctive
                architecture. The houses in Al Balad are built from coral stones
                and feature wooden rawasheen (bay windows) that are intricately
                carved and painted. These structures not only showcase the
                craftsmanship of their builders but also reflect the adaptation
                to Jeddah’s climate, with the rawasheen providing ventilation
                and shade.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/flagpole/image1.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 font-cairo font-medium text-[15px]">
            One of the defining features of Al Balad is its distinctive
            architecture. The houses in Al Balad are built from coral stones and
            feature wooden rawasheen (bay windows) that are intricately carved
            and painted. These structures not only showcase the craftsmanship of
            their builders but also reflect the adaptation to Jeddah’s climate,
            with the rawasheen providing ventilation and shade.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Significance and Heritage
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Al Balad's history dates back centuries, with its origins rooted
              in the early settlement of Jeddah. Over the years, the district
              has served as a vital center for trade, pilgrimage, and cultural
              exchange. Its architecture, characterized by the use of locally
              sourced coral stones, showcases the craftsmanship of Hijazi
              builders and the adaptation to the region's climate. Many
              buildings in Al Balad have been meticulously preserved, providing
              insights into the daily life and cultural traditions of past
              generations. Architectural Highlights One of the defining features
              of Al Balad is its distinctive architecture, which combines
              elements of Islamic, Ottoman, and traditional Hijazi styles. The
              coral stone buildings, adorned with rawasheen and intricately
              patterned doors, create a visually striking backdrop against the
              blue skies and bustling streets. The architecture not only
              reflects aesthetic beauty but also serves practical purposes, such
              as natural cooling and ventilation—a testament to the ingenuity of
              Arabian builders.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/flagpole/image3.jpeg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural Attractions and Points of Interest
          </h2>
          <div className="mt-4">
            <p>
              Al Balad is home to several cultural attractions that offer
              visitors a deeper understanding of Saudi Arabia's history and
              heritage. Key landmarks include historic mosques such as the
              Al-Shafei Mosque and the Al-Masjid Al-Kabir (Grand Mosque), which
              have played significant roles in the religious and social life of
              the community. The district also boasts traditional souqs like
              Souq Al Alawi, where visitors can browse through stalls selling
              spices, textiles, jewelry, and handicrafts—a true feast for the
              senses. Preservation Efforts and Tourism In recent years, efforts
              have been made to preserve Al Balad's architectural heritage and
              promote sustainable tourism. Restoration projects have revitalized
              historic buildings, while cultural initiatives and festivals
              celebrate the district's cultural diversity and artistic heritage.
              Tourists flock to Al Balad to experience its authentic ambiance,
              explore its winding alleyways, and admire its architectural
              splendor—a journey back in time amidst the modern cityscape of
              Jeddah.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
