import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sendRatingAndReview } from "../../../../../utility/Api";
import Loader from "../../../../../components/loader";
import BookingNavbar from "../../../../../components/Navbar/BookingNavBar";
import Footer from "../../../../../components/footer";
import { IoArrowBack } from "react-icons/io5";
import Slider from "@mui/material/Slider";
import Rating from "react-rating-stars-component";
import DisplayReview from "./DisplayReview";
import { styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)({
  color: "#00936c",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#00b585",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const SubmitReview = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const {
    state: { booking },
  } = useLocation();
  const navigate = useNavigate();

  const [reviewData, setReviewData] = useState({
    huzConcierge: 0,
    huzSupport: 0,
    huzPlatform: 0,
    huzServiceQuality: 0,
    huzResponseTime: 0,
    huzComment: "",
    partnerTotalStars: 0,
    partnerComment: "",
  });

  const [errors, setErrors] = useState({
    huzComment: "",
    partnerComment: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setReviewData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (name, value) => {
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: `${name.split(/(?=[A-Z])/).join(" ")} is required.`,
      }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleStarClick = (value) => {
    setReviewData((prev) => ({ ...prev, partnerTotalStars: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { huzComment, partnerComment } = reviewData;
    let valid = true;

    if (!huzComment) {
      setErrors((prev) => ({
        ...prev,
        huzComment: "Huz Comment is required.",
      }));
      valid = false;
    }

    if (!partnerComment) {
      setErrors((prev) => ({
        ...prev,
        partnerComment: "Partner Comment is required.",
      }));
      valid = false;
    }

    if (valid) {
      setSubmitting(true);
      try {
        await sendRatingAndReview(
          booking.user_session_token,
          booking.booking_number,
          ...Object.values(reviewData)
        );
        alert("Review submitted successfully!");
        navigate(-1); // Go back to the previous page
      } catch (error) {
        console.error("Error submitting review:", error);
        alert("Failed to submit review. Please try again.");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const existingReview = booking.booking_rating?.[0];

  return (
    <div>
      <BookingNavbar />
      <div>
        <img
          src="/images/Booking/BannerImage.png"
          alt="Banner"
          className="w-full"
        />
      </div>
      <div className="container mx-auto py-10">
        <div className="mb-10 flex items-center gap-3">
          <button
            onClick={() => navigate(-1)}
            className="p-1 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300"
          >
            <IoArrowBack size={20} />
          </button>
          <h1 className="text-[28px] text-[#484848] font-bold">
            Submit a Review
          </h1>
        </div>
        {existingReview ? (
          <DisplayReview review={existingReview} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-white p-8 rounded-lg shadow-md"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { field: "huzConcierge", label: "Concierge Rating" },
                { field: "huzSupport", label: "Support Rating" },
                { field: "huzPlatform", label: "Platform Rating" },
                { field: "huzServiceQuality", label: "Service Quality Rating" },
                { field: "huzResponseTime", label: "Response Time Rating" },
              ].map(({ field, label }, index) => (
                <div key={index}>
                  <label className="block text-gray-700 font-semibold">
                    {label}
                  </label>
                  <CustomSlider
                    value={reviewData[field]}
                    min={1}
                    max={5}
                    step={1}
                    onChange={(e, value) => handleChange(field, value)}
                    valueLabelDisplay="auto"
                  />
                </div>
              ))}
              <div className="md:col-span-2">
                <label className="block text-gray-700 font-semibold">
                  Huz Comment
                </label>
                <textarea
                  name="huzComment"
                  value={reviewData.huzComment}
                  onChange={(e) => handleChange("huzComment", e.target.value)}
                  onBlur={() => handleBlur("huzComment", reviewData.huzComment)}
                  className="mt-2 p-3 block w-full border rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                  placeholder="Write your comment here"
                />
                {errors.huzComment && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.huzComment}
                  </p>
                )}
              </div>
            </div>
            <div>
              <label className="block text-gray-700 font-semibold">
                Partner Total Stars
              </label>
              <Rating
                name="partnerTotalStars"
                count={5}
                value={reviewData.partnerTotalStars}
                onChange={handleStarClick}
                size={30}
                activeColor="#ffd700"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-semibold">
                Partner Comment
              </label>
              <textarea
                name="partnerComment"
                value={reviewData.partnerComment}
                onChange={(e) => handleChange("partnerComment", e.target.value)}
                onBlur={() =>
                  handleBlur("partnerComment", reviewData.partnerComment)
                }
                className="mt-2 p-3 block w-full border rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Write your comment here"
              />
              {errors.partnerComment && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.partnerComment}
                </p>
              )}
            </div>
            <div>
              <button
                type="submit"
                className="w-full p-3 bg-[#00936c] text-white rounded-lg shadow-md hover:bg-green-800 flex justify-center items-center"
                disabled={submitting}
              >
                {submitting ? <Loader /> : "Submit Review"}
              </button>
            </div>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SubmitReview;
