import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Card from "./Card"; // Adjust the import path as needed
import "../../../pages/UserPanel/homepage/swiper.css";

const HajjTabs = ({ packages }) => {
  const [swiper, setSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const updateButtonVisibility = (index, total) => {
    if (!swiper) return;
    prevButtonRef.current.classList.toggle("hidden", index === 0);
    nextButtonRef.current.classList.toggle("hidden", index === total - 1);
  };

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
    updateButtonVisibility(swiper.activeIndex, swiper.slides.length);
  };

  return (
    <div className="w-[85%] mx-auto pt-10 relative">
      <div className="flex justify-between items-center mb-2">
        <div>
          <h2 className="text-2xl text-[#484848] font-bold mb-1">Hajj Packages</h2>
          <p className="text-[#484848] text-sm lg:text-md mb-4">
            Travellers with similar searches booked these packages
          </p>
        </div>
        <a href="/hajj" className="text-blue-500 hover:text-blue-800 block mt-12 md:mt-0">More</a>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation={{ prevEl: prevButtonRef.current, nextEl: nextButtonRef.current }}
        className="mySwiper swiper-container"
        breakpoints={{
          1440: { slidesPerView: 4, spaceBetween: 10 },
          1024: { slidesPerView: 4, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 30 },
        }}
        scrollbar={{ draggable: true }}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
      >
        {packages.map((pkg, index) => (
          <SwiperSlide key={index}>
            <Card {...pkg} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-navigation">
        <div ref={prevButtonRef} className="swiper-button-prev swiper-container hidden" onClick={() => swiper.slidePrev()} style={{ top: 250 }} />
        <div ref={nextButtonRef} className="swiper-button-next swiper-container hidden" onClick={() => swiper.slideNext()} style={{ top: 250 }} />
      </div>
    </div>
  );
};

export default HajjTabs;
