import React, { useEffect } from "react";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
// import Banner from "./Banner";
import MainPage from "./MainPage";
import Slider from "./Slider";
import Banner from "../../../../../../components/HeroSection/Banner";

const WadijinMainPage = () => {
  const images = [
    process.env.PUBLIC_URL + "/images/Madinah/wadijin/sliderimg1.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/wadijin/sliderimg2.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/wadijin/sliderimg3.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/wadijin/sliderimg4.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/wadijin/sliderimg5.jpg",
  ];
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <HeaderFooter>
        <Banner
          image={"/images/Madinah/wadijin/bannerbg.png"}
          title={"Wadi-e-Jinn"}
        />
        <MainPage />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default WadijinMainPage;
