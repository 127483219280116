import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import PackageCard from "./PackageCard";
import Filters from "./Filters";
import SortDropdown from "./Sorting";

const PackagesPage = ({ isModalOpen, setIsModalOpen, packages }) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  const defaultFilters = {
    departureCity: "",
    departureDate: "",
    duration: "",
    roomType: "",
    mealsIncluded: false,
    tourGuideIncluded: false,
    sortBy: "priceLowToHigh", // Default sort is price low to high
  };

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilterChange = (key, value) =>
    setFilters((prev) => ({ ...prev, [key]: value }));

  const areFiltersDefault = (filters) => {
    for (const key in defaultFilters) {
      if (JSON.stringify(filters[key]) !== JSON.stringify(defaultFilters[key])) {
        return false;
      }
    }
    return true;
  };

  const filteredPackages = useMemo(() => {
    if (areFiltersDefault(filters)) {
      return packages;
    }

    const filtered = packages.filter((pkg) => {
      const matchesCity =
        !filters.departureCity ||
        pkg.company_detail.company_name
          .toLowerCase()
          .includes(filters.departureCity.toLowerCase());
      const matchesDate =
        !filters.departureDate ||
        new Date(pkg.start_date) >= new Date(filters.departureDate);
      const matchesDuration =
        !filters.duration ||
        pkg.mecca_nights + pkg.madinah_nights === parseInt(filters.duration);
      const matchesRoomType =
        !filters.roomType ||
        pkg.hotel_info_detail.some(
          (hotel) =>
            hotel.room_sharing_type.toLowerCase() ===
            filters.roomType.toLowerCase()
        );
      const matchesMeals =
        !filters.mealsIncluded ||
        (pkg.is_breakfast_included ||
          pkg.is_lunch_included ||
          pkg.is_dinner_included);
      const matchesTourGuide =
        !filters.tourGuideIncluded || pkg.is_tour_guide_included;

      return (
        matchesCity &&
        matchesDate &&
        matchesDuration &&
        matchesRoomType &&
        matchesMeals &&
        matchesTourGuide
      );
    });

    // Sorting
    if (filters.sortBy === "priceLowToHigh") {
      filtered.sort((a, b) => a.package_base_cost - b.package_base_cost);
    } else if (filters.sortBy === "priceHighToLow") {
      filtered.sort((a, b) => b.package_base_cost - a.package_base_cost);
    } else if (filters.sortBy === "startDateAsc") {
      filtered.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    } else if (filters.sortBy === "startDateDesc") {
      filtered.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    }

    return filtered;
  }, [packages, filters]);

  return (
    <div className="flex flex-col lg:flex-row gap-4 mx-auto w-[85%] py-10">
      <button
        className="lg:hidden bg-[#00936c] text-white py-2 px-4 rounded-lg mb-4"
        onClick={() => setIsModalOpen(true)}
      >
        Filter
      </button>

      <div className="hidden lg:block bg-white shadow-lg rounded-lg p-6 w-full border lg:w-1/4 lg:h-auto lg:min-h-[550px]">
        <Filters handleFilterChange={handleFilterChange} filters={filters} />
      </div>

      <div className="flex-grow grid-cols-1 space-y-6">
        <SortDropdown onSortChange={(sortBy) => handleFilterChange("sortBy", sortBy)} />
        {filteredPackages.length > 0 ? (
          <div className="flex-grow grid-cols-1 space-y-6">
            {filteredPackages.map((pkg) => (
              <PackageCard key={pkg.huz_token} pkg={pkg} huz_token={pkg.huz_token} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[70vh] text-center text-[#4b465c] md:text-[24px] xl:text-[32px] font-[600] font-kd">
            <img src="/images/error.svg" alt="No Data" className="w-20 lg:w-28 xl:w-32" />
            <p>There is no data yet, please try again later.</p>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[90%] max-w-md max-h-screen overflow-y-auto p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsModalOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Filters</h2>
            <Filters handleFilterChange={handleFilterChange} filters={filters} />
          </div>
        </div>
      )}
    </div>
  );
};

PackagesPage.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
};

export default PackagesPage;
