import React, { useEffect } from "react";
import { alHudaybiyah } from "../../../../../../data";
import icon from "../../../../../../assets/makkahPageIcon.svg";
const Page = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      <h1 className="font-cairo bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        The village of Hudaybiyyah is located about nine miles (14.5 km) outside
        Mecca
      </h1>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
          <h1 className="font-cairo text-[15px] font-bold pb-1">
            Historical Significance of Treaty of Al-Hudaybiyah
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="md:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
              <p>
                The Treaty of Hudaibiyah, signed in 628 CE (6 AH), marked a
                significant moment in Islamic history. It was an agreement
                between the Prophet Muhammad, representing the Muslim community
                of Medina, and the Quraysh tribe of Mecca. This treaty not only
                established a temporary peace between the two factions but also
                set the stage for the eventual Muslim conquest of Mecca.
              </p>
              <p>
                Its strategic and diplomatic implications had profound effects
                on the spread of Islam and the consolidation of Muslim power in
                the Arabian Peninsula. Upon reaching the outskirts of Mecca, at
                a place called Hudaibiyah, negotiations commenced between the
                Muslims and the Quraysh. The talks were tense and prolonged,
                involving several back-and-forth communications. Eventually, a
                compromise was reached, resulting in the Treaty of Hudaibiyah.
              </p>
              <p>
                In the sixth year of the Hijrah, the Prophet Muhammad (peace be
                upon him) and approximately 1,400 of his followers embarked on a
                pilgrimage to Mecca with the intention of performing Umrah. This
                peaceful pilgrimage was intended to demonstrate the Muslims'
                respect for the Kaaba and their desire to worship without
                conflict. However, the Quraysh tribe, viewing this as a
                potential threat to their control over Mecca, sought to prevent
                the Muslims from entering the city. Upon reaching the outskirts
                of Mecca, at a place called Hudaibiyah, negotiations commenced
                between the Muslims and the Quraysh. The talks were tense and
                prolonged, involving several back-and-forth communications.
                Despite the initial hostility and mistrust, both parties
                recognized the benefits of reaching a peaceful resolution,
                leading to the formulation of the Treaty of Al-Hudaybiyah.
              </p>
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              <img
                src={alHudaybiyah.history.image}
                alt="Wadi-e Jinn"
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* 4 ROWS with GRAY BACKGROUND  */}

        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-gray-100 space-y-4 p-6">
            <h1 className="font-cairo font-bold text-xl">
              Consolidation of Power
            </h1>
            <p className="font-cairo font-medium text-[15px]">
              The Treaty of Hudaibiyah allowed the Muslims to consolidate their
              power without the constant threat of Quraysh aggression. This
              period of peace enabled the Muslims to focus on spreading their
              message and expanding their influence through non-violent means.
            </p>
          </div>
          <div className="border-y-2 border-[#E6F4F0] p-6 space-y-4">
            <h1 className="font-cairo font-bold text-xl ">
              Diplomatic Recognition
            </h1>
            <p className="font-cairo font-medium text-[15px]">
              By negotiating and signing the treaty, the Quraysh implicitly
              recognized the Muslims as a legitimate political and religious
              community. This recognition was a significant step in the
              establishment of Islam as a major force in the Arabian Peninsula.
            </p>
          </div>
          <div className="bg-gray-100 p-6 space-y-4">
            <h1 className="font-cairo font-bold text-xl">Spread of Islam</h1>
            <p className="font-cairo font-medium text-[15px]">
              The peace afforded by the treaty provided an opportunity for Islam
              to spread beyond the confines of Medina. Many tribes across
              Arabia, seeing the strength and resolve of the Muslim community,
              began converting to Islam. This period saw a substantial increase
              in the number of Muslims.
            </p>
          </div>
        </div>

        <div className="space-y-6">
          <div className="mt-12 flex flex-wrap">
            <div className="w-full lg:w-[55%] p-4">
              <h3 className="font-cairo font-bold text-2xl mb-4">
                Key Terms of the Treaty
              </h3>
              <div className="space-y-3">
                <div className="flex gap-2 ">
                  <img src={icon} alt="" className="h-4 mt-1.5" />
                  <p className="font-cairo font-medium text-[15px]">
                    Both parties agreed to a cessation of hostilities for ten
                    years, allowing for a period of peace and stability.
                  </p>
                </div>
                <div className="flex gap-2 ">
                  <img src={icon} alt="" className="h-4 mt-1.5" />
                  <p className="font-cairo font-medium text-[15px]">
                    The Muslims would return to Medina without performing the
                    pilgrimage that year but would be allowed to enter Mecca for
                    three days the following year.
                  </p>
                </div>
                <div className="flex gap-2 ">
                  <img src={icon} alt="" className="h-4 mt-1.5" />
                  <p className="font-cairo font-medium text-[15px]">
                    Tribes were free to align themselves with either the Muslims
                    or the Quraysh without any interference or repercussions.
                  </p>
                </div>
                <div className="flex gap-2 ">
                  <img src={icon} alt="" className="h-4 mt-1.5" />
                  <p className="font-cairo font-medium text-[15px]">
                    Any Muslim fleeing to Medina from Mecca without the
                    permission of their guardian would be returned to the
                    Quraysh, while those fleeing to Mecca from Medina would not
                    be returned to the Muslims.
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> */}
            <div className="w-full lg:w-[45%] flex flex-col justify-center items-center">
              <img
                src="/images/Makkah/AlHudaybiyah/Treaty.png"
                alt="Hudaiba img not found"
              />
            </div>
          </div>
          <div className="bg-[#00936C] text-[#484848] text-center bg-opacity-10 p-10">
            <p className="lg:text-[20px] font-cairo lg:px-7 font-semibold">
              "The Prophet (ﷺ) made peace with them (the Quraish) on the
              condition that they would allow him to visit the Ka'bah (i.e.,
              perform `Umrah) but he did not allow that for them (i.e., the
              pagans of Quraish)."
            </p>
            <p className="lg:text-[23px] text-[#00936c] lg:mt-4 font-cairo lg:px-7 font-bold">
              صحيح البخاري ١٥٩٤
            </p>
          </div>
        </div>
        <div className="mt-12 ">
          <h1 className="font-cairo font-bold text-2xl">
            The Aftermath and Legacy
          </h1>
          <div className="flex flex-col pt-6 lg:gap-4 lg:flex-row">
            <div className="md:w-[55%] ">
              <div className="font-cairo font-medium text-[15px]">
                <p>
                  The treaty held for approximately two years until it was
                  broken by an ally of the Quraysh attacking a tribe allied with
                  the Muslims. This breach gave Prophet Muhammad the pretext to
                  march on Mecca. In 630 CE (8 AH), the Muslims entered Mecca
                  with little resistance, leading to the city's peaceful
                  surrender and the eventual establishment of Islam as the
                  dominant faith in the region. The Treaty of Hudaibiyah is
                  often cited as a masterstroke of diplomacy by Prophet
                  Muhammad. It demonstrated his ability to prioritize long-term
                  strategic goals over immediate gains, ensuring the survival
                  and growth of the Muslim community.
                </p>
                <p>
                  The treaty's emphasis on negotiation and compromise remains a
                  valuable lesson in conflict resolution and diplomacy. The
                  Treaty of Hudaibiyah was a pivotal moment in the history of
                  Islam. It showcased the strategic acumen of Prophet Muhammad
                  and underscored the importance of patience and diplomacy in
                  achieving lasting success. The treaty's legacy is evident in
                  the rapid expansion and consolidation of the Muslim community,
                  ultimately leading to the unification of the Arabian Peninsula
                  under Islam.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 lg:ml-8">
              <img
                src={alHudaybiyah.why.image}
                alt="Wadi-e Jinn"
                className="w-full h-full "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
