import React, { useEffect } from "react";
import Footer from "../../../components/footer";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const facts = [
    { value: "7.7M+", text: "active packages worldwide" },
    {
      value: "100K+",
      text: "cities and towns with active Hajj Umrah listings",
    },
    { value: "220+", text: "countries and regions with Hajj Umrah listings" },
    { value: "1.5B+", text: "Hajj Umrah guest arrivals all-time" },
    { value: "5M+", text: "hosts on Hajj Umrah" },
    { value: "$250B+", text: "earned by hosts, all-time" },
    { value: "$14K", text: "earned by the typical US host in 2023" },
    { value: "$10B+", text: "total taxes collected and remitted globally" },
  ];

  return (
    <div>
      <BookingNavbar />
      <div className="w-full">
        <img
          src="/images/AboutUs/aboutus.jpg"
          alt="About Us"
          className="w-full"
        />
      </div>
      <div>
        <section className="bg-white py-12 px-6">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-2 font-cairo text-gray-700">
              About us
            </h1>
            <p className="text-lg text-gray-700 font-kd">
              Welcome to HajjUmrah.co, your trusted partner in making your
              pilgrimage to the holy cities of Mecca and Medina a fulfilling and
              memorable experience. We specialize in providing comprehensive
              Hajj and Umrah packages tailored to meet the spiritual needs and
              comfort of our valued clients. Our dedicated team of experts
              ensures that every aspect of your journey is meticulously planned
              and executed, from visa processing to accommodation and
              transportation. At HajjUmrah.co, we are committed to facilitating
              a seamless and spiritually enriching pilgrimage, allowing you to
              focus on your devotion and worship. Join us and embark on a
              journey of a lifetime with peace of mind and heart.
            </p>
          </div>
        </section>
        <section className="bg-gray-100 py-12">
          <div className="w-4/5 mx-auto">
            <h2 className="text-2xl font-bold mb-6 font-cairo text-gray-700">
              Fast facts
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {facts.map((fact, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg flex flex-col justify-between"
                >
                  <div>
                    <h3 className="text-3xl font-bold text-gray-700 font-cairo">
                      {fact.value}
                    </h3>
                    <p className="text-lg text-gray-700 font-kd">{fact.text}</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    as of December 31, 2023
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;