import React from "react";
import HeroSection from "../../../../../../components/HeroSection/HeroSection";
import Page from "./Page";
import { alHudaybiyah } from "../../../../../../data";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "./Slider";

const Home = () => {
  return (
    <div>
      <HeaderFooter>
        <HeroSection
          herobg={alHudaybiyah.hero.image}
          heading={alHudaybiyah.hero.heading}
          paragraph={alHudaybiyah.hero.description}
          subheading={alHudaybiyah.hero.subHeading}
        />
        <Page />
        <div className="py-16">

        <Slider />
        </div>
      </HeaderFooter>
    </div>
  );
};

export default Home;
