import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderComponent from "../../../components/Header.js";
import Footer from "../../../components/footer.js";
import Sidebar from './Sidebar';

const MainPage = ({ currentPath }) => {
  return (
    <section>
      <HeaderComponent/>
      <div className="min-h-screen w-[85%] gap-10 mx-auto flex flex-col lg:flex-row my-14">
        <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
          <Sidebar currentPath={currentPath} />
        </div>
        <div className="w-full lg:w-3/4">
          <Outlet />
        </div>
      </div>
      <Footer/>
    </section>

  );
};

export default MainPage;
