import React, { useContext } from "react";
import { RiCheckboxIndeterminateLine } from "react-icons/ri";
import { TbCheckbox } from "react-icons/tb";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import madinaJson from "../../../../madina_hotels.json"; // Ensure correct import path
import makkahJson from "../../../../makkah_hotels.json"; // Ensure correct import path
import { CurrencyContext } from "../../../../utility/CurrencyContext";

// Function to fetch hotel images from JSON based on hotel name and city
const fetchHotelImages = (hotelName, hotelCity) => {
  const hotels = hotelCity === "Mecca" ? makkahJson : madinaJson;
  const hotel = hotels.find((hotel) => hotel.hotel_Name === hotelName);
  return hotel ? [hotel.image1, hotel.image2, hotel.image3, hotel.image4] : [];
};

const PackageCard = React.forwardRef(
  (
    {
      package_name,
      package_base_cost,
      start_date,
      end_date,
      hotel_info_detail,
      company_detail,
      huz_token,
      pkg,
      rating_count,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

    const handleDivClick = () => {
      navigate(`/detailpage/?packageId=${huz_token}`, {
        state: {
          huz_token,
        },
      });
    };

    // Fetch images for all hotels in the package
    const images = pkg.hotel_info_detail.flatMap((hotel) =>
      fetchHotelImages(hotel.hotel_name, hotel.hotel_city)
    );
    const convertedCost = exchangeRates[selectedCurrency]
      ? (pkg.package_base_cost / exchangeRates["PKR"]) *
        exchangeRates[selectedCurrency]
      : pkg.package_base_cost;
    return (
      <div
        className="items-center flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden border gap-x-4"
        onClick={handleDivClick}
      >
        {/* Image Section with Fixed Size */}
        <div className="flex-shrink-0 w-48 h-48 lg:w-56 lg:h-56">
          <Swiper
            pagination={{
              clickable: true,
              loop: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="z-50">
                  <img
                    src={image}
                    alt={`${pkg.package_name}`}
                    className="w-full h-[200px] md:h-[225px] object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* Main Package Information */}
        <div className="lg:w-full">
          <div className="space-y-1">
            {/* Package Details */}
            <div className="space-y-2">
              <h2 className="text-lg xl:text-2xl font-medium text-[#4b465c]">
                {pkg.package_name}
              </h2>
            </div>
            <p className="text-gray-600 text-xs xl:text-[14px]">
              {new Date(pkg.start_date).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}{" "}
              to{" "}
              {new Date(pkg.end_date).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </p>
            <div style={{ color: "#00936c" }}>
              <span className="mt-4 text-lg font-bold mr-1">
                <NumericFormat
                  className="font-bold text-base"
                  value={convertedCost}
                  displayType={"text"}
                  thousandSeparator
                  prefix={`${selectedCurrency} `}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
              <span className="text-gray-500 text-xs lg:text-sm">
                per person
              </span>
            </div>
          </div>
          {/* Included/Excluded Sections */}
          <div className="mt-2">
            <h3 className="text-base lg:text-sm text-[#4b465c]">Included:</h3>
            <div className="flex flex-wrap gap-2 my-2 text-sm">
              {pkg.is_visa_included && <Tag text="Visa" />}
              {pkg.is_insurance_included && <Tag text="Insurance" />}
              {pkg.is_tour_guide_included && <Tag text="Tour Guide" />}
              {pkg.is_airport_reception_included && (
                <Tag text="Airport Reception" />
              )}
              {pkg.is_breakfast_included && <Tag text="Breakfast" />}
              {pkg.is_lunch_included && <Tag text="Lunch" />}
              {pkg.is_dinner_included && <Tag text="Dinner" />}
            </div>

            <h3 className="text-base lg:text-sm text-[#4b465c]">Excluded:</h3>
            <div className="flex flex-wrap gap-2 my-2 text-sm">
              {!pkg.is_visa_included && <ExcludedTag text="Visa" />}
              {!pkg.is_insurance_included && <ExcludedTag text="Insurance" />}
              {!pkg.is_tour_guide_included && <ExcludedTag text="Tour Guide" />}
              {!pkg.is_airport_reception_included && (
                <ExcludedTag text="Airport Reception" />
              )}
              {!pkg.is_breakfast_included && <ExcludedTag text="Breakfast" />}
              {!pkg.is_lunch_included && <ExcludedTag text="Lunch" />}
              {!pkg.is_dinner_included && <ExcludedTag text="Dinner" />}
            </div>
          </div>
        </div>
        {/* Right-side Information */}
        <div className="flex flex-col mt-4 p-2 lg:mt-0 lg:w-[22%]">
          <div className="flex bg-white p-3 gap-4 rounded-lg mb-3 w-full items-center">
            <div className="bg-[#00936c] p-2  text-white text-sm font-bold flex items-center justify-center rounded-lg rounded-bl-none">
              {pkg.rating_count.average_stars}
            </div>
            <div className="">
              <p className="text-left font-semibold text-sm text-black">
                Rating
              </p>
              <p className="text-left text-[12px] text-gray-500">
                {pkg.rating_count.rating_count} reviews
              </p>
            </div>
          </div>
          <div className="space-y-2">
            <div className="bg-[#f2f2f2] flex py-1 px-5 justify-between items-center rounded-md ">
              <p className="text-[9px] md:text-[10px] lg:text-[12px] xl:text-[14px] font-thin">
                Mecca <br className="hidden lg:block" /> Nights
              </p>
              <p className="text-[17px] md:text-[20px] lg:text-[25px] xl:text-[30px] font-[700] text-[#00936C]">
                {pkg.mecca_nights}
              </p>
            </div>
            <div className="bg-[#f2f2f2] flex p-2 px-5 justify-between items-center gap-2 rounded-md ">
              <p className="text-[9px] md:text-[10px] lg:text-[12px] xl:text-[14px] font-thin">
                Madinah <br className="hidden lg:block" /> Nights
              </p>
              <p className="text-[17px] md:text-[20px] lg:text-[25px] xl:text-[30px] font-[700] text-[#00936C]">
                {pkg.madinah_nights}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

// Helper component to show included/excluded tags
const Tag = ({ text }) => {
  return (
    <span className=" flex items-center text-xs gap-2 text-green-700">
      <TbCheckbox /> {text}
    </span>
  );
};

// Tag for excluded items
const ExcludedTag = ({ text }) => {
  return (
    <span className="flex items-center text-xs gap-2 text-red-700">
      <RiCheckboxIndeterminateLine /> {text}
    </span>
  );
};

export default PackageCard;
