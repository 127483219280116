import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          The Jeddah Flagpole: A Towering Symbol of National Pride
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction to the Jeddah Flagpole
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Standing proudly at the Saudi Flag Roundabout in Jeddah, the
                Jeddah Flagpole is not just a landmark; it's a testament to
                Saudi Arabia's national pride and engineering prowess. This
                towering structure has captured the world's attention as the
                tallest flagpole globally, soaring high above the city skyline.
                <br />
                Geographical Location and Significance
                <br />
                Historical Background and Construction The idea for the Jeddah
                Flagpole emerged from Saudi Arabia's desire to create a
                monumental structure that would symbolize national unity and
                pride. Construction of the flagpole began in 2014, and it was
                completed in 2017, marking a significant milestone in the
                country's architectural and engineering achievements. The
                flagpole stands at an astonishing height of over 170 meters (560
                feet), surpassing previous records and cementing its place in
                history. Architectural and Engineering Marvel The Jeddah
                Flagpole is more than just a tall structure; it is a marvel of
                modern engineering. The flagpole is constructed from steel and
                features a monumental Saudi flag that measures several hundred
                square meters in size. The engineering design ensures that the
                flag remains visible and unfurled, even in strong winds,
                showcasing both the beauty and resilience of the Saudi flag.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/flagpole/image1.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            The flagpole serves as a powerful symbol of Saudi Arabia's national
            identity and cultural heritage. The waving Saudi flag represents
            unity, strength, and the values cherished by the Saudi people. It
            serves as a reminder of the country's rich history, its leadership
            in the Arab world, and its pivotal role in the global community.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Architectural Design and Symbolism
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The architectural design of the Jeddah Flagpole combines
              aesthetics with functionality, featuring a sleek, tapering
              structure that culminates in a grand platform from which the Saudi
              flag proudly waves. The flag's design incorporates the national
              colors of green and white, symbolizing growth, prosperity, and
              peace. The flagpole's height not only sets a world record but also
              symbolizes Saudi Arabia's leadership and stature on the global
              stage.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/flagpole/image3.jpeg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural and National Significance
          </h2>
          <div className="mt-4">
            <p>
              Beyond its impressive dimensions, the Jeddah Flagpole holds deep
              cultural significance for the Saudi people. It serves as a
              powerful symbol of unity, strength, and pride in Saudi national
              identity. The unfurling of the Saudi flag atop the flagpole
              symbolizes the nation's commitment to its values, traditions, and
              aspirations for the future. The flagpole also serves as a focal
              point for national celebrations and events, rallying the Saudi
              community in moments of national pride and unity. Visitor
              Experience and Impact Visitors to the Jeddah Flagpole are
              captivated by its sheer size and the sight of the expansive Saudi
              flag fluttering gracefully overhead. The surrounding area offers
              panoramic views of Jeddah's skyline, providing ample opportunities
              for photography and contemplation. The flagpole's location near
              cultural attractions, shopping centers, and restaurants makes it a
              convenient stop for tourists seeking to experience both the
              historical charm and modern vibrancy of Jeddah.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
