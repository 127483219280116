import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { getHotelImages } from "../../../utility/HotelImages"; // Adjust the path as needed

const Card = ({
  package_name,
  package_cost,
  start_date,
  end_date,
  hotel_info_detail,
  huz_token,
}) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchedImages = hotel_info_detail.flatMap((hotel) =>
      getHotelImages(hotel.hotel_city, hotel.hotel_name)
    );
    setImages(fetchedImages);
  }, [hotel_info_detail]);

  const handleClick = () => {
    navigate("/detailpage", {
      state: {
        package_name,
        package_cost,
        start_date,
        end_date,
        huz_token,
      },
    });
  };

  return (
    <div
      className="lg:px-0 lg:p-0 relative shadow-md rounded-lg cursor-pointer w-full"
      onClick={() =>
        navigate(`/detailpage/?packageId=${huz_token}`, {
          state: { huz_token },
        })
      }
    >
      <Swiper
        pagination={{ clickable: true, loop: true }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={package_name}
              className="w-full h-[225px] object-cover rounded-t-lg"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="p-1 md:p-3 md:relative top-40 md:top-0 bg-white w-full">
        <h3 className="text-md font-bold">{package_name}</h3>
        <p className="text-[14px] font-[500]">
          {new Date(start_date).toLocaleDateString()} to{" "}
          {new Date(end_date).toLocaleDateString()}
        </p>
        <NumericFormat
          className="font-bold text-[16px]"
          value={package_cost}
          displayType={"text"}
          thousandSeparator
          prefix={"PKR "}
        />
      </div>
    </div>
  );
};

export default Card;
