import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { NumericFormat } from "react-number-format";
import { getPackagesForHome } from "../../../utility/Api";
import { BiErrorAlt } from "react-icons/bi";
import madinaJson from "../../../madina_hotels.json";
import makkahJson from "../../../makkah_hotels.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/pagination";
import "./swiper.css";
import { CurrencyContext } from "../../../utility/CurrencyContext";
import Loader from "../../../components/loader";

const styles = `
.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
}

.card-container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .card-container {
    max-width: 250px;
  }
}

@media (min-width: 1024px) {
  .card-container {
    max-width: 250px;
  }
}

@media (min-width: 1440px) {
  .card-container {
    max-width: 250px;
  }
}

.rating-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #00936c;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}
`;

const fetchHotelImages = (hotelName, hotelCity) => {
  const hotels = hotelCity === "Mecca" ? makkahJson : madinaJson;
  const hotel = hotels.find((hotel) => hotel.hotel_Name === hotelName);
  return hotel ? [hotel.image1, hotel.image2, hotel.image3, hotel.image4] : [];
};

const Card = React.forwardRef(
  (
    {
      package_name,
      package_cost,
      start_date,
      end_date,
      hotel_info_detail,
      company_detail,
      huz_token,
      rating_count,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

    const images = hotel_info_detail.flatMap((hotel) =>
      fetchHotelImages(hotel.hotel_name, hotel.hotel_city)
    );
    const averageStars = rating_count?.average_stars ?? 0.0;

    // Convert package cost to selected currency
    const convertedCost = exchangeRates[selectedCurrency]
      ? (package_cost / exchangeRates["PKR"]) * exchangeRates[selectedCurrency]
      : package_cost;

    return (
      <div className="w-auto" ref={ref}>
        <div
          className="lg:px-0 lg:p-0 relative shadow-custom-box rounded-2xl cursor-pointer w-full mb-14 card-content"
          onClick={() =>
            navigate(`/detailpage/?packageId=${huz_token}`, {
              state: { huz_token },
            })
          }
        >
          <Swiper
            pagination={{ clickable: true, loop: true }}
            modules={[Pagination]}
            className=""
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="relative">
                  <img
                    src={image}
                    alt={package_name}
                    className="w-full h-[225px] object-cover rounded-t-lg"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="p-1 md:p-3 bg-white w-full rounded-2xl text-content">
            <h3 className="text-md font-bold">{package_name}</h3>
            {company_detail.company_name && (
              <h3 className="text-xs text-[#00936c]">
                <span className="text-xs">By </span>
                {company_detail.company_name}
              </h3>
            )}
            <p className="text-xs font-[500] mt-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
              {new Date(start_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}{" "}
              to{" "}
              {new Date(end_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </p>
          </div>
          <div className="flex justify-between items-center pb-4 px-4">
            <div>
              <span className="bg-[#00936c] text-sm px-2 py-[2px] text-white rounded-md rounded-bl-none">
                {averageStars}
              </span>
              <span className="text-xs text-gray-400 font-thin"> Rating</span>
            </div>
            <NumericFormat
              className="font-bold text-base"
              value={convertedCost}
              displayType={"text"}
              thousandSeparator
              prefix={`${selectedCurrency} `}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
      </div>
    );
  }
);

const Package = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const cardRefs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPackagesForHome();
        setPackages(data.results);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const setCardHeight = () => {
      const cards = cardRefs.current.map((ref) =>
        ref.querySelector(".card-content")
      );
      const textContents = cardRefs.current.map((ref) =>
        ref.querySelector(".text-content")
      );

      const maxHeight = Math.max(
        ...cards.map((card) => (card ? card.offsetHeight : 0))
      );
      const maxTextHeight = Math.max(
        ...textContents.map((content) => (content ? content.offsetHeight : 0))
      );

      cards.forEach((card) => {
        if (card) card.style.height = `${maxHeight}px`;
      });
      textContents.forEach((content) => {
        if (content) content.style.minHeight = `${maxTextHeight}px`;
      });
    };

    setCardHeight();
    window.addEventListener("resize", setCardHeight);
    return () => window.removeEventListener("resize", setCardHeight);
  }, [packages]);

  const handleSwiperInit = (swiper) => {
    swiper.params.navigation.prevEl = prevButtonRef.current;
    swiper.params.navigation.nextEl = nextButtonRef.current;
    swiper.navigation.init();
    swiper.navigation.update();
  };

  if (error) {
    return (
      <div className="w-[50%] mx-auto my-4">
        <div className="flex items-center text-sm text-red-500 w-full rounded-md bg-red-200 gap-5 p-3 px-6">
          <BiErrorAlt />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-[85%] text-[#484848] mx-auto mt-12 relative">
      {isLoading ? (
        <div className="flex justify-center items-center ">
          <Loader />
        </div>
      ) : packages.length === 0 ? (
        <div>No packages available</div>
      ) : (
        <div className="">
          <div className="items-center">
            <h2 className="text-lg sm:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-[#484848] font-cairo font-bold mb-1">
              Looking for the perfect package?
            </h2>
            <div className="flex justify-between mb-6 sm:mb-4">
              <p className="text-[#484848] text-xs sm:text-sm xl:text-base 2xl:text-lg font-medium mb-0 sm:mb-1 font-cairo">
                Travellers with similar searches booked these packages
              </p>
              <div className="flex justify-end mt-4 sm:mt-0">
                <a
                  href="/umrah"
                  className="text-blue-500 hover:text-blue-800 block"
                >
                  More
                </a>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={{
              prevEl: prevButtonRef.current,
              nextEl: nextButtonRef.current,
            }}
            modules={[Pagination, Navigation]}
            breakpoints={{
              2524: { slidesPerView: 8.7, spaceBetween: 20 },
              2424: { slidesPerView: 8.4, spaceBetween: 20 },
              2324: { slidesPerView: 8.1, spaceBetween: 20 },
              2224: { slidesPerView: 7.8, spaceBetween: 20 },
              2124: { slidesPerView: 7.5, spaceBetween: 20 },
              2024: { slidesPerView: 7.2, spaceBetween: 20 },
              1924: { slidesPerView: 6.9, spaceBetween: 20 },
              1824: { slidesPerView: 6.6, spaceBetween: 20 },
              1724: { slidesPerView: 6.3, spaceBetween: 20 },
              1624: { slidesPerView: 6, spaceBetween: 20 },
              1524: { slidesPerView: 5.5, spaceBetween: 20 },
              1424: { slidesPerView: 4, spaceBetween: 20 },
              1324: { slidesPerView: 4, spaceBetween: 20 },
              1224: { slidesPerView: 4, spaceBetween: 20 },
              1124: { slidesPerView: 4, spaceBetween: 20 },
              1024: { slidesPerView: 4, spaceBetween: 20 },
              924: { slidesPerView: 3.7, spaceBetween: 20 },
              824: { slidesPerView: 3.2, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              700: { slidesPerView: 2, spaceBetween: 20 },
              600: { slidesPerView: 1, spaceBetween: 20 },
              550: { slidesPerView: 1, spaceBetween: 20 },
              500: { slidesPerView: 1, spaceBetween: 20 },
            }}
            scrollbar={{ draggable: true }}
            onInit={handleSwiperInit}
          >
            {packages.map((pkg, index) => (
              <SwiperSlide key={index}>
                <Card {...pkg} ref={(el) => (cardRefs.current[index] = el)} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="custom-navigation">
            <div
              className="swiper-button-prev swiper-container acha md:acha2 sm:-mt-20 lg:mt-0"
              ref={prevButtonRef}
            ></div>
            <div
              className="swiper-button-next swiper-container acha md:acha2 sm:-mt-20 lg:mt-0"
              ref={nextButtonRef}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

// Inject custom styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Package;
