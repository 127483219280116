import React from "react";

const Map = ({ onLocationClick, locations }) => (
  <div className="relative">
    <img src="/images/map-large.jpg" alt="Map" className="w-full h-auto" />
    {locations.map(({ id, name, coordinates }) => (
      <button
        key={id}
        className="absolute flex items-center bg-yellow-600 text-white pr-2 rounded-full transform -translate-x-1/2 -translate-y-1/2"
        style={{ top: coordinates.top, left: coordinates.left }}
        onClick={() => onLocationClick(id)}
      >
        <div className="flex items-center justify-center w-8 h-8 bg-black text-yellow-400 rounded-full mr-2">
          {id}
        </div>
        {name}
      </button>
    ))}
  </div>
);

export default Map;
