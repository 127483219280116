import React from 'react';

const SecurityPage = () => {
  const securitySettings = [
    { label: 'Password', description: 'Reset your password regularly to keep your account secure', action: 'Reset' },
    { label: 'Two-factor authentication', description: 'Increase the security of your account by setting up two-factor authentication.', action: 'Set up' },
    { label: 'Active sessions', description: 'Selecting ‘Sign out’ will sign you out from all devices except this one. The process can take up to 10 minutes.', action: 'Sign out' },
    { label: 'Delete account', description: 'Permanently delete your Booking.com account', action: 'Delete account' },
  ];

  return (
    <div className="w-full">
      <h1 className="text-3xl font-bold mb-4">Security</h1>
      <p className="text-base text-gray-600 mb-6">Change your security settings, set up secure authentication or delete your account.</p>
      <div className="space-y-4">
        {securitySettings.map((setting, index) => (
          <div key={index} className="flex justify-between items-center border-b pb-4">
            <div>
              <p className="">{setting.label}</p>
              <p className="text-gray-600 text-sm font-light">{setting.description}</p>
            </div>
            <a href="#" className="text-sm font-light text-[#00936C]">{setting.action}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecurityPage;
