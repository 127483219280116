import React, { useContext, useRef } from "react";
import { TbCheckbox } from "react-icons/tb";
import { NumericFormat } from "react-number-format";
import { CurrencyContext } from "../../../utility/CurrencyContext";
import Loader from "../../../components/loader";
const SideBar = ({
  pkg,
  checkInDate,
  checkOutDate,
  totalAdults,
  totalChildren,
  totalInfants,
  totalCost,
}) => {
  const inputRef = useRef();

  const calculateLengthOfStay = () => {
    if (checkInDate && checkOutDate) {
      const startDate = new Date(checkInDate);
      const endDate = new Date(checkOutDate);
      const timeDifference = Math.abs(endDate - startDate);
      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    }
    return "";
  };
  const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

  if (!pkg) {
    return (
      <div className="md:max-w-sm w-[90%] mx-auto space-y-5 bg-white text-[#4B465C]">
        {/* <div className="mb-4 border border-[#B2ABAB] shadow-custom-box rounded-md p-4"> */}
        <div
          className={`w-full mb-4 border border-[#B2ABAB] shadow-custom-box p-4 rounded-md flex items-center justify-center y`}
        >
          <Loader />{" "}
        </div>
      </div>
    );
  }

  const dates = pkg.mecca_nights + pkg.madinah_nights;
  const totalPersons = totalAdults + totalChildren + totalInfants;

  const convertedCost =
    pkg && exchangeRates[selectedCurrency]
      ? (totalCost / exchangeRates["PKR"]) * exchangeRates[selectedCurrency]
      : totalCost;

  return (
    <div className="md:max-w-sm space-y-2 bg-white text-[#4B465C]">
      {/* Hotel Information Section */}
      <div className="border border-[#B2ABAB] rounded-md p-4 space-y-1">
        <h1 className="text-md font-bold">{pkg.package_name}</h1>
        <p className="text-sm">
          {pkg.description
            ? pkg.description.substring(0, 200)
            : "Description not available"}
          ...
        </p>
        <div className="flex gap-2 text-sm mt-2">
          <div className="flex items-center gap-1">
            <TbCheckbox className="text-[#00936c]" />
            <p>
              Mecca Nights
              <span className="text-[#00936c] pl-1 font-bold">
                {pkg.mecca_nights}
              </span>
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <TbCheckbox className="text-[#00936c]" />
            <p>
              Madinah Nights
              <span className="text-[#00936c] pl-1 font-bold">
                {pkg.madinah_nights}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Booking Details Section */}
      <div className="border border-[#B2ABAB] space-y-4 rounded-md p-4">
        <h2 className="text-md font-bold">Your booking details</h2>
        <div className="flex justify-between w-full text-sm">
          <div>
            <p>Start Date</p>
            <p className="text-[14px] xl:text-[16px] font-semibold">
              {checkInDate
                ? new Date(checkInDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                : new Date(pkg.start_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
            </p>
          </div>
          <div className="h-100 border-[1px] border-[#B2ABAB]"></div>
          <div>
            <p>End Date</p>
            <p className="text-[14px] xl:text-[16px] font-semibold">
              {checkOutDate
                ? new Date(checkOutDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                : new Date(pkg.end_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
            </p>
          </div>
        </div>
        <div className="text-md font-semibold">
          <p>Total length of stay:</p>
          <span className="text-sm font-medium ">
            {pkg.is_package_open_for_other_date
              ? calculateLengthOfStay() || dates
              : dates}{" "}
            Nights
          </span>
        </div>
        <hr />
        <div>
          <span className="text-md font-semibold">You selected</span>
          <div className="flex justify-between items-center">
            <div className="text-[14px] xl:text-md font-semibold">
              <p>
                {pkg.is_package_open_for_other_date
                  ? calculateLengthOfStay() || dates
                  : dates}{" "}
                Nights stay with{" "}
                <input
                  type="text"
                  ref={inputRef}
                  className="border-none p-0 w-4 h-4 text-md font-bold"
                  value={totalPersons}
                  readOnly
                />{" "}
                persons
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto space-y-4 bg-white border border-[#B2ABAB] rounded-md shadow-md">
        <h1 className="text-md font-semibold font-cairo pb-0 p-4">
          Your price summary
        </h1>
        <div className="flex justify-between bg-[#ECF1F0] p-4">
          <span className="text-md font-bold mb-1">Price</span>
          <div>
            <NumericFormat
              value={convertedCost}
              displayType={"text"}
              thousandSeparator
              prefix={`${selectedCurrency} `}
              decimalScale={2}
              fixedDecimalScale={true}
              className="flex justify-end font-bold"
            />
            <div className="text-sm">
              +{" "}
              <NumericFormat
                value={convertedCost}
                displayType={"text"}
                thousandSeparator
                prefix={`${selectedCurrency} `}
                decimalScale={2}
                fixedDecimalScale={true}
                className="font-bold"
              />{" "}
              taxes and charges
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
