import React, { useEffect } from "react";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Banner from "./Banner";
import Slider from "../Wadijin2/Slider";
import Page from "./Page";

const WadijinMainPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider.jpeg",
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider1.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider2.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider3.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider4.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/mountainUhud/slider5.jpg",
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default WadijinMainPage;
