import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          The Geographical Location of Nassif House Museum
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Introduction to Nassif House Museum
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                <p>
                  Nassif House Museum is situated in the historic Al-Balad
                  district of Jeddah, Saudi Arabia. This area, known for its
                  narrow alleyways and centuries-old architecture, is a UNESCO
                  World Heritage site, reflecting the rich cultural and
                  historical significance of Jeddah as a gateway to the holy
                  cities of Mecca and Medina. The museum is located on Al-Dahab
                  Street, a central artery in the Al-Balad district, making it
                  easily accessible to both locals and tourists.
                </p>
                Introduction to Nassif House Museum
                <p className="lg:hidden xl:block">
                  Nassif House Museum, also known as Bayt Nassif, is one of
                  Jeddah's most iconic heritage buildings. Constructed in 1872,
                  the house originally belonged to Sheikh Omar Effendi Nassif, a
                  prominent merchant and politician of the time. The house is a
                  fine example of traditional Hijazi architecture, characterized
                  by its intricate wooden lattice windows, coral stone walls,
                  and spacious interiors designed to facilitate air circulation
                  in the region's hot climate.
                </p>
                {/*  */}
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/naseefhouse/image1.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            Nassif House Museum, also known as Bayt Nassif, is one of Jeddah's
            most iconic heritage buildings. Constructed in 1872, the house
            originally belonged to Sheikh Omar Effendi Nassif, a prominent
            merchant and politician of the time. The house is a fine example of
            traditional Hijazi architecture, characterized by its intricate
            wooden lattice windows, coral stone walls, and spacious interiors
            designed to facilitate air circulation in the region's hot climate.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Architectural Features
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The Nassif House Museum is a four-story building, designed to
              accommodate the large Nassif family and their guests. The ground
              floor includes a central courtyard, surrounded by rooms used for
              social gatherings and administrative purposes. The upper floors
              contain private living quarters, including bedrooms and family
              areas. One of the most striking features of the Nassif House is
              its rawasheen—intricately carved wooden balconies that project out
              from the facade. These rawasheen not only enhance the building's
              aesthetic appeal but also provide shade and allow for ventilation,
              a crucial feature in Jeddah's hot climate. The house is also
              adorned with mashrabiya, wooden lattice screens that offer privacy
              while allowing light and air to filter through. The Role of Nassif
              House Museum Today Today, the Nassif House Museum functions as a
              cultural and educational center. It hosts various exhibitions,
              workshops, and events that aim to preserve and promote the
              heritage of Jeddah and the wider Hijaz region. The museum's
              exhibits include historical photographs, artifacts, and documents
              that narrate the story of Jeddah's transformation from a modest
              fishing village to a bustling urban center.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/naseefhouse/image4.jpeg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Visiting the Museum
          </h2>
          <div className="mt-4">
            <p>
              Visitors to the Nassif House Museum can enjoy a guided tour that
              covers the building's history, architecture, and the significant
              events that took place within its walls. The museum is open to the
              public, offering a unique opportunity to experience a piece of
              Saudi Arabia's rich cultural tapestry. The Al-Balad district,
              where the museum is located, is also home to several other
              historical buildings, souks, and traditional eateries, making it a
              perfect destination for a day of cultural exploration. Conclusion
              The Nassif House Museum stands as a testament to Jeddah's rich
              history and cultural heritage. Its preservation and transformation
              into a museum have allowed both locals and visitors to connect
              with the past and appreciate the architectural and historical
              significance of this remarkable building. Whether you are a
              history enthusiast, an architecture aficionado, or simply a
              curious traveler, a visit to the Nassif House Museum offers a
              fascinating insight into the legacy of old Jeddah and the broader
              history of Saudi Arabia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
