import React, { useEffect, useRef } from "react";
import { gharSur } from "../../../../../../data";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { GharEsurImages } from "../../../../../../placesdata";
// import img from "../imgs/abbas.svg";

const Page = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    // Define the function inside useEffect
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Call the function
    scrollToTop();
  }, []);

  const facts = [
    "Ghar-e-Sur is a cave located on Jabal Thawr, a mountain near Mecca.",
    "The cave holds historical significance as the hiding place of Prophet Muhammad and his companion Abu Bakr during their migration to Medina.",
    "The cave is situated at an elevation of about 760 meters above sea level.",
    "Pilgrims often visit Ghar-e-Sur to reflect on the Prophet's journey and seek spiritual inspiration.",
  ];

  // console.log("hello", gharSur.hero.img);

  return (
    <div className="text-[#4b465c] font-cairo">
      <h1 className="font-cairo bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        Distance from  Kaaba in Mecca: 5 miles (or approximately 8 kilometers)
        south of the Kaaba in Mecca, Saudi Arabia.
      </h1>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
          <h1 className="font-cairo text-[25px] font-bold pb-1">History</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
              <p>
                History The Cave of Thawr, also known as Ghar-e-Sur, is a site
                with historical significance in Islamic tradition, particularly
                regarding the Hijrah (migration) of the Prophet Muhammad (peace
                be upon him). <br /> During a critical juncture in early Islamic
                history, when the Prophet Muhammad (peace be upon him) faced
                severe persecution in Mecca from the Quraysh tribe, he and his
                close companion Abu Bakr sought refuge in the Cave of Thawr.
                This event occurred in the year 622 CE, corresponding to the
                12th year of Muhammad's prophethood.
                <br /> The Quraysh, aware of Muhammad's plan to migrate to
                Medina, had set a bounty on his head and were actively searching
                for him to prevent his escape. Under these circumstances,
                Muhammad and Abu Bakr took shelter in the Cave of Thawr while
                they made their journey towards the city of Yathrib, which later
                became known as Medina.
              </p>
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              {" "}
              <img
                src={gharSur.history.image}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full h-full rounded-lg"
              />
              <p>
                <em>
                  Pilgrims visiting Jabal al-Nour, where the Cave of Hira is
                  located, near Mecca.
                </em>
              </p>
            </div>
          </div>
        </div>
        <div className="py-10">
          <div className="w-full mx-auto">
            <h2 className="font-cairo font-bold text-xl pt-6">
              4 Facts about the Cave of Ghar-e-Sur
            </h2>
            <ul className="space-y-3 pt-5">
              {facts.map((fact, index) => (
                <li
                  key={index}
                  className="flex items-start font-medium text-[15px] gap-3"
                >
                  <img
                    src={icon}
                    alt=""
                    className="text-[15px] text-[#00936c] mt-1"
                  />
                  <p className=" text-lg leading-relaxed">{fact}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="py-5">
          <h1 className="font-cairo text-[25px] font-bold pb-1">
            Resign for what peruse the people visit the GHAR-E-HIRA
          </h1>
          <h2 className="font-cairo text-[15px] font-medium pb-1">
            People visit the Cave of Hira, or Ghar-e-Hira, for several reasons,
            primarily of spiritual significance:
          </h2>
        </div>
        <div className="lg:flex block gap-14 py-10">
          <div className="block lg:w-[55%]">
            <h3 className="font-cairo text-[25px] font-bold pb-1">
              Pilgrimage:
            </h3>
            <div className=" space-y-4  my-4 font-cairo font-medium text-[15px]">
              <p>
                Ghar-e-Sur remains a sacred site for Muslims worldwide, who
                visit it as part of their pilgrimage to Mecca. Pilgrims often
                ascend the mountain to visit the cave, reflecting on the
                profound spiritual experiences that unfolded there over fourteen
                centuries ago.
              </p>
              <p>
                The cave served as a refuge for the Prophet Muhammad (peace be
                upon him) and his companion Abu Bakr during their Hijrah
                (migration) to Medina. Ghar-e-Sur symbolizes the themes of trust
                in Allah, perseverance in the face of adversity, and the
                importance of seeking divine protection and guidance.
              </p>
            </div>
          </div>
          <img
            src={gharSur.why.image}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg"
          />
        </div>
        <div className="gap-5 mt-7 lg:flex">
          <img
            src={gharSur.pilgrimage2.image}
            alt=""
            className="w-full lg:w-[45%] rounded-lg"
          />
          <div className="block lg:w-[55%] pt-10 lg:pt-0">
            <h1 className="font-cairo text-[25px] font-bold pb-1">
              Historical Interest:
            </h1>
            <div className=" space-y-4  my-4 font-cairo font-medium text-[15px] ">
              <p className="">
                Ghar-e-Sur is intimately associated with a crucial event in
                Islamic history—the Hijrah (migration) of the Prophet Muhammad
                (peace be upon him) from Mecca to Medina. This cave served as a
                refuge for the Prophet and his companion Abu Bakr during their
                perilous journey.
              </p>
              <p>
                Visitors, including scholars, historians, and tourists, are keen
                to learn about the circumstances surrounding this significant
                event and to walk in the footsteps of these revered figures.
                Exploring Ghar-e-Sur offers a profound connection to the rich
                tapestry of Islamic heritage and the enduring legacy of the
                Prophet's journey.
              </p>
              <p>
                {" "}
                Discover the hidden gem of Ghar-e-Sur, the historic cave that
                played a pivotal role in the Prophet Muhammad's migration to
                Medina.
              </p>
            </div>
          </div>
        </div>
        <div className=" lg:flex block gap-5 mt-10 py-10">
          <div className="block lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px]">
            <p className=" ">
              Pilgrims from around the world embark on a sacred journey to
              Makkah, drawn by the profound significance of the Cave of Thawr
              and the Cave of Hira. These auspicious caves are revered for the
              numerous miracles that took place within their hallowed confines.
            </p>
            <p>
              Beyond their historical importance, the caves offer a serene and
              spiritual environment, allowing visitors to reflect deeply on
              Allah and His countless blessings.
            </p>
            <p>
              {" "}
              The experience of visiting these sacred sites fosters a unique
              sense of connection and devotion for the faithful.
            </p>
          </div>
          <img
            src={gharSur.pilgrimage.image}
            alt=""
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>
        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={GharEsurImages.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={GharEsurImages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={GharEsurImages.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page;
