import React, { useEffect, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import bgimg from "../../../assets/bgImage.png";
import Footer from "../../../components/footer";
import { createBookingRequest, saveUserAddreess } from "../../../utility/Api";
import countries from "../Login/country_data.json"; // Ensure this path is correct
import HeaderForUserSetup from "../HeaderForUserSetup";
import Loader from "../../../components/loader";
const AddressForm = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    streetAddress: "",
    addressLine2: "",
    countryRegion: "",
    city: "",
    state: "",
    postalCode: "",
    address_id: "", // Add this field for address_ids
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const {
    partner_session_token,
    huz_token,
    userFromBooking,
    selectedPersons,
    selectedChilds,
    checkInDate,
    checkOutDate,
    total_price,
    specialRequest,
    isActive,
    bookingFormData, // Accepting the bookingFormData from the location state
  } = location.state || {};

  let session_token;
  let is_booking_page;
  is_booking_page = JSON.parse(localStorage.getItem("IsBookingPage")) || false;
  const new_user_profile =
    JSON.parse(localStorage.getItem("Login-User-Profile")) || {};
  session_token = new_user_profile.session_token;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      countryRegion: selectedOption ? selectedOption.value : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      countryRegion: "",
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    if (!formData[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/([A-Z])/g, " $1")} is required`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.streetAddress)
      newErrors.streetAddress = "Street address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.postalCode) newErrors.postalCode = "Postal code is required";
    if (!formData.countryRegion)
      newErrors.countryRegion = "Country/region is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const packageId = huz_token;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(false); // Ensure loader is not shown if validation fails
      return;
    }
    setIsLoading(true);

    try {
      const response = await saveUserAddreess(
        session_token,
        formData.streetAddress,
        formData.addressLine2,
        formData.city,
        formData.state,
        formData.countryRegion,
        formData.postalCode
      );
      localStorage.setItem("Address Data", JSON.stringify(response.data));

      if (is_booking_page) {
        let active_value;
        switch (isActive) {
          case 1:
            active_value = "Bank";
            break;
          case 2:
            active_value = "Cheque";
            break;
          case 3:
            active_value = "Voucher";
            break;
          default:
            active_value = "default value"; // Optionally handle other cases
        }

        const bookingResponse = await createBookingRequest(
          session_token,
          partner_session_token,
          huz_token,
          selectedPersons,
          selectedChilds,
          checkInDate,
          checkOutDate,
          total_price,
          specialRequest,
          active_value
        );
        localStorage.setItem(
          "Booking-Response",
          JSON.stringify(bookingResponse.data)
        );

        if (isActive === 1) {
          navigate("/bankingDetail");
        } else if (isActive === 2) {
          navigate("/voucherDetail");
        }
      } else {
        if (userFromBooking) {
          navigate(`/reserveform/?packageId=${encodeURIComponent(packageId)}`, {
            state: { bookingFormData }, // Forwarding the bookingFormData to the next component
          });
        } else {
          navigate("/");
        }
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setIsLoading(false); // Ensure loader is turned off after submission or error
    }
  };

  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  return (
    <div>
      <div
        className="flex flex-col min-h-screen pb-10 font-kd text-[#484848] border-[#B2ABAB]"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <HeaderForUserSetup />
        <div className="space-y-5 text-[#4b465c] font-kd md:w-[90%] mx-auto py-10">
          <div className="">
            <div className="justify-end">
              <div className="md:w-[55%] w-full md:mx-auto rounded-lg bg-white shadow-custom-box space-y-5">
                <div className="border-[1px] rounded-md">
                  <div className="p-2 text-md font-bold text-center">
                    <p className="font-semibold text-lg my-2 opacity-80">
                      Enter Address Details
                    </p>
                  </div>
                  <hr />
                  <form
                    className="space-y-2 p-5 px-10 w-[90%] mx-auto"
                    onSubmit={handleSubmit}
                  >
                    <div className="">
                      <p className="text-md font-semibold">Street Address</p>
                      <div className="py-5">
                        <div className="mb-4 lg:w-full lg:mx-auto">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="streetAddress"
                          >
                            Street address
                          </label>
                          <input
                            type="text"
                            id="streetAddress"
                            name="streetAddress"
                            value={formData.streetAddress}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="e.g. 123 street"
                            className="p-2 outline-none border rounded-md w-full font-thin placeholder:text-xs text-sm shadow-sm"
                          />
                          {errors.streetAddress && (
                            <div className="text-red-500 text-xs flex items-center gap-1 mt-1">
                              <BiErrorAlt />
                              <p className="text-red-600 text-xs">
                                {errors.streetAddress}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mb-4 lg:w-full">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="addressLine2"
                          >
                            Address line 2
                          </label>
                          <input
                            type="text"
                            id="addressLine2"
                            name="addressLine2"
                            value={formData.addressLine2}
                            onChange={handleInputChange}
                            placeholder="Unit number, suite, floor, building, etc."
                            className="p-2 outline-none border rounded-md placeholder:text-xs w-full font-thin text-sm shadow-sm"
                          />
                        </div>
                        <div className="mb-4 lg:w-full">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="countryRegion"
                          >
                            Country/region
                          </label>
                          <Select
                            id="countryRegion"
                            name="countryRegion"
                            value={countryOptions.find(
                              (option) =>
                                option.value === formData.countryRegion
                            )}
                            onChange={handleSelectChange}
                            onBlur={() =>
                              handleBlur({ target: { name: "countryRegion" } })
                            }
                            options={countryOptions}
                            className={`react-select-container outline-none placeholder:text-xs w-full shadow-sm text-sm text-[#4b465c] ${
                              errors.countryRegion
                                ? "border-red-500"
                                : "border-gray-200"
                            }`}
                            classNamePrefix="react-select"
                          />
                          {errors.countryRegion && (
                            <div className="text-red-500 text-xs flex items-center gap-1 mt-1">
                              <BiErrorAlt />
                              <p className="text-red-600 text-xs">
                                {errors.countryRegion}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mb-4 lg:w-full">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="city"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="e.g. New York"
                            className="p-2 outline-none border placeholder:text-xs rounded-md w-full font-thin text-sm shadow-sm"
                          />
                          {errors.city && (
                            <div className="text-red-500 text-xs flex items-center gap-1 mt-1">
                              <BiErrorAlt />
                              <p className="text-red-600 text-xs">
                                {errors.city}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mb-4 lg:w-full">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="state"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            id="state"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            className="p-2 border outline-none placeholder:text-xs rounded-md w-full font-thin text-sm shadow-sm"
                          />
                          {errors.state && (
                            <div className="text-red-500 text-xs flex items-center gap-1 mt-1">
                              <BiErrorAlt />
                              <p className="text-red-600 text-xs">
                                {errors.state}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mb-4 lg:w-full">
                          <label
                            className="block text-gray-700 text-xs font-light mb-2"
                            htmlFor="postalCode"
                          >
                            Postal code
                          </label>
                          <input
                            type="text"
                            id="postalCode"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            maxLength={10}
                            className="p-2 outline-none border placeholder:text-xs rounded-md w-full font-thin text-sm shadow-sm"
                          />
                          {errors.postalCode && (
                            <div className="text-red-500 text-xs flex items-center gap-1 mt-1">
                              <BiErrorAlt />
                              <p className="text-red-600 text-xs">
                                {errors.postalCode}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto py-5">
                      <button
                        className={`w-full text-sm py-2 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
                          isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isLoading}
                        type="submit"
                      >
                        {isLoading ? <Loader /> : <p>Agree and Continue</p>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddressForm;
