import React, { useRef, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import bgimg from "../../../assets/bgImage.png";
import Loader from "../../../components/loader";
import { AuthContext } from "../../../App";
import { BiErrorAlt } from "react-icons/bi";
import { sendOtp, verifyOtp } from "../../../utility/AuthApis"; // Uncomment this when ready to use API
import HeaderForUserSetup from "../HeaderForUserSetup";

const OtpVerificationPage = () => {
  const { state, dispatch } = useContext(AuthContext);
  const location = useLocation();

  const { userFromBooking, huz_token, formData } = location.state || {};
  console.log("from OTP screen", formData);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const inputRefs = useRef(otp.map(() => React.createRef()));
  const navigate = useNavigate();

  const handleOtpChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value.substr(value.length - 1, 1);
      setOtp(newOtp);

      if (value.length > 0 && index < otp.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }

      if (value === "" && index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
      setErrors(""); // Clear errors when user starts entering data
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    const newOtp = pasteData.split("").map((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].current.value = char;
        if (index < otp.length - 1) {
          inputRefs.current[index + 1].current.focus();
        }
      }
      return char;
    });
    setOtp(newOtp);
  };

  const user_phone_number =
    JSON.parse(localStorage.getItem("User-Phone-Number")) || "";
  const login_user_profile =
    JSON.parse(localStorage.getItem("Login-User-Profile")) || "";
  const userExist = login_user_profile?.data?.message || {};

  const handleOtpSubmit = async () => {
    setIsLoading(true);
    setErrors(""); // Clear errors
    const packageId = huz_token;
    const enteredOtp = otp.join("");
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // For now, use static OTP verification
    if (enteredOtp === "123456") {
      if (userExist !== "User with this phone number does not exist.") {
        dispatch({ type: "USER", payload: true });
        if (userFromBooking) {
          navigate(`/reserveform/?packageId=${encodeURIComponent(packageId)}`, {
            state: {
              huz_token,
              partner_session_token: location.state.partner_session_token,
              formData,
            },
          });
        } else {
          navigate("/");
        }
      } else {
        navigate("/login/signup", {
          state: { userFromBooking, huz_token, formData },
        });
      }
    } else {
      setErrors("Invalid OTP. Please try again.");
    }

    // Uncomment this when ready to use API
    /*
    try {
      const response = await verifyOtp(user_phone_number, enteredOtp);
      if (response.success) {
        if (userExist !== 'This Phone Number does not exist.') {
          dispatch({ type: 'USER', payload: true });
          if (userFromBooking) {
            navigate(`/reserveform/?packageId=${encodeURIComponent(packageId)}`, { state: { huz_token, partner_session_token: location.state.partner_session_token, formData } });
          } else {
            navigate('/');
          }
        } else {
          navigate('/login/signup', { state: { userFromBooking, huz_token, formData } });
        }
      } else {
        setErrors(response.message);
      }
    } catch (error) {
      setErrors(error.message);
    } finally {
      setIsLoading(false);
    }
    */

    setIsLoading(false);
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    setErrors(""); // Clear errors

    const { partner_session_token } = JSON.parse(
      localStorage.getItem("SignedUp-User-Profile")
    );

    try {
      await sendOtp(user_phone_number);
    } catch (error) {
      console.error("Error resending OTP:", error);
      setErrors(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen font-kd"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="sticky top-0 z-10">
        <HeaderForUserSetup />
      </div>
      <main className="flex-grow flex justify-center items-center py-8 overflow-y-auto">
        <div className="w-[85%] lg:w-[31%] py-10 px-6 bg-white rounded-lg shadow-custom-box text-center">
          <div className="flex justify-center mb-4">
            <div className="bg-[#f2f2f2] rounded-full p-2">
              <img
                src="/images/otpIcon.svg"
                alt="Verification"
                style={{ width: "98px", height: "98px" }}
              />
            </div>
          </div>
          <h2 className="text-lg font-semibold text-[#4B465C]">
            Verify your phone number to proceed
          </h2>
          <p className="mb-6 text-sm font-normal text-[#4B465C] opacity-80">
            We sent a verification code to{" "}
            <span className="font-bold text-[#4B465C] opacity-100">
              {user_phone_number}
            </span>
            <br />
            Enter the code sent to your phone number in the field below.
          </p>

          <div className="flex justify-between mb-6" onPaste={handlePaste}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                id={`otp-input-${index}`}
                ref={inputRefs.current[index]}
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                placeholder="-"
                onFocus={(e) => e.target.select()}
                className="w-12 p-3 border rounded-md text-center text-sm"
              />
            ))}
          </div>
          {errors && (
            <div className="text-red-500 flex my-3 items-center gap-1">
              <BiErrorAlt />
              <span className="text-red-500 text-xs">{errors}</span>
            </div>
          )}

          <button
            onClick={handleOtpSubmit}
            className={`w-full text-sm py-3 mb-3 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : <p>Continue</p>}
          </button>

          <p className="text-sm text-[#4B465C]">
            Did not receive email?{" "}
            <button
              className="text-[#00936C] underline font-semibold"
              onClick={handleResendOtp}
            >
              Resend
            </button>
          </p>
        </div>
      </main>
    </div>
  );
};

export default OtpVerificationPage;
