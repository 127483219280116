const Card = ({ image, heading, para, navigation }) => {
  return (
    <div className="space-y-1.5 w-auto">
      <img
        src={image}
        alt={heading}
        onClick={navigation}
        className="w-full h-[140px] rounded-md"
      />
      <h1 className="font-cairo font-semibold text-sm md:text-sm xl:text-base 2xl:text-lg text-[#484848] opacit-80">
        {heading}
      </h1>
      <p className="text-sm leading-[20px] text-[#484848] opacity-80">
        {para} Packages
      </p>
    </div>
  );
};

export default Card;