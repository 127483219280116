import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { ClockTowerImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const MakkahClockTower = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${ClockTowerImages.image5})`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Makkah Clock Tower</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Makkah Clock Tower: A Symbol of Modernity and Spirituality in the
          Heart of Islam
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Masjid al-Khayf: A Sacred Confluence of Geography and Spirituality
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                The Makkah Clock Tower, also known as Abraj Al Bait, is an
                iconic landmark situated in the heart of Mecca, Saudi Arabia.
              </p>
              <p>
                {" "}
                Adjacent to the Grand Mosque (Masjid al-Haram), this towering
                structure symbolizes a harmonious blend of modern architecture
                and profound religious significance. With its imposing height
                and the world's largest clock face, the tower is visible from
                miles away, guiding pilgrims to the holy city.
              </p>
              <p>
                {" "}
                The complex includes luxurious accommodations, a shopping mall,
                and an Islamic museum, enhancing the experience for millions of
                visitors.
              </p>
              <p>
                The Makkah Clock Tower stands as a testament to the city's
                unique convergence of geography and spirituality, embodying both
                the devotion of the Islamic faith and the marvels of
                contemporary design.
              </p>
              <p className="lg:hidden xl:block">
                The Makkah Clock Tower, also known as Abraj Al Bait, is an
                iconic landmark situated in the heart of Mecca, Saudi Arabia.
                Adjacent to the Grand Mosque (Masjid al-Haram), this towering
                structure symbolizes a harmonious blend of modern architecture
                and profound religious significance. With its imposing height
                and the world's largest clock face, the tower is visible from
                miles away, guiding pilgrims to the holy city.
              </p>
              <p className="lg:hidden xl:block">
                Architectural and Geographic Splendor The Makkah Clock Tower is
                part of the Abraj Al Bait complex, which includes luxurious
                accommodations, a shopping mall, and an Islamic museum,
                enhancing the experience for millions of visitors. The tower
                itself stands as a beacon, its clock face measuring 43 meters in
                diameter, making it the largest in the world. The structure’s
                height, reaching 601 meters, ensures that it is a focal point of
                the Meccan skyline, visible from various parts of the city and
                beyond.
              </p>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={ClockTowerImages.image2}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full rounded-lg"
              />
              <p>
                <em className="text-[10px] md:text-[16px]">
                  makkah clock tower in mecca
                </em>
              </p>
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            The Makkah Clock Tower, also known as Abraj Al Bait, is an iconic
            landmark situated in the heart of Mecca, Saudi Arabia. Adjacent to
            the Grand Mosque (Masjid al-Haram), this towering structure
            symbolizes a harmonious blend of modern architecture and profound
            religious significance. With its imposing height and the world's
            largest clock face, the tower is visible from miles away, guiding
            pilgrims to the holy city.
          </p>
          <p className="hidden lg:flex xl:hidden">
            Architectural and Geographic Splendor The Makkah Clock Tower is part
            of the Abraj Al Bait complex, which includes luxurious
            accommodations, a shopping mall, and an Islamic museum, enhancing
            the experience for millions of visitors. The tower itself stands as
            a beacon, its clock face measuring 43 meters in diameter, making it
            the largest in the world. The structure’s height, reaching 601
            meters, ensures that it is a focal point of the Meccan skyline,
            visible from various parts of the city and beyond.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Understanding the Makkah Clock Tower: Historical and Spiritual
            Dimensions
          </h1>
          <div className="pt-3">
            <p className=" font-medium text-[15px]">
              The Makkah Clock Tower, a striking landmark in the Islamic world,
              stands as a beacon of both modernity and spiritual significance.
              Situated in the heart of Mecca, this towering structure is more
              than just a marvel of contemporary engineering; it encapsulates
              profound historical and religious themes within its imposing
              presence.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  Recognition and Reflection:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The Makkah Clock Tower is not only a symbol of architectural
                  prowess but also a site for deep spiritual reflection. As it
                  overlooks the holy Kaaba, it serves as a reminder for pilgrims
                  and visitors to recognize the importance of their faith and
                  the history of Islam. The towering structure prompts a sense
                  of awe and reverence, encouraging individuals to contemplate
                  their spiritual journey and the divine presence that pervades
                  this sacred city.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  The Days of Hajj:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  During the annual Hajj pilgrimage, the Makkah Clock Tower
                  plays a central role in the lives of millions of Muslims. It
                  helps regulate the flow of time and activities during the
                  intense spiritual rituals performed in Mecca. Pilgrims rely on
                  the clock tower for accurate prayer times and to coordinate
                  their movements, ensuring they fulfill their religious
                  obligations with precision. Its presence enhances the
                  spiritual ambiance of the pilgrimage, guiding the faithful as
                  they engage in prayer, reflection, and the various rites of
                  Hajj.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  A Symbol of Islamic Unity:
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The Makkah Clock Tower also embodies the unity and continuity
                  of the Islamic faith. As one of the tallest structures in the
                  world, it serves as a powerful symbol of Islamic heritage and
                  progress. Its construction near the Grand Mosque ties the
                  modern achievements of the Muslim world to its historical and
                  spiritual roots. The clock tower stands as a testament to the
                  enduring values of Islam, emphasizing the importance of
                  community, devotion, and the relentless pursuit of spiritual
                  enlightenment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          The Tower's Magnificent Features:
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              In the bustling city of Mecca, amidst the throngs of pilgrims and
              the serene aura of spirituality, stands a towering symbol of
              modernity and faith: the Makkah Clock Tower. Rising majestically
              above the sacred precincts of the Grand Mosque, this architectural
              marvel has come to represent not only the advancement of
              technology but also the timeless significance of Islamic heritage
              and devotion. Join us on a journey as we delve into the history,
              design, and spiritual significance of the Makkah Clock Tower.
            </p>
            <div className="space-y-1.5 py-2">
              <p className="font-medium text-[15px]">
                At the heart of the Makkah Clock Tower is the Royal Clock, one
                of the largest and most intricate timepieces in the world.
                Mounted on the tower's facades, the clock faces measure over 43
                meters (141 feet) in diameter, making them visible from afar.
                Each face is adorned with intricate Arabic calligraphy and
                illuminated by millions of LED lights, ensuring its visibility
                even during the darkest hours of the night. The clock mechanism
                itself is a marvel of precision engineering, synchronized to the
                atomic clock in Riyadh to ensure accuracy to the second.
              </p>
            </div>
          </div>
          <img
            src={ClockTowerImages.image3}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
          >
            <SwiperSlide>
              <img
                src={ClockTowerImages.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ClockTowerImages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ClockTowerImages.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ClockTowerImages.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default MakkahClockTower;
