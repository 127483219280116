import React, { useEffect } from "react";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
// import lines from "../../assets/lines.svg";
import Footer from "../../../components/footer";
import Search from "./Search";
import ExploreMoreCards from "./Guest/ExploreMoreCards";
import "./Guest/global.css";
import TabsWithSearch from "./Tabs";

function HelpCenter() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div className="bg-gray-50">
      <BookingNavbar />
      <div className="antialiased pt-16 space-y-10">
        <h1 className="text-center font-semibold md:text-[40px] text-gray-900">
          Hi, how can we help?
        </h1>
        <Search />
        <TabsWithSearch />
        <ExploreMoreCards />{" "}
      </div>
      <Footer />
    </div>
  );
}

export default HelpCenter;
