import React, { useEffect } from "react";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import Subscription from "../../../components/subscription";

const HajjGuide = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const customClass = "w-[85%] mx-auto";
  return (
    <div className="">
      <BookingNavbar />
      <header className="relative bg-blue-600 text-white text-justify	">
        <div className="absolute inset-0 opacity-75">
          <img
            src="/images/HajjGuide/guide-to-hajj-hero.jpg"
            alt="Kaaba"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative p-8 flex flex-col items-start justify-center h-64">
          <h1 className="md:text-[20px] font-kd text-[16px] font-bold mb-4">
            Hajj Guide: Step by step guide to performing Hajj
          </h1>
          <div className="flex space-x-4">
            <a href="#" className="text-white">
              <FaFacebookSquare className="text-2xl" />
            </a>
            <a href="#" className="text-white">
              <FaTwitterSquare className="text-2xl" />
            </a>
            <a href="#" className="text-white">
              <FaLinkedin className="text-2xl" />
            </a>
          </div>
        </div>
      </header>
      <div className={`${customClass} px-4 py-8 text-justify	`}>
        <section className="mb-8 text-[16px] font-kd text-justify	">
          <h2 className="text-[20px] font-kd mb-4 font-bold">What Is Hajj?</h2>
          <p className="mb-4">
            Each year, millions of Muslims from all across the world perform{" "}
            <em className="border-b-2 border-[#00936C]">Hajj</em>, the sacred
            pilgrimage and the fifth pillar of Islam.
          </p>
          <p className="mb-4">
            <em className="border-b-2 border-[#00936C]">Hajj</em> takes place in
            Makkah, in modern day Saudi Arabia, during the holy month of Dhul
            Hijjah, the 12th month in the Islamic calendar.
          </p>
          <p className="mb-4">
            <em className="border-b-2 border-[#00936C]">Hajj</em> is a spiritual
            duty and a <strong>pillar of Islam</strong>, meaning that{" "}
            <strong>
              Hajj must be performed by every Muslim at least once in their
              lifetime
            </strong>
            , so long as they are financially, physically, and emotionally able
            to do so. Going more than once during your lifetime is permitted
            whilst sincerely seeking Allah’s (SWT) (which means ‘The Most
            Glorified, The Most High’) pleasure.
          </p>
          <p className="mb-4">Allah (SWT) says to the Muslims in the Qur’an:</p>
        </section>

        <section className="bg-[#e6f4f0] text-[#484848] font-kd p-8 rounded-lg">
          <blockquote className="italic text-lg">
            <p className="mb-4">
              &ldquo;And [due] to Allah from the people is a pilgrimage to the
              House – for whoever is able to find thereto a way. But whoever
              disbelieves – then indeed, Allah is free from need of the
              worlds.&rdquo;
            </p>
            <footer className="text-right">Qur’an | 3:97</footer>
          </blockquote>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <p className="text-[16px] font-kd mb-4">
            For anyone preparing to perform <em>Hajj</em>, it’s an incredibly
            exciting time, but also a time of great spiritual importance – the
            first time going can be a once in a lifetime experience for a
            Muslim.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The <em>Hajj</em> is a test of patience and temperament – a
            spiritual, emotional, and physical challenge. However, it offers
            Muslims the opportunity to refresh our spiritual selves, to cleanse
            us of our sins and to draw closer to Allah (SWT).
          </p>
          <p className="text-[16px] font-kd mb-4">
            As Prophet Muhammad (peace and blessings be upon him) said:
          </p>
        </section>

        <section className=" text-[#484848] font-kd p-8 rounded-lg mb-8">
          <blockquote className="italic text-[20px] font-kd">
            <p className="mb-4">
              &ldquo;Whoever performs Hajj for the sake of Allah and does not
              utter any obscene speech or do any evil deed, will go back [free
              of sin] as his mother bore him.&rdquo;
            </p>
            <footer className="text-right">Hadith | Bukhari and Muslim</footer>
          </blockquote>
        </section>

        <section>
          <p className="text-[16px] font-kd mb-4">
            <em>Hajj</em> takes place each year between the 8th and 12th of Dhul
            Hijjah. Muslims use the lunar calendar, so the corresponding
            Gregorian date will vary year to year.
          </p>
          <p className="text-[16px] font-kd mb-4">
            This year, <em>Hajj</em> is taking place between 14th June to the
            19th June 2024 in the Gregorian Calendar.
          </p>
          <p className="text-[16px] font-kd">
            This handy and comprehensive <em>Hajj</em> guide will help you
            understand the different aspects of the holy pilgrimage, from its
            origin, to how to perform Hajj.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	pt-5`}>
        <section className="mb-8 ">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            The Story Of Hajj
          </h2>
          <p className="text-[16px] font-kd mb-4">
            While <em className="border-b-2 border-[#00936C]">Hajj</em> is
            something that was taught to Muslims by the Prophet Muhammad (peace
            and blessings be upon him), its origin actually dates back to the
            teachings of another of Islam’s beloved Prophets, Ibrahim (AS)
            (which means upon him be peace), thousands of years before.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The Prophet Muhammad (peace and blessings be upon him) began the{" "}
            <em>Hajj</em> in 628 CE during the month of Dhul Hijjah, and is the
            same <em>Hajj</em> that Muslims perform today.
          </p>
          <p className="text-[16px] font-kd mb-4">
            However, Dhul Hijjah was also a sacred month for the pagan Arabs in
            pre-Islamic Arabia.
          </p>
          <p className="text-[16px] font-kd mb-4">
            During this month, fighting was forbidden for the Arabs, and they
            also made pilgrimage to the <em>Ka’ba</em> – the cubic like
            structure in <em>Masjid al-Haram</em>, that at the time was being
            used to house the Arabs’ pagan idols.
          </p>
          <p className="text-[16px] font-kd mb-4">
            It is known today by Muslims as <em>Baitullah</em>, or ‘the House of
            God’. It plays an important part in the rites of <em>Hajj</em> but
            is ultimately a mosque and not something that Muslims worship.
          </p>
          <p className="text-[16px] font-kd">
            In fact, the <em>Ka’ba</em> was built by Ibrahim (AS), or Prophet
            Abraham, thousands of years earlier by the command of Allah (SWT) –
            it is because of Ibrahim (AS) that Muslims perform <em>Hajj</em>.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            Hajar, Isma'il [AS] And The Well Of Zamzam
          </h2>
          <p className="text-[16px] font-kd mb-4">
            Ibrahim (AS) or Khalilullah (the friend of Allah) as he is referred
            to, is considered to be one of the greatest of Allah’s (SWT)
            creations. His reflective nature and the soundness of his heart
            brought him to the revelation of one God, Allah (SWT) – Ibrahim’s
            (AS) story of prophethood is well documented in the Islamic
            tradition.
          </p>
          <p className="text-[16px] font-kd mb-4">
            During his prophethood, Ibrahim (AS) encountered several trials that
            serve as reminders and lessons to mankind regarding devotion to
            Allah (SWT), sacrifice, faith, as well as other crucial tenets of
            Islam. These trials include the test of Ibrahim’s (AS) willingness
            to sacrifice his son Isma’il (AS) for the sake of Allah (SWT), and
            the test of leaving his wife Hajar and son Isma’il (AS) alone in the
            desert of Makkah – it is this test that provides the basis for Hajj.
          </p>
          <p className="text-[16px] font-kd mb-4">
            By the instruction of Allah (SWT), Ibrahim (AS) was to leave Hajar
            and Isma’il (AS) in the ancient desert of Makkah. The little food
            and water that they had soon ran out, and Isma’il (AS), an infant at
            the time, was crying of thirst. Hajar, desperately in search of
            water, ran between the nearby hills of Safa and Marwah in the hope
            of spotting someone who may be able to help.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Hajar returned to find Isma’il (AS) striking and scraping the ground
            with his leg in distress, when suddenly a spring burst forth from
            the barren desert. By the command of Allah (SWT), a source of water
            from deep within the earth (that is still in use today), provided
            Hajar and Isma’il (AS) with water – this is known as the well of Zam
            Zam.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The water source provided a means of trade for Hajar, with passing
            nomads exchanging food and other provisions for water. The site
            became prosperous for Hajar and her son, and when Ibrahim (AS) was
            commanded to return to them in the desert, he was amazed to see the
            miracles that had unfolded for them, and the fruits of his faith in
            Allah (SWT).
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            The Construction Of The Kaa’ba
          </h2>
          <p className="text-[16px] font-kd mb-4">
            It was at the site of the well of Zam Zam that Ibrahim (AS) was
            commanded to build the Kaa’ba.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Ibrahim (AS) and his son Isma’il (AS) worked together to build a
            small stone structure called the Kaa’bah. It was built to mark a
            space for the sacred gathering of Muslims – all those who believed
            in the one God, Allah (SWT).
          </p>
          <h3 className="text-[16px] font-bold font-kd mb-4">
            The Inception of Hajj (The Sacred Pilgrimage)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            As time elapsed, the site of the miracle well of ZamZam and the
            Kaa’bah would provide the means for Makkah to become a thriving and
            prosperous settlement. Ibrahim (AS) returned to the site each year
            to offer his pilgrimage to Allah (SWT), and years later, when
            Isma’il (AS) was given his prophethood, he continued the tradition –
            the inception of Hajj.
          </p>
          <p className="text-[16px] font-kd mb-4">
            However, during the thousands of years that would pass, the site
            that was built to commemorate the lessons of Ibrahim’s (AS) trial,
            the miracle of Allah (SWT) and most importantly the belief in one
            God, was taken over by pagan Arabs and the worship of idols and
            spirits. This now thriving and prosperous settlement grew into a
            city for trade and the worship of pagan Gods – of which the Kaa’ba
            would eventually house.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Thousands of years later, a man named Muhammad, born into the
            high-status Quraysh tribe of Makkah, would be given revelation and
            prophethood. The last of Allah’s (SWT) messengers, Muhammad (peace
            and blessings be upon him), established Islam in the land for
            mankind – with this came the commandment to restore the Kaa’ba to
            its original purpose and resume Hajj.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The first Hajj was performed in 632 CE, by the Prophet Muhammad
            (peace and blessings be upon him), re-establishing the traditions
            started by the Prophet Ibrahim (AS).
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            Hajj As We Know It Today
          </h2>
          <p className="text-[16px] font-kd mb-4">
            Today, over 2.5 million Muslims of every race, class, and culture
            around the world travel to Makkah each year in Dhul Hijjah, to stand
            equal before Allah (SWT) and fulfil the rites of Hajj.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Some people save up money their entire lives to be able to perform
            Hajj, while others are able to fulfil it more than once. Regardless
            of whether you’ve been able to attend or not, the month of Dhul
            Hijjah holds countless blessings for Muslims around the world.
            During the month of Dhul Hijjah, Muslims can even earn the reward of
            Hajj by going to Hajj by seeking hopeful reward during this holy
            month. Find out more about the benefits of Dhul Hijjah here.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            Who Is Eligible To Perform Hajj?
          </h2>
          <p className="text-[16px] font-kd mb-4">
            Hajj forms one of the five pillars of Islam. As such, all Muslims
            must perform Hajj at least once in their lifetime.
          </p>
        </section>
      </div>
      <div className="bg-blue-100">
        <div className={`${customClass} px-4  py-8 text-justify	`}>
          <h2 className="text-[20px] font-bold font-kd mb-8">
            The five pillars of Islam:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center justify-center items-center p-4 pb-7 bg-white shadow-lg rounded-lg space-y-3">
              <div className="justify-center flex items-center">
                <img
                  src="/images/HajjGuide/participate_teal_speech.gif"
                  alt=""
                  className="w-[200px] h-[200px] flex justify-center text-center items-center"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 font-kd">
                Shahada (Profession of Faith)
              </h3>
              <p className="text-[16px] font-kd">
                The belief and declaration that “There is no god but God, and
                Muhammad is the Messenger of God”
              </p>
            </div>
            <div className="text-center justify-center items-center p-4 pb-7 bg-white shadow-lg rounded-lg space-y-3">
              <div className="justify-center flex items-center">
                <img
                  src="/images/HajjGuide/salah.gif"
                  alt=""
                  className="w-[200px] h-[200px] flex justify-center text-center items-center"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 font-kd">
                Salah (Prayer)
              </h3>
            </div>
            <div className="text-center justify-center items-center p-4 pb-7 bg-white shadow-lg rounded-lg space-y-3">
              <div className="justify-center flex items-center">
                <img
                  src="/images/HajjGuide/zakat.gif"
                  alt=""
                  className="w-[200px] h-[200px] flex justify-center text-center items-center"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 font-kd">
                Zakat (Alms giving)
              </h3>
            </div>
            <div className="text-center justify-center items-center p-4 pb-7 bg-white shadow-lg rounded-lg space-y-3">
              <div className="justify-center flex items-center">
                <img
                  src="/images/HajjGuide/fast.gif"
                  alt=""
                  className="w-[200px] h-[200px] flex justify-center text-center items-center"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 font-kd">
                Sawm (Fasting)
              </h3>
            </div>
            <div className="text-center justify-center items-center p-4 pb-7 bg-white shadow-lg rounded-lg space-y-3">
              <div className="justify-center flex items-center">
                <img
                  src="/images/HajjGuide/hajj.gif"
                  alt=""
                  className="w-[200px] h-[200px] flex justify-center text-center items-center"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 font-kd">
                Hajj (Pilgrimage)
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className={`${customClass} px-4 py-5 text-justify	`}>
        <section className="mb-8">
          <p className="text-[16px] font-kd mb-4">
            However, there are still certain conditions that need to be met in
            order to perform <em>Hajj</em>:
          </p>
          <ol className="list-decimal list-inside text-[16px] font-kd mb-4 space-y-2">
            <li>
              Firstly, only Muslim adults (whether male or female) are required
              to perform <em>Hajj</em>. This means that while children may go to{" "}
              <em>Hajj</em>, it is not required of them.
            </li>
            <li>
              Secondly, the very weak, sick, elderly, or otherwise physically
              incapable Muslims are exempt from having to perform the
              pilgrimage.
            </li>
            <li>
              Thirdly, the Muslim must be financially able to perform{" "}
              <em>Hajj</em>. This means that a person in debt is not obliged to
              perform <em>Hajj</em> until he has cleared his debt – and must
              have the intention of doing so as a priority. However, if one is
              in debt, they may still perform <em>Hajj</em> as long as:
              <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                <li>The creditor permits it</li>
                <li>The debtor has time to pay off the debt</li>
                <li>
                  <em>Hajj</em> does not affect their ability to pay off the
                  debt
                </li>
              </ul>
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            A Step-By-Step Guide To Performing Hajj
          </h2>
          <p className="text-[16px] font-kd mb-4">
            SubhanAllah, each and every year, around 25,000 Muslims from the UK
            travel to the holy cities of Makkah to perform <em>Hajj</em>, in
            unity with Muslims from all over the globe.
          </p>
          <p className="text-[16px] font-kd mb-4">
            So, if you’re one of these pilgrims heading off to Saudi Arabia to
            fulfil this sacred pillar, read on! We’ve put together this handy
            step-by-step <em>Hajj</em> guide to help you through this blessed
            journey!
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            The 19 Steps Of Hajj At A Glance
          </h2>
          {/* <ol className="list-decimal list-inside text-[16px] font-kd mb-4 space-y-2">
            <li>Preparation and Intention</li>
            <li>Enter state of Ihram</li>
            <li>Tawaf x7</li>
            <li>Safa and Marwa</li>
            <li>Clip/Shave Hair (Umrah ends)</li>
            <li>Resting and Praying</li>
            <li>Enter state of Ihram</li>
            <li>Arrive at Mina</li>
            <li>Day of Arafah</li>
            <li>Muzdalifah (under the night sky)</li>
            <li>Rami (stoning of the devil)</li>
            <li>Hady</li>
            <li>Shaving of the Head</li>
            <li>Tawaf al-Ifadha</li>
            <li>Saai’</li>
            <li>Rami (stoning of the devil)</li>
            <li>Spend night at Mina</li>
            <li>Rami (stoning of the devil)</li>
            <li>Farewell Tawaf al-Wida</li>
          </ol> */}
          <ol className="list-decimal list-inside text-[16px] font-kd mb-4 space-y-2">
            {[
              "Preparation and Intention",
              "Enter state of Ihram",
              "Tawaf x7",
              "Safa and Marwa",
              "Clip/Shave Hair (Umrah ends)",
              "Resting and Praying",
              "Enter state of Ihram",
              "Arrive at Mina",
              "Day of Arafah",
              "Muzdalifah (under the night sky)",
              "Rami (stoning of the devil)",
              "Hady",
              "Shaving of the Head",
              "Tawaf al-Ifadha",
              "Saai’",
              "Rami (stoning of the devil)",
              "Spend night at Mina",
              "Rami (stoning of the devil)",
              "Farewell Tawaf al-Wida",
            ].map((item, index) => (
              <li
                key={index}
                className="before:content-[counter(list-item)] before:text-[#00936C] list-none"
              >
                &nbsp;&nbsp; {item}
              </li>
            ))}
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            When Do I Perform Hajj?
          </h2>
          <p className="text-[16px] font-kd mb-4">
            In the Islamic tradition, Muslims operate within the lunar calendar
            (as opposed to the Gregorian calendar), and <em>Hajj</em> takes
            place each year between the 8th to the 12th of Dhul Hijjah. However,
            the corresponding Gregorian dates differ year to year as the lunar
            calendar appears to shift forward approximately 11-12 days.
          </p>
          <p className="text-[16px] font-kd mb-4">
            These days, <em>Hajj</em> pilgrims travel to Makkah by land, air and
            sea during the days and weeks prior to the pilgrimage period. If
            you’re travelling from the UK, you are likely to fly to Saudi
            Arabia, touching down in Jeddah or Medina.
          </p>
          <p className="text-[16px] font-kd">
            From there you will travel with your <em>Hajj</em> group to Makkah.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            How Do I Perform Hajj?
          </h2>
          <p className="text-[16px] font-kd mb-4">
            There are three forms of <em>Hajj</em>: <em>Tamattu, Ifrad</em> and{" "}
            <em>Qiran</em>.
          </p>
          <p className="text-[16px] font-kd mb-4">
            For the purpose of this <em>Hajj</em> guide, we will use the{" "}
            <em>Tamattu</em> form of <em>Hajj</em>, which is what the Prophet
            Muhammad (peace and blessings be upon him) encouraged the Muslims to
            perform.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Here are the 19 steps of <em>Hajj</em> in detail:
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            1. Preparation and Intention
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Before you arrive at Makkah to begin <em>Hajj</em>, it’s important
            to make your intention (<em>niyyah</em>) within your heart. The
            intention must be to perform the <em>Hajj</em> for the sake of Allah
            alone, with a desire for good in the hereafter. It should not be
            done with the intention of being seen by others or for worldly gain.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            2. Entering The State Of Ihram
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Next, you’ll enter into the state of <em>ihram</em> (ritual purity).
            For men, this means wearing the designated white cloth with one
            piece wrapped around your shoulder and one around your waist. Ladies
            may wear any clothing of their choice but should ensure they observe
            the rules of <em>Hijab</em>. Face coverings, however, are not
            permitted. Full-covering shoes are also not permitted. Footwear must
            be in the form of sandals for both women and men.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <em>Ihram</em> describes the spiritual state you enter once you have
            made the intention to go to <em>Hajj</em>. When over 2.5 million
            pilgrims descend into Makkah, there should not be any outward
            distinction between them. Everyone stands equal before Allah (SWT) –
            one’s status, race, culture, and wealth are irrelevant. The rules
            around the clothing of <em>ihram</em> are extremely simple, yet
            strict and must be adhered to.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Throughout these holy days and whilst in the state of <em>ihram</em>
            , we should also be particularly mindful of our behaviour and words.
            We should avoid smoking, engaging in sexual relations, swearing,
            shaving our hair, and cutting our nails. We must also not use
            perfume or scented soaps.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Once you arrive at the holy Masjid al-Haram in Makkah, with these
            preparations in mind, you’re ready to start the biggest spiritual
            journey of your life!
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> Carry spare <em>ihram</em> clothing if you
            can. As for sandals, we recommend investing in a decent pair of
            trekking sandals. They tend to be the most comfortable and
            practical, given that you will be walking long distances on tarmac
            as well as gravel. Try and wear-in your sandals before you depart
            for Saudi so that you don’t break out in blisters or face discomfort
            once you’re there.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>
              {" "}
              Upon arrival in Makkah, you must first perform your <em>Umrah</em>
            </strong>
            , which means you will do your <em>Tawaf</em> and <em>Sa’i</em> as
            outlined in the next steps.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">3. Tawaf x 7</h3>
          <p className="text-[16px] font-kd mb-4">
            <em>Tawaf</em> is one of the principal rites of the pilgrimage and
            refers to walking in circles around the Ka’bah in an anti-clockwise
            motion.
          </p>
          <p className="text-[16px] font-kd mb-4">
            One <em>Tawaf</em> is made up of seven complete circuits, with each
            one starting and ending at the black stone, which is situated within
            the Ka’bah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            In addition to your <em>Tawaf</em>, you can also offer voluntary
            prayers to thank Allah (SWT) for arriving safely and to mark the
            start of this incredibly special spiritual journey.
          </p>
        </section>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            4. Safa and Marwa
          </h3>
          <p className="text-[16px] font-kd mb-4">
            After you’ve performed your <em>Tawaf</em>, you’ll then perform
            what’s known as <em>Sa’i</em> (walking and running between the two
            hills of Safa and Marwa).
          </p>
          <p className="text-[16px] font-kd mb-4">
            You’ll begin the <em>Sa’i</em> on the hill of Safa and walk towards
            the hill of Marwa. You will eventually see the green marker, at
            which point you will run until the next green marker and continue
            walking until you reach Marwa. This completes one lap. You will then
            return to Safa to complete your second lap. Your <em>Sa’i</em> is
            complete once you have performed a total of seven laps between the
            hills of Safa and Marwa.
          </p>
          <p className="text-[16px] font-kd mb-4">
            This is an important ritual in memory of Prophet Ibrahim’s (AS) wife
            Hajar, and her struggle in the desert in search of water for her son
            Prophet Isma’il (AS). <em>Sa’i</em> symbolises the ongoing struggle
            that we encounter throughout our lives, as Hajar experienced
            herself.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            7. Clip/Shave Hair (Umrah Ends)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Once <em>Sa’i</em> is complete, men will have their hair clipped or
            shaved, whilst a woman clips her hair to the length of her
            fingertip. This marks the completion of your <em>Umrah</em>,
            allowing you to leave <em>ihram</em>, until the 8th of Dhul Hijjah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> Be very patient here. You’ll be tired from
            tawaf, and you might notice fellow pilgrims in a hurry to complete
            the <em>Sa’i</em>. Feel free to take your time. Take regular breaks,
            and drink Zam Zam water which is available between Safa and Marwa.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            6. Resting and praying
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Now that you’ve performed your <em>Umrah</em>, for the rest of this
            blessed month, you’ll stay in Makkah to complete your spiritual
            journey of <em>Hajj</em>, surrounded by your fellow Muslim sisters
            and brothers, SubhanAllah! Make sure to get plenty of rest and make
            the best use of your time by performing sincere acts of worship.
            Your <em>Hajj</em> will begin on the 8th Dhul Hijjah.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            7. Re-entering State of Ihram (Day 1/8th Dhul Hijjah)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Fulfilling your sacred obligation of Hajj will be the most spiritual
            period of your life Insha’Allah (God willing), filled with blessings
            and forgiveness from Allah (SWT)! The 8th day of Dhul Hijjah marks
            the beginning of the days of Hajj and the next stage of your
            spiritual journey. You’ll purify yourself and enter the state of{" "}
            <em>ihram</em> once again.
          </p>
          <h4 className="text-xl font-bold mb-2">Rules of Ihram</h4>
          <p className="text-[16px] font-kd mb-4">
            It’s very important to note that when you are in a state of{" "}
            <em>ihram</em>, you are not permitted to smoke, swear, shave, clip
            your nails, or engage in any form of sexual relations. Fighting and
            arguments are also forbidden, and participants are prohibited from
            hunting, killing, or unjustifiably breaking anything. You must also
            avoid scented products such as perfumes, moisturisers, makeup, or
            soaps. You may, however, substitute them for unscented toiletries
            which are permissible to use.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Once you’re all set to enter the <em>ihram</em>, you’ll begin
            reciting the following invocation called the Talbiyah:
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>
              <em>
                لَبَّيْكَ اللّهُمَّ لَبَّيْكَ، لَبَّيْكَ لَا شَرِيكَ لَكَ
                لَبَّيْكَ، إِنَّ الْحَمْدَ وَالنِّعْمَةَ لَكَ وَالْمُلْك، لَا
                شَرِيكَ لَك
              </em>
            </strong>
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>Transliteration:</strong>
            <br />
            Labbayk Allāhumma labbayk. Labbayk lā shareeka laka labbayk. Inna
            al-hamda, wa n-ni’mata, Laka wal mulk. Lā shareeka lak.
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>Translation:</strong>
            <br />
            “Here I am, O Allah, here I am, here I am. You have no partner, here
            I am. Verily all praise and blessings are Yours, and all
            sovereignty. You have no partner.”
          </p>
          <p className="text-[16px] font-kd mb-4">
            You will then proceed with your Hajj group to the neighbourhood of
            Mina in Makkah, which is located roughly eight kilometres from the
            centre of Makkah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> We would highly recommend carrying some
            essentials such as unscented sun cream, unscented Vaseline and soap.
            Sun cream is particularly handy, especially if you have no hair as
            it is not permissible for men to cover their head or face whilst in{" "}
            <em>ihram</em>. Vaseline is also particularly valuable for both
            women and men to protect against painful rashes that may occur as a
            result of constant walking. All of these are readily available in
            Saudi Arabia.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            8. Arrive At Mina
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Once you arrive in the tent city (neighbourhood) of Mina, you’ll
            settle into your allocated tent. Here you’ll pray <em>Salah</em>{" "}
            (obligatory prayers), including Dhuhr, Asr, Maghrib, ‘Isha and Fajr,
            shortening your four-unit prayers to two units each, without
            combining them, as stated in the Qu’ran.
          </p>
          <p className="text-[16px] font-kd mb-4">
            You’ll spend the night and pray to Allah (SWT), reading the Qur’an
            and preparing for day two. It’s an important time for spiritual
            reflection and devotion, so try and make the most of this special
            night.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> Be patient here, as space inside the tents can
            be very tight, and you might find yourself very close to fellow
            pilgrims. The weather is likely to be very hot, so remember to keep
            yourself hydrated. The Saudi authorities have installed cold water
            stations in close proximity to all tents, so familiarise yourself
            with your surroundings. You may also notice that Hajj authority
            workers regularly stock up complementary cold drinks in cooler boxes
            next to your tent. They’re there for you to consume, so enjoy them
            to quench your thirst, but don’t forget the spiritual purpose of
            being there.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            9. Day of ‘Arafah (Day 2/9th Dhul Hijjah)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            After sunrise in Mina, you’ll then head to the plains of ‘Arafah,
            reciting <em>Istighfar</em> (asking for forgiveness) and making
            supplications – marking the Day of ‘Arafah when we ask Allah (SWT)
            for forgiveness for our sins.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Upon reaching the plains of Mount ‘Arafah, pilgrims observe
            shortened Dhuhr and Asr prayers combined (two <em>rakat</em> instead
            of four). However, according to Abu Hanifa, the combining of Dhuhr
            and Asr is only valid if one prays behind the Imam in the Masjid.
            Therefore if one was to pray in the tent, then they should not
            combine both salah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            On this day, there will be a sermon delivered from Masjid al-Nimra
            on Mount ‘Arafah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Try to listen to the <em>Khutbah</em> (sermon) if possible. Your
            group may also facilitate a translation of this sermon in English.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The day of ‘Arafah is one of the most important days for Muslims
            across the world, as Allah (SWT) refers to the Day of ‘Arafah in
            Surah al-Maidah as the Day on which He perfected His religion,
            completed His favours upon His beloved Prophet Muhammad (peace and
            blessings be upon him) and approved Islam as a way of life!
          </p>
          <p className="text-[16px] font-kd mb-4">
            The Prophet (peace and blessings be upon him) also said:
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <blockquote className="bg-[#e6f4f0] text-[#484848] font-kd p-4 rounded-lg mb-8">
          <p className="text-xl italic mb-4">
            “There is no day on which Allah frees people from the Fire more so
            than on the day of ‘Arafah. He comes close to those (people standing
            on ‘Arafah), and then He reveals before His Angels saying, ‘What are
            these people seeking.’”
          </p>
          <footer className="text-right">Hadith | Muslim</footer>
        </blockquote>
        <p className="text-[16px] font-kd mb-4">
          So, be sure to stand on the plains of ‘Arafah and make lots of Du’a
          (supplication), focusing your energy on Allah (SWT), asking Him for
          forgiveness and blessings for you and your family. Don’t forget to
          include your friends, relatives, neighbours as well as the wider{" "}
          <em>Ummah</em> (community) in your Du’a on this special day.
        </p>
        <p className="text-[16px] font-kd mb-4">
          <strong>Tip:</strong> Don’t be tempted to exhaust your energy and trek
          up Mount ‘Arafah, also known as Jabal al-Rahmah, on this day. Jabal
          al-Rahmah is the hill from where the Prophet Muhammad (peace and
          blessings be upon him) delivered his sermon. There is no authentic
          source to suggest any benefit in trekking the hill itself or to
          undertake this climb as a religious ritual. The level area surrounding
          the hill is called the Plains of ‘Arafah and this is where you should
          be spending your time in contemplation and prayer.
        </p>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            10. Arrive in Muzdalifah
          </h3>
          <p className="text-[16px] font-kd mb-4">
            After sunset, you’ll depart ‘Arafah and head to Muzdalifah – an open
            plain between Mina and ‘Arafah. Once you reach Muzdalifah you’ll
            perform your Maghrib and ‘Isha <em>Salah</em>, one after the other,
            shortening the ‘Isha <em>Salah</em> to two <em>Rakat</em>.
          </p>
          <p className="text-[16px] font-kd mb-4">
            As Abdullah ibn Umar (RA) narrates:
          </p>
          <blockquote className="bg-[#e6f4f0] text-[#484848] font-kd p-4 rounded-lg mb-4">
            <p className="text-[16px] font-kd mb-4">
              “The Prophet (peace and blessings be upon him) offered the Maghrib
              and ‘Isha prayers together at Muzdalifah with a separate{" "}
              <em>Iqamah</em> (second call to prayer) for each of them and did
              not offer any optional prayer in between them or after each of
              them.” [Bukhari]
            </p>
          </blockquote>
          <p className="text-[16px] font-kd mb-4">
            Afterwards, you can then spend the night in worship or resting. The
            Prophet (peace and blessings be upon him) went to sleep until
            shortly before Fajr, choosing not to engage in night worship as he
            normally did. So, don’t be tempted to exhaust yourself but rest
            instead – you have a long day ahead of you!
          </p>
          <p className="text-[16px] font-kd mb-4">
            While in Muzdalifah, you may also collect pebbles to perform{" "}
            <em>Rami</em> (the stoning of the devil) over the next three days.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The size of the pebbles should be similar to the size of date
            stones/seeds. <strong>You will need a total of 49 pebbles.</strong>
          </p>
          <p className="text-[16px] font-kd mb-4">
            However, it is advised that you pick up a further 21 pebbles as a
            precautionary measure to bring your total up to 70.
          </p>
          <p className="text-[16px] font-kd mb-4">
            When you proceed to the phases of throwing the pebbles at the{" "}
            <em>Jamarat</em> (the stone pillars), you may miss the target, or
            some pebbles may fall from your hand. Therefore, it’s better to have
            more than to be short. Pebbles can also be collected from anywhere
            in Mina.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> At Muzdalifah, you will stay under the night
            sky. There are no tents or other accommodation facilities here.
            Although there are plenty of lights, it is still fairly dark. Try to
            stay near your group, as it is very easy to get lost among the
            thousands of pilgrims. Toilets and Wudhu facilities are available in
            Muzdalifah, but they will likely be crowded so patience must be
            exercised here.
          </p>
          <p className="text-[16px] font-kd mb-4">
            We would highly recommend you use the toilets and freshen up before
            you leave ‘Arafah.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            11. Rami and Hady (Day 3/10th Dhul Hijjah & start of Eid al-Adha)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            The 10th of Dhul Hijjah is also called the <em>Yawm al-Nahr</em>, or
            the Day of Sacrifice (<em>Qurbani</em>).
          </p>
          <p className="text-[16px] font-kd mb-4">
            After performing Fajr <em>Salah</em> you will depart Muzdalifah and
            go back towards Mina. Remember to continuously recite the{" "}
            <em>Talbiyah</em>.
          </p>
          <p className="text-[16px] font-kd mb-4">
            On this day, Pilgrims perform the <em>Hady</em> (sacrificial animal)
            and also commence the first of three days ‘stoning of the devil’
            rite or <em>Rami</em>. Muslims around the world also offer{" "}
            <em>Qurbani</em> during this time and begin the four-day festival of
            Eid al-Adha. We know this from this verse in the Qur’an:
          </p>
          <blockquote className="text-[#484848] p-4 rounded-lg mb-4">
            <p className="text-[16px] font-kd mb-4">
              “And when you are safe, then, whoever avails the advantage of the
              ‘Umrah along with the Hajj shall make an offering of whatever
              animal is available. However, any one who finds none shall fast
              for three days during Hajj, and for seven days when you return;
              thus they are ten in all. This is for him whose family folk are
              not residents of Al-Masjid-ul-Harām.” [Qur’an 2:196]
            </p>
          </blockquote>
          <h4 className="text-[20px] font-bold font-kd mb-2">
            Origin Of Rami (The Stoning Of The Devil)
          </h4>
          <p className="text-[16px] font-kd mb-4">
            The stoning of the <em>Jamarat</em> – otherwise referred to as the
            ‘stoning of the devil’ – is a ritual carried out by Hajj pilgrims
            whereby pebbles are thrown at three stone structures in Mina across
            three days.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The first day of stoning occurs on the 10th of Dhul Hijjah. On this
            day Muslims also offer <em>Qurbani</em> and celebrate Eid al-Adha.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The act of throwing stones at the <em>Jamarat</em> is known as{" "}
            <em>Rami</em>. The ritual of <em>Rami</em> is symbolic of the
            actions of Ibrahim (AS) when he was faced with the trial of having
            to sacrifice his son, Isma’il (AS) upon the commandment of Allah
            (SWT).
          </p>
          <p className="text-[16px] font-kd mb-4">
            On the way to carry out the commandment, Iblis (Satan) repeatedly
            tried to tempt Ibrahim’s (AS) into disobeying Allah (SWT). As
            Ibrahim (AS) reached Jamarat al-Aqaba, Allah (SWT) ordered Angel
            Jibreel (AS) to instruct Ibrahim (AS) to throw seven stones at
            Iblis. He obliged, and Iblis fled immediately. The three{" "}
            <em>Jamarat</em> indicate the three places where Iblis tried to
            dissuade Ibrahim (AS) from obeying the command of Allah. However,
            the pillars do not contain Iblis, as many people wrongly led to
            believe.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            How To Perform The Rami (The Stoning Of The Devil)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            On the 10th, 11th, and 12th day of Dhul Hijjah, you will perform{" "}
            <em>Rami</em> (the stoning of the devil). The size of the pebbles
            should be similar to the size of date stones or seeds.{" "}
            <strong>You will need a total of 49 pebbles.</strong>
          </p>
          <p className="text-[16px] font-kd mb-4">
            You’ll need a certain number of pebbles for each of the three days.
            The breakdown is as follows:
          </p>
          <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
            <li>7 pebbles for the 10th of Dhul Hijjah</li>
            <li>21 pebbles for the 11th of Dhul Hijjah</li>
            <li>21 pebbles for the 12th of Dhul Hijjah</li>
          </ul>
          <p className="text-[16px] font-kd mb-4">
            Try to carry four small pouches per person for your pebbles. UK coin
            bags are a good size and convenient to carry. Use the four pouches
            to group pebbles according to the breakdown above.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Once you reach the <em>Jamarat</em>, you will head to Jamarat
            al-Aqaba, which is the largest pillar, and here you will throw the
            first seven pebbles at the concrete pillar. You will only pelt this
            one pillar on the first day.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Upon each throw, you’ll say the takbīr:
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>
              <em>اللّهُ أَكْبَرُ</em>
            </strong>
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>Transliteration:</strong>
            <br />
            Allāhu ‘Akbar
          </p>
          <p className="text-[16px] font-kd mb-4 text-center">
            <strong>Translation:</strong>
            <br />
            “Allah is The Greatest”
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> Don’t rush to perform the Rami. The Hajj
            authority will most likely allocate a set time for your group to go
            and perform Rami. They do this to minimise overcrowding and the risk
            to pilgrims.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Try not to get angry and throw your sandals or other valuable
            possessions at the pillars. Iblis is not contained within the
            pillar, so you’ll only lose your valuables and there is no benefit
            in doing so!
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            12. Qurbani and Eid al-Adha
          </h3>
          <p className="text-[16px] font-kd mb-4">
            The festival of Eid al-Adha, or the Feast of Sacrifice, is also
            celebrated by Muslims around the world on this day who are not on
            pilgrimage on the 10th Dhul Hijjah. By offering <em>Qurbani</em>{" "}
            (sacrifice), Muslims mark Prophet Ibrahim’s (AS) willingness to
            sacrifice his son Isma’il (AS) upon the commandment of Allah (SWT).
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            13. Shaving The Head (Day 4/11th Dhul Hijjah)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            After offering the <em>Hady</em>, you will proceed to shave or trim
            your hair if you are male. The Prophet Muhammad (peace and blessings
            be upon him) shaved his hair and this is preferable. A woman trims
            her hair by the length of a fingertip.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Now you are allowed to leave the state of <em>ihram</em> and wear
            comfortable clothing. You are also allowed to resume otherwise Halal
            activities that were forbidden in the <em>ihram</em>, except for
            sexual intimacy. It is Sunnah (practice of the Prophet PBUH) to
            apply perfume as the Prophet Muhammad (peace and blessings be upon
            him) smelt strongly of musk at this point.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> Use disposable blades to shave each other’s
            head within your group. If that’s not possible, you’ll notice a
            number of barbers in Mina ready to shave your head for a price.
            Ensure that the barbers use new blades to avoid infection.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            14 & 15. Tawaf al-Ifadha and Saai’ (Day 5/12th Dhul Hijjah)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            You will now go to Makkah to perform <em>Tawaf al-Ifadha</em> and
            then another circuit of <em>Saai’</em> as part of your <em>Hajj</em>{" "}
            rituals.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <em>Tawaf al-Ifadha</em> and <em>Saai’</em> are obligatory. You must
            perform the <em>Tawaf al-Ifadha</em> and the <em>Saai’</em> after
            the <em>Rami</em>, the <em>Qurbani</em> (sacrifice) and shaving (or
            trimming) of the head.
          </p>
          <p className="text-[16px] font-kd mb-4">
            With the completion of the <em>Tawaf</em> and <em>Saai’</em>, you
            are then allowed to relax and do everything that was lawful before
            entering the <em>ihram</em>, including engaging in marital
            relations.
          </p>
          <p className="text-[16px] font-kd mb-4">
            You will, however, return to your tents in Mina and continue with
            the remaining rituals of <em>Hajj</em>.
          </p>
          <p className="text-[16px] font-kd mb-4">
            <strong>Tip:</strong> The Tawaf area will be extremely crowded
            during this time. Try to use the upper levels of the Haram or the
            roof. You might want to do this around midnight when it tends to be
            quieter.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            16. The Second Day of Rami
          </h3>
          <p className="text-[16px] font-kd mb-4">
            On the 11th Dhul Hijjah, you will proceed to your second day of{" "}
            <em>Rami</em> (the stoning of the devil).
          </p>
          <p className="text-[16px] font-kd mb-4">
            On this occasion you pelt each of the three pillars in order. You
            will begin with Jamarah al-Ula (the small pillar), then Jamarah
            al-Wusta (the second/middle pillar) and finally, Jamarah al-Aqaba
            (the third/large pillar). You stop after the first and second
            Jamarat to make Du’a facing the qibla. Each one should be stoned
            with seven consecutive pebbles accompanied by the Takbir. Remember
            to take your spare pebbles with you in case you lose some!
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            17. Spend the night in Mina
          </h3>
          <p className="text-[16px] font-kd mb-4">
            Once your second <em>Rami</em> is completed, you will return to your
            camp in Mina and spend the rest of the day and night in worship,
            making the most of the remaining time you have.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            18. The Third Day of Rami
          </h3>
          <p className="text-[16px] font-kd mb-4">
            On the afternoon of 12th Dhul Hijjah, you’ll have your final batch
            of pebbles (21 pebbles) ready to repeat the same steps as the
            previous day.
          </p>
        </section>
      </div>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <h3 className="text-[20px] font-bold font-kd mb-4">
            19. Tawaf al-Wida (The Farewell Tawaf)
          </h3>
          <p className="text-[16px] font-kd mb-4">
            You’ve now only one step left to perform before completing Hajj and
            departing from Makkah. The farewell Tawaf is the last rite Muslims
            must perform.
          </p>
          <p className="text-[16px] font-kd mb-4">
            This <em>Tawaf</em> is <em>Wajib</em> (obligatory) according to
            Hanafis, Shafi'is and Hanbalis but <em>Sunnah</em> according to
            Malikis and must be performed prior to leaving the boundaries of the{" "}
            <em>Haram</em>. Omitting this <em>Tawaf</em>, without a valid
            reason, is not deemed lawful in Islam.
          </p>
          <p className="text-[16px] font-kd mb-4">Ibn Abbas (RA) narrated:</p>
          <blockquote className="text-[#484848] p-4 rounded-lg mb-4">
            <p className="text-[16px] font-kd mb-4">
              “The people were ordered to perform the Tawaf al-Wida as the last
              thing before leaving (Makkah), except the menstruating women who
              were excused.” [Bukhari]
            </p>
          </blockquote>
          <p className="text-[16px] font-kd mb-4">
            For this <em>Tawaf</em>, you will complete seven laps of Tawaf. Then
            perform two <em>Rakat</em> of <em>Salah</em> and drink Zam Zam
            water. There is no <em>Sa’i</em> or shaving/trimming of the head
            after this <em>Tawaf</em>.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Hajj Mabrook! You’ve now completed your Hajj!
          </p>
          <p className="text-[16px] font-kd mb-4">
            For those of us who are unable to travel and perform <em>Hajj</em>,
            don’t despair! The best ten days of the year are 1st to the 10th of
            Dhul Hijjah.
          </p>
          <p className="text-[16px] font-kd mb-4">
            These days are a second chance to earn the mercy and forgiveness of
            Allah (SWT) after Ramadan.
          </p>
          <p className="text-[16px] font-kd mb-4">
            For those of us who haven’t been able to go to <em>Hajj</em> this
            year, we should use this blessed time to do more good deeds. These
            can include anything such as giving charity, honouring one’s
            parents, upholding the ties of kinship, and enjoying what is good
            and forbidding what is evil. It is also important to enhance our
            obligatory acts of worship by being mindful of our prayers and by
            making sure our <em>Zakat</em> on time. For those of us who have not
            yet completed the pilgrimage of <em>Hajj</em>, this is also an ideal
            time to make Du’a that Allah (SWT) grants us the opportunity when He
            deems best.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Our Prophet (peace and blessings be upon him) said:
          </p>
        </section>
      </div>
      <blockquote className="bg-[#e6f4f0] text-[#484848] font-kd py-2 rounded-lg mb-4">
        <p className="text-xl italic mb-4 w-[85%] mx-auto">
          “There are no days that are greater before Allah or in which good
          deeds are more beloved to Him, than these ten days, so recite a great
          deal of Tahleel (saying of la ilaaha illa Allah), Takbeer (saying of
          Allahu Akbar) and Tahmeed (Alhamdulillah) during them.”
        </p>
        <footer className="text-right w-[85%] mx-auto">Hadith | Ahmad</footer>
      </blockquote>
      <div className={`${customClass} px-4 text-justify	`}>
        <section className="mb-8">
          <p className="text-[16px] font-kd mb-4">
            It’s highly recommended for you to fast and remember Allah (SWT) as
            much as you can.
          </p>
          <p className="text-[16px] font-kd mb-4">
            The Qurbani for non-travellers is <em>Sunnah muakkadah</em>{" "}
            (confirmed Sunnah) according to most scholars and <em>Waajib</em> (a
            religious duty) according to the Hanafis. If you can afford to offer
            the sacrifice, then you are encouraged to do so. This would be in
            remembrance of the struggle and dedication of Prophet Ibrahim (AS)
            to Allah (SWT) and support a family in need.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Give your <em>Zakat</em> and <em>Sadaqah</em> during the blessed
            days of Dhul Hijjah and reap the rewards of this blessed month.
          </p>
          <p className="text-[16px] font-kd mb-4">
            Don’t forget to give your <em>Qurbani</em> in time for Eid!
          </p>
        </section>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default HajjGuide;
