import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Discover the Hidden Gem of Taif: Masjid Al Qantara
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Masjid Al Qantara
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px]">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Nestled in the heart of Taif, a city renowned for its cool
                mountain breeze and lush landscapes, Masjid Al Qantara stands as
                a testament to the rich history and culture of Saudi Arabia.
                Taif, often referred to as the "Garden of the Hejaz," is located
                in the western part of the country, positioned on the slopes of
                the Sarawat Mountains. This city is celebrated for its scenic
                beauty, fertile soil, and favorable climate, which make it a
                popular summer retreat for both residents and visitors. Masjid
                Al Qantara is strategically situated within Taif, making it
                easily accessible for tourists and locals alike.
              </p>
              <p className="lg:hidden xl:block">
                The city itself is well-connected by a network of roads, and its
                proximity to major cities such as Mecca, approximately 70
                kilometers to the west, enhances its accessibility. This makes
                Taif, and consequently Masjid Al Qantara, an essential stop for
                pilgrims and travelers exploring the region. The unique
                geographical location of Taif, at an elevation of over 1,800
                meters (about 6,000 feet) above sea level, provides a refreshing
                climate that contrasts sharply with the typically arid
                conditions of the Arabian Peninsula. This high elevation
                contributes to the city’s moderate temperatures, particularly in
                the summer months, making it a pleasant escape from the intense
                heat experienced elsewhere in the region. The cooler climate,
                combined with the city's abundant greenery and natural springs,
                has earned Taif the nickname "The Summer Capital of Saudi
                Arabia.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/madhounHouse/image4.webp"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            The city itself is well-connected by a network of roads, and its
            proximity to major cities such as Mecca, approximately 70 kilometers
            to the west, enhances its accessibility. This makes Taif, and
            consequently Masjid Al Qantara, an essential stop for pilgrims and
            travelers exploring the region. The unique geographical location of
            Taif, at an elevation of over 1,800 meters (about 6,000 feet) above
            sea level, provides a refreshing climate that contrasts sharply with
            the typically arid conditions of the Arabian Peninsula. This high
            elevation contributes to the city’s moderate temperatures,
            particularly in the summer months, making it a pleasant escape from
            the intense heat experienced elsewhere in the region. The cooler
            climate, combined with the city's abundant greenery and natural
            springs, has earned Taif the nickname "The Summer Capital of Saudi
            Arabia.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Masjid Al Qantara in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Masjid Al Qantara, also known as Al Madhoun House, holds
              significant historical and religious importance in Islam. The
              mosque is believed to date back to the early Islamic era, making
              it a vital piece of the Islamic heritage in the region. It has
              served as a place of worship and community gathering for
              centuries, embodying the spiritual and social fabric of the local
              Muslim population. The architecture of Masjid Al Qantara is a
              blend of traditional Islamic design and local Taif craftsmanship.
              Its unique features include intricate calligraphy, beautifully
              carved wooden doors, and a serene courtyard that provides a
              tranquil space for prayer and reflection. The mosque's design
              reflects the deep-rooted Islamic architectural traditions while
              incorporating elements that are unique to the region, showcasing
              the rich cultural tapestry of Taif.
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Masjid Al Qantara
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Guided Tours: To fully appreciate the historical significance
                and architectural beauty of Masjid Al Qantara, consider taking a
                guided tour. Knowledgeable guides can provide insights into the
                mosque's history, its role in the local community, and its
                architectural features.
              </li>
              <li>
                - Prayer Time Visits: Visiting the mosque during prayer times
                allows you to experience the spiritual ambiance and witness the
                local community coming together for worship. It is a serene and
                humbling experience that highlights the mosque's importance in
                daily life.
              </li>
              <li>
                - Photography: The mosque's picturesque location and stunning
                architecture make it a great spot for photography. Be respectful
                of worshippers and adhere to local customs when taking photos.
              </li>
              <li>
                - Cultural Etiquette: As with any religious site, it's essential
                to dress modestly and behave respectfully. Women should cover
                their heads, and both men and women should ensure their clothing
                covers their arms and legs.
              </li>
              <li>
                - Local Cuisine: After exploring the mosque, take some time to
                enjoy the local cuisine in Taif. The city is known for its
                delicious traditional dishes, which provide a perfect way to
                round off your visit.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
