import React from 'react';
import { NavLink } from 'react-router-dom';
import { Person, Security, Payment, Notifications, Tune, PrivacyTip, Group } from '@mui/icons-material';

const Sidebar = ({ currentPath }) => {
  const menuItems = [
    { name: 'Personal details', icon: <Person />, path: '/personal-details' },
    // { name: 'Preferences', icon: <Tune />, path: '/preferences' },
    { name: 'Security', icon: <Security />, path: '/security' },
    // { name: 'Payment details', icon: <Payment />, path: '/payment-details' },
    // { name: 'Privacy', icon: <PrivacyTip />, path: '/privacy' },
    { name: 'Email notifications', icon: <Notifications />, path: '/email-notifications' },
    // { name: 'Other travellers', icon: <Group />, path: '/other-travellers' },
  ];

  return (
    <div className="w-full border rounded-lg">
      <ul>
        {menuItems.map((item, index) => (
          <li key={index} className={`border-b ${item.path === currentPath ? '' : ''}`}>
            <NavLink
              to={item.path}
              className={`flex items-center p-6 rounded-lg text-sm hover:bg-gray-100 font-light  ${item.path === currentPath ? 'text-[#00936C] font-light' : ''}`}
            >
              <span className="mr-3">{item.icon}</span>
              <span >{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
