import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";
import { MasjidGhamama } from "../../../../../../data";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/MasjidMustarah5.jpg",
    process.env.PUBLIC_URL + "/images/MasjidMustarah2.svg",
    process.env.PUBLIC_URL + "/images/MasjidMustarah3.svg",
    process.env.PUBLIC_URL + "/images/MasjidMustarah4.svg",
  ];
  console.log(images);

  return (
    <div className="">
      <HeaderFooter>
        <Banner image={MasjidGhamama.hero.image} title={"Masjid Mustarah"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
