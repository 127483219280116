import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/Taif/holyplace/rosefields/image4.jpeg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/rosefields/image3.jpg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/rosefields/image2.jpg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/rosefields/image1.jpg",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={"/images/Taif/holyplace/rosefields/image1.jpg"}
          title={"The Roses of Taif"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
