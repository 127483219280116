import React from "react";

const Page = () => {
  // useEffect(() => {
  //   // Define the function inside useEffect
  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };
  //   // Call the function
  //   scrollToTop();
  // }, []);

  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Discover Tranquility at Salman Farsi Garden in Saudi Arabia
        </p>
      </div>
      <div className="w-[85%] mx-auto mt-12">
        <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
          {" "}
          The Geographical Location of the Garden of Salman Farsi
        </h1>
        <div className="">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                The Garden of Salman Farsi, known as Bagh-e-Salman-e-Farsi in
                Persian, is situated in the heart of Iran, specifically in the
                city of Shiraz. Shiraz is renowned for its historical and
                cultural significance, earning the title of the "City of
                Gardens." It is located in the Fars Province, an area that has
                been a significant center of Persian culture for over a
                millennium. Geographical Details: The exact coordinates of the
                Garden of Salman Farsi place it within the lush landscapes that
                characterize much of the Shiraz region. Shiraz benefits from a
                moderate climate and fertile soil, making it ideal for the
                cultivation of gardens and vineyards. The garden itself is
                nestled amidst rolling hills and verdant greenery, providing a
                serene and picturesque retreat from the bustling city life.
                Accessibility and Atmosphere: The garden's location ensures easy
                accessibility for both locals and tourists, offering a tranquil
                escape with its combination of natural beauty and historical
                relevance. Visitors can immerse themselves in the rich history
                and cultural heritage of Shiraz while enjoying the peaceful
                ambiance of the garden. Cultural and Historical Significance:
                The Garden of Salman Farsi is not merely a place of natural
                beauty but also a symbol of Persian horticultural artistry. It
                reflects the Persian tradition of designing gardens as
                paradisiacal spaces that blend seamlessly with the surrounding
                landscape. Such gardens are known for their meticulous layout,
                use of water features, and inclusion of pavilions or resting
                areas.
              </p>
            </div>
            <div>
              <img
                src="/images/salman2.svg"
                alt="Masjid-abu-bakar"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="pt-7">
            <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
              The Garden of Salman Farsi in Islam
            </h2>
            <div className="mt-4">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
                The Garden of Salman Farsi holds significant spiritual
                importance in Islam, named after Salman the Persian (Salman
                al-Farsi), one of the revered companions of the Prophet
                Muhammad. Salman al-Farsi is celebrated for his profound wisdom
                and contributions to early Islamic society, including his
                pivotal role in the Battle of the Trench where his strategic
                insight led to the successful defense of Medina. In Islamic
                tradition, gardens are often seen as earthly representations of
                paradise, a concept deeply embedded in the Quranic descriptions
                of Jannah (heaven). The Garden of Salman Farsi, therefore,
                symbolizes a spiritual haven, reflecting the Islamic ethos of
                peace, contemplation, and the beauty of creation. It serves as a
                reminder of the contributions of Salman al-Farsi and his journey
                from Persia to embracing Islam, embodying the universal reach
                and inclusive nature of the religion.
              </p>
            </div>
            <div className="mt-8 flex flex-col items-center">
              <img
                src="/images/salman3.svg"
                //   src="https://via.placeholder.com/600x300"
                alt="Islamic Calligraphy"
                className="w-full h-auto rounded-lg shadow-lg"
              />
              <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
                "The Garden of Salman Farsi in Saudi Arabia"
                <br />
              </p>
            </div>
          </div>
        </div>
        <main className="pt-7">
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Photography and Art</h2>
            <p className="text-lg mb-4">
              The natural beauty and historical architecture of the garden
              provide excellent opportunities for photography and artistic
              inspiration. Whether you are an amateur photographer or a seasoned
              artist, the garden offers countless subjects to capture and
              interpret.
            </p>
            <div className="flex justify-center">
              <img
                src="/images/salman4.svg"
                alt="Photography in the Garden of Salman Farsi"
                className="rounded-lg shadow-lg"
              />
            </div>
          </section>
          <section>
            <h2 className="text-2xl font-bold mb-4">Cultural Events</h2>
            <p className="text-lg mb-4">
              Throughout the year, the Garden of Salman Farsi hosts various
              cultural events, including poetry readings, musical performances,
              and religious ceremonies. These events offer a deeper
              understanding of the local culture and traditions, providing
              visitors with a unique and enriching experience.
            </p>
            <div className="flex justify-center">
              <img
                src="/images/salman5.jpg"
                alt="Cultural Events in the Garden of Salman Farsi"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Page;
