import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Every spring roses bloom in the western Saudi city of Taif, turning
          pockets of the Kingdom’s vast desert landscape a vivid & fragrant
          pink.
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Discovering the Beauty and Benefits of Taif Rose Fields
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Nestled amidst the majestic mountains of Taif in Saudi Arabia,
                the Taif rose fields offer a breathtaking spectacle that
                captivates both the eyes and the senses. These fields are
                renowned worldwide for their cultivation of Damask roses (Rosa
                damascena), a delicate and aromatic flower that has been
                cherished for centuries for its beauty and therapeutic
                properties.
                <br />
                The Charm of Taif Rose Cultivation
                <br />
                Taif, with its cool climate and fertile soil, provides an ideal
                environment for growing Damask roses. The cultivation of roses
                here dates back to ancient times, with the tradition passed down
                through generations. From March to May, when the roses bloom in
                full splendor, the landscape of Taif transforms into a sea of
                pink and red hues, filling the air with a sweet, floral
                fragrance.
                {/*  */}
              </p>
              <p className="text-[#484848] lg:hidden xl:block mb-4 font-cairo font-medium text-[15px] mr-10">
                Harvesting and Production Process The process of harvesting
                roses in Taif is meticulous and labor-intensive. During the
                early morning hours, skilled farmers carefully handpick the
                delicate rose petals before the sun rises too high, ensuring the
                preservation of their fragrance and essential oils. These petals
                are then distilled through a traditional method known as steam
                distillation, where they yield precious rose oil, also called
                "attar of roses."
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/rosefields/image1.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 font-cairo font-medium text-[15px] mr-10">
            Harvesting and Production Process The process of harvesting roses in
            Taif is meticulous and labor-intensive. During the early morning
            hours, skilled farmers carefully handpick the delicate rose petals
            before the sun rises too high, ensuring the preservation of their
            fragrance and essential oils. These petals are then distilled
            through a traditional method known as steam distillation, where they
            yield precious rose oil, also called "attar of roses."
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Significance
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Beyond its architectural beauty, Shubra Palace holds historical
              significance as a residence for members of the Saudi royal family
              and visiting dignitaries over the decades. It served as a venue
              for important state meetings, cultural gatherings, and diplomatic
              events, embodying the kingdom's diplomatic and cultural outreach.
              <br />
              Cultural and Artistic Legacy
              <br />
              The palace is not only a symbol of royal prestige but also a hub
              of cultural preservation and artistic expression. It houses a rich
              collection of artifacts, including traditional Saudi furnishings,
              ancient manuscripts, and artworks that depict the kingdom's
              history and cultural evolution. Visitors can explore these
              exhibits to gain insights into Saudi Arabian heritage and artistic
              traditions.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/rosefields/image2.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        {/* <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Ecological Conservation and Sustainability
          </h2>
          <div className="mt-4">
            Shubra Palace in Saudi Arabia exemplifies a commitment to ecological
            conservation and sustainability, ensuring its recreational value and
            environmental significance. The management of Shubra Palace
            prioritizes sustainable practices such as water conservation,
            preservation of native flora, and protection of wildlife. Visitors
            are encouraged to respect and contribute to maintaining the palace's
            natural ecosystem, ensuring its beauty endures for future
            generations to appreciate.
            <br />
            Beyond its ecological role, Shubra Palace stands as a symbol of
            Saudi Arabia's cultural charm and dedication to enhancing community
            well-being through its serene surroundings. Whether visitors seek
            relaxation, recreation, or cultural exploration, the palace offers a
            sanctuary where one can reconnect with nature, find solace, and
            create lasting memories with loved ones. It embodies the warmth and
            tranquility of Saudi hospitality, inviting all to experience its
            cultural richness firsthand.
          </div>
        </div> */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Benefits of Taif Rose Oil
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Cosmetic and Perfumery: Taif rose oil is highly valued in the
                perfume industry for its rich, floral scent. It is often used as
                a base note in luxury perfumes, adding depth and complexity to
                fragrances.
              </li>
              <li>
                - Skincare: Rose oil is renowned for its skincare benefits. It
                has moisturizing and soothing properties, making it suitable for
                sensitive skin. It helps in reducing redness and inflammation,
                and is used in various skincare products like creams, lotions,
                and serums.
              </li>
              <li>
                - It is used in aromatherapy to promote relaxation, reduce
                stress, and enhance mood. Taif rose essential oil can be
                diffused, added to bathwater, or used in massage blends.
                Skincare: Taif rose oil is often included in high-end skincare
                products due to its potential benefits for the skin.
              </li>
              <li>
                - Rose is often used to enhance meditation to promote feelings
                of love, compassion, and self-worth
              </li>
              <li>
                - Taif rose water is used as an essential ingredient in some
                sectarian recipes for desserts and delicacies
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
