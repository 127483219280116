import React from "react";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
import Subscription from "../../../../components/subscription";
import Footer from "../../../../components/footer";

const Reservation = () => {
  return (
    <div>
      <BookingNavbar/>
      <div className="min-h-screen bg-gray-100 md:p-6 p-3">
        <div className="max-w-4xl mx-auto rounded-lg md:p-8">
          <h1 className="text-[20px] font-kd mb-6 text-center font-bold">
            Help with a Reservation
          </h1>
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Viewing Reservations
            </h2>
            <img
              src="https://unsplash.com/photos/gMsnXqILjp4/download?force=true&w=1920"
              alt="Viewing Reservations"
              className="w-full mb-4 rounded"
            />
            <p className="text-gray-600 mb-4">
              You can view all your reservations from the dashboard. Follow
              these steps to access your reservations:
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>Login to your vendor account.</li>
              <li>
                Navigate to the "Reservations" section from the dashboard menu.
              </li>
              <li>
                View a list of all bookings including details like customer
                name, booking date, and package details.
              </li>
            </ul>
          </section>
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Managing Reservations
            </h2>
            <img
              src="https://unsplash.com/photos/2FPjlAyMQTA/download?force=true&w=1920"
              alt="Managing Reservations"
              className="w-full mb-4 rounded"
            />
            <p className="text-gray-600 mb-4">
              You can manage your reservations efficiently through the following
              steps:
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>
                <strong>Confirm or Cancel Reservations:</strong> Click on a
                reservation to view its details. Use the options provided to
                confirm or cancel the reservation.
              </li>
              <li>
                <strong>Update Reservation Status:</strong> Keep the customer
                informed by updating the reservation status (e.g., confirmed,
                pending, cancelled).
              </li>
              <li>
                <strong>Modify Reservation Details:</strong> If necessary, you
                can modify details such as travel dates, customer information,
                and special requests.
              </li>
            </ul>
          </section>
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Responding to Customer Inquiries
            </h2>
            <img
              src="https://unsplash.com/photos/OQMZwNd3ThU/download?force=true&w=1920"
              alt="Responding to Customer Inquiries"
              className="w-full mb-4 rounded"
            />
            <p className="text-gray-600 mb-4">
              It is important to respond promptly to customer inquiries related
              to reservations. Here's how:
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>Check the "Messages" section regularly for new inquiries.</li>
              <li>
                Provide clear and detailed responses to customer questions.
              </li>
              <li>
                Use the message templates available for quick and consistent
                communication.
              </li>
              <li>Keep a record of all communications for future reference.</li>
            </ul>
          </section>
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Handling Cancellations and Refunds
            </h2>
            <p className="text-gray-600 mb-4">
              Cancellations and refunds are part of the business. Follow these
              steps to handle them efficiently:
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>
                <strong>Process Cancellations:</strong> Navigate to the
                reservation details and click on the "Cancel" option. Confirm
                the cancellation to proceed.
              </li>
              <li>
                <strong>Issue Refunds:</strong> Follow the platform’s refund
                policy to process refunds. Ensure you inform the customer about
                the refund status.
              </li>
              <li>
                <strong>Maintain Records:</strong> Keep detailed records of all
                cancellations and refunds for future reference and reporting.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Need Further Assistance?
            </h2>
            <p className="text-gray-600 mb-4">
              If you need additional help with reservations, please contact our
              support team. We are here to assist you with any issues or
              questions.
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>
                Visit our{" "}
                <a
                  href="/help-center"
                  className="text-[#00936C] hover:underline"
                >
                  Help Center
                </a>{" "}
                for FAQs and guides.
              </li>
              <li>
                Contact our support team via email at{" "}
                <a
                  href="mailto:support@hajjumrah.com"
                  className="text-[#00936C] hover:underline"
                >
                  support@hajjumrah.com
                </a>
                .
              </li>
              <li>
                Use the live chat option available on our platform for real-time
                assistance.
              </li>
            </ul>
          </section>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Reservation;
