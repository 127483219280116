// Currency.js
import React, { useContext } from 'react';
import { CurrencyContext } from '../../utility/CurrencyContext';

const Currency = ({ setIsOpen }) => {
  const { updateCurrency } = useContext(CurrencyContext);

  const SuggestedCurrencyData = [
    { title: "U.S. Dollar", icon: "USD" },
    { title: "Euro", icon: "EUR" },
    { title: "Saudi Arabian Riyal", icon: "SAR" },
    { title: "Pound Sterling", icon: "GBP" },
    { title: "United Arab Emirates Dirham", icon: "AED" },
    { title: "Thai Baht", icon: "THB" },
  ];

  const AllCurrencyData = [
    { title: "Pakistani Rupee", icon: "PKR" },
    { title: "Euro", icon: "EUR" },
    { title: "Argentine Peso", icon: "ARS" },
    { title: "Australian Dollar", icon: "AUD" },
    { title: "Azerbaijani Manat", icon: "AZN" },
    { title: "Canadian Dollar", icon: "CAD" },
    { title: "Chinese Yuan", icon: "CNY" },
    { title: "Japanese Yen", icon: "JPY" },
    { title: "Swiss Franc", icon: "CHF" },
    { title: "Indian Rupee", icon: "INR" },
    { title: "Brazilian Real", icon: "BRL" },
    { title: "Russian Ruble", icon: "RUB" },
    { title: "South Korean Won", icon: "KRW" },
    { title: "Mexican Peso", icon: "MXN" },
    { title: "South African Rand", icon: "ZAR" },
    { title: "New Zealand Dollar", icon: "NZD" },
    { title: "Turkish Lira", icon: "TRY" },
    { title: "Hong Kong Dollar", icon: "HKD" },
    { title: "Singapore Dollar", icon: "SGD" },
    { title: "U.S. Dollar", icon: "USD" },
    { title: "Saudi Arabian Riyal", icon: "SAR" },
    { title: "Pound Sterling", icon: "GBP" },
    { title: "United Arab Emirates Dirham", icon: "AED" },
    { title: "Thai Baht", icon: "THB" },
  ];

  const handleCurrencyClick = (icon) => {
    updateCurrency(icon);
    setIsOpen(false); // Close the modal
  };

  return (
    <div className="space-y-4">
      <p className="text-sm">
        Where applicable, prices will be converted to—and shown in—the currency
        you select. The currency you pay in may differ based on your
        reservation, and a service fee may also apply.
      </p>
      <h2 className="text-md font-bold">Suggested for you</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 cursor-pointer">
        {SuggestedCurrencyData.map((item) => (
          <div
            key={item.icon}
            className="hover:bg-[#f2f2f2] text-[#1a1a1a] text-sm p-2 rounded-md"
            onClick={() => handleCurrencyClick(item.icon)}
          >
            <p>{item.title}</p>
            <p className="text-[#595959]">{item.icon}</p>
          </div>
        ))}
      </div>
      <h2 className="text-md font-bold">All currencies</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 cursor-pointer">
        {AllCurrencyData.map((item) => (
          <div
            key={item.icon}
            className="hover:bg-[#f2f2f2] text-[#1a1a1a] text-sm p-2 rounded-md"
            onClick={() => handleCurrencyClick(item.icon)}
          >
            <p>{item.title}</p>
            <p className="text-[#595959]">{item.icon}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Currency;