import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderComponent from "../../components/Header.js";
import Footer from "../../components/footer.js";

import { Person, Security, Payment, Notifications, Tune, PrivacyTip, Group } from '@mui/icons-material';

const AccountSettings = () => {
    const settings = [
        {
            icon: <Person className="text-gray-600" fontSize="small" />,
            title: 'Personal details',
            description: "Update your information and find out how it's used.",
            link: '/personal-details'
        },
        // {
        //     icon: <Tune className="text-gray-600" fontSize="small" />,
        //     title: 'Preferences',
        //     description: 'Change your language, currency and accessibility requirements.',
        //     link: '/preferences'
        // },
        {
            icon: <Security className="text-gray-600" fontSize="small" />,
            title: 'Security',
            description: 'Change your security settings, set up secure authentication or delete your account.',
            link: '/security'
        },
        // {
        //     icon: <Payment className="text-gray-600" fontSize="small" />,
        //     title: 'Payment details',
        //     description: 'Securely add or remove payment methods to make it easier when you book.',
        //     link: '/payment-details'
        // },
        // {
        //     icon: <Notifications className="text-gray-600" fontSize="small" />,
        //     title: 'Email notifications',
        //     description: "Decide what you want to be notified about, and unsubscribe from what you don't.",
        //     link: '/email-notifications'
        // },
        {
            icon: <PrivacyTip className="text-gray-600" fontSize="small" />,
            title: 'Privacy',
            description: 'Exercise your privacy rights and control how your data is used.',
            link: '/privacy'
        },
        // {
        //     icon: <Group className="text-gray-600" fontSize="small" />,
        //     title: 'Other travellers',
        //     description: "Add or edit information about the people you're travelling with.",
        //     link: '/other-travellers'
        // }
    ];

    return (
        <section>
            <HeaderComponent />
            <div className="w-[85%] mx-auto my-14">
                <h1 className="text-3xl font-bold mb-4">Account settings</h1>
                <p className="text-lg mb-6">Manage your Booking.com experience</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                    {settings.map((setting, index) => (
                        <div key={index} className="flex items-start p-4 border border-gray-300 rounded-lg">
                            <div className="mr-4 p-2 bg-gray-200 rounded-full flex justify-center items-center w-10 h-10">
                                {setting.icon}
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold">{setting.title}</h2>
                                <p className="text-sm text-gray-600 mb-2">{setting.description}</p>
                                <NavLink to={setting.link} className="text-sm text-[#00936C] hover:underline">
                                    {setting.title}
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default AccountSettings;
