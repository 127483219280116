import React, { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { gharslider, pageOneContent } from "../../../../../../data.js";
import { Navigation } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import icon from "../../../../../../assets/makkahPageIcon.svg";

const Distance = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const facts = [
    "With dimensions of 4 m and 1.5 m in length and width, this cave is set on a 380 m slope mountain, possessing a shape that is similar to a camel’s hump.",
    "According to researcher Mohie ElDin Al-Hashmi, the Hira Cave has enough space to hold six adults at a time.",
    "During Hajj, approximately 5,000 pilgrims trek up to the cave daily.",
    "After sunset, the top of the Nour mountain offers a panoramic view of the Makkah city dotted with bright yellow city lights.",
  ];

  return (
    <div className="text-[#4b465c] font-cairo">
      <h1 className="font-cairo bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        Distance from Kaaba in Mecca: 2.5 Miles
      </h1>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
          <h1 className="font-cairo text-[25px] font-bold pb-1">History</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[55%] space-y-4  mb-4 font-cairo font-medium text-[15px] leading-7">
              <p>
                History A site where the Prophet Muhammad (peace be upon him)
                received the first revelations of the Quran from the Angel
                Gabriel, marking the beginning of his prophethood. The history
                of Ghar-e-Hira is intertwined with the spiritual journey of
                Prophet Muhammad. It is said that he used to retreat to this
                cave for meditation and contemplation even before the onset of
                his prophethood.
              </p>
              <p>
                Muhammad would spend extended periods in solitude in the cave,
                seeking spiritual solace and reflecting on the state of society
                around him According to Islamic tradition, during one of his
                solitary retreats in the Cave of Hira in the month of Ramadan,
                in the year 610 CE, the Angel Gabriel appeared to Muhammad and
                delivered the first verses of the Quran. The encounter was
                overwhelming and transformative, as Muhammad received the
                command to recite (Iqra'): "Read in the name of your Lord who
                created, Created man from a clinging substance.
              </p>
              <p>
                Read, and your Lord is the most Generous, Who taught by the pen,
                Taught man that which he knew not." (Quran, Surah Al-Alaq,
                96:1-5) This event, known as the first revelation or the
                beginning of prophethood, marked a pivotal moment not only in
                Muhammad's life but also in the history of Islam.
              </p>
             
            </div>
            <div className="lg:w-[45%] mt-4 md:mt-0 ">
              <img
                src={pageOneContent.history.image}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full h-full rounded-lg"
              />
              <p>
                <em>
                  Jabal al-Nour, where the Cave of Hira is located, near Mecca.
                </em>
              </p>
            </div>
          </div>
        </div>
        <div className="py-10 mt-10">
          <div className="w-full mx-auto">
            <h2 className="font-cairo font-bold text-xl">
              4 Facts about the Cave of Hira
            </h2>
            <ul className="space-y-3 pt-5">
              {facts.map((fact, index) => (
                <li
                  key={index}
                  className="flex items-start font-medium text-[15px] gap-3"
                >
                  <img
                    src={icon}
                    alt=""
                    className="text-[15px] text-[#00936c] mt-1"
                  />
                  <p className=" text-lg leading-relaxed">{fact}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="py-5">
          <h1 className="font-cairo text-[25px] font-bold pb-1">
            Resign for what peruse the people visit the GHAR-E-HIRA
          </h1>
          <h2 className="font-cairo text-[15px] font-medium pb-1">
            People visit the Cave of Hira, or Ghar-e-Hira, for several reasons,
            primarily of spiritual significance:
          </h2>
        </div>
        <div className="lg:flex block gap-14">
          {" "}
          <img
            src={pageOneContent.why.image}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg"
          />
          <div className="block lg:w-[55%] pt-10">
            <h3 className="font-cairo text-[25px] font-bold pb-1">
              Pilgrimage:
            </h3>
            <div className=" space-y-4  my-4 font-cairo font-medium text-[15px]">
              <p>
                Many Muslims visit Ghar-e-Hira as part of their pilgrimage to
                Mecca, known as Hajj or Umrah. They consider it a sacred site
                and an essential stop during their journey to the Kaaba and
                other holy places in Mecca.
              </p>
              <p>
                Ghar-e-Hira remains a sacred site for Muslims worldwide, who
                visit it as part of their pilgrimage to Mecca. Pilgrims often
                ascend the mountain to visit the cave, reflecting on the
                profound spiritual experiences that unfolded there over fourteen
                centuries ago. The cave serves as a symbol of the importance of
                contemplation, introspection, and divine revelation in Islam.
              </p>
            </div>
          </div>
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={gharslider.image1}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={gharslider.image2}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={gharslider.image3}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={gharslider.image4}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={gharslider.image5}
                alt=""
                className="rounded-lg h-[300px]"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-1 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Distance;
