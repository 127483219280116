import { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../../../../../../components/footer";
import { arafahImagesdata } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Arafah = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${arafahImagesdata.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Arafah</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Discover the transformative power of Arafah: a day that shapes
          destinies and deepens faith.
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div className="">
          <h1 className="text-[25px] font-bold pb-1">
            The Geographic and Spiritual Significance of Arafat
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="md:w-[55%] space-y-4  mb-4 font-medium text-[15px]">
              <p>
                Arafat, also known as Mount Arafat, is one of the most
                significant and revered locations in Saudi Arabia, particularly
                within the Islamic faith. Situated approximately 20 kilometers
                southeast of Mecca, Arafat is an essential destination for
                millions of Muslims who visit during the annual Hajj pilgrimage.
                This plain and its central hill, Jabal al-Rahmah (the Mount of
                Mercy), hold profound spiritual significance and are the focal
                points of various religious observances.
              </p>
              <p>
                Arafat is a vast, open plain surrounded by mountains, covering
                about 13 square kilometers. Its most prominent feature is Jabal
                al-Rahmah, a granite hill that rises about 70 meters above the
                plain. Pilgrims gather on this hill during the Hajj to perform
                the ritual of Wuquf, or standing in prayer and reflection, which
                is considered the pinnacle of the pilgrimage experience.
              </p>
              <p className="lg:hidden xl:block">
                Arafat holds historical significance dating back to the time of
                Prophet Muhammad (peace be upon him), who delivered his farewell
                sermon during his final pilgrimage (Hajjat-ul-Wada) from the
                plains of Arafat. This sermon, known as the Farewell Sermon,
                emphasized principles of unity, equality, justice, and
                compassion among Muslims, leaving a lasting legacy for
                generations to come.
              </p>
              <p className="lg:hidden xl:block">
                Pilgrimage Experience For pilgrims, Arafat represents a profound
                spiritual journey and a pivotal moment in their lives. It is a
                time of deep introspection, repentance, and supplication, as
                believers strive to attain spiritual fulfillment and
                forgiveness. The atmosphere at Arafat is one of communal
                solidarity, where Muslims from diverse backgrounds come together
                in devotion and worship, united by their faith and shared
                pilgrimage experience.
              </p>
            </div>
            <div className="md:w-[45%] text-center">
              <img
                src={arafahImagesdata.image2}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full"
              />
              <p className="text-[10px] md:text-[16px]">
                Mount of Mercy (Jabal al-Rahmah) in Arafat, near the holy city
                of Mecca, Saudi Arabia. It was taken during the annual Hajj
                pilgrimage.
              </p>
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            Arafat holds historical significance dating back to the time of
            Prophet Muhammad (peace be upon him), who delivered his farewell
            sermon during his final pilgrimage (Hajjat-ul-Wada) from the plains
            of Arafat. This sermon, known as the Farewell Sermon, emphasized
            principles of unity, equality, justice, and compassion among
            Muslims, leaving a lasting legacy for generations to come.
          </p>
          <p className="hidden lg:flex xl:hidden">
            Pilgrimage Experience For pilgrims, Arafat represents a profound
            spiritual journey and a pivotal moment in their lives. It is a time
            of deep introspection, repentance, and supplication, as believers
            strive to attain spiritual fulfillment and forgiveness. The
            atmosphere at Arafat is one of communal solidarity, where Muslims
            from diverse backgrounds come together in devotion and worship,
            united by their faith and shared pilgrimage experience.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Meaning of Arafat</h1>
          <div className="pt-3">
            <p className="font-cairo font-medium text-[15px]">
              The term "Arafat" has deep significance in the Islamic tradition.
              The word "Arafat" comes from the Arabic root عَرَفَ (ʿarafa),
              which means "to know" or "to recognize." In the context of Islamic
              history and theology, Arafat refers to several key concepts and
              events:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-6">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  Recognition and Repentance:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  Arafat is associated with the idea of recognition and
                  repentance. It is believed that on the plain of Arafat, Adam
                  and Eve recognized each other after being expelled from
                  Paradise and sought forgiveness from God for their
                  disobedience.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  The Day of Arafah:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  The Day of Arafah is one of the most important days in the
                  Islamic calendar, especially for those performing the Hajj. It
                  is a day dedicated to standing (Wuquf) on the plain of Arafat,
                  engaging in deep prayer, reflection, and seeking God's mercy
                  and forgiveness.
                </p>
              </div>

              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  The Farewell Sermon:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  Arafat is also the place where Prophet Muhammad delivered his
                  Farewell Sermon, emphasizing the principles of equality,
                  justice, and the rights and responsibilities of Muslims. This
                  sermon is a significant moment of recognition of the Prophet's
                  mission and the core values of Islam.
                </p>
              </div>
            </div>
          </div>
        </div>

        <h3 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Hajj Rituals Performed in Arafat
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              The rituals in Arafat on the 9th day of Dhu al-Hijjah, the Day of
              Arafah, are the most significant part of the Hajj pilgrimage.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                The rituals in Arafat are:
              </h4>
              <div className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Wuquf (Standing at Arafat)
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Khutbah (Sermon) at Masjid Namirah
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Performing Salah (Prayer)
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Supplication at Jabal al-Rahmah
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Departure to Muzdalifah
                  </p>
                </div>
              </div>
              <p className="font-medium text-[15px]">
                {" "}
                Pebbles: Pilgrims collect pebbles in Muzdalifah and throw seven
                pebbles at each pillar over three days.
              </p>
            </div>
          </div>
          <img
            src={arafahImagesdata.image5}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg mt-5 lg:mt-0"
          />
        </div>
        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={arafahImagesdata.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={arafahImagesdata.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={arafahImagesdata.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={arafahImagesdata.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={arafahImagesdata.image5}
                alt="5"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default Arafah;
