import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { BiErrorAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FaFilePdf, FaFileWord, FaFileAlt } from "react-icons/fa";
import {
  updateBookingDocumentStatus,
  deleteBookingDocument,
  fetchBookingDetails,
} from "../../../../utility/Api";
import toast, { Toaster } from "react-hot-toast";
import CustomLoader from "../../../../components/loader";

const UploadRequiredDocsForm = ({ isEditing, booking }) => {
  const { adults = 0, child: children = 0, infants = 0 } = booking;
  const totalTravelers = adults + children + infants;
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [fileErrors, setFileErrors] = useState(
    Array(totalTravelers).fill({ photo: "", passport: "" })
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userDocuments, setUserDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const navigate = useNavigate();
  const [uploadedDocuments, setUploadedDocuments] = useState(
    Array(totalTravelers).fill({ photo: false, passport: false })
  );

  useEffect(() => {
    fetchDocuments();
  }, [booking]);

  const fetchDocuments = async () => {
    try {
      const bookingData = await fetchBookingDetails(
        booking.user_session_token,
        booking.booking_number
      );
      setUserDocuments(bookingData.user_documents);
    } catch (error) {
      console.error("Failed to fetch booking details", error);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const onDrop = async (acceptedFiles, travelerIndex, docType) => {
    if (acceptedFiles.length > 1) {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[travelerIndex] = {
          ...newErrors[travelerIndex],
          [docType]: "Only one file can be uploaded at a time.",
        };
        return newErrors;
      });
      return;
    }

    const file = acceptedFiles[0];

    if (!file) {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[travelerIndex] = {
          ...newErrors[travelerIndex],
          [docType]: "Please select only one file.",
        };
        return newErrors;
      });
      return;
    }

    if (uploadedDocuments[travelerIndex][docType]) {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[travelerIndex] = {
          ...newErrors[travelerIndex],
          [docType]: "Only one file can be uploaded for this document type.",
        };
        return newErrors;
      });
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[travelerIndex] = {
          ...newErrors[travelerIndex],
          [docType]: "File is too large (Max size: 2MB)",
        };
        return newErrors;
      });
      return;
    } else {
      setFileErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[travelerIndex] = {
          ...newErrors[travelerIndex],
          [docType]: "",
        };
        return newErrors;
      });
    }

    const travelerType =
      travelerIndex < adults
        ? "Adult"
        : travelerIndex < adults + children
        ? "Child"
        : "Infant";
    const travelerNumber = `Traveler_${travelerIndex + 1}-${travelerType}_${
      travelerIndex + 1
    }`;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result;

      try {
        setIsLoading(true);
        await updateBookingDocumentStatus(
          booking.partner_session_token,
          booking.booking_number,
          file,
          docType === "passport" ? "user_passport" : "user_passport_photo",
          travelerNumber,
          booking.user_session_token
        );
        toast.success("Documents uploaded successfully!");
        await fetchDocuments();

        // Update upload status
        setUploadedDocuments((prev) => {
          const newUploaded = [...prev];
          newUploaded[travelerIndex] = {
            ...newUploaded[travelerIndex],
            [docType]: true,
          };
          return newUploaded;
        });
      } catch (error) {
        setErrorMessage("Failed to upload Documents.");
        toast.error("Failed to upload Documents.");
      } finally {
        setIsLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = async (docToDelete) => {
    if (isEditing) {
      const props = {
        session_token: booking.user_session_token,
        user_document_id: docToDelete.user_document_id,
        booking_number: booking.booking_number,
      };

      try {
        await deleteBookingDocument(props);
        toast.success("Document deleted successfully!");
        await fetchDocuments();

        // Update the uploadedDocuments state to show the Dropzone again
        const travelerIndex = userDocuments.findIndex(
          (doc) => doc.user_document_id === docToDelete.user_document_id
        );

        if (travelerIndex !== -1) {
          const updatedStatus = { ...uploadedDocuments[travelerIndex] };
          if (docToDelete.document_type === "user_passport") {
            updatedStatus.passport = false;
          } else if (docToDelete.document_type === "user_passport_photo") {
            updatedStatus.photo = false;
          }
          setUploadedDocuments((prev) => {
            const newUploaded = [...prev];
            newUploaded[travelerIndex] = updatedStatus;
            return newUploaded;
          });
        }
      } catch (error) {
        toast.error("Failed to delete document.");
      }
    }
  };

  const Dropzone = ({ travelerIndex, docType, isDisabled }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [".png", ".jpg", ".jpeg"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
      },
      maxFiles: 1,
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, travelerIndex, docType),
      disabled: isDisabled,
    });

    return (
      <div className="w-full">
        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-gray-300 p-6 rounded-lg flex flex-col justify-center items-center cursor-pointer hover:border-blue-400 transition-all duration-200 ${
            isDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <input {...getInputProps()} />
          {isLoading ? (
            <CustomLoader />
          ) : (
            <p>Drag 'n' drop a file here, or click to select a file</p>
          )}
        </div>
        {fileErrors[travelerIndex]?.[docType] && (
          <div className="text-red-500 text-sm flex items-center gap-1 mt-2">
            <BiErrorAlt />
            <p>{fileErrors[travelerIndex][docType]}</p>
          </div>
        )}
      </div>
    );
  };

  const renderFileIcon = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return <FaFilePdf size={50} className="text-red-500" />;
      case "doc":
      case "docx":
        return <FaFileWord size={50} className="text-blue-500" />;
      default:
        return <FaFileAlt size={50} className="text-gray-500" />;
    }
  };

  const renderTravelerSection = (travelerType, index) => {
    const hasUploadedPassport = userDocuments.some(
      (doc) =>
        doc.comment === `Traveler_${index + 1}-${travelerType}_${index + 1}` &&
        doc.document_type === "user_passport"
    );
    const hasUploadedPhoto = userDocuments.some(
      (doc) =>
        doc.comment === `Traveler_${index + 1}-${travelerType}_${index + 1}` &&
        doc.document_type === "user_passport_photo"
    );
    const isDisabled = hasUploadedPassport && hasUploadedPhoto;

    return (
      <div
        className="space-y-4 p-4 bg-white rounded-lg shadow-md w-full mb-4"
        key={index}
      >
        <label className="block text-lg font-semibold text-gray-700 mb-2">
          Upload Documents for {travelerType} Traveler {index + 1}
        </label>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 p-2">
            {!isDisabled && !uploadedDocuments[index]?.photo && (
              <Dropzone
                travelerIndex={index}
                docType="photo"
                isDisabled={isDisabled}
              />
            )}
            <div className="w-full mt-4">
              <label className="block text-md font-semibold text-gray-700 mb-2">
                Uploaded Photos
              </label>
              {userDocuments
                .filter(
                  (doc) =>
                    doc.comment ===
                      `Traveler_${index + 1}-${travelerType}_${index + 1}` &&
                    doc.document_type === "user_passport_photo"
                )
                .map((doc) => (
                  <div
                    key={doc.user_document_id}
                    className="w-full p-2 bg-white shadow rounded-lg flex flex-col items-center"
                  >
                    <a
                      href={`${BASE_URL}${doc.user_document}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {doc.user_document.endsWith(".png") ||
                      doc.user_document.endsWith(".jpg") ||
                      doc.user_document.endsWith(".jpeg") ? (
                        <img
                          src={`${BASE_URL}${doc.user_document}`}
                          alt="Document"
                          className="h-20 w-20 object-cover rounded"
                        />
                      ) : (
                        renderFileIcon(doc.user_document)
                      )}
                    </a>
                    <p className="text-sm text-gray-700 mt-2 text-center truncate">
                      {doc.user_document.split("/").pop()}
                    </p>
                    <button
                      onClick={() => handleDelete(doc)}
                      className="mt-2 text-red-500 hover:text-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-full sm:w-1/2 p-2">
            {!isDisabled && !uploadedDocuments[index]?.passport && (
              <Dropzone
                travelerIndex={index}
                docType="passport"
                isDisabled={isDisabled}
              />
            )}
            <div className="w-full mt-4">
              <label className="block text-md font-semibold text-gray-700 mb-2">
                Uploaded Passports
              </label>
              {userDocuments
                .filter(
                  (doc) =>
                    doc.comment ===
                      `Traveler_${index + 1}-${travelerType}_${index + 1}` &&
                    doc.document_type === "user_passport"
                )
                .map((doc) => (
                  <div
                    key={doc.user_document_id}
                    className="w-full p-2 bg-white shadow rounded-lg flex flex-col items-center"
                  >
                    <a
                      href={`${BASE_URL}${doc.user_document}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {doc.user_document.endsWith(".png") ||
                      doc.user_document.endsWith(".jpg") ||
                      doc.user_document.endsWith(".jpeg") ? (
                        <img
                          src={`${BASE_URL}${doc.user_document}`}
                          alt="Document"
                          className="h-20 w-20 object-cover rounded"
                        />
                      ) : (
                        renderFileIcon(doc.user_document)
                      )}
                    </a>
                    <p className="text-sm text-gray-700 mt-2 text-center truncate">
                      {doc.user_document.split("/").pop()}
                    </p>
                    <button
                      onClick={() => handleDelete(doc)}
                      className="mt-2 text-red-500 hover:text-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="text-red-500 text-sm flex items-center gap-1 mt-2">
            <BiErrorAlt />
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    );
  };

  const renderAllTravelers = () => {
    let travelerSections = [];
    for (let i = 0; i < totalTravelers; i++) {
      let travelerType;
      if (i < adults) {
        travelerType = "Adult";
      } else if (i < adults + children) {
        travelerType = "Child";
      } else {
        travelerType = "Infant";
      }
      travelerSections.push(renderTravelerSection(travelerType, i));
    }
    return travelerSections;
  };

  return (
    <div className="flex flex-col space-y-6 mt-10 items-center justify-center">
      <Toaster position="top-right" reverseOrder={false} />
      {loadingDocuments ? (
        <CustomLoader />
      ) : (
        <div className="space-y-6 w-full max-w-4xl">
          <p className="text-gray-600">
            Supported formats: PNG, JPG, JPEG, PDF, DOC, DOCX (Max size: 2MB)
          </p>
          {renderAllTravelers()}
          <button
            onClick={() => navigate(-1)}
            className="w-full py-3 bg-[#00936C] text-white rounded-lg hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 transition-all duration-200"
          >
            Go Back
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadRequiredDocsForm;
