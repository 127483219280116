import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { dadanImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import Footer from "../../../../../../components/footer";

const points = [
  "Rock-Cut Tombs",
  "Ancient Temples",
  "Ancient Inscriptions",
  "Trade Center",
  "Cultural Heritage",
];

const sliderimages = [
  dadanImages.image1,
  dadanImages.image2,
  dadanImages.image3,
  dadanImages.image4,
  dadanImages.image5,
];

const Dadan = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${dadanImages.image1})` }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">
            Dadan: A Journey Through Time
          </h1>
        </div>
      </div>

      <div className="bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center">
        <h1 className="w-[90%] mx-auto">
          Unveil the mysteries of Dadan, where ancient history meets
          architectural splendor amidst the enchanting landscapes of AlUla!
        </h1>
      </div>

      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Dadan, an ancient kingdom in AlUla, is referenced in historical
                texts and traditions as a significant center of trade and
                culture. Although not directly mentioned in the Quran, its
                proximity to other key historical sites in the region highlights
                its importance in the broader narrative of the Arabian
                Peninsula's rich history.
              </p>
              <div className="py-2.5 lg:hidden xl:block">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className="font-medium text-[15px] pt-4">
                  Dadan is renowned for its impressive rock-cut tombs and
                  monumental structures that showcase the advanced architectural
                  skills of its ancient inhabitants. The ruins of temples and
                  other significant buildings reveal a civilization with a deep
                  appreciation for art, craftsmanship, and urban planning. The
                  intricate carvings and designs on the tombs and monuments
                  reflect a blend of local and foreign influences, creating a
                  unique architectural style that stands out in the region.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={dadanImages.image4}
                alt="Abu Bakar Masjid"
                className="w-full rounded-md"
              />
            </div>
          </div>
          <div className="py-2.5 hidden lg:block xl:hidden">
            <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
            <p className="font-medium text-[15px] pt-4">
              Dadan is renowned for its impressive rock-cut tombs and monumental
              structures that showcase the advanced architectural skills of its
              ancient inhabitants. The ruins of temples and other significant
              buildings reveal a civilization with a deep appreciation for art,
              craftsmanship, and urban planning. The intricate carvings and
              designs on the tombs and monuments reflect a blend of local and
              foreign influences, creating a unique architectural style that
              stands out in the region.
            </p>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Significance of Dadan</h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                Community Hub: A thriving center of trade, culture, and social
                interaction in ancient times. Religious Services: Home to
                temples and religious structures that served as places of
                worship and community gatherings. Architectural Marvel:
                Impressive rock-cut tombs and monumental structures showcasing
                advanced engineering and artistry. Spiritual Sanctuary: Sacred
                sites and temples that reflect the spiritual beliefs and
                practices of its ancient inhabitants.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10 gap-4">
              {[
                {
                  title: "Community Hub:",
                  description:
                    "A thriving center of trade, culture, and social interaction in ancient times.",
                },
                {
                  title: "Religious Services:",
                  description:
                    "Home to temples and religious structures that served as places of worship and community gatherings.",
                },
                {
                  title: "Architectural Marvel:",
                  description:
                    "Impressive rock-cut tombs and monumental structures showcasing advanced engineering and artistry.",
                },
                {
                  title: "Spiritual Sanctuary:",
                  description:
                    " Sacred sites and temples that reflect the spiritual beliefs and practices of its ancient inhabitants.",
                },
              ].map((item, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg">
                  <span className="font-bold block text-[25px]">
                    {item.title}
                  </span>
                  <p className="font-normal text-[15px] pt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-10">
          <h1 className="font-bold block text-[25px]">Activities at Dadan</h1>
          <div className="space-y-1.5">
            <p className="font-normal text-[15px] pt-2">
              Guided Tours: Explore the ancient ruins with knowledgeable guides
              who bring the history and significance of Dadan to life. <br />
              Archaeological Exploration: Engage with ongoing research and learn
              about the latest discoveries from archaeological teams. <br />
              Photography: Capture the stunning beauty of the rock-cut tombs and
              the surrounding desert landscape. <br />
              Cultural Events: Participate in local festivals and workshops that
              celebrate the rich heritage of Dadan. <br />
              Hiking: Traverse the rugged terrain and discover hidden
              archaeological sites and breathtaking views. <br />
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={dadanImages.image3}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={dadanImages.image4}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>

        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10">
          Dadan
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className="font-medium text-[15px]">
              Ongoing Excavations Continuous archaeological efforts uncovering
              new facets of Dadan's history. Educational Opportunities: A site
              of learning and exploration for history enthusiasts and scholars.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">Key Features of Dadan</h4>
              <ul className="font-medium text-[15px]">
                {points.map((point, index) => (
                  <li key={index} className="flex gap-2 py-2 px-2">
                    <img src={icon} alt="icon" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              <p className="font-medium text-[15px]">
                Natural Landscape: Nestled amidst the striking desert scenery
                and rock formations of AlUla.
              </p>
            </div>
          </div>
          <img
            src={dadanImages.image6}
            alt="Abu Bakar Masjid"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {sliderimages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg h-[300px] w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dadan;
