import React from "react";
const HeaderForUserSetup = () => {
  return (
    <div className="w-[85%] mx-auto ">
      <nav className="flex items-center justify-between text-black font-poppins w-full text-sm leading-5 pt-1">
        <div className="py-2 pr-4">
          <a
            className="cursor-pointer font-bold font-poppins text-[20px] text-white h-6 relative inline-flex"
            href="/"
          >
            <img src="/images/group.svg" alt="" className="lg:h-12 h-10" />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default HeaderForUserSetup;
