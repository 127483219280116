import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { kabba } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const Kabba = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // useEffect(() => {
  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };

  //   // Scroll to top when the component mounts
  //   scrollToTop();

  //   // Scroll to top when the page is reloaded or navigated from another page
  //   window.addEventListener("beforeunload", scrollToTop);

  //   return () => {
  //     window.removeEventListener("beforeunload", scrollToTop);
  //   };
  // }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${kabba.image1})`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Kabba</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          The Kaaba, a large stone structure and Islam's holiest building.
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">Prayer and pilgrimage</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Pilgrimage to a holy site is a core principle of almost all
                faiths. The Kaaba, meaning cube in Arabic, is a square building,
                elegantly draped in a silk and cotton veil. Located in Mecca,
                Saudi Arabia, it is the holiest shrine in Islam.
              </p>
              <p>
                In Islam, Muslims pray five times a day and after 624 C.E.,
                these prayers were directed towards Mecca and the Kaaba rather
                than Jerusalem; this direction (or qibla in Arabic), is marked
                in all mosques and enables the faithful to know in what
                direction they should pray. The Qur‘an established the direction
                of prayer.
              </p>
              <p>
                All Muslims aspire to undertake the hajj, or the annual
                pilgrimage, to the Kaaba once in their life if they are able.
                Prayer five times a day and the hajj are two of the five pillars
                of Islam, the most fundamental principles of the faith.
              </p>
              <p className="lg:hidden xl:block">
                Upon arriving in Mecca, pilgrims gather in the courtyard of the
                Masjid al-Haram around the Kaaba. They then circumambulate
                (tawaf in Arabic) or walk around the Kaaba, during which they
                hope to kiss and touch the Black Stone (al-Hajar al-Aswad),
                embedded in the eastern corner of the Kaaba.
              </p>
              <p className="lg:hidden xl:block">
                The Five Pillars of Islam Prayer five times a day and the hajj
                pilgrimage to the Kaaba are two of the five pillars of Islam,
                fundamental principles that guide the faith and practice of
                Muslims worldwide. The five pillars also include the declaration
                of faith (Shahada), giving to charity (Zakat), and fasting
                during the month of Ramadan (Sawm).
              </p>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={kabba.image2}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full rounded-lg"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            Upon arriving in Mecca, pilgrims gather in the courtyard of the
            Masjid al-Haram around the Kaaba. They then circumambulate (tawaf in
            Arabic) or walk around the Kaaba, during which they hope to kiss and
            touch the Black Stone (al-Hajar al-Aswad), embedded in the eastern
            corner of the Kaaba.
          </p>
          <p className="hidden lg:flex xl:hidden">
            The Five Pillars of Islam Prayer five times a day and the hajj
            pilgrimage to the Kaaba are two of the five pillars of Islam,
            fundamental principles that guide the faith and practice of Muslims
            worldwide. The five pillars also include the declaration of faith
            (Shahada), giving to charity (Zakat), and fasting during the month
            of Ramadan (Sawm).
          </p>
        </div>
        <div className="mt-7 hidden lg:block">
          <img
            src={kabba.image6}
            alt="Jabal al-Nour and the Cave of Hira"
            className="w-full h-[400px] rounded-lg"
          />
          <p>
            <em className="text-[10px] md:text-[16px]">
              Eastern corner of the Kaaba with the Black Stone, al-Hajar
              al-Aswad (photos: Saudi Arabia General Presidency of the Grand
              Mosque and the Prophet’s Mosque)
            </em>
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            The history and form of the Kaaba
          </h1>
          <div className="pt-3">
            <div>
              <p className=" font-medium text-[15px]">
                The Kaaba was a sanctuary in pre-Islamic times. Muslims believe
                that Abraham (known as Ibrahim in the Islamic tradition), and
                his son, Ismail, constructed the Kaaba. Tradition holds that it
                was originally a simple unroofed rectangular structure. The
                Quraysh tribe, who ruled Mecca, rebuilt the pre-Islamic Kaaba in
                c. 608 C.E. with alternating courses of masonry and wood. A door
                was raised above ground level to protect the shrine from
                intruders and flood waters. <br />
                <br />
                Muhammad was driven out of Mecca in 620 C.E. to Yathrib, which
                is now known as Medina. Upon his return to Mecca in 629/30 C.E.,
                the shrine became the focal point for Muslim worship and
                pilgrimage. The pre-Islamic Kaaba housed the Black Stone and
                statues of pagan gods. Muhammad reportedly cleansed the Kaaba of
                idols upon his victorious return to Mecca, returning the shrine
                to the monotheism of Ibrahim. The Black Stone is believed to
                have been given to Ibrahim by the angel Gabriel and is revered
                by Muslims. Muhammad made a final pilgrimage in 632 C.E., the
                year of his death, and thereby established the rites of
                pilgrimage.
              </p>
            </div>
            <div className="pt-10 space-y-2">
              <span className="font-bold block text-[25px]">Modifications</span>
            </div>
            <div className="md:flex gap-5">
              <div className="block md:w-[55%]">
                <div className="font-medium text-[15px] leading-7">
                  <p className="font-medium text-[15px]">
                    The Kaaba has been modified extensively throughout its
                    history. The area around the Kaaba was expanded in order to
                    accommodate the growing number of pilgrims by the second
                    caliph, ‘Umar (ruled 634–44). The caliph ‘Uthman (ruled
                    644–56) built the colonnades around the open plaza where the
                    Kaaba stands and incorporated other important monuments into
                    the sanctuary.
                  </p>
                  <p>
                    During the civil war between the caliph Abd al-Malik and Ibn
                    Zubayr who controlled Mecca, the Kaaba was set on fire in
                    683 C.E. Reportedly, the Black Stone broke into three pieces
                    and Ibn Zubayr reassembled it with silver. He rebuilt the
                    Kaaba in wood and stone, following Ibrahim’s original
                    dimensions and also paved the space around the Kaaba.
                  </p>
                  <p>
                    {" "}
                    After regaining control of Mecca, Abd al-Malik restored the
                    part of the building that Muhammad is thought to have
                    designed. None of these renovations can be confirmed through
                    study of the building or archaeological evidence; these
                    changes are only outlined in later literary sources.
                  </p>
                  <p className="lg:hidden xl:block">
                    Reportedly under the Umayyad caliph al-Walid (ruled 705–15),
                    the mosque that encloses the Kaaba was decorated with
                    mosaics like those of the Dome of the Rock and the Great
                    Mosque of Damascus. By the seventh century, the Kaaba was
                    covered with kiswa, a black cloth that is replaced annually
                    during the hajj.
                  </p>
                </div>
              </div>
              <div className="md:w-[45%]">
                <img
                  src={kabba.image7}
                  alt="Jabal al-Nour and the Cave of Hira"
                  className="w-full h-[400px] rounded-lg mt-10 lg:mt-0"
                />
                <p>
                  <em className="text-[10px] md:text-[16px]">
                    The Kaaba with kiswa, c. 1910
                  </em>
                </p>
              </div>
            </div>
            <p className="hidden lg:flex xl:hidden">
              Reportedly under the Umayyad caliph al-Walid (ruled 705–15), the
              mosque that encloses the Kaaba was decorated with mosaics like
              those of the Dome of the Rock and the Great Mosque of Damascus. By
              the seventh century, the Kaaba was covered with kiswa, a black
              cloth that is replaced annually during the hajj.
            </p>
          </div>
        </div>
        <div className="pt-10 lg:flex md:block block gap-5">
          <div className="md:w-[45%]">
            <img
              src={kabba.image8}
              alt="Cave of Hira"
              className="w-full rounded-lg h-[400px]"
            />
            <p>
              <em className="text-[10px] md:text-[12px]">
                Muhyi Lari, Illustration of The Great Mosque, Futuh al-Haramayn
                (a Handbook for Pilgrims to Mecca and Medina), fols 19b–20a,
                1582, Mecca, Saudi Arabia (The Khalili Collection)
              </em>
            </p>
          </div>
          <div className="block md:w-[55%]">
            <div className="font-medium text-[15px] leading-7">
              <p>
                Under the early Abbasid caliphs (750–1250), the mosque around
                the Kaaba was expanded and modified several times. According to
                travel writers, such as the Ibn Jubayr, who saw the Kaaba in
                1183, it retained the eighth century Abbasid form for several
                centuries. From 1269–1517, the Mamluks of Egypt controlled the
                Hijaz, the highlands in western Arabia where Mecca is located.
                Sultan Qaitbay (ruled 1468–96) built a madrasa (a religious
                school) against one side of the mosque. Under the Ottoman
                sultans, Süleyman I (ruled 1520–66) and Selim II (ruled
                1566–74), the complex was heavily renovated. In 1631, the Kaaba
                and the surrounding mosque were entirely rebuilt after floods
                had demolished them in the previous year. This mosque, which is
                what exists today, is composed of a large open space with
                colonnades on four sides and with seven minarets, the largest
                number of any mosque in the world. At the center of this large
                plaza sits the Kaaba, as well as many other holy buildings and
                monuments.
              </p>
              <p className="lg:hidden xl:block ">
                At the heart of the Makkah Clock Tower is the Royal Clock, one
                of the largest and most intricate timepieces in the world.
                Mounted on the tower's facades, the clock faces measure over 43
                meters (141 feet) in diameter, making them visible from afar.
                Each face is adorned with intricate Arabic calligraphy and
                illuminated by millions of LED lights, ensuring its visibility
                even during the darkest hours of the night. The clock mechanism
                itself is a marvel of precision engineering, synchronized to the
                atomic clock in Riyadh to ensure accuracy to the second.
              </p>
            </div>
          </div>
        </div>

        <div className="py-10">
          <img src={kabba.image9} alt="" className="mx-auto" />
          <p>
            <em className="text-[10px] md:text-[12px] flex justify-center">
              The Kaaba with surrounding colonnades and minarets, pre-Islamic
              monument, rededicated by Muhammad in 631–32 C.E., multiple
              renovations, Mecca, Saudi Arabia (photo: marviikad, CC BY-NC 2.0)
            </em>
          </p>
        </div>
        <p className=" font-medium text-[15px]">
          The last major modifications were carried out in the 1950s by the
          government of Saudi Arabia to accommodate the increasingly large
          number of pilgrims who come on the hajj. Today the mosque covers
          almost forty acres.
        </p>

        <h1 className="text-[25px] font-bold pt-10">The Kaaba today</h1>
        <div className=" font-medium text-[15px]">
          <p>
            Today, the Kaaba is a cubical structure, unlike almost any other
            religious structure. It is fifteen meters tall and ten and a half
            meters on each side; its corners roughly align with the cardinal
            directions. The door of the Kaaba is now made of solid gold; it was
            added in 1982.
          </p>
          <p>
            The kiswa, a large cloth that covers the Kaaba, which used to be
            sent from Egypt with the hajj caravan, today is made in Saudi
            Arabia. Until the advent of modern transportation, all pilgrims
            undertook the often dangerous hajj, or pilgrimage, to Mecca in a
            large caravan across the desert, leaving from Damascus, Cairo, or
            other major cities in Arabia, Yemen or Iraq.
          </p>
        </div>
        <div className="py-10">
          <img src={kabba.image10} alt="" className="mx-auto" />
          <p>
            <em className="text-[10px] md:text-[12px] flex justify-center">
              Kiswa of the Kaaba at the gold door, 2016
            </em>
          </p>
        </div>
        <p className=" font-medium text-[15px]">
          The numerous changes to the Kaaba and its associated mosque serve as
          good reminder of how often buildings, even sacred ones, were renovated
          and remodeled either due to damage or to the changing needs of the
          community. Only Muslims may visit the holy cities of Mecca and Medina
          today.
        </p>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
          >
            <SwiperSlide>
              <img
                src={kabba.image11}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={kabba.image12}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={kabba.image13}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={kabba.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Kabba;
