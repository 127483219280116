import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.css";

import image1 from "../../../assets/trendingimg/makkahimg.jpg";
import image2 from "../../../assets/trendingimg/madinahimg.jpg";

const data = [
  { imageUrl: image1, title: "Mecca", link: "/articles/makkah" },
  { imageUrl: image2, title: "Madinah", link: "/articles/madinah" },
  { imageUrl: "/images/taif2.png", title: "Taif", link: "/articles/taif" },
];

const TrendingDestinations = () => {
  const navigate = useNavigate();

  return (
    <div className="w-[85%] mx-auto mt-12 text-[#484848]">
      <h2 className="text-lg sm:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-[#484848] mb-1 font-cairo">
        Trending destinations
      </h2>
      <p className="text-xs sm:text-sm xl:text-base 2xl:text-lg font-medium text-[#484848] mb-5 font-cairo">
        Travellers searching for Saudi Arabia also booked these
      </p>
      <div className="block lg:hidden w-auto">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          className=""
        >
          {data.map(({ imageUrl, title, link }, index) => (
            <SwiperSlide
              key={index}
              className="outline-none relative cursor-pointer w-auto"
              onClick={() => navigate(link)}
            >
              <img
                src={imageUrl}
                alt={title}
                className="w-full h-60 object-cover rounded-md"
              />
              <h3 className="absolute top-2 font-semibold font-cairo text-lg text-white shadow-2xl left-5">
                {title}
              </h3>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden lg:block w-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px]">
          {data.map(({ imageUrl, title, link }, index) => (
            <div
              key={index}
              className="col-span-1 relative cursor-pointer"
              onClick={() => navigate(link)}
            >
              <img
                src={imageUrl}
                alt={title}
                className="w-full xl:h-[250px] lg:h-[200px] md:h-[170px] sm:h-[150px] object-cover rounded-md"
              />
              <h3 className="absolute top-0 left-0 p-1.5 m-1 rounded-md font-kd font-semibold text-lg tracking-wide text-white shadow-2xl backdrop-blur-sm bg-white/30">
                {title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingDestinations;