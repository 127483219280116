import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // import useNavigate for navigation
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { format, addDays } from "date-fns";
import { AiOutlineClose } from "react-icons/ai";

const MobileSearchBar = () => {
  const navigate = useNavigate(); // initialize navigation
  const [checkInDate, setCheckInDate] = useState(addDays(new Date(), 1));
  const [showDateModal, setShowDateModal] = useState(false);
  const [showGuestModal, setShowGuestModal] = useState(false);

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);

  const formattedCheckInDate = format(checkInDate, "MM/dd/yyyy");

  const handleSearch = () => {
    sessionStorage.removeItem("refreshed"); // Clear the flag before navigating
    // Navigate to PackagesPage with parameters
    navigate("/umrah", {
      state: {
        fromHomepage: true, // Set this flag when navigating from homepage
        adults,
        children,
        infant: infants,
        start_date: checkInDate.toISOString().split("T")[0],
        flight_from: "Multan",
        page: 1,
        page_size: 10,
      },
    });
  };

  return (
    <div className="lg:hidden flex flex-col items-center w-full px-4 py-2 relative">
      <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden border-2 border-[#00936C] relative">
        {/* Location Input */}
        <div className="flex items-center border-b px-4 py-2">
          <input
            type="text"
            placeholder="Flight From?"
            className="border-0 text-sm bg-transparent outline-none text-gray-700 w-full"
          />
        </div>

        {/* Date Selection */}
        <div
          className="flex items-center border-b px-4 py-2 cursor-pointer"
          onClick={() => setShowDateModal(true)}
        >
          <span className="text-black text-sm px-4">When are you planning to go?</span>
          <input
            type="text"
            readOnly
            className="border-0 text-sm bg-transparent outline-none text-gray-700 w-full"
            value={formattedCheckInDate}
            placeholder="Select Date"
          />
        </div>

        {showDateModal && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
            onClick={() => setShowDateModal(false)} // Close on background click
          >
            <div
              className="bg-white p-4 rounded-lg shadow-lg relative"
              onClick={(e) => e.stopPropagation()} // Prevent closing on modal click
            >
              <div className="flex justify-between items-center pb-2">
                <h2 className="text-lg font-semibold">Select Date</h2>
                <button onClick={() => setShowDateModal(false)} className="text-gray-700 hover:text-black">
                  <AiOutlineClose size={24} />
                </button>
              </div>

              {/* Single Calendar for Date Selection */}
              <Calendar
                date={checkInDate}
                onChange={(date) => {
                  setCheckInDate(date);
                  setShowDateModal(false); // Close modal after selecting date
                }}
                minDate={addDays(new Date(), 1)}
              />
            </div>
          </div>
        )}

        {/* Guest Number Input */}
        <div className="flex items-center border-b px-4 py-2 cursor-pointer" onClick={() => setShowGuestModal(true)}>
          <span className="text-black text-sm px-4">Guests</span>
          <input
            type="text"
            readOnly
            className="border-0 text-sm bg-transparent outline-none text-gray-700 w-full"
            value={`${adults} Adults, ${children} Children, ${infants} Infants`}
            placeholder="Select Guests"
          />
        </div>

        {showGuestModal && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
            onClick={() => setShowGuestModal(false)} // Close on background click
          >
            <div
              className="bg-white p-4 rounded-lg shadow-lg relative"
              onClick={(e) => e.stopPropagation()} // Prevent closing on modal click
            >
              {/* Guest Selection */}
              <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <span>Adults</span>
                  <div className="flex items-center gap-2">
                    <button onClick={() => setAdults(Math.max(1, adults - 1))} className="px-2 py-1 bg-gray-200 rounded">-</button>
                    <span>{adults}</span>
                    <button onClick={() => setAdults(adults + 1)} className="px-2 py-1 bg-gray-200 rounded">+</button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>Children</span>
                  <div className="flex items-center gap-2">
                    <button onClick={() => setChildren(Math.max(0, children - 1))} className="px-2 py-1 bg-gray-200 rounded">-</button>
                    <span>{children}</span>
                    <button onClick={() => setChildren(children + 1)} className="px-2 py-1 bg-gray-200 rounded">+</button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>Infants</span>
                  <div className="flex items-center gap-2">
                    <button onClick={() => setInfants(Math.max(0, infants - 1))} className="px-2 py-1 bg-gray-200 rounded">-</button>
                    <span>{infants}</span>
                    <button onClick={() => setInfants(infants + 1)} className="px-2 py-1 bg-gray-200 rounded">+</button>
                  </div>
                </div>
              </div>

              <button onClick={() => setShowGuestModal(false)} className="mt-4 w-full bg-[#00936C] text-white py-2 rounded">
                Save
              </button>
            </div>
          </div>
        )}

        {/* Search Button */}
        <div className="flex items-center w-full">
          <button
            onClick={handleSearch}
            className="bg-[#00936C] text-white py-3 justify-center w-full shadow-md flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchBar;
