import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UploadRequiredDocsForm from "./UploadRequiredDocsForm";
import Footer from "../../../../components/footer";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";

const UploadRequiredDocs = () => {
  const location = useLocation();
  const [booking, setBooking] = useState(location.state?.booking || null);
  useEffect(() => {
    if (!booking) {
      const storedBookingData = localStorage.getItem("Booking-Data");
      if (storedBookingData) {
        setBooking(JSON.parse(storedBookingData));
      }
    }
  }, [booking]);

  if (!booking) {
    return <div>Loading booking details...</div>;
  }

  return (
    <div>
      <BookingNavbar />
      <div className="bg-[#f6f6f6] min-h-screen">
        <div className="w-[85%] mx-auto">
          <div className="pt-10">
            <h1 className="font-k2d mb-3 text-2xl text-[#4B465C]">
              Required Documents
              </h1>
            <p className="font-k2d font-light text-sm text-gray-500">
              Kindly Submit your documents so that your Booking can proceed further!
              </p>
          </div>
            <UploadRequiredDocsForm booking={booking} isEditing={true} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadRequiredDocs;
