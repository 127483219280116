import React from "react";
import { FaRegCircle } from "react-icons/fa";
import { MdOutlineDone } from "react-icons/md";
import demo from "../../../../../assets/TransportBooking/demo.jpg";
import keys from "../../../../../assets/TransportBooking/keys.png";

const ViewDeal = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const responsiveDesign = (pixel) => {
    return width * (pixel / 1366);
  };

  const responsiveHeight = (pixel) => {
    return height * (pixel / 1233);
  };

  const responsive = (pixel) => {
    return responsiveDesign(pixel / 2) + responsiveHeight(pixel / 2);
  };

  // md screen size
  const tbDesign = (pixel) => {
    return width * (pixel / 768);
  };

  const tbHeight = (pixel) => {
    return height * (pixel / 1024);
  };

  const tbresponsive = (pixel) => {
    return tbDesign(pixel / 2) + tbHeight(pixel / 2);
  };

  return (
    <div className="w-[85%] mx-auto py-10">
      <div>
        <p
          className={`font-bold text-[#484848]`}
          style={{
            fontSize: `${Math.floor(responsive(28))}px`,
          }}
        >
          Your Booking
        </p>
      </div>
      <div className="md:flex flex  md:flex-row flex-col-reverse gap-5">
        <div className="block">
          <div className="overflow-hidden p-4 flex flex-col md:flex-row border-b">
            <div className="md:w-[25%] w-full mt-7">
              <img src={demo} alt="Car" className="object-cover" />
            </div>
            <div className="w-full md:pl-4">
              <div className="flex items-center justify-between">
                <span className="bg-[#00936cce] hover:bg-[#00936c] text-white text-[11px] font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Top Pick
                </span>
              </div>
              <div className="flex gap-1 mt-4">
                <h3 className="text-[18px] font-bold text-gray-900 font-cairo ">
                  Hyundai Elantra
                </h3>
              </div>
              <div className="mt-2 flex flex-wrap gap-x-9 md:gap-y-1 gap-y-2.5  md:w-[300px] font-kd">
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="size-[14px] -mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16.5 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0M18 6A6 6 0 1 0 6 6a6 6 0 0 0 12 0M3 23.25a9 9 0 1 1 18 0 .75.75 0 0 0 1.5 0c0-5.799-4.701-10.5-10.5-10.5S1.5 17.451 1.5 23.25a.75.75 0 0 0 1.5 0"></path>
                    </svg>
                  </span>
                  5 seats
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    {/* <MdAutoMode size={17} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.25 14.25v-4.5l-1.374.416 3 4.5c.412.617 1.374.326 1.374-.416v-4.5a.75.75 0 0 0-1.5 0v4.5l1.374-.416-3-4.5c-.412-.617-1.374-.326-1.374.416v4.5a.75.75 0 0 0 1.5 0m3 6a3.75 3.75 0 0 0-3.75-3.75.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75a3.75 3.75 0 0 0 3.75-3.75m-1.5 0a2.25 2.25 0 0 1-2.25 2.25l.75.75v-6l-.75.75a2.25 2.25 0 0 1 2.25 2.25M18.5 4.5H20A2.25 2.25 0 0 0 20 0h-1.5a.75.75 0 0 0-.75.75v6a.75.75 0 0 0 1.5 0v-6l-.75.75H20A.75.75 0 0 1 20 3h-1.5a.75.75 0 0 0 0 1.5M4.25 6.75v4.5A2.25 2.25 0 0 0 6.5 13.5H8a.75.75 0 0 1 .75.75v4.5A2.25 2.25 0 0 0 11 21h3a.75.75 0 0 0 0-1.5h-3a.75.75 0 0 1-.75-.75v-4.5A2.25 2.25 0 0 0 8 12H6.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-1.5 0m3-3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0m1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0"></path>
                    </svg>
                  </span>
                  Automatic
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    {/* <IoBagHandleOutline size={17} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M22.5 14.249v4.5a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25v-9a2.25 2.25 0 0 1 2.25-2.25h16.5a2.25 2.25 0 0 1 2.25 2.25zm1.5 0v-4.5a3.75 3.75 0 0 0-3.75-3.75H3.75A3.75 3.75 0 0 0 0 9.749v9a3.75 3.75 0 0 0 3.75 3.75h16.5a3.75 3.75 0 0 0 3.75-3.75zm-18-7.5v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m10.5 0v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m0 0v-3a2.25 2.25 0 0 0-2.25-2.25h-4.5a2.25 2.25 0 0 0-2.25 2.25v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v3a.75.75 0 0 0 1.5 0"></path>
                    </svg>
                  </span>
                  1 Large bag
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    {/* <GiGymBag size={17} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15 14.75H9a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5m.75 3.25a.76.76 0 0 0-.75-.75H9a.75.75 0 0 0 0 1.5h6a.76.76 0 0 0 .75-.75m3-6.5v9a2.25 2.25 0 0 1-2.25 2.25h-.75v.5a.75.75 0 0 1-1.5 0v-.5h-4.5v.5a.75.75 0 0 1-1.5 0v-.5H7.5a2.25 2.25 0 0 1-2.25-2.25v-9A2.25 2.25 0 0 1 7.5 9.25h1.75v-8C9.25.56 9.81 0 10.5 0h3c.69 0 1.25.56 1.25 1.25v8h1.75a2.25 2.25 0 0 1 2.25 2.25m-8-2.25h2.5V1.5h-2.5zm6.5 2.25a.76.76 0 0 0-.75-.75h-9a.76.76 0 0 0-.75.75v9a.76.76 0 0 0 .75.75h9a.76.76 0 0 0 .75-.75z"></path>
                    </svg>
                  </span>
                  1 Small bag
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    {/* <IoMdSpeedometer size={17} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M9.75 15.292v-.285a2.25 2.25 0 0 1 4.5 0v.285a.75.75 0 0 0 1.5 0v-.285a3.75 3.75 0 1 0-7.5 0v.285a.75.75 0 0 0 1.5 0M13.54 5.02l-2.25 6.75a.75.75 0 0 0 1.424.474l2.25-6.75a.75.75 0 1 0-1.424-.474M6.377 6.757a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5m12.75 3.75a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5m-1.496-3.75a1.125 1.125 0 1 0 1.119 1.131v-.006c0-.621-.504-1.125-1.125-1.125a.75.75 0 0 0 0 1.5.375.375 0 0 1-.375-.375V7.88a.375.375 0 1 1 .373.377.75.75 0 1 0 .008-1.5m-8.254-3a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5M21.88 17.541a16.5 16.5 0 0 0-19.76 0 .75.75 0 1 0 .898 1.202 15 15 0 0 1 17.964 0 .75.75 0 1 0 .898-1.202m.62-5.534c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5m1.5 0c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12m-19.123-1.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5"></path>
                    </svg>
                  </span>
                  600 km per rental
                </div>
              </div>
              <div className="md:flex md:items-center justify-between mt-1.5 font-kd">
                <div className="text-sm text-gray-600">
                  <a
                    href="#"
                    className="text-[#00936cce] hover:text-[#00936c] hover:underline"
                  >
                    Makkah - Al Lith Road
                  </a>{" "}
                  <span className="block text-xs">DownTown</span>
                </div>
              </div>
            </div>
          </div>

          {/* left section bottom */}
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center my-4">
              <div className="flex-1">
                <h2 className="text-[20px] font-bold  text-[#484848] mb-2">
                  Great choice!
                </h2>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-sm">
                  <p className="text-[14px] font-normal">
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Customer rating: 6.5 / 10
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Most popular company here
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Most popular fuel policy
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Easy to find counter
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Well-maintained cars
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Clean cars
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0 ml-4 items-end float-end justify-end hidden lg:flex">
                <img
                  src={keys}
                  alt="Keys"
                  className="w-[60%] h-auto object-cover"
                />
              </div>
            </div>
            <hr className="w-full my-2 opacity-50 ml-1.5" />

            <div className="flex justify-between items-center my-4">
              <div className="flex-1">
                <h2 className="text-[20px] font-bold  text-[#484848] mb-2">
                  Included in the price
                </h2>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-sm">
                  <p className="text-[14px] font-normal">
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Collision Damage Waiver with Rs 148,363 excess
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    Theft Protection with Rs 148,363 excess
                  </p>
                  <p>
                    <MdOutlineDone className="inline text-[#00936c] mr-2 size-[14px]" />
                    600 kilometres per rental
                  </p>
                </div>
              </div>
            </div>

            <hr className="w-full my-2 opacity-50" />
            <div className="bg-white rounded-lg  my-4 md:flex justify-between items-center">
              <div>
                <h2 className="text-lg font-bold mb-2">Extras</h2>
                <p className="text-sm mb-4">
                  Child seats, additional drivers and more.
                </p>
                <button className="text-[#00936cce] hover:text-[#00936c]">
                  Add extras
                </button>
              </div>
              <button className="bg-[#00936cce] hover:bg-[#00936c] text-white font-bold py-2 px-4 rounded">
                Continue to book
              </button>
            </div>
          </div>
        </div>

        {/* Right-side Information */}
        <div className="flex flex-col gap-y-3">
          <div className="bg-white border rounded-lg p-4 my-4 items-start">
            <h2 className="text-[20px] font-bold mb-4 text-white">
              Pick-up and drop-off
            </h2>
            <div className="flex gap-3">
              <div className=" flex flex-col justify-center items-start">
                <FaRegCircle />
                <hr className="w-[1px] h-[70%] border-[1px] border-[#484848] my-2 opacity-50 ml-1.5" />
                <FaRegCircle />
              </div>
              <div className="block">
                <div className="mb-5">
                  <h3 className="text-[14px] font-normal mb-1">
                    Sun, 30 Jun · 10:00 am
                  </h3>
                  <p className="text-[14px] font-bold mb-1">
                    Makkah - Al Lith Road
                  </p>
                  <a
                    href="#pickup-instructions"
                    className="text-[#00936c] text-[14px] font-normal mb-1"
                  >
                    View pick-up instructions
                  </a>
                </div>
                <div className="mb-3">
                  <h3 className="text-[14px] font-normal mb-1">
                    Wed, 3 Jul · 10:00 am
                  </h3>
                  <p className="text-[14px] font-bold mb-1">
                    Makkah - Al Lith Road
                  </p>
                  <a
                    href="#pickup-instructions"
                    className="text-[#00936c] text-[14px] font-normal mb-1"
                  >
                    View pick-up instructions
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border rounded-lg p-4">
            <h2 className="text-lg font-bold text-[#484848] mb-3">
              Car price breakdown
            </h2>
            <div className="mb-3">
              <div className="font-bold text-[#484848]">Car hire charge</div>
              <div className="text-sm">
                Rs 31,336.58 <span className="text-xs">US$112.60</span>
              </div>
            </div>
            <p className="text-sm text-[#484848]">
              PKR prices are approx. You'll pay in USD, because that's your
              local currency.
            </p>
            <div className="mt-3">
              <div className="font-bold text-[#484848]">Price for 3 days:</div>
              <div className="text-sm">approx. Rs 31,336.58</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDeal;
