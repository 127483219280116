import React from "react";
import HeroSection from "../../../../../../components/HeroSection/HeroSection";
import { MasjidNimra } from "../../../../../../data";
import Page from "./Page";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";

const Home = () => {
  return (
    <div className="pb-20">
      <HeaderFooter>
        <HeroSection
          herobg={MasjidNimra.hero.image}
          heading={MasjidNimra.hero.heading}
          paragraph={MasjidNimra.hero.description}
        />
        <Page />
      </HeaderFooter>
    </div>
  );
};

export default Home;
