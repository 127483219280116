import React from "react";
import { useNavigate } from "react-router-dom";
import { FaFilePdf, FaFileWord, FaFileImage } from "react-icons/fa";
import dlt from "../../../../assets/booking/delete.svg";
import { deleteBookingDocument } from "../../../../utility/Api";

const getFileIcon = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return <FaFilePdf className="text-red-500" />;
    case "doc":
    case "docx":
      return <FaFileWord className="text-blue-500" />;
    case "png":
    case "jpg":
    case "jpeg":
      return <FaFileImage className="text-green-500" />;
    default:
      return <FaFileImage className="text-gray-500" />;
  }
};

const RequiredDocs = ({ documents = [], booking, setDocuments }) => {
  const { REACT_APP_API_BASE_URL } = process.env;
  const navigate = useNavigate();

  const openDocument = (documentLink) => {
    window.open(`${REACT_APP_API_BASE_URL}${documentLink}`, "_blank");
  };

  const handleAddMore = () => {
    navigate("/upload-documents", { state: { booking } });
  };

  const handleDelete = async (documentId) => {
    const props = {
      session_token: booking.user_session_token,
      document_id: documentId,
      booking_number: booking.booking_number,
      partner_session_token: booking.partner_session_token,
    };

    try {
      await deleteBookingDocument(props);
      setDocuments((prevDocs) =>
        prevDocs.filter((doc) => doc.document_id !== documentId)
      );
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h2 className="text-base font-medium text-gray-500">
          Shared Required Documents
        </h2>
        <button
          onClick={handleAddMore}
          className="text-white text-xs bg-[#00936C] hover:bg-[#007B54] rounded px-3 py-1.5"
        >
          Add more
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-2 rounded">
        {documents.map((doc) => (
          <div
            key={doc.document_id}
            className="flex justify-between items-center p-3 bg-white border border-gray-200 rounded shadow-sm"
          >
            <div className="flex items-center space-x-2">
              {getFileIcon(doc.document_link)}
              <p
                className="text-sm text-gray-700 cursor-pointer"
                onClick={() => openDocument(doc.document_link)}
              >
                {doc.document_link.split("/").pop()}
              </p>
            </div>
            <button
              onClick={() => handleDelete(doc.document_id)}
              className="text-red-500 hover:text-red-600"
            >
              <img src={dlt} alt="Delete" className="w-4 h-4" />
            </button>
          </div>
        ))}
        {booking.user_documents.map((doc) => (
          <div
            key={doc.user_document_id}
            className="flex justify-between items-center p-3 bg-white border border-gray-200 rounded shadow-sm"
          >
            <div className="flex items-center space-x-2">
              {getFileIcon(doc.user_document)}
              <p
                className="text-sm text-gray-700 cursor-pointer"
                onClick={() => openDocument(doc.user_document)}
              >
                {doc.user_document.split("/").pop()}
              </p>
            </div>
            {/* You can add delete functionality for user documents if needed */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RequiredDocs;