import React, { useState, useEffect } from "react";
import HeaderComponent from "../../homepage/Header/header";
import PackagesPage from "./PackagesPage";
import Footer from "../../../../components/footer";
import Subscription from "../../../../components/subscription";
import { getHajjPackages } from "../../../../utility/Api";
import LoaderForPages from "../../../../components/loaderForPages";

const Hajj = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const result = await getHajjPackages();
        console.log('Fetched packages:', result); // Log the fetched packages
        if (result.error) {
          setError(result.error);
        } else {
          setPackages(result.data.results);
        }
      } catch (err) {
        setError("An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);

  return (
    <div>
      <HeaderComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <main>
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <LoaderForPages />
          </div>
        ) : (
          <PackagesPage
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            packages={packages}
          />
        )}
      </main>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Hajj;
