import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const Filters = ({ handleFilterChange, handleSliderChange, filters }) => {
    const clearFilters = () => {
        handleFilterChange("departureCity", "");
        handleFilterChange("departureDate", "");
        handleFilterChange("duration", 0);
        // handleFilterChange("costRange", [0, 500000]);
        // handleFilterChange("distanceFromKabba", [0, 20]);
        // handleFilterChange("distanceFromNabwi", [0, 20]);
        handleFilterChange("is_meals_included", false);
        handleFilterChange("is_tour_guide_included", false);
        handleFilterChange("roomType", "");
    };

    const incrementDuration = () => {
        const duration = parseInt(filters.duration || 0);
        handleFilterChange("duration", duration + 1);
    };

    const decrementDuration = () => {
        const duration = parseInt(filters.duration || 0);
        handleFilterChange("duration", Math.max(0, duration - 1));
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Filter</h2>
                <button className="text-[#00936c] text-sm" onClick={clearFilters}>Clear all filters</button>
            </div>
            <hr className="mb-4" />

            {/* Departure City */}
            <div className="mb-4">
                <label className="block mb-2 text-sm">Departure City:</label>
                <input
                    type="text"
                    placeholder="Enter city"
                    value={filters.departureCity || ""}
                    onChange={(e) => handleFilterChange("departureCity", e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 rounded-md"
                />
            </div>

            {/* Departure Date */}
            <div className="mb-4">
                <label className="block mb-2 text-sm">Departure Date:</label>
                <input
                    type="date"
                    value={filters.departureDate || ""}
                    onChange={(e) => handleFilterChange("departureDate", e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 rounded-md"
                />
            </div>

            {/* Duration */}
            <div className="mb-4">
                <div className="flex items-center justify-between">
                    <span className="block text-sm">Duration (nights)</span>
                    <div className="flex items-center border border-gray-300 rounded-md px-4 py-2">
                        <button
                            onClick={decrementDuration}
                            className="text-gray-500"
                        >
                            &ndash;
                        </button>
                        <span className="mx-2">{filters.duration ?? 0}</span>
                        <button
                            onClick={incrementDuration}
                            className="text-blue-500"
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>

            {/* Room Type */}
            <div className="mb-4">
                <h3 className="font-semibold">Room Type</h3>
                {["Single", "Double", "Triple", "Quad"].map((type) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={type}>
                        <input
                            type="radio"
                            name="roomType"
                            className="mr-2"
                            checked={filters.roomType === type}
                            onChange={() => handleFilterChange("roomType", type)}
                        />
                        {type}
                    </label>
                ))}
            </div>

            {/* Budget Slider */}
            {/* <hr className="mb-4" />
            <div className="mb-6">
                <h3 className="font-semibold">Your Budget</h3>
                <div className=" mb-3 text-sm text-gray-600">
                    PKR {filters.costRange?.[0] ?? 0} - PKR {filters.costRange?.[1] ?? 500000}
                </div>
                <Slider
                    range
                    min={0}
                    max={500000}
                    value={filters.costRange || [0, 500000]}
                    onChange={handleSliderChange}
                    trackStyle={[{ backgroundColor: "#00936c" }]}
                    handleStyle={[{ borderColor: "#00936c" }, { borderColor: "#00936c" }]}
                />
            </div> */}

            {/* Hotel Distance from Kaaba
            <hr className="mb-4" />
            <div className="mb-6">
                <h3 className="font-semibold">Hotel Distance From Kaaba</h3>
                <div className=" mb-3 text-sm text-gray-600">
                    KM {filters.distanceFromKabba?.[0] ?? 0} - KM {filters.distanceFromKabba?.[1] ?? 20}
                </div>
                <Slider
                    range
                    min={0}
                    max={20}
                    value={filters.distanceFromKabba || [0, 20]}
                    onChange={(value) => handleFilterChange("distanceFromKabba", value)}
                    trackStyle={[{ backgroundColor: "#00936c" }]}
                    handleStyle={[{ borderColor: "#00936c" }, { borderColor: "#00936c" }]}
                />
            </div> */}

            {/* Meals Included */}
            <div className="mb-4">
                <h3 className="font-semibold">Meals Included</h3>
                <label className="flex items-center mt-2 mb-2 text-sm">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={filters.is_meals_included || false}
                        onChange={(e) => handleFilterChange("is_meals_included", e.target.checked)}
                    />
                    Meals
                </label>
            </div>

            {/* Tour Guide Included */}
            <div className="mb-4">
                <h3 className="font-semibold">Tour Guide Included</h3>
                <label className="flex items-center mt-2 mb-2 text-sm">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={filters.is_tour_guide_included || false}
                        onChange={(e) => handleFilterChange("is_tour_guide_included", e.target.checked)}
                    />
                    Tour Guide
                </label>
            </div>
        </div>
    );
};

export default Filters;
