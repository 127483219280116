import React, { useContext, useState } from "react";
import Dropdown from "./Dropdown";
import Currency from "./Currency";
import bg from "../../assets/herobg.svg";
import { CurrencyContext } from "../../utility/CurrencyContext";

const BookingNavbar = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative", // Make sure this is set to relative
  };
  const [isOpen, setIsOpen] = useState(false);
  const { selectedCurrency } = useContext(CurrencyContext);
  const handleClick = () => {
    setIsOpen(true);
  };
  return (
    <div style={backgroundImageStyle} className="border-b-2 border-[#efefef]">
      {" "}
      <div className="w-[85%] mx-auto ">
        <nav className="flex items-center justify-between text-black font-poppins w-full text-sm leading-5 pt-1">
          <div className="py-2 pr-4">
            <a
              className="cursor-pointer font-bold font-poppins text-[20px] text-white h-6 relative inline-flex"
              href="/"
            >
              <img src="/images/group.svg" alt="" className="lg:h-12 h-10" />
            </a>
          </div>
          {/* Links for larger screens */}
          <div className="flex items-center space-x-4">
            <button
              onClick={handleClick}
              className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden hover:bg-[#dddddd] transition duration-150"
            >
              {selectedCurrency}
            </button>
            {!isOpen && <Dropdown isOpenDropdown={handleClick} />}
          </div>
        </nav>
      </div>
      {isOpen && (
        <div className="fixed pt-[1000px] md:pt-[300px] xl:pt-[100px] lg:pb-5 overflow-y-auto inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[85%] p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Select Your Currency</h2>
            <Currency setIsOpen={setIsOpen} />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingNavbar;
