import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { safamarwa } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import icon from "../../../../../../assets/makkahPageIcon.svg";

const SafaMarwa = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // useEffect(() => {
  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };

  //   // Scroll to top when the component mounts
  //   scrollToTop();

  //   // Scroll to top when the page is reloaded or navigated from another page
  //   window.addEventListener("beforeunload", scrollToTop);

  //   return () => {
  //     window.removeEventListener("beforeunload", scrollToTop);
  //   };
  // }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${safamarwa.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {" "}
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Safa Marwa and Sa'i:</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Uncover the enduring legacy of Sa'i and its profound impact on the
          faithful. What lessons does this ritual hold for us today?
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10 text-justify">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            The Geographic and Spiritual Significance of Safa and Marwa
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            {" "}
            <div className="lg:w-[55%] space-y-4  mb-4 font-medium text-[15px]">
              <p>
                Safa and Marwa are two small hills located within the Masjid
                al-Haram in Mecca, Saudi Arabia, playing a crucial role in the
                Islamic rituals of Hajj and Umrah. Geographically, these hills
                are about 450 meters apart and are now enclosed within the grand
                mosque, allowing pilgrims to perform the ritual of Sa'i in
                comfort regardless of weather conditions.
              </p>
              <p>
                Spiritually, Safa and Marwa hold immense significance as they
                commemorate the profound story of Hagar's unwavering faith and
                perseverance. According to Islamic tradition, Hagar, the wife of
                Prophet Abraham and mother of Ishmael, ran between these hills
                seven times in search of water for her thirsty son. Her trust in
                God was rewarded with the miraculous spring of Zamzam. This act
                of running, known as Sa'i, symbolizes the trials faced by Hagar
                and her ultimate faith in divine providence. Performing Sa'i
                between Safa and Marwa is a powerful spiritual exercise for
                pilgrims, reflecting on themes of endurance, patience, and trust
                in God.
              </p>
              <p className="lg:hidden xl:block">
                It serves as a reminder of the historical narrative that binds
                Muslims to their faith, reinforcing their connection to God and
                the importance of perseverance through life's challenges. The
                ritual is a deeply moving experience that enhances the spiritual
                depth of the pilgrim's journey, making Safa and Marwa not just
                significant geographic landmarks but also profound symbols of
                Islamic faith and devotion.
              </p>
              <p className="lg:hidden xl:block">
                Safa and Marwa are two small hills located within the precincts
                of Masjid al-Haram in Mecca, Saudi Arabia. They play a crucial
                role in the Islamic rituals of Hajj and Umrah, serving as
                enduring symbols of faith, perseverance, and divine providence.
                Geographic Details: Geographically, Safa and Marwa are situated
                about 450 meters apart within the expansive complex of Masjid
                al-Haram. Over time, these hills have been enclosed within the
                grand mosque, allowing pilgrims to perform the ritual of Sa'i
                comfortably regardless of weather conditions. The Sa'i involves
                walking or running seven times back and forth between Safa and
                Marwa, a pivotal ritual during both Hajj and Umrah. Spiritual
                Significance: Spiritually, Safa and Marwa hold immense
                significance in Islamic tradition as they commemorate the
                profound story of Hagar's unwavering faith and perseverance.
                According to Islamic teachings, Hagar (Hajar in Arabic), the
                wife of Prophet Abraham (Ibrahim) and mother of Ishmael
                (Ismail), was left with her infant son in the barren valley of
                Mecca by divine command. With no water to sustain her child,
                Hagar ran desperately between the hills of Safa and Marwa seven
                times, seeking aid and guidance from Allah.
              </p>
            </div>
            <div className="lg:w-[45%] text-center">
              {" "}
              <img
                src={safamarwa.image3}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            It serves as a reminder of the historical narrative that binds
            Muslims to their faith, reinforcing their connection to God and the
            importance of perseverance through life's challenges. The ritual is
            a deeply moving experience that enhances the spiritual depth of the
            pilgrim's journey, making Safa and Marwa not just significant
            geographic landmarks but also profound symbols of Islamic faith and
            devotion.
          </p>
          <p className="hidden lg:flex xl:hidden">
            Safa and Marwa are two small hills located within the precincts of
            Masjid al-Haram in Mecca, Saudi Arabia. They play a crucial role in
            the Islamic rituals of Hajj and Umrah, serving as enduring symbols
            of faith, perseverance, and divine providence. Geographic Details:
            Geographically, Safa and Marwa are situated about 450 meters apart
            within the expansive complex of Masjid al-Haram. Over time, these
            hills have been enclosed within the grand mosque, allowing pilgrims
            to perform the ritual of Sa'i comfortably regardless of weather
            conditions. The Sa'i involves walking or running seven times back
            and forth between Safa and Marwa, a pivotal ritual during both Hajj
            and Umrah. Spiritual Significance: Spiritually, Safa and Marwa hold
            immense significance in Islamic tradition as they commemorate the
            profound story of Hagar's unwavering faith and perseverance.
            According to Islamic teachings, Hagar (Hajar in Arabic), the wife of
            Prophet Abraham (Ibrahim) and mother of Ishmael (Ismail), was left
            with her infant son in the barren valley of Mecca by divine command.
            With no water to sustain her child, Hagar ran desperately between
            the hills of Safa and Marwa seven times, seeking aid and guidance
            from Allah.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Meaning of Safa Marwa</h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                The term "Safa Marwa" holds deep significance in the Islamic
                tradition, encompassing both geographical and spiritual
                dimensions. Safa and Marwa are two small hills situated within
                the Masjid al-Haram in Mecca, central to the Hajj and Umrah
                pilgrimages. Here’s an exploration of their meaning and
                importance:
              </p>
              <p>
                Safa Marwa is more than just a pair of hills; it is a profound
                symbol of faith, perseverance, and divine providence in Islam.
                The historical, religious, and cultural significance of Safa
                Marwa resonates deeply within the hearts of Muslims, drawing
                pilgrims from around the world to reflect on their spiritual
                journey.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Historical and Spiritual Significance:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  Safa Marwa is closely associated with the story of Hagar
                  (Hajira), the wife of Prophet Abraham and mother of Ishmael.
                  According to Islamic tradition, Hagar ran between these two
                  hills in search of water for her thirsty son. Her faith and
                  perseverance were rewarded by the appearance of the Zamzam
                  well, making this site one of profound significance in Islamic
                  history.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  The Ritual of Sa'i:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  Performing Sa'i involves walking seven times between Safa and
                  Marwa. This ritual is a key component of Hajj and Umrah,
                  symbolizing Hagar's enduring faith and determination. Pilgrims
                  begin at Safa, making their intentions and supplications, then
                  proceed to Marwa, reflecting on Hagar's struggle and triumph
                  through God's grace, embodying a deep spiritual connection.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Spiritual Reflection and Devotion:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  The act of Sa'i is more than a physical journey; it is a
                  spiritual exercise that invites pilgrims to embody the virtues
                  of patience, resilience, and unwavering trust in God. By
                  retracing Hagar's steps, Muslims connect deeply with a pivotal
                  narrative of Islamic history, fostering a profound sense of
                  devotion and spiritual renewal, enriching their faith and
                  inner strength.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Unity and Continuity:
                </span>
                <p className="font-cairo font-normal text-[15px] pt-2">
                  Safa Marwa stands as a testament to the unity and continuity
                  of the Muslim Ummah (community). Pilgrims from around the
                  world perform Sa'i together, reinforcing a shared heritage and
                  collective faith. This communal act underscores the universal
                  values of Islam and the enduring relevance of Hagar's story in
                  the lives of Muslims today, symbolizing unity and spiritual
                  continuity.
                </p>
              </div>
            </div>
          </div>
        </div>

        <h3 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Sa'i: The Ritual of Safa Marwa
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              The ritual of Sa'i between Safa and Marwa is a profound expression
              of spiritual endurance and faith, reenacting Hagar's desperate
              search for water.
            </p>

            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">Key Aspects of Sa'i:</h4>
              <div className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">Start at Safa</p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">Walk to Marwa</p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Repeat Seven Times
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Reflect on Faith and Resilience
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Final Supplication
                  </p>
                </div>
              </div>
              <p className="font-medium text-[15px]">
                {" "}
                This act of walking between these hills is a central part of the
                Hajj and Umrah pilgrimages, symbolizing the spiritual journey
                and the quest for mercy and forgiveness.
              </p>
            </div>
          </div>
          <img
            src={safamarwa.image5}
            alt="Safa and Marwa"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={safamarwa.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={safamarwa.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={safamarwa.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={safamarwa.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={safamarwa.image5}
                alt="5"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default SafaMarwa;
