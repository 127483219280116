import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          A Haven of Greenery and Tranquility
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural and Recreational Events
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
              <p className="text-[#484848] font-cairo font-medium text-[15px] leading-7">
                Arruddaf Park offers a refreshing escape from the hustle and
                bustle of urban life. As you enter the park, you are greeted by
                towering palm trees and neatly manicured lawns that invite you
                to unwind and relax. The park's design seamlessly blends natural
                landscapes with modern amenities, creating a perfect balance
                between recreation and conservation.
                <br />
                Scenic Walks and Relaxing Spaces
                <br />
                One of the highlights of Arruddaf Park is its winding pathways
                that lead you through different sections adorned with seasonal
                flowers and shrubs. Whether you prefer a leisurely stroll or a
                brisk jog, the park's well-maintained trails cater to various
                preferences. Along the way, benches and shaded areas offer ideal
                spots to pause, breathe in the fresh air, and admire the
                surroundings.
              </p>
              <p className="lg:hidden xl:block ">
                One of Arruddaf Park's standout features is its network of
                winding pathways that meander through different sections adorned
                with seasonal flowers and lush shrubs. Whether you prefer a
                leisurely stroll or an invigorating jog, the park's
                well-maintained trails cater to various preferences and fitness
                levels. Along these pathways, strategically placed benches and
                shaded areas provide perfect spots to pause, catch your breath,
                and immerse yourself in the peaceful ambiance.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/arruddafPark/image2.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] hidden lg:flex xl:hidden mb-4 font-cairo font-medium text-[15px]">
            One of Arruddaf Park's standout features is its network of winding
            pathways that meander through different sections adorned with
            seasonal flowers and lush shrubs. Whether you prefer a leisurely
            stroll or an invigorating jog, the park's well-maintained trails
            cater to various preferences and fitness levels. Along these
            pathways, strategically placed benches and shaded areas provide
            perfect spots to pause, catch your breath, and immerse yourself in
            the peaceful ambiance.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Family-Friendly Attractions
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              For families, Arruddaf Park is a paradise of entertainment and
              relaxation. Children can enjoy playgrounds equipped with slides,
              swings, and climbing frames, while parents can unwind nearby. The
              park's picnic areas provide a perfect setting for family
              gatherings and social outings, where you can savor homemade meals
              amidst nature's tranquility. Cultural and Recreational Events
              Throughout the year, Arruddaf Park hosts a variety of cultural and
              recreational events that bring the community together. From art
              exhibitions and music performances to seasonal festivals
              celebrating local traditions, the park serves as a vibrant hub of
              cultural activities. These events not only entertain but also
              foster a sense of community pride and unity among residents and
              visitors.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/arruddafPark/image1.avif"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Ecological Conservation and Sustainability
          </h2>
          <div className="mt-4">
            its recreational value, Arruddaf Park plays a crucial role in
            environmental conservation. The park's management emphasizes
            sustainable practices such as water conservation, native plant
            preservation, and wildlife protection. Visitors are encouraged to
            respect the natural ecosystem and contribute to maintaining the
            park's pristine beauty for future generations to enjoy. A Symbol of
            Taif's Charm Arruddaf Park is not just a green space; it is a symbol
            of Taif's charm and commitment to enhancing quality of life through
            nature. Whether you visit to relax, play, or explore, the park
            offers a sanctuary where you can connect with nature, rejuvenate
            your spirit, and create cherished memories with loved ones. It
            embodies the essence of Taif's hospitality and beauty, inviting you
            to experience the city's warmth and tranquility firsthand. In
            conclusion, Arruddaf Park in Taif, Saudi Arabia, stands as a
            testament to the city's dedication to preserving green spaces and
            promoting community well-being. It captivates visitors with its
            natural splendor, recreational opportunities, and cultural richness,
            making it a destination worth exploring and celebrating in a blog
            that captures its essence.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
