import React, { useEffect } from "react";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Banner from "./Banner";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const BigGHarsMainPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/Madinah/birghars/birghars.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/birghars/birghars1.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/birghars/birghars2.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/birghars/birghars3.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/birghars/ghars4.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/birghars/ghars5.jpg",
  ];

  return (
    <div>
      <HeaderFooter>
        <Banner />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default BigGHarsMainPage;
