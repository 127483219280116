import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import bgimg from "../../../assets/bgImage.png";
import Footer from "../../../components/FooterForSingup";
import Loader from "../../../components/loader";
import country_data from "./country_data.json";
import { checkUserExistence, sendOtp } from "../../../utility/AuthApis";
import { BiErrorAlt } from "react-icons/bi";
import HeaderForUserSetup from "../HeaderForUserSetup.js";

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ phone_number: "", country: "PK" });
  const [errors, setErrors] = useState({ phone_number: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [countrycode, setCountryCode] = useState(
    country_data.find((item) => item.code === "PK").dial_code
  );

  const handleCountryCodeChange = (e) => setCountryCode(e.target.value);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^[0-9]{0,10}$/.test(value)) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (value)
        setErrors((prevErrors) => ({ ...prevErrors, phone_number: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ phone_number: "" });
    setApiError("");
    setIsLoading(true);

    if (!formData.phone_number) {
      setErrors({ phone_number: "Phone Number is required" });
      setIsLoading(false);
      return;
    }

    try {
      const number = countrycode + formData.phone_number;
      const response = await checkUserExistence(number);
      if (response) {
        localStorage.setItem("Login-User-Profile", JSON.stringify(response));
        localStorage.setItem("User-Phone-Number", JSON.stringify(number));
        await sendOtp(number);
        navigate("/otpverification");
      }
    } catch (error) {
      setApiError(
        error.response?.status === 400
          ? error.response.data.message || "An error occurred"
          : "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen font-kd"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <HeaderForUserSetup />

      <main className="flex-grow flex justify-center items-center p-4">
        <div className="w-full max-w-md py-14 px-6 bg-white rounded-lg shadow-custom-box md:mx-auto space-y-6">
          <h2 className="mb-1 text-lg font-kd text-[#4B465C] font-semibold text-left">
            Log in or sign up
          </h2>
          <div>
            <div className="rounded-t-md border-t-[1px] border-l-[1px] border-r-[1px] border-[#B2ABAB] border-b-0">
              <p className="text-[#867676] text-left text-[11px] pl-2.5 pt-1">
                Country code
              </p>
              <select
                className="w-full px-2.5 py-1.5 border-none text-sm text-black font-normal focus:outline-none focus:ring-0"
                value={countrycode}
                onChange={handleCountryCodeChange}
              >
                {country_data.map((items) => (
                  <option
                    key={items.code}
                    value={items.dial_code}
                    className="text-sm text-black font-normal"
                  >
                    {items.name} {items.dial_code}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex px-2.5 py-1.5 items-center border-[1px] border-[#B2ABAB] rounded-b-md">
              <p className="text-sm w-1/2 text-[#4b465c]">Phone Number</p>
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                className="border-none w-full text-sm bg-white focus:outline-none focus:ring-0"
                style={{ backgroundColor: "white" }}
              />
            </div>
            {errors.phone_number && (
              <div className="text-red-500 text-left text-xs flex items-center gap-1 mt-2">
                <BiErrorAlt />
                <p className="text-red-500 text-xs">{errors.phone_number}</p>
              </div>
            )}
            {apiError && (
              <div className="text-red-500 text-left text-xs flex items-center gap-1 mt-2">
                <BiErrorAlt />
                <div className="text-red-500 text-xs">{apiError}</div>
              </div>
            )}
          </div>
          <p className="text-xs text-left my-1 font-kd text-[#4b465c]">
            We’ll call or text you to confirm your number. Standard message and
            data rates apply.{" "}
            <span className="text-[#00936c] font-bold">Privacy Policy</span>
          </p>
          <button
            onClick={handleSubmit}
            className={`w-full text-sm py-2 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : <p>Continue</p>}
          </button>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default LoginPage;
