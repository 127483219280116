import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/Madinah/miqat/miqat2.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/miqat/miqat3.jpg",
    process.env.PUBLIC_URL + "/images/Madinah/miqat/miqat4.png",
    process.env.PUBLIC_URL + "/images/Madinah/miqat/miqat5.jpg",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={"/images/Madinah/miqat/miqat1.jpg"}
          title={"Masjid Miqat"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
