import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          "Rising Above: Al Hada Mountain, Saudi Arabia's Natural Jewel"
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Al Hada Mountain
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Al Hada Mountain is situated in the western region of Saudi
                Arabia, near the city of Taif. It forms part of the Sarawat
                Mountains range, which runs parallel to the Red Sea coast. Known
                for its rugged terrain and scenic landscapes, Al Hada Mountain
                offers panoramic views of the surrounding valleys and is
                renowned for its cooler climate compared to the hot plains
                below. The mountain's elevation and natural beauty make it a
                popular destination for tourists and locals seeking respite from
                the heat and a chance to enjoy outdoor activities amidst natural
                surroundings.
              </p>
              <p>
                Al Hada Mountain is easily accessible by road, with
                well-maintained routes that connect it to the city of Taif. One
                of the most famous features of Al Hada is its cable car system,
                which offers an exhilarating ride from the base to the summit,
                providing passengers with stunning aerial views of the
                landscape. The mountain is also home to various recreational
                facilities, including resorts, parks, and hiking trails, making
                it a popular spot for outdoor activities such as picnicking,
                trekking, and sightseeing.
              </p>
              <p className="lg:hidden xl:block">
                Significance and Popularity The mountain's natural beauty and
                cooler climate make it a sought-after retreat for those looking
                to escape the heat of the plains. It is a favored destination
                for family outings, romantic getaways, and nature enthusiasts
                who wish to explore the diverse flora and fauna of the region.
                Additionally, Al Hada's strategic location near Taif, a city
                known for its historical and cultural significance, adds to its
                appeal, attracting visitors who are also interested in exploring
                the rich heritage of the area.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/hadaMountain/image4.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
            Significance and Popularity The mountain's natural beauty and cooler
            climate make it a sought-after retreat for those looking to escape
            the heat of the plains. It is a favored destination for family
            outings, romantic getaways, and nature enthusiasts who wish to
            explore the diverse flora and fauna of the region. Additionally, Al
            Hada's strategic location near Taif, a city known for its historical
            and cultural significance, adds to its appeal, attracting visitors
            who are also interested in exploring the rich heritage of the area.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Al Hada Mountain in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              In Islamic tradition, Al Hada Mountain holds significance as part
              of the broader natural landscape mentioned in religious texts and
              historical accounts. While specific religious events or incidents
              directly associated with Al Hada Mountain may not be documented,
              its presence in the region and its role in providing a cooler
              climate and scenic views contribute to its cultural and
              environmental importance for visitors and residents alike.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/hadaMountain/image6.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Al Hada Mountain?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Scenic Drives: Enjoy breathtaking views along the winding
                roads that ascend Al Hada Mountain, often referred to as the "Al
                Hada Road" or "Al Hada Scenic Route."
              </li>
              <li>
                - Hiking Trails: Discover various hiking trails that cater to
                different skill levels, allowing you to experience the
                mountain's diverse flora and fauna up close.
              </li>
              <li>
                - Cable Car Ride: Experience a bird's-eye view of the mountain
                and surrounding landscapes by taking a cable car ride that
                ascends to higher altitudes.
              </li>
              <li>
                - Visit Al Hada Resort: Stay at one of the resorts nestled on
                the mountain slopes, offering amenities like cafes, restaurants,
                and panoramic viewpoints.
              </li>
              <li>
                - Picnicking and Relaxation: Take advantage of designated picnic
                areas and viewpoints along the mountain roads for a relaxing day
                amidst nature.
              </li>
              <li>
                - Cultural Encounters: Explore nearby villages and towns such as
                Taif, known for its rich cultural heritage and agricultural
                significance, providing a deeper insight into the region's
                traditions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
