import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import Footer from "../../../../../../components/footer";
import { HegraAlulaImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const points = [
  "UNESCO World Heritage Site",
  "Qasr Al-Farid",
  "Rock-Cut Tombs",
  "Ancient Trade Route Hub",
  "Nabatean Architecture",
];

const sliderimages = [
  HegraAlulaImages.image1,
  HegraAlulaImages.image2,
  HegraAlulaImages.image3,
  HegraAlulaImages.image4,
  HegraAlulaImages.image5,
];

const Hegra = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${HegraAlulaImages.image1})` }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">
            Hegra: The Ancient City of Stone Marvels
          </h1>
        </div>
      </div>

      <div className="bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center">
        <h1 className="w-[90%] mx-auto">
          Discover Hegra, a testament to ancient civilizations etched in rock.
          With its grand tombs and intricate carvings, this archaeological
          wonder invites you to step back in time and explore the mysteries of a
          forgotten era.
        </h1>
      </div>

      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Hegra, also known as Al-Hijr or Madain Saleh, holds a
                significant place in Islamic history. It is mentioned in the
                Quran as the settlement of the Thamud people, who were known for
                their architectural prowess. According to Islamic tradition, the
                Prophet Salih was sent to guide the Thamud people, but they
                ignored his warnings. As a result, they faced divine punishment.
                The remains of Hegra serve as a powerful reminder of this
                ancient story, emphasizing themes of faith and obedience in
                Islamic teachings.
              </p>
              <div className="py-2.5 lg:hidden xl:block">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className="font-medium text-[15px] pt-4">
                  Hegra's architectural splendor is nothing short of
                  mesmerizing. The site is famous for its rock-cut tombs,
                  intricately carved into sandstone mountains by the Nabataeans.
                  These tombs feature elaborate facades adorned with columns,
                  cornices, and bas-reliefs that reflect the artistic brilliance
                  of the Nabataean civilization. The most iconic of these is the
                  Qasr Al-Farid, also known as the "Lonely Castle," which stands
                  majestically alone, showcasing an unfinished façade that adds
                  to its mystique. Walking through Hegra, you'll encounter a
                  stunning array of structures that blend natural beauty with
                  human ingenuity, making it a true masterpiece of ancient
                  architecture.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={HegraAlulaImages.image4}
                alt="Abu Bakar Masjid"
                className="w-full rounded-md"
              />
            </div>
          </div>
          <div className="py-2.5 hidden lg:block xl:hidden">
            <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
            <p className="font-medium text-[15px] pt-4">
              Hegra's architectural splendor is nothing short of mesmerizing.
              The site is famous for its rock-cut tombs, intricately carved into
              sandstone mountains by the Nabataeans. These tombs feature
              elaborate facades adorned with columns, cornices, and bas-reliefs
              that reflect the artistic brilliance of the Nabataean
              civilization. The most iconic of these is the Qasr Al-Farid, also
              known as the "Lonely Castle," which stands majestically alone,
              showcasing an unfinished façade that adds to its mystique. Walking
              through Hegra, you'll encounter a stunning array of structures
              that blend natural beauty with human ingenuity, making it a true
              masterpiece of ancient architecture.
            </p>
          </div>
        </div>

        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Significance of Hegra</h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                Hegra holds immense historical and cultural significance. It was
                the southernmost settlement of the Nabataean Kingdom, a crucial
                trading hub on the ancient incense route. As Saudi Arabia's
                first UNESCO World Heritage Site, Hegra is not only a symbol of
                the rich Nabataean heritage but also a testament to the region's
                historical importance in global trade and cultural exchanges.
                Its preservation and recognition highlight the value of
                protecting and celebrating ancient sites that contribute to our
                understanding of human history and civilization.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10 gap-4">
              {[
                {
                  title: "Community Hub:",
                  description:
                    "Hegra served as a central meeting place for the Nabatean community, facilitating social gatherings, trade, and cultural exchanges.",
                },
                {
                  title: "Religious Services:",
                  description:
                    "The city was a vital center for religious activities, with sites like Jabal Ithlib used for worship and rituals.",
                },
                {
                  title: "Architectural Marvel:",
                  description:
                    "Hegra is renowned for its stunning rock-cut tombs and monuments, showcasing the advanced engineering and artistic skills of the Nabateans.",
                },
                {
                  title: "Spiritual Sanctuary:",
                  description:
                    "The serene desert landscape and sacred sites within Hegra provided a tranquil environment for spiritual reflection and reverence.",
                },
              ].map((item, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg">
                  <span className="font-bold block text-[25px]">
                    {item.title}
                  </span>
                  <p className="font-normal text-[15px] pt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-10">
          <h1 className="font-bold block text-[25px]">Activities at Hegra</h1>
          <div className="space-y-1.5">
            <p className="font-normal text-[15px] pt-2">
              Visiting Hegra offers a plethora of activities for history buffs,
              adventure seekers, and casual tourists alike.
              <br />
              Guided Tours: Explore the ancient city with knowledgeable guides
              who bring the history and significance of each site to life.
              <br />
              Photography: Capture the stunning beauty of the rock-cut tombs and
              the surrounding desert landscape. Cultural Events: Participate in
              events and festivals that celebrate the rich heritage of the
              Nabatean civilization. Stargazing: Experience the magic of the
              desert night sky, where the clear atmosphere offers unparalleled
              views of the stars.
              <br />
              Archaeological Exploration: Delve into the ongoing archaeological
              efforts and learn about the latest discoveries.
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={HegraAlulaImages.image3}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={HegraAlulaImages.image4}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>

        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10">
          Hegra Mountain
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className="font-medium text-[15px]">
              Abu Bakar Masjid is a symbol of spiritual devotion and
              architectural excellence in Jeddah. It continues to inspire and
              serve the Muslim community through its religious, educational, and
              social initiatives.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Key Features of Abu Bakar Masjid:
              </h4>
              <ul className="font-medium text-[15px]">
                {points.map((point, index) => (
                  <li key={index} className="flex gap-2 py-2 px-2">
                    <img src={icon} alt="icon" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              <p className="font-medium text-[15px]">
                Hegra is Natural Landscape: Surrounded by striking desert
                scenery and rock formations. Archaeological Significance:
                Ongoing excavations continue to reveal the city's historical
                treasures.
              </p>
            </div>
          </div>
          <img
            src={HegraAlulaImages.image6}
            alt="Abu Bakar Masjid"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {sliderimages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg h-[300px] w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Hegra;
