import React, { useEffect } from "react";
import HeroSection from "../../../../../../components/HeroSection/HeroSection";
import { MasjidJinn } from "../../../../../../data";
import Page from "./Page";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div className="pb-20">
      <HeaderFooter>
        <HeroSection
          herobg={MasjidJinn.hero.image}
          heading={MasjidJinn.hero.heading}
          paragraph={MasjidJinn.hero.description}
        />
        <Page />
      </HeaderFooter>
    </div>
  );
};

export default Home;
