import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Nature area with a kids' playground & picnic spots around a small dam,
          plus seasonal streams.
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Saiysad Park in Taif, Saudi Arabia
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Saiysad Park, located in Taif, Saudi Arabia, is renowned for its
                scenic beauty and recreational offerings, making it a popular
                destination for locals and tourists alike Location and Overview
                Saiysad Park is situated in the Al Hada district of Taif, a city
                known for its pleasant climate and stunning mountainous
                landscapes. The park is nestled amidst the picturesque Sarawat
                Mountains, providing visitors with breathtaking panoramic views
                of the surrounding valleys and greenery.
              </p>
              <p className="lg:hidden xl:block ">
                Scenic Walks: Saiysad Park features well-maintained pathways
                that wind through the park, allowing visitors to take leisurely
                strolls while enjoying the fresh mountain air and scenic views.
                Picnic Areas: There are designated picnic spots equipped with
                tables and benches, perfect for families and groups to enjoy
                outdoor meals amidst nature. Children's Playgrounds: For
                families visiting with children, the park offers playgrounds
                with modern play equipment, ensuring a fun and safe environment
                for kids to play and explore. Botanical Gardens: The park is
                adorned with seasonal flowers, shrubs, and palm trees, enhancing
                its natural beauty and providing visitors with opportunities for
                peaceful walks and photography. Viewpoints: Several viewpoints
                within the park offer panoramic vistas of the Sarawat Mountains
                and the surrounding landscape, making it a prime location for
                photography enthusiasts and nature lovers.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/saiysadpark/image1.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-7">
                Scenic Walks: Saiysad Park features well-maintained pathways
                that wind through the park, allowing visitors to take leisurely
                strolls while enjoying the fresh mountain air and scenic views.
                Picnic Areas: There are designated picnic spots equipped with
                tables and benches, perfect for families and groups to enjoy
                outdoor meals amidst nature. Children's Playgrounds: For
                families visiting with children, the park offers playgrounds
                with modern play equipment, ensuring a fun and safe environment
                for kids to play and explore. Botanical Gardens: The park is
                adorned with seasonal flowers, shrubs, and palm trees, enhancing
                its natural beauty and providing visitors with opportunities for
                peaceful walks and photography. Viewpoints: Several viewpoints
                within the park offer panoramic vistas of the Sarawat Mountains
                and the surrounding landscape, making it a prime location for
                photography enthusiasts and nature lovers.
              </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Attractions and Features
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Floral Gardens: Saiysad Park is celebrated for its meticulously
              maintained gardens featuring a diverse array of flora. Visitors
              can stroll through vibrant flower beds, enjoying the fragrant
              blooms that include local species as well as imported varieties.
              Water Features: The park incorporates tranquil water features such
              as fountains and small ponds, adding to its serene ambiance. These
              features not only enhance the aesthetic appeal but also provide a
              soothing atmosphere for relaxation. Recreational Facilities: For
              those seeking recreational activities, Saiysad Park offers
              amenities such as playgrounds for children, walking paths for
              leisurely strolls, and designated picnic areas where families and
              friends can gather. Viewpoints: One of the highlights of Saiysad
              Park is its elevated viewpoints that offer sweeping vistas of Taif
              and the surrounding countryside. These viewpoints are perfect for
              capturing memorable photographs or simply enjoying the natural
              beauty of the region.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/saiysadpark/image6.webp"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural Significance
          </h2>
          <div className="mt-4">
            Beyond its natural beauty, Saiysad Park holds cultural significance
            as a gathering place for local communities during festivals and
            holidays. It serves as a venue for cultural events, art exhibitions,
            and traditional performances, showcasing the rich heritage of Saudi
            Arabia.
            <br />
            Accessibility and Visitor Information Saiysad Park is easily
            accessible by road, located a short drive from Taif city center.
            Entry to the park is typically free or involves a nominal fee,
            making it accessible to all visitors. The park is open year-round,
            though spring and early autumn are considered ideal times to visit
            due to the pleasant weather and blooming gardens.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
