import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          "Unveiling the Legacy: Masjid Abdullah ibn Abbas Beckons With Rich
          History and Serenity"
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Halima Sadia House,
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px]">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Halima al-Saadia's house, situated in Bani Saad near al-Sheikh
                Muharib, approximately 5 kilometers north of Mecca (Makkah),
                holds significant historical importance in Islamic tradition.
                This modest dwelling was where Halima, from the Banu Saad tribe,
                and her husband fostered Prophet Muhammad (peace be upon him)
                during his infancy and early childhood. This period of fosterage
                played a crucial role in the Prophet's upbringing, providing him
                with care and nurturing in a pastoral setting typical of the
                Arabian Peninsula. While the exact remains of the house may not
                be identifiable today, the location remains a symbol of familial
                bonds, compassion, and the early years of one of the most
                influential figures in history. It serves as a place of
                reflection and reverence for those interested in the Prophet's
                life and the cultural values embodied in his upbringing.
              </p>
              <p className="lg:hidden xl:block">
                Halima al-Saadia’s role as the Prophet’s wet nurse is a
                cherished part of Islamic history. According to tradition, the
                Banu Saad tribe, known for their pure Arabic dialect and healthy
                living conditions, often took in children from the city to
                foster them. The Prophet Muhammad's (peace be upon him) early
                years spent in Halima's care are considered pivotal, as they
                exposed him to the purity of the desert life and the rich,
                eloquent Arabic language spoken by the Bedouins. The Location
                Today While the exact remains of Halima al-Saadia's house may
                not be identifiable today, the location remains a symbol of
                familial bonds, compassion, and the early years of one of the
                most influential figures in history. The area around Bani Saad
                near al-Sheikh Muharib continues to attract those interested in
                the Prophet’s life and the cultural values embodied in his
                upbringing. Pilgrims and visitors often reflect on the nurturing
                environment that contributed to the Prophet’s character and the
                significance of Halima’s role in his life.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/halimaSadia/image2.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            Halima al-Saadia’s role as the Prophet’s wet nurse is a cherished
            part of Islamic history. According to tradition, the Banu Saad
            tribe, known for their pure Arabic dialect and healthy living
            conditions, often took in children from the city to foster them. The
            Prophet Muhammad's (peace be upon him) early years spent in Halima's
            care are considered pivotal, as they exposed him to the purity of
            the desert life and the rich, eloquent Arabic language spoken by the
            Bedouins. The Location Today While the exact remains of Halima
            al-Saadia's house may not be identifiable today, the location
            remains a symbol of familial bonds, compassion, and the early years
            of one of the most influential figures in history. The area around
            Bani Saad near al-Sheikh Muharib continues to attract those
            interested in the Prophet’s life and the cultural values embodied in
            his upbringing. Pilgrims and visitors often reflect on the nurturing
            environment that contributed to the Prophet’s character and the
            significance of Halima’s role in his life.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Halima al-Saadia's in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Halima al-Saadia's house holds profound significance in Islamic
              history as the place where Prophet Muhammad (peace be upon him)
              spent his early years under her care. Located in Bani Saad, near
              al-Sheikh Muharib, north of Mecca (Makkah), Saudi Arabia, this
              humble dwelling was where Halima, from the Banu Saad tribe, and
              her husband Harith ibn Abd al-Uzza fostered the Prophet during his
              infancy. This period, known as the period of fosterage or "milk
              kinship," provided a nurturing environment in a pastoral setting,
              shaping the Prophet's early life and character. Halima's role as
              his foster mother is revered in Islamic tradition for the
              compassion and care she provided, reflecting important values of
              family, community, and love in Islam. The house of Halima
              al-Saadia thus stands as a historical landmark, symbolizing the
              beginnings of Prophet Muhammad's life and his connection to the
              people of Arabia before his prophethood, emphasizing the
              importance of nurturing and upbringing in shaping the Islamic
              tradition.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/halimaSadia/image3.jpeg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              "And I did not create the jinn and mankind except to worship Me"
              <br />
              <span className="italic font-bold">
                Surah Adz Dzaariyaat [verse 56]
              </span>
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to explore Halima al-Saadia ?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Research and Study: Begin by researching books, articles, and
                online resources that delve into the life of Halima al-Saadia
                and her role as the Prophet Muhammad's foster mother. Look for
                historical accounts and scholarly interpretations that discuss
                her influence and the cultural context of fosterage in early
                Islamic society.
              </li>
              <li>
                - Visit Bani Saad: Plan a visit to Bani Saad, the village near
                Mecca where Halima al-Saadia's house is located. While the exact
                remains of the house may not be identifiable, experiencing the
                environment and local culture can provide insights into the
                pastoral lifestyle of the time and the geographical setting
                where the Prophet spent his early years.
              </li>
              <li>
                - Guided Tours: Consider joining guided tours of historical
                sites in Mecca and its surroundings that include stops at
                significant locations related to Prophet Muhammad's life,
                including Bani Saad. Guides can provide context and historical
                information that enriches your understanding of Halima
                al-Saadia's role.
              </li>
              <li>
                - Cultural Context: Explore the cultural traditions and
                practices of the Banu Saad tribe, particularly regarding
                fosterage and familial relationships. Understanding these
                cultural aspects enhances appreciation for Halima al-Saadia's
                nurturing role and her impact on the Prophet's upbringing.
              </li>
              <li>
                - Reflect and Learn: Take time to reflect on the significance of
                Halima al-Saadia's contributions to early Islamic history and
                the values of compassion and care in Islam. Consider how her
                role as a foster mother shaped the Prophet Muhammad's character
                and teachings, emphasizing the importance of familial bonds and
                community support.
              </li>
              <li>
                - Engage with Scholars: Attend lectures, seminars, or
                discussions led by scholars and experts in Islamic history and
                culture. Engaging with knowledgeable individuals can provide
                deeper insights into Halima al-Saadia's historical context and
                her enduring legacy in Islamic tradition.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
