import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/Taif/holyplace/taifzoo/image5.jpeg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/taifzoo/image4.jpg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/taifzoo/image3.jpg",
    process.env.PUBLIC_URL + "/images/Taif/holyplace/taifzoo/image2.avif",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={"/images/Taif/holyplace/taifzoo/image2.avif"}
          title={"Taif Zoo"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
