import React from "react";
import Rating from 'react-rating-stars-component';
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)({
  color: '#00936c',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#00936c',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const DisplayReview = ({ review }) => {
  const {
    huz_concierge,
    huz_support,
    huz_platform,
    huz_service_quality,
    huz_response_time,
    huz_comment,
    partner_total_stars,
    partner_comment,
  } = review;

  return (
    <div className="space-y-6 bg-white p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800">You have already reviewed this booking</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {[
          { label: "Concierge Rating", value: huz_concierge },
          { label: "Support Rating", value: huz_support },
          { label: "Platform Rating", value: huz_platform },
          { label: "Service Quality Rating", value: huz_service_quality },
          { label: "Response Time Rating", value: huz_response_time },
        ].map(({ label, value }, index) => (
          <div key={index}>
            <label className="block text-gray-600 font-medium mb-1">{label} - {value}/5</label>
            <CustomSlider
              value={value}
              min={1}
              max={5}
              step={1}
              disabled
              valueLabelDisplay="auto"
            />
          </div>
        ))}
        <div className="md:col-span-2">
          <label className="block text-gray-600 font-medium mb-1">Huz Comment</label>
          <div className="mt-2 p-4 block w-full border rounded-lg shadow-sm bg-gray-100 text-gray-700">
            {huz_comment}
          </div>
        </div>
      </div>
      <div>
        <label className="block text-gray-600 font-medium mb-1">Partner Total Stars</label>
        <Rating
          value={partner_total_stars}
          count={5}
          size={30}
          edit={false}
          activeColor="#00936c"
        />
      </div>
      <div>
        <label className="block text-gray-600 font-medium mb-1">Partner Comment</label>
        <div className="mt-2 p-4 block w-full border rounded-lg shadow-sm bg-gray-100 text-gray-700">
          {partner_comment}
        </div>
      </div>
    </div>
  );
};

export default DisplayReview;
