import React, { useEffect, useRef, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaHeart, FaUserCheck, FaUserCircle, FaUserCog } from "react-icons/fa";
import { AuthContext } from "../../App";
import { Link } from "react-router-dom";
import user from "../../assets/user.svg";
import { AiOutlineLogin } from "react-icons/ai";
import { IoBagAddSharp, IoWallet } from "react-icons/io5";
import { RiLogoutBoxFill } from "react-icons/ri";
import { BiSolidCommentError } from "react-icons/bi";
const Dropdown = ({ isOpenDropdown }) => {
  const { state, dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    dispatch({ type: "USER", payload: false });
    localStorage.clear();
  };

  const [isOpen, setIsOpen] = useState(false);
  // const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // Close dropdown when clicking outside
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  let name;
  const login_user_profile =
    JSON.parse(localStorage.getItem("Login-User-Profile")) || {};

  const new_user_profile =
    JSON.parse(localStorage.getItem("New-User-Profile")) || {};
  if (Object.keys(login_user_profile).length !== 0) {
    name = new_user_profile.name;
  } else if (Object.keys(new_user_profile).length !== 0) {
    name = login_user_profile.name;
  }
  const user_name = JSON.parse(
    localStorage.getItem("Login-User-Profile")
  )?.name;

  return (
    <div
      className="relative inline-block text-left z-[150] font-kd"
      ref={dropdownRef}
    >
      <div>
        {state ? (
          <button
            type="button"
            className="inline-flex items-center w-[100%] gap-2 text-sm font-medium text-[#4b465c]"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={toggleDropdown}
          >
            <img
              src={user}
              alt=""
              className="h-8 w-8 border-[1px] border-[#00936c] rounded-full"
            />
            <div className="text-left">
              <p className="text-md font-bold">Your Account</p>
              <p className="text-xs text-[#00936c]">{user_name}</p>
            </div>
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex border-2 border-[#DDDDD] items-center w-[70px] p-2 md:w-[90px] rounded-2xl md:px-4 justify-between md:py-2 bg-[#EFF0F2] shadow-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={toggleDropdown}
          >
            <RxHamburgerMenu className="h-4 w-4 md:h-5 md:w-5" />
            <FaUserCircle className="h-4 w-4 md:h-5 md:w-5" />
          </button>
        )}
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <a
              href="http://partner.hajjumrah.co/"
              className="block md:hidden px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Become A Partner
            </a>{" "}
            <button
              onClick={isOpenDropdown}
              className="block md:hidden px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Select Currency
            </button>
            {state ? (
              <>
                <Link
                  to="/account-settings"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <FaUserCog />
                    <p>Manage Account</p>
                  </div>
                </Link>
                <Link
                  to="/allbookingsinfo"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <IoBagAddSharp />

                    <p>Bookings & Trips</p>
                  </div>
                </Link>
                <Link
                  to="/account-rewards&wallet"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <IoWallet />

                    <p>Rewards & Wallet</p>
                  </div>
                </Link>
                <Link
                  to="/allbookingsinfo"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <FaUserCheck />
                    <p>Reviews</p>
                  </div>
                </Link>
                <Link
                  to="/all-complaints"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <BiSolidCommentError />
                    <p>Complaints</p>
                  </div>
                </Link>
                <Link
                  to="/account-savedlist"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <FaHeart />

                    <p>Saved</p>
                  </div>
                </Link>
                <Link
                  onClick={handleLogout}
                  to="/login"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-2">
                    <RiLogoutBoxFill />

                    <p>Log out</p>
                  </div>
                </Link>
              </>
            ) : (
              <Link
                to="/login"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                <div className="flex items-center gap-2">
                  <AiOutlineLogin />
                  <p>Log in or Sign up</p>
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
