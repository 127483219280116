import React, { useRef } from "react";
import { TbCheckbox } from "react-icons/tb";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { taif } from "../../../../../../data";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Page = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const facts = [
    "Ta'if is situated at 1,879 meters above sea level, offering a cooler climate and lush greenery.",
    "It holds a key place in Islamic history, where Muhammad initially attempted to spread Islam.",
    "The city is known for its rich cultural heritage, traditional markets, and historic mosques.",
    "Ta'if is famous for its production of roses, used in rosewater and perfumes.",
  ];

  // console.log("hello", gharSur.hero.img);

  return (
    <div className="w-[90%] mx-auto">
      <h1 className="font-cairo bg-[#cce9e2] mx-64 p-4 rounded-2xl md:text-[15px] text-[15px] font-semibold text-center justify-center flex my-10">
        The distance between Mecca and Al Taif is 244 km. The road distance is
        90.6 km.
      </h1>
      <h1 className="font-cairo md:text-[25px] text-[15px] font-bold text-[#484848]">
        History
      </h1>
      <div className="flex gap-10">
        <p className="font-cairo w-1/2 md:text-[16px] text-[10px] font-normal leading-[32px]">
          Step into the vibrant history of Ta'if, Saudi Arabia's hidden jewel.
          Once a thriving religious center alongside Mecca, Ta'if's roots trace
          back to the 6th century AD, when it was adorned with the idol of the
          goddess Lāt. Despite its fertile surroundings, Ta'if faded in
          significance over time until the arrival of Muhammad in the 7th
          century CE, heralding a new era of Islam in the region. Centuries of
          shifting powers followed until Ta'if finally found its place within
          the Kingdom of Saudi Arabia in 1924. Since then, it has blossomed into
          a modern city with infrastructure developments like highways and
          public services. Join us as we uncover the rich tapestry of Ta'if's
          past and present, where ancient traditions meet contemporary progress
          in the heart of Saudi Arabia.
        </p>
        <div className="w-1/2 text-center">
          <img
            src={taif.history.image}
            alt="Jabal al-Nour and the Cave of Hira"
            className="w-full h-[340px] rounded-lg"
          />
          <p>
            <em>
              "Pilgrims gathered at Masjid Nimra in Arafat, Saudi Arabia, during
              Hajj."
            </em>
          </p>
        </div>
      </div>
      <div className="pb-6">
        <div className="w-full mx-auto">
          <h2 className="text-[25px] font-bold font-cairo text-left text-gray-800 mb-5">
            4 Facts about Taif
          </h2>
          <ul className="space-y-3">
            {facts.map((fact, index) => (
              <li key={index} className="flex items-start gap-2 rounded-lg">
                <TbCheckbox className="text-[15px] text-gray-700 mt-1" />
                <p className="text-gray-700 text-lg font-cairo leading-relaxed">
                  {fact}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="py-5">
        <h1 className="font-cairo text-[28px] md:text-[25px] font-bold text-gray-800">
          Unraveling the Enchantment of Taif
        </h1>
        <h2 className="font-cairo font-normal text-[18px] md:text-[15px] py-4 text-gray-600">
          Taif, a city nestled in the mountains of Saudi Arabia, holds a special
          place in Islamic history and culture. Renowned for its pleasant
          climate and lush landscapes, Taif has long been a sanctuary of
          tranquility and spiritual reflection. The city is famously associated
          with the Prophet Muhammad (peace be upon him), who sought solace and
          support here during his mission. Today, Taif continues to captivate
          visitors with its rich heritage, serene ambiance, and significant
          historical sites. From ancient mosques to vibrant markets, Taif offers
          a unique blend of tradition and natural beauty. Its legacy of devotion
          and resilience remains a source of inspiration for generations, making
          Taif a cherished destination for those seeking both spiritual and
          cultural enrichment.
        </h2>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:block block gap-14">
        <img
          src={taif.why.image}
          alt="Cave of Hira"
          className="w-full md:w-1/2 lg:w-1/2 rounded-lg"
        />
        <div className="block">
          <h3 className="font-cairo font-bold text-[24px] md:text-[25px] pb-2 text-gray-800">
            Sacred Serenity: The Legacy of Taif Majestic Tranquility: The Spirit
            of Taif
          </h3>
          <p className="font-cairo font-normal text-[10px] md:text-[18px] leading-[32px] text-gray-600">
            Taif stands as a timeless symbol of reverence, drawing devout
            visitors from across the globe to honor its sacred significance and
            seek solace in its spiritual aura. Each journey to this revered city
            is a profound pilgrimage, where visitors immerse themselves in
            contemplation, prayer, and reflection upon the profound legacies
            left by those who have graced its historic precincts. The atmosphere
            resonates with a tangible sense of devotion and humility, as
            travelers traverse the city's landmarks, offering silent prayers and
            seeking divine blessings. The serene ambiance of Taif invites
            introspection and spiritual rejuvenation, fostering a deep
            connection with the enduring spirit of faith and piety that
            permeates its sacred environs. Indeed, Taif stands as a living
            testament to the enduring legacy of Islam, providing a sanctuary for
            believers to nurture their spiritual journey and honor the noble
            souls who have shaped its history. As pilgrims depart from this
            sacred city, they carry with them not only the blessings of the
            revered past but also a renewed sense of purpose and devotion to
            Allah, inspired by the timeless teachings and examples set forth by
            those who find eternal peace within the blessed precincts of Taif.
          </p>
        </div>
      </div>
      <div className="bg-[#cce9e2] p-4 rounded text-left mx-auto my-10">
        <p className="text-[#484848] font-semibold font-cairo text-[18px]">
          "Discover Taif: An Arabian gem boasting lush gardens, vibrant markets,
          and a rich historical tapestry. Escape to the cool mountain breeze and
          uncover the city's hidden charms. Join us on a journey through this
          enchanting destination today!"
        </p>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:block block gap-5 mt-10">
        <div className="block">
          <p className="font-cairo font-normal text-[22px] leading-[40px]">
            Embark on a journey of spiritual discovery: Masjid-e-Nimra, the
            Sanctuary of Serenity. This sacred site in Makkah beckons pilgrims
            from across the globe, celebrated for its historical importance and
            divine tranquility. Immerse yourself in the peaceful atmosphere,
            where reflections on Allah's blessings abound. Visiting this blessed
            masjid fosters a deep sense of connection and devotion, enriching
            the hearts of believers with its spiritual treasures.
          </p>
        </div>
        <img src={taif.pilgrimge.image} alt="" className="w-1/2 rounded-lg " />
      </div>
      <div className="gap-5 mt-7 flex">
        <img
          src={taif.pilgrimge2.image}
          alt=""
          className="pt-1.5 w-1/2 rounded-lg"
        />
        <div className="">
          <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-2 text-gray-800">
            Exploring the Sacred Essence of Masjid-e-Nimra:
          </h1>
          <div className="block">
            <p className="font-cairo font-normal text-[10px] md:text-[18px] leading-[32px] text-gray-600">
              Embark on a profound journey into the revered traditions of
              Masjid-e-Nimra, often revered as a sanctuary of spiritual
              enlightenment. Situated in the heart of Makkah, this sacred haven
              beckons believers from every corner of the globe to converge in
              profound reverence. Deeply rooted in the Islamic heritage,
              Masjid-e-Nimra stands as a timeless symbol of spiritual devotion
              and rejuvenation, inviting pilgrims to embark on a transformative
              voyage of the soul. As pilgrims set foot within its sacred
              precincts, they are enveloped by an atmosphere of tranquility and
              serenity, where the echoes of prayers and reflections resonate
              through the air. Amidst the fragrant gardens and lush greenery,
              seekers of spiritual solace find sanctuary as they ponder their
              blessings and offer heartfelt supplications to the Divine.
            </p>
          </div>
        </div>
      </div>

      {/* Slider */}
      <div className="slider-container relative pt-7">
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={3}
          loop={true}
          navigation={false} // Disable built-in navigation
          modules={[Navigation]}
        >
          <SwiperSlide>
            <img
              src={taif.history.image}
              alt="jamrat0"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={taif.pilgrimge.image}
              alt="jamrat1"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={taif.pilgrimge2.image}
              alt="jamrat2"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src={taif.why.image} alt="" className="rounded-lg h-[340px]" />
          </SwiperSlide>
        </Swiper>
        {/* Custom Navigation Buttons */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default Page;
