import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Discover the Timeless Majesty of Mount Uhud: Where History and Nature
          Unite
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Mount Uhud
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
                Mount Uhud is a prominent mountain located in the Hejaz region
                of Saudi Arabia, near the city of Medina. Situated approximately
                5 kilometers (3 miles) north of Medina, it stands as a
                significant landmark both historically and geographically. The
                mountain stretches over 7 kilometers (4.3 miles) and reaches an
                elevation of about 1,077 meters (3,533 feet) above sea level,
                making it one of the highest peaks in the area.
              </p>
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] text-justify">
                {" "}
                The geographical coordinates of Mount Uhud are approximately
                24.51°N latitude and 39.59°E longitude. The mountain is part of
                the larger Hejaz mountain range that runs parallel to the Red
                Sea coast. This strategic location has historically made it a
                critical point of interest, particularly during the early
                Islamic period. The surrounding terrain is characterized by
                rugged, rocky landscapes, typical of the Arabian Peninsula's
                mountainous regions.
              </p>
              <p className="text-[#484848] mb-4 lg:hidden xl:block font-cairo font-medium text-[15px] text-justify">
                Visitors to Mount Uhud can enjoy panoramic views of the Medina
                valley and explore the numerous trails that lead to various
                historical sites. The mountain not only offers a glimpse into
                the rich Islamic heritage but also provides an opportunity to
                witness the natural splendor of Saudi Arabia's mountainous
                landscapes.
              </p>
            </div>

            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src="/images/Madinah/mountainUhud/slider3.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
            <p className="text-[#484848] mb-4 font-cairo hidden lg:flex xl:hidden font-medium text-[15px] text-justify">
              Visitors to Mount Uhud can enjoy panoramic views of the Medina
              valley and explore the numerous trails that lead to various
              historical sites. The mountain not only offers a glimpse into the
              rich Islamic heritage but also provides an opportunity to witness
              the natural splendor of Saudi Arabia's mountainous landscapes.
            </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Mount Uhud in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Mount Uhud holds a significant place in Islamic history and
              tradition. Located near the city of Medina in Saudi Arabia, it is
              best known for being the site of the Battle of Uhud, a pivotal
              event in early Islamic history. This battle took place on March
              23, 625 CE (3 AH in the Islamic calendar) and involved the Muslim
              community of Medina, led by the Prophet Muhammad, and the Quraish
              tribe from Mecca. The battle was marked by initial Muslim success
              but ended in a setback due to a strategic mistake, teaching
              lessons of discipline, obedience, and unity. The mountain is also
              revered as the resting place of many martyrs, including Hamza ibn
              Abdul-Muttalib, the uncle of the Prophet Muhammad. Visiting Mount
              Uhud is a common practice for pilgrims and tourists, who come to
              reflect, pray, and learn about its historical significance. The
              Prophet Muhammad's saying, "Uhud is a mountain that loves us and
              we love it," underscores the deep emotional and spiritual
              connection to this site. Mount Uhud remains a powerful symbol in
              Islam, representing the early struggles and sacrifices of the
              Muslim community, and continues to inspire Muslims worldwide with
              its enduring principles of faith and perseverance.
            </p>
          </div>
          <div className="w-full">
            <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1 mb-5">
              The Battle of Uhud and Its Lessons
            </h2>
            <div className="">
              <p className="text-[#484848] mb-4">
                <span className="font-bold">Aal-Imran 3:149-151</span>
              </p>
              <p className="text-gray-700 mb-4">
                “O you who have believed, if you obey those who disbelieve, they
                will turn you back on your heels, and you will [then] become
                losers. But Allah is your protector, and He is the best of
                helpers. We will cast terror into the hearts of those who
                disbelieve for what they have associated with Allah of which He
                had not sent down [any] authority. And their refuge will be the
                Fire, and wretched is the residence of the wrongdoers.”
                <br />
                <span className="font-bold">[Aal-Imran 3:149-151]</span>
              </p>
              <div className="text-center mb-4">
                <img
                  src="/images/Madinah/mountainUhud/verse.webp"
                  alt="Quranic Verse"
                  className="mx-auto rounded-lg shadow-lg"
                />
              </div>
              <p className="text-gray-700">
                The Battle of Uhud was a significant event in early Islamic
                history where the Muslims initially succeeded but faced setbacks
                due to disobedience. These verses remind believers of the
                importance of steadfast faith and obedience to Allah. The
                consequences of abandoning these principles were evident in the
                battle, emphasizing the need for unity and adherence to divine
                guidance.
              </p>
            </div>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Mount Uhud
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Visit the Martyrs' Graves: Pay respects at the graves of the
                Battle of Uhud martyrs, including Hamza ibn Abdul-Muttalib,
                located at the foot of the mountain.
              </li>
              <li>
                - Hike the Trails: Follow the various trails that lead to
                different parts of the mountain, offering a blend of historical
                exploration and natural beauty.
              </li>
              <li>
                - Historical Plaques and Markers: Read the plaques and markers
                placed around the site to learn about the Battle of Uhud and its
                significance in Islamic history.
              </li>
              <li>
                - Scenic Views: Enjoy panoramic views of the Medina valley from
                different vantage points on the mountain.
              </li>
              <li>
                - Guided Tours: Consider joining a guided tour to gain in-depth
                knowledge about the history and significance of Mount Uhud from
                experienced guides.
              </li>
              <li>
                - Reflect and Pray: Use the serene environment of Mount Uhud for
                personal reflection and prayer, connecting spiritually with the
                historical significance of the site.
              </li>
              <li>
                - Photography: Capture the rugged beauty of the mountain and its
                surroundings, a popular activity for visitors.
              </li>
              <li>
                - Visit During Pilgrimage: If performing Hajj or Umrah, include
                a visit to Mount Uhud in your itinerary for a comprehensive
                spiritual experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
