import React from "react";
import Makkah from "./Makkah/Makkah";
import HeaderFooter from "./HeaderFooter";

const Articles = () => {
  return (
    <div>
      <HeaderFooter>
        <Makkah />
      </HeaderFooter>
    </div>
  );
};
export default Articles;
