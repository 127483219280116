import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchBookingDetails,
  updateObjectionResponse,
} from "../../../../utility/Api";
import VisaDetails from "../BookingDetailedPageComponents/ActiveSatusComponents/VisaDetails";
import AirlineDetails from "../BookingDetailedPageComponents/ActiveSatusComponents/AirlineDetails";
import TransportDetails from "../BookingDetailedPageComponents/ActiveSatusComponents/TransportDetails";
import HotelDetails from "../BookingDetailedPageComponents/ActiveSatusComponents/HotelDetails";
import Loader from "../../../../components/loader"; // Assuming you have a loader component
import Sidebar from "../BookingDetailedPageComponents/Sidebar";
import PackageDetails from "../BookingDetailedPageComponents/PackageDetails";
import BookingInfo from "../BookingDetailedPageComponents/BookingInfo";
import RequiredDocs from "../BookingDetailedPageComponents/RequiredDocs";
import Footer from "../../../../components/footer";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
import { CiTimer } from "react-icons/ci";

const BookingDetailedPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  const { booking_number } = useParams();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientRemarks, setClientRemarks] = useState("");
  const [objectionFile, setObjectionFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [remarksError, setRemarksError] = useState("");
  const [fileError, setFileError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = JSON.parse(
          localStorage.getItem("Login-User-Profile")
        );
        if (userProfile && userProfile.session_token) {
          const sessionToken = userProfile.session_token;
          const bookingData = await fetchBookingDetails(
            sessionToken,
            booking_number
          );
          setBooking(bookingData);
          localStorage.setItem("Booking-Data", JSON.stringify(bookingData)); // Save booking data to local storage
        } else {
          throw new Error("No session token found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [booking_number]);

  const handleClick = (path) => {
    navigate(path, { state: { booking } });
  };

  const handleRemarksChange = (e) => {
    setClientRemarks(e.target.value);
    if (e.target.value) {
      setRemarksError("");
    }
  };

  const handleFileChange = (e) => {
    setObjectionFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileError("");
    }
  };

  const handleSubmitObjection = async (objectionId) => {
    let valid = true;

    if (!clientRemarks) {
      setRemarksError("Remarks are required.");
      valid = false;
    }

    if (!objectionFile) {
      setFileError("A file is required.");
      valid = false;
    }

    if (!valid) {
      return;
    }

    setSubmitting(true);
    try {
      const userProfile = JSON.parse(
        localStorage.getItem("Login-User-Profile")
      );
      const sessionToken = userProfile.session_token;
      await updateObjectionResponse(
        sessionToken,
        booking_number,
        objectionFile,
        clientRemarks,
        objectionId
      );
      const updatedBooking = await fetchBookingDetails(
        sessionToken,
        booking_number
      );
      setBooking(updatedBooking);
      setClientRemarks("");
      setObjectionFile(null);
    } catch (error) {
      console.error("Error updating objection response:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div>
        <BookingNavbar />
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <BookingNavbar />
        <div className="w-[90%] mx-auto flex lg:flex-row flex-col lg:h-full mb-10 py-7">
          <div>Error: {error}</div>
        </div>
      </div>
    );
  }

  const {
    booking_documents = [],
    booking_status,
    booking_documents_status = [],
    booking_objections = [],
    booking_airline_details = [],
    booking_hotel_and_transport_details = [],
    user_documents = [],
  } = booking;

  const allDocuments = [...booking_documents, ...user_documents];

  const objectionsWithValues = booking_objections.filter(
    (objection) =>
      objection.client_remarks && objection.required_document_for_objection
  );
  const objectionsWithoutValues = booking_objections.filter(
    (objection) =>
      !objection.client_remarks || !objection.required_document_for_objection
  );

  return (
    <div>
      <BookingNavbar />
      <div className="w-[90%] mx-auto flex lg:flex-row flex-col lg:h-full mb-10 py-7">
        <div className="lg:w-[25%] space-y-6">
          <Sidebar booking={booking} />
          {booking_status === "Completed" && (
            <>
              <button
                onClick={() => handleClick("/submit-review")}
                className="w-full p-2 text-white bg-gray-500 rounded-md"
              >
                Submit a Review
              </button>
              <button
                onClick={() => handleClick("/report-problem")}
                className="w-full p-2 text-white bg-red-500 rounded-md"
              >
                Report a Problem
              </button>
            </>
          )}
        </div>
        <div className="lg:w-2/3 lg:px-4 space-y-6 mt-6 lg:mt-0 lg:space-y-4 flex-grow">
          <PackageDetails booking={booking} />
          <BookingInfo booking={booking} />
          {booking_status === "Paid" && (
            <div>
              <CiTimer className="text-[52px] font-bold flex justify-center align-middle text-center w-full" />

              <div className="text-[26px] flex justify-center align-middle text-center w-full">
                Wait till your payment confirmed by HajjUmrah.co{" "}
              </div>
            </div>
          )}
          <div>
            {booking_status === "Initialize" && (
              <button
                onClick={handleClick}
                className="w-full p-2 text-white bg-[#00936c] rounded-md"
              >
                Pay Now
              </button>
            )}
          </div>
          <div>
            {booking_status !== "Paid" && booking_status !== "Initialize" && (
              <RequiredDocs
                documents={allDocuments.filter(
                  (doc) => doc.document_for === "Required_Documents"
                )}
                booking={booking}
              />
            )}
            {booking_documents_status.length > 0 &&
              booking_documents_status[0].is_visa_completed && (
                <VisaDetails
                  documents={allDocuments.filter(
                    (doc) => doc.document_for === "eVisa"
                  )}
                  booking={booking}
                />
              )}
            {booking_documents_status.length > 0 &&
              booking_documents_status[0].is_airline_completed && (
                <AirlineDetails
                  documents={allDocuments.filter(
                    (doc) => doc.document_for === "airline"
                  )}
                  booking={booking}
                />
              )}
            {booking_documents_status.length > 0 &&
              booking_documents_status[0].is_transport_completed && (
                <TransportDetails booking={booking} />
              )}
            {booking_documents_status.length > 0 &&
              booking_documents_status[0].is_hotel_completed && (
                <HotelDetails booking={booking} />
              )}

            {booking_status === "Objection" && (
              <div className="p-6 border rounded-md mt-8 font-kd">
                <h3 className="text-red-600 text-lg font-semibold mb-4">
                  Objection
                </h3>

                {objectionsWithValues.map((objection) => (
                  <div
                    key={objection.objection_id}
                    className="mt-4 p-4 bg-white border rounded-md"
                  >
                    <p className="text-sm text-gray-600 mb-2">
                      Remarks or Reason: {objection.remarks_or_reason}
                    </p>
                    <div className="mt-2">
                      <p className="text-sm text-gray-600">
                        Client Remarks: {objection.client_remarks}
                      </p>
                      <a
                        href={`${process.env.REACT_APP_API_BASE_URL}${objection.required_document_for_objection}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline text-sm"
                      >
                        View Document
                      </a>
                    </div>
                  </div>
                ))}

                {objectionsWithoutValues.map((objection) => (
                  <div
                    key={objection.objection_id}
                    className="mt-4 p-2 bg-white border rounded-md"
                  >
                    <p className="text-sm text-gray-600 mb-2">
                      Remarks or Reason: {objection.remarks_or_reason}
                    </p>
                    <div className="mt-2 block">
                      <textarea
                        value={clientRemarks}
                        onChange={handleRemarksChange}
                        placeholder="Enter your remarks here"
                        className="w-full p-2 border-[1px] border-gray-200 rounded-md mb-2 resize-none placeholder:text-[15px] focus:outline-none focus:ring-2 focus:ring-[#00936c] focus:border-transparent"
                      />
                      {remarksError && (
                        <p className="text-red-500 text-xs mt-1">
                          {remarksError}
                        </p>
                      )}
                      <label className="block mt-4">
                        <span className="sr-only">Choose file</span>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-green-50 file:text-green-700
                      hover:file:bg-green-100"
                        />
                      </label>
                      {fileError && (
                        <p className="text-red-500 text-xs mt-3">{fileError}</p>
                      )}
                      <button
                        onClick={() =>
                          handleSubmitObjection(objection.objection_id)
                        }
                        className="p-2 px-3.5 mt-5 block bg-[#00936c] text-white rounded-md"
                        disabled={submitting}
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingDetailedPage;
