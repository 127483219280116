import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import BookingNavbar from "../../../../../components/Navbar/BookingNavBar";
import Footer from "../../../../../components/footer";
import Loader from "../../../../../components/loader";
import { raiseComplaintBookingWise } from "../../../../../utility/Api";
import { FaMicrophone, FaStop } from "react-icons/fa";
import RecordRTC, { invokeSaveAsDialog } from "recordrtc";
import { MdReportProblem } from "react-icons/md";

const ReportProblem = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const { booking } = location.state;

  const [complaintTitle, setComplaintTitle] = useState("");
  const [complaintMessage, setComplaintMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [audioMessage, setAudioMessage] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [audioError, setAudioError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const recorderRef = useRef(null);

  const handleTitleBlur = () => {
    if (!complaintTitle) {
      setTitleError("Title is required.");
    } else {
      setTitleError("");
    }
  };

  const handleMessageBlur = () => {
    if (!complaintMessage) {
      setMessageError("Message is required.");
    } else {
      setMessageError("");
    }
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/mp3",
      });
      recorder.startRecording();
      recorderRef.current = recorder;
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing audio stream: ", err);
    }
  };

  const handleStopRecording = () => {
    recorderRef.current.stopRecording(() => {
      const blob = recorderRef.current.getBlob();
      setAudioBlob(blob);
      setAudioURL(URL.createObjectURL(blob));
      setIsRecording(false);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!complaintTitle) {
      setTitleError("Title is required.");
    }
    if (!complaintMessage) {
      setMessageError("Message is required.");
    }
    if (complaintTitle && complaintMessage && !audioError) {
      setSubmitting(true);
      try {
        let audioFile = audioMessage;
        if (!audioFile && audioBlob) {
          audioFile = new File([audioBlob], "audio_message.mp3", {
            type: "audio/mp3",
          });
        }

        await raiseComplaintBookingWise(
          booking.user_session_token,
          booking.booking_number,
          complaintTitle,
          complaintMessage,
          responseMessage,
          audioFile
        );

        alert("Complaint submitted successfully!");
        navigate(-1); // Go back to the previous page
      } catch (error) {
        console.error("Error raising complaint:", error);
        alert("Failed to submit complaint. Please try again.");
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <div>
      <BookingNavbar />
      <div>
        <img
          src="/images/Booking/BannerImage.png"
          alt="Banner"
          className="w-full"
        />
      </div>
      <div className="container mx-auto py-10">
        <div className="mb-10 flex justify-between">
          <div className="flex items-center gap-3">
            <button
              onClick={() => navigate(-1)}
              className="p-1 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300"
            >
              <IoArrowBack size={20} />
            </button>
            <h1 className="text-[28px] text-[#484848] font-bold">
              Report a Problem
            </h1>
          </div>
          <button
            onClick={() => navigate("/all-complaints", { state: { booking } })}
            className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-green-600 rounded-full shadow-md group hover:bg-green-100 hover:shadow-lg"
          >
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#00936c] group-hover:translate-x-0 ease">
              <MdReportProblem className="w-6 h-6" />
            </span>
            <span className="absolute flex items-center justify-center w-full h-full text-[#00936c] transition-all duration-300 transform group-hover:translate-x-full ease">
              View All Complaints
            </span>
            <span className="relative invisible">View All Complaints</span>
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-white p-8 rounded-lg shadow-md"
        >
          <div>
            <label className="block text-gray-700 font-semibold">
              Complaint Title
            </label>
            <input
              type="text"
              value={complaintTitle}
              onChange={(e) => setComplaintTitle(e.target.value)}
              onBlur={handleTitleBlur}
              className="mt-2 p-3 block w-full border rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              placeholder="Enter the title of your complaint"
            />
            {titleError && (
              <p className="text-red-500 text-sm mt-1">{titleError}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Complaint Message
            </label>
            <textarea
              value={complaintMessage}
              onChange={(e) => setComplaintMessage(e.target.value)}
              onBlur={handleMessageBlur}
              className="mt-2 p-3 block w-full border rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              placeholder="Describe the issue you faced"
            />
            {messageError && (
              <p className="text-red-500 text-sm mt-1">{messageError}</p>
            )}
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Response Message (Optional)
            </label>
            <textarea
              value={responseMessage}
              onChange={(e) => setResponseMessage(e.target.value)}
              className="mt-2 p-3 block w-full border rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              placeholder="Enter any additional response message"
            />
          </div>
          <label className="block text-gray-700 font-semibold">
            Audio Message (Optional)
          </label>
          <div className="flex items-center gap-4">
            {isRecording ? (
              <button
                type="button"
                onClick={handleStopRecording}
                className="p-3 bg-red-600 text-white rounded-full shadow-md hover:bg-red-700"
              >
                <FaStop size={20} />
              </button>
            ) : (
              <button
                type="button"
                onClick={handleStartRecording}
                className="p-3 bg-[#00936c] text-white rounded-full shadow-md hover:bg-green-700"
              >
                <FaMicrophone size={20} />
              </button>
            )}
            <p className="text-gray-600">
              {isRecording ? "Recording..." : "Record your message"}
            </p>
          </div>
          {audioURL && (
            <div className="mt-4">
              <label className="block text-gray-700 font-semibold">
                Playback Recording
              </label>
              <audio controls src={audioURL} className="mt-2 w-full"></audio>
            </div>
          )}
          <div>
            <button
              type="submit"
              className="w-full p-3 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 flex justify-center items-center"
              disabled={submitting}
            >
              {submitting ? <Loader /> : "Submit Complaint"}
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ReportProblem;