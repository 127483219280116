import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegSave, FaShareSquare } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { BsFillSendArrowDownFill } from "react-icons/bs";
import shape from "../../../assets/shape.png";
import Subscription from "../../../components/subscription";
import Footer from "../../../components/footer";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";

const jobData = [
  {
    id: 1,
    title: "Full Stack Engineer",
    description:
      "We are looking for a Senior Full-stack engineer to join our development team.",
  },
  {
    id: 2,
    title: "Apply Now Admin & Operations Officer",
    description:
      "We are looking for an Operations Officer to join our development team.",
  },
  {
    id: 3,
    title: "Customer Sales Specialist",
    description:
      "We are looking for a Customer Sales Specialist to join our development team.",
  },
];

const JobOne = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const { id } = useParams();

  const currentJob = jobData.find((j) => j.id === Number(id)) || jobData[0];

  const handleApplyNow = () => {
    navigate("/apply-form");
  };

  return (
    <div className="rounded-lg text-[#4B465C]">
      <BookingNavbar />
      <div className="mb-6 bg-[#E5F1EE] text-center justify-center">
        <h1 className="font-bold text-2xl pt-20 pb-2">Job Detail</h1>
        <img src={shape} alt="shape" />
      </div>
      <div className="md:flex w-[80%] mx-auto py-14">
        <div className="md:w-[70%]">
          <div className="md:flex justify-between items-center mb-6">
            <div className="flex-1">
              <h1 className="text-3xl font-bold">{currentJob.title}</h1>
            </div>
            <div className="flex gap-2">
              <button
                className="bg-[#00936C] flex items-center justify-center text-white font-bold py-2 px-4 rounded hover:bg-[#00936ce7]"
                onClick={handleApplyNow}
              >
                Apply Now
                <BsFillSendArrowDownFill className="ml-2 mt-1" />
              </button>
              <button className="text-gray-500 p-2 hover:bg-gray-100 rounded">
                <FaRegSave className="text-xl" />
              </button>
              <button className="text-gray-500 p-2 hover:bg-gray-100 rounded">
                <FaShareSquare className="text-xl" />
              </button>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">About this role</h2>
            <p className="text-md">{currentJob.description}</p>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Qualifications</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>
                At least 2-4 years of relevant experience in product design or
                related roles.
              </li>
              <li>
                Knowledge of design validation, either through quantitative or
                qualitative research.
              </li>
              <li>Have good knowledge using Figma and Figjam.</li>
              <li>
                Experience with analytics tools to gather data from users.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-2">Responsibilities</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Create design and user journey on every feature and
                product/business units across multiple devices (Web+App).
              </li>
              <li>
                Identifying design problems through user journey and devising
                elegant solutions.
              </li>
              <li>
                Develop low and hi fidelity designs, user experience flow, &
                prototype, translate it into highly-polished visual compositions
                following style and brand guidelines.
              </li>
              <li>
                Brainstorm and works together with Design Lead, UX Engineers,
                and PMs to execute a design sprint on specific story or task.
              </li>
            </ul>
          </div>
        </div>
        <div className="md:w-[30%] p-4">
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-4">
            <h3 className="text-lg font-semibold mb-4">Job Overview</h3>
            <div className="space-y-3">
              <div className="flex items-center">
                <span className="icon mr-2">👩‍💻</span>
                <span>Product Designer</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">📅</span>
                <span>0-3 Years</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">📍</span>
                <span>New York</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">💵</span>
                <span>$35k - $45k</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">🎓</span>
                <span>Bachelor Degree</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">🏢</span>
                <span>Private</span>
              </div>
              <div className="flex items-center">
                <span className="icon mr-2">🕒</span>
                <span>Posted 2 hrs ago</span>
              </div>
              <button className="w-full flex items-center justify-center gap-2 bg-[#00936C] hover:bg-[#00936ce1] text-white font-bold py-2 px-4 rounded mt-4">
                <CiBookmark className="mt-1" />
                Add Bookmark
              </button>
            </div>
          </div>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default JobOne;
