import React from "react";

const Page = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          8 min (3.7 km) distance from Madina via First Ring Rd - King
          Faisal/King Faisal Rd
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                {" "}
                Historical Background of Masjid Abu Bakr
              </h1>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <p
                className="font-cairo font-normal leading-[32px] text-justify"
                style={{ marginTop: "10px" }}
              >
                Masjid AbuBakr, named after Abu Bakr as-Siddiq, the first Caliph
                of Islam, stands as a significant center for worship, community
                engagement, and cultural education. This mosque not only serves
                as a place for daily prayers but also as a community hub that
                fosters a deeper understanding of Islam and promotes social
                cohesion among its members The masjid abi-Bakr (مسجد ابو بكر
                الصديق) is located at the south-western flank of the Prophet
                Muhammad’s (SAW) mosque and north-west of the Masjid-al-Ghamama.
                According to a tradition Muhammad prayed al Eid prayer at this
                site, and abu Bakr after the prophet continued the tradition
                during his Caliphate, so it was attributed to him. It was
                built-up for the first time in the Princedom of Omar Bin Abdul
                Aziz in Madina (86-91 AH/705-709 CE), and it was renovated by
                the Ottoman Sultan Mahmoud II in 1254 Hj./1838 CE. Masjid Abi
                Bakr, a small square-shaped mosque, boasts a side length of nine
                meters and was constructed using black basalt stones.
                Internally, it is painted white and adorned with a dome soaring
                12 meters high. The mosque encompasses a rectangular courtyard
                measuring 13 meters in length and 6 meters in width, accompanied
                by a minaret towering to a height of 15 meters. This single
                minaret, resembling the Bab as-Salam Minaret of Masjid Nabawi,
                retains its Ottoman structure and features a muazzin's balcony
                at its center. The dome of Masjid Abi Bakr is externally
                designed with ribbed-style architecture, adding to its ornate
                appearance.
              </p>
            </div>
            <div>
              <img
                src="/images/Madinah/masjidabubakar/abubakar7.jpg"
                alt="Masjid-abu-bakar"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
            {" "}
            Religious Significance of Masjid Abu Bakr
          </h1>
          <div className="grid md:grid-cols-2 gap-6 items-center">
            <div>
              <p
                className="font-cairo font-normal leading-[32px] text-justify"
                style={{ marginTop: "10px" }}
              >
                Masjid AbuBakr, a cornerstone of its community, offers a range
                of religious services fostering spiritual growth and unity.
                Central are the five daily prayers and the Jumu'ah prayer on
                Fridays, featuring sermons on contemporary issues and spiritual
                guidance. The mosque hosts educational programs, including
                Quranic recitation, Hadith studies, and Islamic jurisprudence
                sessions for various ages and knowledge levels. During Ramadan,
                it becomes a hub of devotion with nightly Taraweeh prayers,
                community Iftars, and spiritual talks. It supports life events
                like weddings, funerals, and naming ceremonies, ensuring
                religious rituals are respected. The mosque engages in social
                welfare activities, organizing charity drives, food
                distributions, and support for the needy, embodying compassion
                and service. Open days and tours for non-Muslims promote
                dialogue and mutual respect. Masjid AbuBakr is more than a place
                of worship; it is a dynamic center for education, social
                services, and community engagement, reflecting the holistic
                nature of Islam. Through its comprehensive services, the mosque
                strengthens attendees' faith and positively contributes to the
                broader society.
              </p>
            </div>
            <div>
              <img
                src="/images/Madinah/masjidabubakar/abubakar4.jpg"
                alt="No pic found"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <img
                src="/images/Madinah/masjidabubakar/abubakar6.jpg"
                alt="Nothing found"
                className="w-full rounded-lg shadow-lg"
              />
              <p style={{ marginTop: "10px" }}>
                <strong>
                  {" "}
                  <i>
                    "The Prophet (ﷺ) said, "If I were to take a Khalil, I would
                    have taken Abu Bakr, but he is my brother and my companion"
                  </i>
                </strong>
              </p>
            </div>
            <div>
              <p className="mt-4 text-gray-800 pl-4 text-lg md:text-xl leading-loose">
                “There are people who forgot that their lifetime would come to
                an end. They kept procrastinating about doing good deeds until
                death came to them. Beware of being like them. Strive hard and
                hasten towards safety, for a serious matter is coming to you,
                and your lifespan will quickly pass. Be aware of death and learn
                lessons from what happened to your fathers, sons and brothers
                (who have passed away).”
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="grid md:grid-cols-1">
            <div>
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                {" "}
                Global Outreach & Community Partnerships
              </h1>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                The mosque actively collaborates with local organizations,
                schools, and government agencies to address community needs.
                These partnerships enhance the impact of the mosque's services
                and contribute to the overall well-being of the community.
                Whether it's through joint charity drives or collaborative
                educational programs, these partnerships are a testament to the
                mosque's commitment to serving the broader community. Masjid
                AbuBakr extends its impact beyond local boundaries through its
                global outreach and humanitarian efforts. The mosque supports
                various international relief organizations, providing aid to
                those affected by natural disasters, conflicts, and poverty.
                These efforts embody the Islamic principles of charity and
                compassion on a global scale.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 space-y-8">
          <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
            Education Programms
          </h1>
          <div className="bg-gray-100 p-6 rounded-lg">
            <h1 className="font-bold block text-xl md:text-2xl mb-2 text-gray-600">
              Islamic Education
            </h1>
            <p className="font-cairo font-normal text-lg md:text-xl text-gray-600">
              A cornerstone of Masjid AbuBakr's mission is the provision of
              Islamic education. The mosque runs a weekend school that offers
              Quranic studies, Arabic language classes, and Islamic history
              lessons. These programs are designed for children and youth,
              helping them develop a strong foundation in their faith.
            </p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <h1 className="font-bold block text-xl md:text-2xl mb-2 text-gray-600">
              Adult Education and Lifelong Learning
            </h1>
            <p className="font-cairo font-normal text-lg md:text-xl text-gray-600">
              Masjid AbuBakr also caters to the educational needs of adults.
              Regular classes and seminars on various topics, including Tafsir
              (Quranic exegesis), Fiqh (Islamic jurisprudence), and Seerah
              (biography of the Prophet Muhammad), are conducted by qualified
              scholars. These sessions encourage lifelong learning and spiritual
              growth among community members.
            </p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <h1 className="font-bold block text-xl md:text-2xl mb-2 text-gray-600">
              Youth Programs and Activities
            </h1>
            <p className="font-cairo font-normal text-lg md:text-xl text-gray-600">
              Understanding the importance of engaging the youth, Masjid AbuBakr
              offers a range of programs specifically designed for young people.
              These include sports leagues, youth camps, and leadership training
              programs. By providing a safe and supportive environment, the
              mosque helps nurture the talents and skills of the younger
              generation.
            </p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <h1 className="font-bold block text-xl md:text-2xl mb-2 text-gray-600">
              Women's Programs
            </h1>
            <p className="font-cairo font-normal text-lg md:text-xl text-gray-600">
              Masjid AbuBakr is committed to empowering women within the
              community. The mosque offers various programs for women, including
              religious classes, support groups, and social events. These
              initiatives aim to foster a sense of belonging and provide women
              with the tools and support they need to thrive.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
