import React, { useEffect } from "react";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Subscription from "../../../components/subscription";
import Footer from "../../../components/footer";

const ContactUs = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <BookingNavbar />
      <div className="bg-gray-50">
        <div className="w-[90%] mx-auto lg:flex gap-3 py-10">
          <div className="relative w-full lg:w-1/2 mt-10">
            <div className="relative lg:flex justify-between">
              <div className="absolute hidden lg:flex top-0 left-0 h-80 w-52 bg-[radial-gradient(circle,_#00936c_20%,_transparent_10%)] [background-size:78px_78px] [background-position:0_0,_0_0] pointer-events-none opacity-5"></div>
              <div className="relative z-10 md:p-10">
                <h2 className="text-[20px] font-semibold mb-4 text-[#484848]">
                  Contact us
                </h2>
                <p className="text-[18px] md:text-[12px] lg:text-[14px] font-normal mb-4 text-[#484848] w-full">
                  Need to get in touch with us? Either fill out the form with
                  your inquiry or find the{" "}
                  <a href="#" className="text-[#00936C] hover:text-[#00936ce1]">
                    department email
                  </a>{" "}
                  you'd like to contact below.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex justify-center lg:justify-end mt-10 lg:mt-0">
            <div className="bg-white md:p-10 p-5 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-[18px] font-semibold mb-6 text-[#484848]">
                Contact us
              </h2>
              <p className="mb-6 text-gray-600">
                Need to get in touch with us? Either fill out the form with your
                inquiry or find the{" "}
                <a href="#" className="text-[#00936C] hover:text-[#00936ce1] ">
                  department email
                </a>{" "}
                you'd like to contact below.
              </p>
              <form className="space-y-4">
                <div className="md:flex md:space-x-4 space-y-3 md:space-y-0">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="firstName"
                      className="flex text-sm font-medium text-gray-700"
                    >
                      First name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#00936C] focus:border-[#00936C] sm:text-sm"
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#00936C] focus:border-[#00936C] sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="flex text-sm font-medium text-gray-700"
                  >
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#00936C] focus:border-[#00936C] sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    What can we help you with?
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#00936C] focus:border-[#00936C] sm:text-sm resize-none"
                  ></textarea>
                </div>
                <button className="w-full py-2 px-4 bg-[#00936C] hover:bg-[#00936ce1] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00936C] focus:border-[#00936C]">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default ContactUs;
