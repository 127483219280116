import React from "react";

const JeddahBanner = () => (
  <div className="w-[85%] mx-auto py-5 pt-12">
    <p className="text-[16px] text-[#484848] text-left leading-[20px] font-kd">
      Jeddah, a bustling city on Saudi Arabia's western coast, is a vital hub of
      commerce and culture. It is a key gateway to the holy sites of Islam,
      including the sacred Masjid al-Haram and the revered Kaaba in Mecca. For
      many, visiting Jeddah is part of their spiritual journey, whether for Hajj
      or Umrah. This city is not only a geographical location but also holds a
      significant place in the Islamic world. Jeddah has grown under the
      stewardship of Saudi kings, who have focused on enhancing the experience
      for visitors and residents alike. The city features extensive developments
      and state-of-the-art facilities that ensure comfort and convenience for
      all. Jeddah remains a symbol of unity and devotion in the Islamic world,
      with its rich history and continuous growth making it an enduring and
      dynamic metropolis.
    </p>
  </div>
);

export default JeddahBanner;
