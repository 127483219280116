import axios from "axios";
// Ensure the token is correctly formatted without spaces
const authHeader = `${process.env.REACT_APP_AUTH_TOKEN}`;
const baseURL = process.env.REACT_APP_API_BASE_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: authHeader,
  },
};
export const checkUserExistence = async (phone_number) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/is_user_exist/`,
      { phone_number },
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return error.response
    }
    throw error;
  }
};


export const sendOtp = async (phoneNumber) => {
  const data = JSON.stringify({ phone_number: phoneNumber });
  try {
    const response = await axios.post(`${baseURL}/common/send_otp_sms/`, data, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const verifyOtp = async (phoneNumber, otp) => {
  const data = JSON.stringify({ phone_number: phoneNumber, otp: otp });
  try {
    const response = await axios.post(`${baseURL}/common/verify_otp/`, data, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


export const Signup = async (phone_number, name, email, dob) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/manage_user_account/`,
      { phone_number, name, email, user_type:"user", firebase_token:'firebase_token'},
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { message: "Something went wrong" };
    }
    throw error;
  }
};

export const SaveUserAddress = async (session_token, street_address, address_line2, city,state, country,postal_code,lat,long) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/manage_user_address_detail/`,
      { session_token, street_address, address_line2, city,state, country,postal_code,lat,long},
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { message: "Something went wrong" };
    }
    throw error;
  }
};


// console.log("phone_number", phone_number)
    // console.log("name", name)
    // console.log("email", email)
    // console.log("user_type", user_type)
    // console.log("firebase_token", firebase_token)

    // console.log("TYPE phone_number", typeof(phone_number))
    // console.log("TYPE name", typeof(name))
    // console.log("TYPE email", typeof(email))
    // console.log("TYPE user_type", typeof(user_type))
    // console.log("TYPE firebase_token", typeof(firebase_token))
    
    // console.log("config", config)

