import React from "react";
import { useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";

const jobData = [
  {
    id: 1,
    title: "Full Stack Engineer",
    description:
      "We are looking for a Senior Full-stack engineer to join our development team.",
    options: [
      { icon: <CiLocationOn />, label: "Onsite/Remote" },
      { icon: <IoMdTime />, label: "Full Time" },
      { label: "Development" },
    ],
    path: "/job-one",
  },
  {
    id: 2,
    title: "Apply Now Admin & Operations Officer",
    description:
      "We are looking for an Operations Officer to join our development team.",
    options: [
      { icon: <CiLocationOn />, label: "Onsite/Remote" },
      { icon: <IoMdTime />, label: "Full Time" },
      { label: "Operations Officer" },
    ],
    path: "/job-one",
  },
  {
    id: 3,
    title: "Customer Sales Specialist",
    description:
      "We are looking for a Customer Sales Specialist to join our development team.",
    options: [
      { icon: <CiLocationOn />, label: "Onsite/Remote" },
      { icon: <IoMdTime />, label: "Full Time" },
      { label: "Sales Specialist" },
    ],
    path: "/job-one",
  },
];

const Card = ({ searchQuery }) => {
  const navigate = useNavigate();
  const filteredJobs = jobData.filter(
    (job) =>
      job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="text-[#4B465C]">
      {filteredJobs.length > 0 ? (
        filteredJobs.map((job) => (
          <div
            key={job.id}
            className="md:flex justify-between items-center p-6 rounded-lg border-[1px] border-[#4B465C] shadow-md mx-auto my-10"
          >
            <div className="flex-grow">
              <h2 className="md:text-lg text-md mb-2 font-bold">{job.title}</h2>
              <p className="mb-4 font-semibold text-sm 2xl:max-w-[700px]">
                {job.description}
              </p>
              <div className="md:flex items-center gap-2 mb-2">
                {job.options.map((option, index) => (
                  <button
                    key={index}
                    className="border-[1px] border-[#4B465C] gap-2 rounded-full flex lg:px-4 md:px-2 w-full md:max-w-[200px] text-center justify-center my-2 md:my-0 py-2"
                  >
                    {option.icon && (
                      <span className="text-lg mt-[2px]">{option.icon}</span>
                    )}
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <button
                className="bg-[#00936C] text-white px-6 py-2 rounded hover:bg-[#007B54] transition-colors"
                onClick={() => navigate(job.path)}
              >
                Apply Now
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center mt-10">No job found</p>
      )}
    </div>
  );
};

export default Card;
