import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          The Geographical Location of Souq Al Alawi
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Souq Al Alawi
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Souq Al Alawi is nestled in the heart of the historic Al-Balad
                district in Jeddah, Saudi Arabia. This vibrant marketplace is
                situated along Al Alawi Street, a bustling thoroughfare that has
                been a commercial hub for centuries. Al-Balad itself is a UNESCO
                World Heritage site, renowned for its traditional architecture,
                narrow alleyways, and rich cultural heritage.
                <br />
                Introduction to Souq Al Alawi
                <br />
                Souq Al Alawi is one of Jeddah's oldest and most iconic souqs,
                offering visitors a glimpse into the city's trading history and
                traditional way of life. The souq is a labyrinth of narrow
                alleys lined with shops, stalls, and boutiques selling a diverse
                array of goods, ranging from spices and perfumes to textiles,
                jewelry, and handicrafts. It serves as a gathering place for
                locals and tourists alike, reflecting Jeddah's cosmopolitan
                character and historical significance.
                <br />
                Historical Significance
                <br />
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/al-alawi/image1.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            The history of Souq Al Alawi dates back centuries, tracing its
            origins to the early days of Jeddah as a trading port on the Red
            Sea. Merchants from across the Arabian Peninsula, Africa, and beyond
            converged here to exchange goods and foster cultural exchange. Over
            time, the souq became a focal point of economic activity and social
            interaction in Jeddah, contributing to the city's prosperity and
            cultural diversity.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Architectural and Cultural Highlights
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              The architecture of Souq Al Alawi is characterized by its
              traditional Hijazi design, featuring coral stone buildings with
              intricately carved wooden balconies known as rawasheen. These
              architectural elements not only add to the souq's aesthetic charm
              but also serve practical purposes, such as providing shade and
              ventilation in Jeddah's warm climate. The souq's layout, with its
              maze-like alleys and hidden courtyards, invites exploration and
              discovery, allowing visitors to immerse themselves in its unique
              atmosphere. Shopping and Cultural Experience Visiting Souq Al
              Alawi offers a sensory experience unlike any other. The air is
              filled with the aromas of spices and incense, while vendors
              enthusiastically display their wares, inviting passersby to browse
              and bargain. Popular items include oud (traditional Arabic
              perfume), dates, coffee beans, traditional clothing such as thobes
              and abayas, as well as handmade crafts and souvenirs. The souq is
              also a culinary delight, with vendors offering local delicacies
              and street food, making it a must-visit destination for food
              enthusiasts.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/al-alawi/image3.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural Preservation and Tourism
          </h2>
          <div className="mt-4">
            <p>
              In recent years, efforts have been made to preserve and promote
              the cultural heritage of Souq Al Alawi. Restoration projects have
              helped maintain the authenticity of its architecture, while
              cultural events and festivals celebrate its history and
              traditions. Tourists flock to the souq to experience its vibrant
              atmosphere and to witness firsthand the intersection of tradition
              and modernity in Jeddah. Conclusion Souq Al Alawi stands as a
              testament to Jeddah's enduring legacy as a center of trade,
              culture, and hospitality. Its ancient alleyways and bustling
              market stalls offer a window into the city's past while showcasing
              its vibrant present. Whether you're interested in shopping for
              souvenirs, sampling local cuisine, or simply soaking in the
              ambiance, a visit to Souq Al Alawi promises an unforgettable
              journey through Jeddah's rich cultural tapestry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
