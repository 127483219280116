import React, { useState, useRef, useEffect } from "react";

const SortDropdown = ({ onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState("priceLowToHigh");
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSortSelection = (sortOption, label) => {
    setSelectedSort(label);
    onSortChange(sortOption);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left z-20" ref={dropdownRef}>
      <button
        type="button"
        className="inline-flex justify-center w-full rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        onClick={handleDropdownToggle}
      >
        Sort by: {selectedSort}
      </button>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
          <div className="py-1">
            <button
              onClick={() => handleSortSelection("priceLowToHigh", "Price (Low to High)")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Price (Low to High)
            </button>
            <button
              onClick={() => handleSortSelection("priceHighToLow", "Price (High to Low)")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Price (High to Low)
            </button>
            <button
              onClick={() => handleSortSelection("startDateAsc", "Start Date (Earliest First)")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Start Date (Earliest First)
            </button>
            <button
              onClick={() => handleSortSelection("startDateDesc", "Start Date (Latest First)")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Start Date (Latest First)
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
