import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiSearchLine } from "react-icons/ri";
import bg from "../../../assets/herobg.svg";
import logo from "../../../assets/logo.svg";
import Dropdown from "../../../components/Navbar/Dropdown";
import Currency from "../../../components/Navbar/Currency";
import { CurrencyContext } from "../../../utility/CurrencyContext";

const BlogHeader = () => {
  const { pathname } = useLocation();
  const { selectedCurrency } = useContext(CurrencyContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(true);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const travelPlaces = [
    { name: "Makkah", path: "/articles/makkah" },
    { name: "Madinah", path: "/articles/madinah" },
    { name: "Jeddah", path: "/articles/jeddah" },
    { name: "Taif", path: "/articles/taif" },
    { name: "Alula", path: "/articles/alula" },
  ];

  return (
    <div
      className="pb-4"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <header>
        <div className="w-[85%] mx-auto text-gray-800">
          <div className="flex flex-col items-start py-2">
            <div className="flex justify-between w-full">
              <Link to="/">
                <div className="flex items-center space-x-6 cursor-pointer">
                  <img src={logo} alt="Logo" className="lg:h-14 h-14" />
                </div>
              </Link>
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleClick}
                  className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden hover:bg-[#dddddd] transition duration-150"
                >
                  {selectedCurrency}
                </button>
                {!isOpen && <Dropdown isOpenDropdown={handleClick} />}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="border-t border-[#efefef] my-4"></div>
      <div className="lg:flex flex-row lg:justify-start mt-4 mx-auto w-[85%]">
        <div className="block lg:hidden space-y-2">
          <div className="text-gray-800 text-xl font-bold">
            <p>Travel Places</p>
          </div>
          <Slider {...settings} className="block lg:hidden">
            {travelPlaces.map((place, index) => (
              <div key={index}>
                <Link
                  to={place.path}
                  className={`${
                    pathname.startsWith(place.path)
                      ? "font-semibold text-sm text-gray-800 underline cursor-pointer whitespace-nowrap"
                      : "hover:text-[#00936C] cursor-pointer"
                  }`}
                >
                  {place.name}
                </Link>
              </div>
            ))}
          </Slider>
        </div>
        <div className="hidden lg:flex justify-between w-full">
          <div className="text-[#484848] font-cairo text-[17px] font-bold">
            <p>Holy Sites Inspiration</p>
          </div>
          <nav className="hidden lg:flex space-x-6 text-[#827F7F]">
            {travelPlaces.map((place, index) => (
              <Link
                key={index}
                to={place.path}
                className={`${
                  pathname.startsWith(place.path)
                    ? "font-semibold text-sm text-gray-800 underline cursor-pointer whitespace-nowrap"
                    : "hover:text-[#00936C] cursor-pointer"
                }`}
              >
                {place.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[85%] p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Select Your Currency</h2>
            <Currency setIsOpen={setIsOpen} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogHeader;
