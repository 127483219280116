import React from "react";
import Page from "./Page";
import HeroSection from "../../../../../../components/HeroSection/HeroSection";
import { taif } from "../../../../../../data";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";

const Home = () => {
  return (
    <div>
      <HeaderFooter>
        <HeroSection
          herobg={taif.hero.image}
          heading={taif.hero.heading}
          paragraph={taif.hero.description}
        />
        <Page />
      </HeaderFooter>
    </div>
  );
};

export default Home;
