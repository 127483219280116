// utility.js
import makkahHotels from "../makkah_hotels.json"; // Adjust the path as needed
import madinahHotels from "../madina_hotels.json"; // Adjust the path as needed

export const getHotelImages = (city, hotelName) => {
  let hotelData;
  if (city.toLowerCase() === "mecca") {
    hotelData = makkahHotels.find(hotel => hotel.hotel_Name === hotelName);
  } else if (city.toLowerCase() === "madinah") {
    hotelData = madinahHotels.find(hotel => hotel.hotel_Name === hotelName);
  }
  return hotelData ? [hotelData.image1, hotelData.image2, hotelData.image3, hotelData.image4] : [];
};
