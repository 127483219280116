import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL +
      "/images/Taif/holyplace/abdullahMasjid/image2.jpeg",
    process.env.PUBLIC_URL +
      "/images/Taif/holyplace/abdullahMasjid/image3.jpeg",
    process.env.PUBLIC_URL +
      "/images/Taif/holyplace/abdullahMasjid/image4.jpeg",
    process.env.PUBLIC_URL +
      "/images/Taif/holyplace/abdullahMasjid/image5.jpeg",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner
          image={"/images/Taif/holyplace/abdullahMasjid/image1.jpeg"}
          title={"Masjid-abdullah-bin-abbas"}
        />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
