import React from "react";
import Card from "./Card";

const cardData = [
  {
    title: "Getting started with Huz",
    imageUrl: "/images/HelpPage/makkahimg.jpg",
    link: "/get-started",
    imagestyles: "rounded-lg",
  },
  {
    title: "Access and manage your account",
    imageUrl: "/images/HelpPage/manage.jpg",
    link: "/manage-account",
    imagestyles: "rounded-lg",
  },
  {
    title: "Help with a reservation",
    imageUrl: "/images/HelpPage/kabba1.jpeg",
    link: "/reservation",
    imagestyles: "rounded-lg",
  },
  {
    title: "AirCover for Pilgrims",
    imageUrl: "/images/HelpPage/aircover2.jpg",
    imagestyles: "rounded-lg",
  },
];

const GuestPage = () => {
  return (
    <div>
      <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
        {cardData.map((card) => (
          <Card
            title={card.title}
            imageUrl={card.imageUrl}
            link={card.link}
            imagestyles={card.imagestyles}
          />
        ))}
      </div>
    </div>
  );
};

export default GuestPage;
