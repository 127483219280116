import React, { useEffect } from "react";
import Banner from "../../../../../../components/HeroSection/Banner";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";
import Slider from "../../MadinahPlaces/Wadijin2/Slider";
import Page from "./Page";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const images = [
    process.env.PUBLIC_URL + "/images/qibla6.jpg",
    process.env.PUBLIC_URL + "/images/qibla1.png",
    process.env.PUBLIC_URL + "/images/qibla2.svg",
    process.env.PUBLIC_URL + "/images/qibla3.svg",
    process.env.PUBLIC_URL + "/images/qibla4.jpg",
  ];

  return (
    <div className="">
      <HeaderFooter>
        <Banner image={"/images/qibla.svg"} title={"Masjid Al Qiblatain"} />
        <Page />
        <Slider images={images} />
      </HeaderFooter>
    </div>
  );
};

export default Home;
