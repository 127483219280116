import React, { useContext, useState } from "react";
import Footer from "../../../components/footer";
import months from "../Login/months.json";
import days from "../Login/days.json";
import years from "../Login/years.json";
import { useNavigate, useLocation } from "react-router-dom";
import bgimg from "../../../assets/bgImage.png";
import { Signup } from "../../../utility/AuthApis";
import { AuthContext } from "../../../App";
import { CiCircleQuestion } from "react-icons/ci";
import { BiErrorAlt } from "react-icons/bi";
import HeaderForUserSetup from "../HeaderForUserSetup";
import Loader from "../../../components/loader";

const SigninPage = () => {
  const { state, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const {
    userFromBooking,
    huz_token,
    formData: bookingFormData,
  } = location.state || {}; // Renamed formData to bookingFormData
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [dobError, setDobError] = useState(""); // Separate error state for DOB
  const [isLoading, setIsLoading] = useState(false);

  const phone_number = JSON.parse(localStorage.getItem("User-Phone-Number"));
  const name = `${firstName} ${lastName}`;
  const dob = `${year} ${month} ${day}`;

  const handleDaysChange = (event) => {
    const selectedValue = event.target.value;
    setDay(selectedValue);
    setDobError(""); // Clear DOB error when user selects a value
  };
  const handleMonthsChange = (event) => {
    const selectedValue = event.target.value;
    setMonth(selectedValue);
    setDobError(""); // Clear DOB error when user selects a value
  };
  const handleYearChange = (event) => {
    const selectedValue = event.target.value;
    setYear(selectedValue);
    setDobError(""); // Clear DOB error when user selects a value
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
      setError("");
    } else if (name === "lastName") {
      setLastName(value);
      setError("");
    } else if (name === "email") {
      setEmail(value);
      setError3("");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let valid = true;
    if (!firstName || !lastName) {
      setError("Enter your full name");
      valid = false;
    }
    if (!email) {
      setError3("Enter your email");
      valid = false;
    } else if (!validateEmail(email)) {
      setError3("Enter a valid email");
      valid = false;
    }
    if (!day || !month || !year) {
      setDobError("Enter your date of birth");
      valid = false;
    }

    if (valid) {
      try {
        const response = await Signup(phone_number, name, email, dob);

        localStorage.setItem("Login-User-Profile", JSON.stringify(response.data));
        dispatch({ type: "USER", payload: true });
        navigate("/addressform", {
          state: {
            huz_token,
            userFromBooking,
            bookingFormData, // Passing the bookingFormData to addressform
          },
        });
      } catch (e) {
        console.error("Error", e);
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div
        className="flex flex-col font-kd min-h-screen pb-10 text-[#484848] border-[#B2ABAB]"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <HeaderForUserSetup />
        <div className="space-y-5 md:w-[90%] mx-auto py-10">
          <div className="">
            <div className=" justify-end">
              <div className="md:w-[55%] w-full md:mx-auto rounded-lg bg-white shadow-custom-box space-y-5">
                <div className="border-[1px] border-[#DEDDDD] rounded-md">
                  <div className="p-2 text-center">
                    <p className="font-semibold text-lg my-2 text-black opacity-80">
                      Finish signing up
                    </p>
                  </div>
                  <hr />
                  <form className="space-y-2 p-5 px-10" onSubmit={handleSubmit}>
                    <p className="font-semibold text-lg text-black opacity-80">
                      Legal name
                    </p>

                    <div className="border-[1px] border-[#DEDDDD] rounded-lg">
                      <div className="px-3 pt-2 pb-0.5">
                        <p className="text-xs text-[#867676]">
                          First name on ID
                        </p>
                        <input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={handleInputChange}
                          placeholder="eg. John"
                          className="border-none rounded-sm text-[#483F3F] placeholder:text-xs text-sm pl-0 p-1 w-full focus:ring-0 focus:outline-none"
                        />
                      </div>
                      <hr />
                      <div className="px-3 pt-2 pb-0.5">
                        <p className="text-xs text-[#867676]">
                          Last name on ID
                        </p>
                        <input
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={handleInputChange}
                          placeholder="eg. Cena"
                          className="border-none rounded-sm text-[#483F3F] placeholder:text-xs text-sm pl-0 p-1 w-full focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      {error && (
                        <div className="text-red-500 text-left text-xs flex items-center gap-1 mt-1">
                          <BiErrorAlt />{" "}
                          <div className="text-red-500 text-xs"> {error}</div>
                        </div>
                      )}
                    </div>

                    <p className="text-xs text-[#483F3F] font-normal tracking-wide pt-1.5">
                      Make sure this match the name on your government ID. If
                      you go by another name, you can add a{" "}
                      <span className=" font-semibold border-b-[2px] border-[#483F3F]">
                        preferred first name.{" "}
                      </span>
                    </p>

                    <div className="pt-5">
                      <p className="text-md font-semibold text-black">
                        Date of birth
                      </p>
                      <div className="flex gap-1 mt-2">
                        <p className="">Birthday</p>
                        <CiCircleQuestion className="text-[#C7BCBC] size-[20px] mt-0.5" />
                      </div>
                      <div className="flex justify-between gap-2 mt-3">
                        <select
                          className="border-[#DEDDDD] rounded-md text-sm p-2 py-2.5 w-full focus:outline-none focus:ring-0"
                          onChange={handleMonthsChange}
                          value={month}
                        >
                          <option value="">Month</option>
                          {months.map((items) => {
                            return (
                              <option
                                key={items.name}
                                value={items.name}
                                className="focus:outline-none focus:ring-0"
                              >
                                {items.name}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          className="border-[#DEDDDD] rounded-md text-sm p-2 py-2.5 w-full focus:outline-none focus:ring-0"
                          onChange={handleDaysChange}
                          value={day}
                        >
                          <option value="">Day</option>
                          {days.map((items) => {
                            return (
                              <option
                                key={items.day}
                                value={items.day}
                                className="focus:outline-none focus:ring-0"
                              >
                                {items.day}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          className="border-[#DEDDDD] rounded-md text-sm p-2 py-2.5 w-full focus:outline-none focus:ring-0"
                          onChange={handleYearChange}
                          value={year}
                        >
                          <option value="">Year</option>
                          {years.map((items) => {
                            return (
                              <option
                                key={items.year}
                                value={items.year}
                                className="focus:outline-none focus:ring-0"
                              >
                                {items.year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {dobError && (
                        <div className="text-red-500 text-left text-xs flex items-center gap-1 mt-2">
                          <BiErrorAlt />{" "}
                          <div className="text-red-500 text-xs">{dobError}</div>
                        </div>
                      )}
                      <div className="pt-2">
                        <p className="text-md font-semibold text-black py-2">
                          Contact info
                        </p>
                        <div className="pt-0.5 pl-2 border-[1px] border-[#DEDDDD] rounded-md ">
                          <p className="text-xs text-[#867676]">Email</p>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            placeholder="abc123@gmail.com"
                            className="border-none text-[#483F3F] font-normal text-sm rounded-sm p-1 pl-0 w-full focus:outline-none focus:ring-0"
                          />
                        </div>
                        {error3 && (
                          <div className="text-red-500 text-left text-xs flex items-center gap-1 mt-2">
                            <BiErrorAlt />{" "}
                            <div className="text-red-500 text-xs">{error3}</div>
                          </div>
                        )}
                        <p className="text-xs pt-3">
                          We will email you trip confirmations and receipts.
                        </p>
                      </div>
                    </div>
                    <p className="text-xs space-y-1.5 pt-3">
                      By selecting{" "}
                      <span className="font-medium">Agree and continue</span> ,
                      I agree to Huz’s{" "}
                      <span className="font-medium text-[#00936C]">
                        Terms of Services. Payments Terms of Services
                      </span>{" "}
                      , and Nondiscrimination Policy and acknowledge the{" "}
                      <span className="font-medium text-[#00936C]">
                        Privacy Policy
                      </span>{" "}
                      .
                    </p>
                    <div className="mx-auto py-5">
                      <button
                        className={`w-full text-sm py-3 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
                          isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isLoading}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {isLoading ? <Loader /> : <p>Agree and Continue</p>}
                      </button>
                    </div>
                    <p className="text-xs text-[#483F3F] font-normal text-[11px]">
                      HUZ will send you members-only deals, inspiration,
                      marketing emails, and push notification. You can opt out
                      of receiving these at any time in your account settings or
                      directly from the marketing notification.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SigninPage;
