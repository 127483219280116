import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/pagination";
import "../UserPanel/homepage/swiper.css";

import { NumericFormat } from "react-number-format";
import { CurrencyContext } from '../../utility/CurrencyContext'; // Assuming you have a context for currency

import makkahJson from '../../makkah_hotels.json'; // Import your JSON data
import madinaJson from '../../madina_hotels.json'; // Import your JSON data

const styles = `
  .mySwiper .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .card-container {
    width: 100%;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    .card-container {
      max-width: 250px;
    }
  }

  @media (min-width: 1024px) {
    .card-container {
      max-width: 250px;
    }
  }

  @media (min-width: 1440px) {
    .card-container {
      max-width: 250px;
    }
  }

  .rating-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #00936c;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
  }
`;

const fetchHotelImages = (hotelName, hotelCity) => {
  const hotels = hotelCity === "Mecca" ? makkahJson : madinaJson;
  const hotel = hotels.find((hotel) => hotel.hotel_Name === hotelName);
  return hotel ? [hotel.image1, hotel.image2, hotel.image3, hotel.image4] : [];
};

const Card = React.forwardRef(({
  package_name,
  package_cost,
  start_date,
  end_date,
  hotel_info_detail,
  company_detail,
  huz_token,
  rating_count
}, ref) => {
  const navigate = useNavigate();
  const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

  const images = hotel_info_detail.flatMap((hotel) => fetchHotelImages(hotel.hotel_name, hotel.hotel_city));
  const averageStars = rating_count?.average_stars ?? 0.0;

  // Convert package cost to selected currency
  const convertedCost = exchangeRates[selectedCurrency]
    ? (package_cost / exchangeRates['PKR']) * exchangeRates[selectedCurrency]
    : package_cost;

  return (
    <div className="w-auto" ref={ref}>
      <div
        className="lg:px-0 lg:p-0 relative shadow-custom-box rounded-2xl cursor-pointer w-full mb-14 card-content"
        onClick={() => navigate(`/detailpage/?packageId=${huz_token}`, { state: { huz_token } })}
      >
        <Swiper pagination={{ clickable: true, loop: true }} modules={[Pagination]} className="">
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <img src={image} alt={package_name} className="w-full h-[225px] object-cover rounded-t-lg" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="p-1 md:p-3 bg-white w-full rounded-2xl text-content">
          <h3 className="text-md font-bold">{package_name}</h3>
          {company_detail.company_name && (
            <h3 className="text-xs text-[#00936c]">
              <span className="text-xs">By </span>
              {company_detail.company_name}
            </h3>
          )}
          <p className="text-xs font-[500] mt-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
            {new Date(start_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}{" "}
            to{" "}
            {new Date(end_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}
          </p>
        </div>
        <div className="flex justify-between items-center pb-4 px-4">
          <div>
            <span className="bg-[#00936c] text-sm px-2 py-[2px] text-white rounded-md rounded-bl-none">{averageStars}
            </span>
            <span className="text-xs text-gray-400 font-thin"> Rating</span>
          </div>
          <NumericFormat
            className="font-bold text-base"
            value={convertedCost}
            displayType={"text"}
            thousandSeparator
            prefix={`${selectedCurrency} `}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </div>
    </div>
  );
});

export default Card;
