import React, { useState } from "react";
import { IoCardOutline } from "react-icons/io5";
import Subscription from "../../../components/subscription";
import Footer from "../../../components/footer";
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    cardName: "",
    cardNumber: "",
    expirationDate: "",
    cvc: "",
    consentMarketing: false,
    consentTransport: false,
  });
  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.cardName.trim()) {
      newErrors.cardName = "Cardholder's name is required.";
    }
    if (!formData.cardNumber.trim()) {
      newErrors.cardNumber = "Card number is required.";
    }
    if (!formData.expirationDate.trim()) {
      newErrors.expirationDate = "Expiration date is required.";
    }
    if (!formData.cvc.trim()) {
      newErrors.cvc = "CVC is required.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      console.log("Form data:", formData);
      // Proceed with form processing here
    }
  };

  const handleOptionChange = (method) => {
    setFormData((prev) => ({ ...prev, paymentMethod: method }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  return (
    <div className="text-[#483F3F]">
      <BookingNavbar />
      <div className="md:p-8 w-[90%] my-10 p-2 md:w-2xl bg-white 2xl:max-w-2xl">
        <div className="space-y-5 2xl:w-[77%] md:w-[90%] mx-auto">
          <div className="md:flex gap-5 justify-between">
            <div className="2xl:w-[70%] w-[90%] mx-auto justify-end">
              <div className="md:max-w-[730px] mx-auto bg-white space-y-5">
                <div className="flex gap-3 items-center">
                  <NavLink to={"/reservepage/accountdetail"}>
                    <img src="/images/arrowIcon.svg" alt="" className="h-4" />
                  </NavLink>
                  <h1 className="text-sm md:text-xl font-bold ">Pay With</h1>
                </div>
                <form className="space-y-2" onSubmit={handleSubmit}>
                  <div className="relative">
                    <IoCardOutline className="absolute left-3 top-4 text-gray-400" />
                    <select
                      className={`pl-10 w-full border rounded-lg p-2 mt-1 ${
                        errors.cardNumber ? "border-red-500" : "border-gray-300"
                      }`}
                      value={formData.cardNumber}
                      onChange={handleInputChange}
                    >
                      <option>Credit card or debit card</option>{" "}
                    </select>
                  </div>
                  <div>
                    <div className="relative">
                      <IoCardOutline className="absolute left-3 top-5 text-gray-400" />
                      <input
                        type="text"
                        name="cardNumber"
                        placeholder="Enter card number"
                        className={`pl-10 w-full border rounded-t-md p-2 mt-1 ${
                          errors.cardNumber
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        value={formData.cardNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.cardNumber && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.cardNumber}
                      </p>
                    )}
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        name="expirationDate"
                        placeholder="MM/YY"
                        className={`w-full border rounded-lg p-2 mt-1 ${
                          errors.expirationDate
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        value={formData.expirationDate}
                        onChange={handleInputChange}
                      />
                      {errors.expirationDate && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.expirationDate}
                        </p>
                      )}

                      <input
                        type="text"
                        name="cvc"
                        placeholder="CVC"
                        className={`w-full border rounded-lg p-2 mt-1 ${
                          errors.cvc ? "border-red-500" : "border-gray-300"
                        }`}
                        value={formData.cvc}
                        onChange={handleInputChange}
                      />
                      {errors.cvc && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.cvc}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* {errors.expirationDate && (
          <p className="text-red-500 text-xs mt-1">{errors.expirationDate}</p>
        )}
        {errors.cvc && (
          <p className="text-red-500 text-xs mt-1">{errors.cvc}</p>
        )} */}

                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      name="consentMarketing"
                      checked={formData.consentMarketing}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <label
                      htmlFor="consentMarketing"
                      className="md:text-[14px] text-[9px]"
                    >
                      I consent to receiving marketing emails from Booking.com
                      including promotions and rewards.
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      name="consentTransport"
                      checked={formData.consentTransport}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <label
                      htmlFor="consentTransport"
                      className="md:text-[14px] text-[9px]"
                    >
                      I consent to receiving information about transport
                      services.
                    </label>
                  </div>

                  <div className="text-xs text-gray-500 mb-4">
                    <p className="mb-4">
                      You can unsubscribe at any time. View our{" "}
                      <a
                        href="/"
                        className="text-[#484848] font-bold text-cairo"
                      >
                        privacy policy
                      </a>
                      .
                    </p>
                    <p>
                      Your booking is directly with Aparthotel Stare Miasto and
                      by completing this booking you agree to the{" "}
                      <a href="/" className="text-[#00936C]">
                        booking conditions
                      </a>
                      ,{" "}
                      <a href="/" className="text-[#00936C]">
                        general terms
                      </a>
                      ,{" "}
                      <a href="/" className="text-[#00936C]">
                        privacy policy
                      </a>
                      , and{" "}
                      <a href="/" className="text-[#00936C]">
                        Wallet terms
                      </a>
                      .
                    </p>
                  </div>

                  <div className="flex justify-end gap-4 mt-4">
                    <button
                      type="button"
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 md:px-4 px-2 md:text-lg text-[8px] rounded"
                    >
                      Check your booking
                    </button>
                    <button
                      type="submit"
                      className="bg-[#00936C] hover:bg-[#00936C] text-white font-bold py-2 md:px-4 px-2 md:text-lg text-[8px] rounded"
                    >
                      Complete booking
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <SideBar />
          </div>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default PaymentForm;
