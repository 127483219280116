import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BookingNavbar from "../../../../../components/Navbar/BookingNavBar";
import Footer from "../../../../../components/footer";
import Loader from "../../../../../components/loader";
import { getAllComplaintsByUser } from "../../../../../utility/Api";
import { motion } from "framer-motion";

const ComplaintsPage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const booking = location.state?.booking;
  const { session_token } = JSON.parse(
    localStorage.getItem("Login-User-Profile")
  );  
  console.log(session_token);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        // const sessionToken = booking.user_session_token; // Use session token from booking object
        const data = await getAllComplaintsByUser(session_token);
        // Prepend base URL to audio_message paths
        const updatedData = data.map((complaint) => ({
          ...complaint,
          audio_message: complaint.audio_message
            ? `${API_BASE_URL}${complaint.audio_message}`
            : null,
        }));
        setComplaints(updatedData);
      } catch (error) {
        console.error("Error fetching complaints:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchComplaints();
  }, [booking, API_BASE_URL]);

  if (loading) {
    return (
      <div>
        <BookingNavbar />
        <div className="flex items-center justify-center min-h-screen">
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <BookingNavbar />
      <div className="w-[85%] mx-auto py-10">
        <motion.h1
          className="text-[28px] text-[#484848] font-bold mb-8 text-start"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          My Complaints
        </motion.h1>
        {complaints.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-[70vh] text-center text-[#4b465c] md:text-[24px] xl:text-[32px] font-[600] font-kd">
            <img
              src="/images/error.svg"
              alt="No Data"
              className="w-20 lg:w-28 xl:w-32"
            />
            <p>No complaint register!</p>
          </div>
        ) : (
          <motion.div
            className="flex flex-col space-y-6"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                opacity: 0,
                y: 20,
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.3,
                  staggerChildren: 0.2,
                },
              },
            }}
          >
            {complaints.map((complaint) => (
              <motion.div
                key={complaint.complaint_id}
                className="bg-gradient-to-r from-gray-100 to-[#00936c3b] rounded-lg shadow-lg p-6 relative hover:shadow-2xl transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <div className="absolute top-2 right-2 bg-[#00936c] text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md">
                  {complaint.complaint_status}
                </div>
                <div className="flex items-center mb-4">
                  <div className="flex-shrink-0 h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-xl font-semibold text-gray-500">
                      {complaint.complaint_title[0]}
                    </span>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-500">
                      Booking #: {complaint.booking_number}
                    </p>
                    <h2 className="text-xl font-semibold">
                      {complaint.complaint_title}
                    </h2>
                  </div>
                </div>
                <p className="text-gray-700 mb-4">
                  {complaint.complaint_message}
                </p>
                {complaint.audio_message && (
                  <audio
                    controls
                    src={complaint.audio_message}
                    className="w-full mb-4 rounded"
                  >
                    Your browser does not support the audio element.
                  </audio>
                )}
                <div className="text-gray-500 text-sm">
                  <p>Ticket: {complaint.complaint_ticket}</p>
                  <p>
                    Time: {new Date(complaint.complaint_time).toLocaleString()}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ComplaintsPage;
