import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
import googlePlayLogo from "../assets/assets/img/svg/store-google.svg";
import appStoreLogo from "../assets/assets/img/svg/store-apple.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#f2f2f2] py-10">
      <div className="w-[85%] mx-auto font-kd">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 font-thin text-xs">
          <div className="space-y-4">
            <h3 className="font-semibold">COMPANY INFORMATION</h3>
            <ul className="space-y-2 text-[14px] font-[400]">
              <li>
                <Link to="/about-us">About us</Link>
              </li>
              <li>
                <Link to="/mission-vision">Mission & Vision</Link>
              </li>
              <li>
                <Link to="/core-values">Our Core Values</Link>
              </li>
              <li>
                <Link to="/how-huz-works">How HUZ Works</Link>
              </li>
              <li>
                <Link to="/huz-partner">HUZ Partner</Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">LEARN MORE</h3>
            <ul className="space-y-2 text-[14px] font-[400]">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/hajj">Hajj Package</Link>
              </li>
              <li>
                <Link to="/umrah">Umrah Package</Link>
              </li>
              <li>
                <Link to="/transport">Transport</Link>
              </li>
              <li>
                <Link to="/articles/makkah">Articles</Link>
              </li>
              <li>
                <Link to="/hajj-guide">Hajj Guide</Link>
              </li>
              <li>
                <Link to="/umrah-guide">Umrah Guide</Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">RESOURCES</h3>
            <ul className="space-y-2 text-[14px] font-[400]">
              <li>
                <Link to="/career">Career</Link>{" "}
              </li>
              <li>
                <Link to="/frequently-asked-questions">Common Question</Link>{" "}
              </li>
              <li>
                <Link to="/error">Legal</Link>
              </li>
              <li>
                <Link to="/terms-services">Terms & Conditions</Link>{" "}
              </li>
              <li>
                <Link to="/error">Refund Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>{" "}
              </li>
              <li>
                <Link to="/error">Cancelation Policy</Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">OFFERS</h3>
            <ul className="space-y-2 text-[14px] font-[400]">
              <li>
                <Link to="/error">Concierge</Link>
              </li>
              <li>
                <Link to="/error">Medical</Link>
              </li>
              <li>
                <Link to="/error">Insurance</Link>
              </li>
              <li>
                <Link to="/error"></Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">HELP</h3>
            <ul className="space-y-2 text-[14px] font-[400]">
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/help-center">Help Center</Link>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-300" />
        <div className="lg:flex space-y-7 lg:space-y-0 justify-between items-center">
          <div className="flex space-x-2">
            <img src={appStoreLogo} alt="App Store" className="h-10" />
            <img src={googlePlayLogo} alt="Google Play" className="h-10" />
          </div>
          <div className="flex items-center xl:space-x-7 lg:space-x-5 space-x-7">
            <a href="/error" className="text-gray-600">
              <FaFacebookF className="size-[20px]" />
            </a>
            <a href="/error" className="text-gray-600">
              <FaTwitter className="size-[20px]" />
            </a>
            <a href="/error" className="text-gray-600">
              <FaInstagram className="size-[20px]" />
            </a>
            <a href="/error" className="text-gray-600">
              <FaLinkedinIn className="size-[20px]" />
            </a>
            <a href="/error" className="text-gray-600">
              <FaYoutube className="size-[20px]" />
            </a>
            <a href="/error" className="text-gray-600">
              <FaPinterestP className="size-[20px]" />
            </a>
          </div>
          <div className="text-gray-600 text-sm block">
            <div className="lg:w-[300px] xl:w-[360px] w-full">
              <p className="text-gray-500 text-[12px]">
                &copy;2023 HUZ™. All Rights Reserved. HUZ™ Apple and the Apple
                logo are trademarks of Apple Inc., registered in the U.S. and
                other countries. Mac App Store is a service mark of Apple Inc.
                Google Play and the Google Play logo are trademarks of Google
                Inc.{" "}
                <a href="/terms-services" className="text-blue-600">
                  Privacy & Terms
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
