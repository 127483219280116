import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { NamirahImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import icon from "../../../../../../assets/makkahPageIcon.svg";

const NamirahMosque = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${NamirahImages.image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Masjid al-Namirah</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Discovering the Spiritual Legacy of Masjid al-Namirah: A Sacred
          Landmark in Islamic History
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            The Historical Essence of Masjid al-Namirah
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Masjid al-Namirah, located in the sacred plains of Arafat, near
                Mecca, holds profound historical and spiritual significance for
                Muslims worldwide. This mosque is an integral part of the Hajj
                pilgrimage, symbolizing unity and the profound connection
                between the believers and their Creator.
              </p>
              <div className="py-2.5">
                <h1 className="text-[25px] font-bold">
                  Meaning and Historical Context
                </h1>
                <p className=" font-medium text-[15px] pt-4">
                  The term "Namirah" derives from a name of a hill nearby,
                  highlighting the mosque's geographical and historical context.
                  Masjid al-Namirah is uniquely positioned at the site where the
                  Prophet Muhammad (peace be upon him) delivered the Farewell
                  Sermon during his final Hajj in 632 CE. This sermon
                  encapsulated the core principles of justice, equality, and
                  piety, laying down a comprehensive guide for the Muslim Ummah.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              {" "}
              <img src={NamirahImages.image3} alt="" />
            </div>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Meaning and Historical Context
          </h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                The term "Namirah" derives from a name of a hill nearby,
                highlighting the mosque's geographical and historical context.
                Masjid al-Namirah is uniquely positioned at the site where the
                Prophet Muhammad (peace be upon him) delivered the Farewell
                Sermon during his final Hajj in 632 CE. This sermon encapsulated
                the core principles of justice, equality, and piety, laying down
                a comprehensive guide for the Muslim Ummah.
              </p>
              <p>
                As pilgrims gather Masjid Al-Nimra year after year, they not
                only honor a sacred tradition but also renew their spiritual
                bonds, reflecting the timeless message of unity, justice, and
                piety that the Prophet Muhammad (peace be upon him) imparted
                centuries ago.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Reference in the Quran
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  {" "}
                  While Masjid al-Namirah itself is not explicitly mentioned in
                  the Quran, the significance of Arafat is underscored in Surah
                  Al-Baqarah (2:198): "But when you depart from 'Arafat,
                  remember Allah at al-Mash'ar al-Haram." This verse emphasizes
                  the importance of the rituals performed at Arafat, directly
                  tying it to the spiritual essence of Masjid al-Namirah.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Combining of Salahs at Masjid al-Namirah
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  During Hajj, one of the unique practices observed at Masjid
                  al-Namirah is the combining of Dhuhr and Asr prayers. Pilgrims
                  gather to perform these prayers together, following the Sunnah
                  of the Prophet Muhammad (peace be upon him). This act
                  symbolizes unity and collective devotion, reinforcing the
                  spirit of togetherness that Hajj embodies.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Historical and Spiritual Significance
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The historical significance of Masjid al-Namirah is deeply
                  intertwined with its spiritual role. As the site of the
                  Prophet's Farewell Sermon, it stands as a monument to Islamic
                  teachings and the principles of humanity, justice, and
                  devotion. Pilgrims who visit this mosque during Hajj
                  experience a profound connection to these timeless values,
                  reflecting on their own faith and the broader Islamic
                  heritage.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  The Rituals Performed
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  At Masjid al-Namirah, pilgrims engage in various rituals that
                  are pivotal to the Hajj experience. After listening to the
                  Khutbah (sermon) on the Day of Arafat, they perform the
                  combined prayers of Dhuhr and Asr. This day is considered the
                  pinnacle of Hajj, where pilgrims earnestly supplicate, seeking
                  forgiveness and spiritual renewal. The collective standing at
                  Arafat symbolizes the Day of Judgment, fostering a sense of
                  humility and devotion among the believers.
                </p>
              </div>
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  Spiritual Reflection and Devotion
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  The atmosphere at Masjid al-Namirah during Hajj is imbued with
                  deep spiritual reflection and fervent devotion. Pilgrims use
                  this time to contemplate their lives, seek forgiveness, and
                  renew their commitment to Allah. The shared experience of
                  standing together at Arafat and performing the prayers fosters
                  a profound sense of community and spiritual unity.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  Unity and Continuity
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Masjid al-Namirah is more than just a physical structure; it
                  is a symbol of the enduring unity and continuity of the Muslim
                  Ummah. The rituals performed here during Hajj serve as a
                  reminder of the collective journey of faith and the shared
                  values that bind Muslims across the globe. The legacy of the
                  Prophet Muhammad’s (peace be upon him) Farewell Sermon
                  continues to resonate, guiding Muslims towards a path of
                  righteousness and unity.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-1.5 py-5 md:flex md:space-y-0 gap-5 text-center pt-10">
          <img
            src={NamirahImages.image2}
            alt="muzdalifah collecting pebbles"
            className="md:w-1/2 rounded-lg md:h-[300px]"
          />
          <img
            src={NamirahImages.image4}
            alt="muzdalifah collecting pebbles"
            className="md:w-1/2 rounded-lg md:h-[300px]"
          />
        </div>

        <h3 className=" font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Significant Practices at Masjid al-Namirah:
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              Masjid al-Namirah, located in the sacred plains of Arafat near
              Mecca, Saudi Arabia, stands as a testament to Islamic history and
              spirituality. This mosque plays a pivotal role during the Hajj
              pilgrimage, symbolizing unity and deep devotion among Muslims.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Significant Practices at Masjid al-Namirah:
              </h4>
              <ul className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Combining of Salahs:
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Listening to the Khutbah (Sermon)
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Spiritual Reflection and Supplication:
                  </li>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />

                  <li className=" leading-relaxed py-2 px-2">
                    Unity and Continuity
                  </li>
                </div>
              </ul>
            </div>
            <p className="font-medium text-[15px]">
              {" "}
              Masjid al-Namirah stands as a beacon of faith and devotion,
              reflecting the rich historical and spiritual tapestry of Islam.
              Its significance during Hajj and its deep historical roots make it
              a crucial part of the pilgrimage, where pilgrims honor sacred
              traditions and renew their spiritual bonds.
            </p>
          </div>
          <img
            src={NamirahImages.image4}
            alt="Muzdalifah"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={NamirahImages.image1}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={NamirahImages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={NamirahImages.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={NamirahImages.image4}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NamirahMosque;
