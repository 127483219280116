import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Tayebat City International Museum: Exploring Jeddah's Cultural
          Heritage
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Tayebat City International Museum
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Tayebat City International Museum, often referred to simply as
                Tayebat Museum, is located in the Al-Balad district of Jeddah,
                Saudi Arabia. This district, recognized as a UNESCO World
                Heritage site, is renowned for its historic architecture and
                cultural significance. The museum is situated on King Fahd Road,
                making it easily accessible to visitors exploring the heart of
                Jeddah.
                <br />
                Introduction to Tayebat City International Museum
                <br />
                Tayebat Museum is a cultural institution dedicated to preserving
                and showcasing the heritage of Saudi Arabia and the wider
                Islamic world. It stands as one of Jeddah's premier museums,
                offering a comprehensive look into the region's history, art,
                architecture, and lifestyle through its diverse collections and
                exhibits. The museum is housed in a traditional Arabian-style
                building, reflecting the architectural charm of Al-Balad.
                <br />
                Historical and Cultural Significance
                <br />
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/jeddah/taybatmuseum/image3.webp"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            The museum's collection spans various periods and aspects of Islamic
            civilization, highlighting artifacts, manuscripts, textiles, and
            artworks that illustrate the cultural diversity and intellectual
            achievements of the Islamic world. The exhibits also delve into the
            history of Saudi Arabia, including the development of Jeddah as a
            commercial and cultural hub over the centuries. Tayebat Museum
            serves as a bridge between the past and present, fostering an
            appreciation for Saudi Arabia's cultural heritage among visitors
            from around the globe.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Architectural Features and Exhibits
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Tayebat Museum is renowned for its architectural beauty, featuring
              courtyards, intricate wooden carvings, and traditional furnishings
              that evoke the ambiance of historic Arabian homes. The museum's
              interior is divided into thematic galleries that explore different
              aspects of Islamic art, science, literature, and daily life.
              Visitors can admire ancient manuscripts, ceramics, jewelry, and
              textiles that offer insights into the artistic and technological
              achievements of Islamic civilizations. Educational Programs and
              Community Engagement In addition to its exhibitions, Tayebat
              Museum hosts educational programs, workshops, and cultural events
              that engage visitors of all ages and backgrounds. These
              initiatives aim to deepen understanding of Islamic culture and
              heritage while promoting dialogue and cultural exchange. The
              museum also plays a vital role in preserving traditional crafts
              and promoting local artisans through its support of cultural
              initiatives.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/jeddah/taybatmuseum/image5.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Visitor Experience and Tourism
          </h2>
          <div className="mt-4">
            <p>
              A visit to Tayebat Museum promises a captivating journey through
              the cultural mosaic of Saudi Arabia and the Islamic world.
              Visitors can explore the museum at their own pace or join guided
              tours that provide in-depth explanations of the exhibits. The
              museum's location in Al-Balad allows visitors to explore nearby
              historical sites, mosques, and traditional souqs, offering a
              comprehensive cultural experience in the heart of Jeddah.
              Conclusion Tayebat City International Museum stands as a beacon of
              cultural preservation and education in Jeddah, showcasing the
              richness and diversity of Saudi Arabia's heritage. Whether you're
              a history enthusiast, an art lover, or simply curious about
              Islamic culture, a visit to Tayebat Museum offers a rewarding and
              enlightening experience. By exploring its exhibitions and engaging
              with its educational programs, visitors can gain a deeper
              appreciation for the cultural legacy that continues to shape Saudi
              Arabia and the world today.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
