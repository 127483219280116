import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { HijazRailwayStationImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import Footer from "../../../../../../components/footer";

const points = [
  "Ottoman Architecture:",
  "Preserved Structures:",
  "Cultural Exhibits",
  "Scenic Location",
  "Pilgrimage Route:",
];

const sliderimages = [
  HijazRailwayStationImages.image1,
  HijazRailwayStationImages.image2,
  HijazRailwayStationImages.image3,
  HijazRailwayStationImages.image4,
  HijazRailwayStationImages.image5,
];

const HijazRailwayStation = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${HijazRailwayStationImages.image1})` }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">
            Hijaz Railway Station in AlUla: A Historic Landmark
          </h1>
        </div>
      </div>

      <div className="bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center">
        <h1 className="w-[90%] mx-auto">
          Step back in time at the Hijaz Railway Station in AlUla, where the
          echoes of the past and the marvels of engineering converge in a
          captivating journey through history!
        </h1>
      </div>

      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                The Hijaz Railway, including the AlUla station, played a crucial
                role during the early 20th century, facilitating pilgrimage
                journeys to the holy cities of Mecca and Medina. The railway was
                built during the Ottoman Empire and was intended to improve the
                travel experience for Hajj pilgrims, significantly reducing the
                arduous journey across the desert.
              </p>
              <div className="py-2.5 lg:hidden xl:block">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className="font-medium text-[15px] pt-4">
                  The Hijaz Railway Station in AlUla is a testament to early
                  20th-century engineering and architectural design. The station
                  features robust stone constructions, arched doorways, and
                  intricate details that blend Ottoman architectural styles with
                  local influences. The station buildings, including the main
                  terminal, water towers, and auxiliary structures, are
                  well-preserved, offering a glimpse into the engineering
                  marvels of the past.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={HijazRailwayStationImages.image4}
                alt="Abu Bakar Masjid"
                className="w-full rounded-md"
              />
            </div>
          </div>
          <div className="py-2.5 hidden lg:block xl:hidden">
            <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
            <p className="font-medium text-[15px] pt-4">
              The Hijaz Railway Station in AlUla is a testament to early
              20th-century engineering and architectural design. The station
              features robust stone constructions, arched doorways, and
              intricate details that blend Ottoman architectural styles with
              local influences. The station buildings, including the main
              terminal, water towers, and auxiliary structures, are
              well-preserved, offering a glimpse into the engineering marvels of
              the past.
            </p>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Significance of Hijaz Railway Station
          </h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                The Hijaz Railway Station in AlUla is more than just a
                historical site; it is a journey through time that highlights
                the engineering prowess and cultural significance of an era that
                shaped the modern Arabian Peninsula.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10 gap-4">
              {[
                {
                  title: "Community Hub:",
                  description:
                    "A vital link that connected AlUla to major cities, facilitating trade and travel",
                },
                {
                  title: "Religious Services:",
                  description:
                    " Served as a key stop for pilgrims traveling to Mecca and Medina",
                },
                {
                  title: "Architectural Marvel:",
                  description:
                    "Showcases early 20th-century engineering and Ottoman architectural styles.",
                },
                {
                  title: "Spiritual Sanctuary:",
                  description:
                    "Played a significant role in the religious journeys of countless pilgrims",
                },
              ].map((item, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg">
                  <span className="font-bold block text-[25px]">
                    {item.title}
                  </span>
                  <p className="font-normal text-[15px] pt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-10">
          <h1 className="font-bold block text-[25px]">
            Activities at Hijaz Railway Station
          </h1>
          <div className="space-y-1.5">
            <p className="font-normal text-[15px] pt-2">
              Guided Tours: Discover the historical significance and
              architectural beauty of the station with expert guides. <br />
              Historical Exhibits: Explore exhibits that detail the
              construction, operation, and impact of the Hijaz Railway.
              Photography: Capture the preserved structures and scenic desert
              backdrop. <br />
              Cultural Events: Participate in events and festivals that
              celebrate the history of the railway and its role in the region.{" "}
              <br />
              Train Rides: Experience a ride on a restored section of the
              railway to get a feel of the historical journeys. <br />
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={HijazRailwayStationImages.image3}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={HijazRailwayStationImages.image4}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>

        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10">
          Dadan
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className="font-medium text-[15px]">
              Ongoing Excavations Continuous archaeological efforts uncovering
              new facets of Dadan's history. Educational Opportunities: A site
              of learning and exploration for history enthusiasts and scholars.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Key Features of Hijaz Railway Station
              </h4>
              <ul className="font-medium text-[15px]">
                {points.map((point, index) => (
                  <li key={index} className="flex gap-2 py-2 px-2">
                    <img src={icon} alt="icon" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              <p className="font-medium text-[15px]">
                Natural Landscape: Nestled amidst the striking desert scenery
                and rock formations of AlUla.
              </p>
            </div>
          </div>
          <img
            src={HijazRailwayStationImages.image6}
            alt="Abu Bakar Masjid"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {sliderimages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg h-[300px] w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HijazRailwayStation;
