import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { JabalIkmahImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import Footer from "../../../../../../components/footer";

const points = [
  "Extensive Inscriptions",
  "Petroglyphs:",
  "Ancient Inscriptions",
  "Open-Air Library",
  "Historical Significance",
];

const sliderimages = [
  JabalIkmahImages.image1,
  JabalIkmahImages.image2,
  JabalIkmahImages.image3,
  JabalIkmahImages.image4,
  JabalIkmahImages.image5,
];

const JabalIkmah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${JabalIkmahImages.image1})` }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">
            Jabal Ikmah: AlUla's Ancient Library
          </h1>
        </div>
      </div>

      <div className="bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center">
        <h1 className="w-[90%] mx-auto">
          Embark on a journey to Jabal Ikmah, AlUla's open-air library, where
          ancient inscriptions and petroglyphs reveal the secrets of lost
          civilizations!
        </h1>
      </div>

      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                Jabal Ikmah, while not directly referenced in Islamic texts,
                forms part of the larger AlUla region, which has deep historical
                roots and connections to ancient civilizations mentioned in
                Islamic tradition. Its proximity to Hegra (Madain Salih) ties it
                to the story of the Thamud people, providing a broader context
                of the region's significance in early Islamic history.
              </p>
              <div className="py-2.5 lg:hidden xl:block">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className="font-medium text-[15px] pt-4">
                  Jabal Ikmah is renowned for its stunning array of ancient
                  inscriptions and rock art, which are etched into the cliffs
                  and boulders of this majestic mountain. These inscriptions,
                  written in various ancient scripts, provide a fascinating
                  glimpse into the lives, beliefs, and languages of the region's
                  early inhabitants. The intricate carvings and petroglyphs
                  stand as a testament to the artistic and cultural achievements
                  of these ancient societies, making Jabal Ikmah a natural
                  gallery of historical art.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={JabalIkmahImages.image4}
                alt="Abu Bakar Masjid"
                className="w-full rounded-md"
              />
            </div>
          </div>
          <div className="py-2.5 hidden lg:block xl:hidden">
            <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
            <p className="font-medium text-[15px] pt-4">
              Jabal Ikmah is renowned for its stunning array of ancient
              inscriptions and rock art, which are etched into the cliffs and
              boulders of this majestic mountain. These inscriptions, written in
              various ancient scripts, provide a fascinating glimpse into the
              lives, beliefs, and languages of the region's early inhabitants.
              The intricate carvings and petroglyphs stand as a testament to the
              artistic and cultural achievements of these ancient societies,
              making Jabal Ikmah a natural gallery of historical art.
            </p>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Significance of Jabal Ikmah{" "}
          </h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                Jabal Ikmah is often referred to as an open-air library, due to
                its rich collection of petroglyphs and inscriptions that date
                back thousands of years. It is one of the most significant
                archaeological sites in the AlUla region, offering invaluable
                insights into the region's history and the interactions between
                different ancient civilizations. The sheer volume and diversity
                of the inscriptions make Jabal Ikmah a critical resource for
                historians and archaeologists studying the linguistic and
                cultural development of the Arabian Peninsula.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10 gap-4">
              {[
                {
                  title: "Community Hub:",
                  description:
                    "A central gathering place for ancient civilizations to share knowledge and culture.",
                },
                {
                  title: "Religious Services:",
                  description:
                    "Inscriptions and carvings that reflect the spiritual and religious practices of ancient societies.",
                },
                {
                  title: "Architectural Marvel:",
                  description:
                    "Intricate rock inscriptions and petroglyphs showcasing advanced artistry and communication.",
                },
                {
                  title: "Spiritual Sanctuary:",
                  description:
                    "A sacred site filled with ancient texts and symbols of spiritual significance",
                },
              ].map((item, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg">
                  <span className="font-bold block text-[25px]">
                    {item.title}
                  </span>
                  <p className="font-normal text-[15px] pt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-10">
          <h1 className="font-bold block text-[25px]">
            Activities at Jabal Ikmah
          </h1>
          <div className="space-y-1.5">
            <p className="font-normal text-[15px] pt-2">
              1. **Guided Tours**: Explore the rock inscriptions with expert
              guides who decode the ancient texts and share their historical
              significance.
              <br />
              2. **Hiking**: Traverse the rugged terrain to discover hidden
              petroglyphs and enjoy panoramic views of the surrounding
              landscape.
              <br />
              3. **Photography**: Capture the stunning rock art and breathtaking
              desert scenery, perfect for creating lasting memories. <br /> 4.
              **Archaeological Exploration**: Learn about ongoing research and
              recent discoveries from archaeological teams working at the site.
              <br />
              5. **Cultural Events**: Participate in local events and workshops
              that celebrate the heritage of AlUla and its historical sites,
              offering an immersive cultural experience. <br />
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={JabalIkmahImages.image3}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={JabalIkmahImages.image4}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>

        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10">
          Hegra Mountain
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className="font-medium text-[15px]">
              Abu Bakar Masjid is a symbol of spiritual devotion and
              architectural excellence in Jeddah. It continues to inspire and
              serve the Muslim community through its religious, educational, and
              social initiatives.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Key Features of Jabal Ikmah
              </h4>
              <ul className="font-medium text-[15px]">
                {points.map((point, index) => (
                  <li key={index} className="flex gap-2 py-2 px-2">
                    <img src={icon} alt="icon" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              <p className="font-medium text-[15px]">
                Cultural Heritage: A key site for understanding the history and
                culture of AlUla. Tourist Attraction: Popular destination for
                history enthusiasts and adventure seekers.
              </p>
            </div>
          </div>
          <img
            src={JabalIkmahImages.image6}
            alt="Abu Bakar Masjid"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {sliderimages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg h-[300px] w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JabalIkmah;
