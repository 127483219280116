import { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../../../../../../components/footer";
import { MinaImagesdata } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Mina = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      {/* <Navbar /> */}
      <BlogHeader />

      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${MinaImagesdata.image2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Mina</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <p className="w-[90%] mx-auto">
          A stage of Hajj journey. It is characterized by white tents. It's the
          largest tent city in the world.
        </p>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div className="">
          <h1 className="text-[25px] font-bold pb-1">
            Historical Significance
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            <div className="lg:w-[55%] space-y-4  mb-4 font-medium text-[15px]">
              <p>
                Mina is steeped in Islamic tradition and is mentioned in several
                significant religious texts. It is notably recognized as the
                place where the Prophet Ibrahim (Abraham) prepared to sacrifice
                his son Ismail (Ishmael) as an act of obedience to God, a story
                that is commemorated during Eid al-Adha. This act of faith is
                central to Islamic teachings and is reflected in the rituals
                performed in Mina during Hajj.
              </p>
              <p>
                The word Mina can also mean ‘to flow’ because here the blood of
                sacrificial animals flows during the festival day of Eidul Adha.
                During the Farewell Pilgrimage (Hajjat-ul-Wada) the Muslims had
                brought with them 100 camels to be sacrificed.
              </p>
              <p>
                Historical Events One significant historical event associated
                with Mina is the Farewell Pilgrimage (Hajjat-ul-Wada) of Prophet
                Muhammad (peace be upon him). During this pilgrimage, Prophet
                Muhammad delivered his farewell sermon, emphasizing principles
                of equality, justice, and compassion among Muslims.
              </p>
            </div>
            <div className="lg:w-[45%] text-center">
              <img
                src={MinaImagesdata.image2}
                alt="Jabal al-Nour and the Cave of Hira"
                className="h-[250px] lg:h-[250px] xl:h-[250px] w-full"
              />
              <p>
                <em>
                  Aerial view of Mina area ahead of the annual Haj pilgrimage,
                  in the holy city of Mecca, Saudi Arabia July 18, 2021.
                  (Reuters)
                </em>
              </p>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Meaning of ‘Mina’</h1>
          <div className="pt-5 font-medium text-[15px]">
            <p>
              Mina is derived from the word with the root letters ma-na-ya which
              means ‘to be put to the test’ or ‘to undergo‘ or ‘to find’. It is
              connected to word ‘manna‘ and ‘tamanna‘ which means ‘to awaken a
              desire’ or ‘to hope for’.{" "}
            </p>
            <p>
              Another reason it is called Mina is because it is connected to the
              test that Prophet Ebrahim (عليه السلام) had to undergo when he was
              ordered to slaughter his son. When his resolve was proven, he
              sacrificed a ram in place of his son. The name implies the ‘place
              where he was tested’ and the ‘place where he succeeded’.
            </p>
            <p>
              {" "}
              The extensive infrastructure and meticulous planning by the Saudi
              authorities ensure that millions of pilgrims can perform their
              religious duties in safety and comfort. As the "City of Tents,"
              Mina stands as a testament to the enduring significance of Hajj
              and the commitment to preserving Islamic traditions while adapting
              to modern needs.
            </p>
          </div>
        </div>
        <h3 className="text-[25px] font-bold pt-10">
          Hajj Rituals Performed in Mina
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              Ramy al-Jamarat (Stoning of the Devil): Pilgrims throw pebbles at
              three pillars representing Satan's temptation of Ibrahim. This act
              symbolizes rejecting evil and reaffirming faith.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">Three Jamarat:</h4>
              <div className=" font-medium text-[15px]">
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Jamarat al-Sughra (Small Pillar)
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className=" leading-relaxed py-2 px-2">
                    Jamarat al-Wusta (Middle Pillar)
                  </p>
                </div>
                <div className="flex gap-2">
                  <img src={icon} alt="h-4 mt-1.5" />
                  <p className="leading-relaxed py-2 px-2">
                    Jamarat al-Aqaba (Large Pillar)
                  </p>
                </div>
              </div>
              <p className="font-medium text-[15px]">
                Pebbles: Pilgrims collect pebbles in Muzdalifah and throw seven
                pebbles at each pillar over three days.
              </p>
            </div>
          </div>
          <img
            src={MinaImagesdata.image6}
            alt="Cave of Hira"
            className="w-full mt-5 lg:mt-0 lg:w-[45%] lg:h-[350px] rounded-lg"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={MinaImagesdata.image1}
                alt=""
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MinaImagesdata.image2}
                alt=""
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MinaImagesdata.image3}
                alt=""
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MinaImagesdata.image4}
                alt=""
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MinaImagesdata.image5}
                alt=""
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mina;
