import React, { useState, useEffect, useRef } from "react";

const makkahZiyarats = [
  "Home of HAZRAT MUHAMMAD صلى الله عليه وسلم",
  "Taif",
  "The Holy Kaaba",
  "Hajar al-Aswad",
  "Jabal al-Nour (Mount of Light)",
  "Jannat al-Mualla",
  "Masjid al-Jinn",
  "Mina",
  "Muzdalifah",
  "Ghar-E-Soor",
  "Masjid al-Haram",
  "Maqam Ibrahim",
  "Well of Zamzam",
  "Cave of Hira",
  "Masjid Aisha",
  "Masjid al-Khayf",
  "Arafat",
];

const madinahZiyarats = [
  "Masjid Ali Nabwi",
  "Quba Mosque",
  "Masjid Al-Qiblatain",
  "Mosque Al Ghamamah",
  "Jannat Al-Baqi",
  "Uhud Mountain",
  "The Baab-As-Salaam",
  "Battlefield Of The Trench (Khandaq)",
];

export { makkahZiyarats, madinahZiyarats };

const PackageSidebar = ({styles}) => {
  // search filter for ziyarats
  const [searchTermStart, setSearchTermStart] = useState("");
  const [searchTermEnd, setSearchTermEnd] = useState("");
  const [location, setLocation] = useState({ start: "", end: "" });
  const [filteredStartZiyarats, setFilteredStartZiyarats] = useState([]);
  const [filteredEndZiyarats, setFilteredEndZiyarats] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);

  const handleSearchStart = (e) => {
    const value = e.target.value;
    setSearchTermStart(value);
    setActiveSuggestion(-1);

    if (value) {
      const results = makkahZiyarats.filter((ziyarat) =>
        ziyarat.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStartZiyarats(results.slice(0, 5)); // Show only first 5 results
    } else {
      setFilteredStartZiyarats([]);
    }
  };

  const handleSearchEnd = (e) => {
    const value = e.target.value;
    setSearchTermEnd(value);
    setActiveSuggestion(-1);

    if (value) {
      const results = madinahZiyarats.filter((ziyarat) =>
        ziyarat.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEndZiyarats(results.slice(0, 5)); // Show only first 5 results
    } else {
      setFilteredEndZiyarats([]);
    }
  };

  const handleSelectStart = (ziyarat) => {
    setLocation({ ...location, start: ziyarat });
    setSearchTermStart(ziyarat);
    setFilteredStartZiyarats([]);
  };

  const handleSelectEnd = (ziyarat) => {
    setLocation({ ...location, end: ziyarat });
    setSearchTermEnd(ziyarat);
    setFilteredEndZiyarats([]);
  };

  const handleKeyDownStart = (e) => {
    if (e.key === "ArrowDown") {
      setActiveSuggestion((prev) =>
        prev < filteredStartZiyarats.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && activeSuggestion >= 0) {
      handleSelectStart(filteredStartZiyarats[activeSuggestion]);
    }
  };

  const handleKeyDownEnd = (e) => {
    if (e.key === "ArrowDown") {
      setActiveSuggestion((prev) =>
        prev < filteredEndZiyarats.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && activeSuggestion >= 0) {
      handleSelectEnd(filteredEndZiyarats[activeSuggestion]);
    }
  };

  useEffect(() => {
    if (activeSuggestion >= 0) {
      const activeElement = startInputRef.current || endInputRef.current;
      activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [activeSuggestion]);
  // search filter for ziyarats ends

  const [filters, setFilters] = useState({
    location: false,
    carSpecs: {
      airConditioning: false,
      fourDoors: false,
    },
    electricCars: {
      fullyElectric: false,
      hybrid: false,
      plugInHybrid: false,
    },
    transmission: false,
    carCategory: {
      small: false,
      medium: false,
      large: false,
      premium: false,
      suvs: false,
    },
    deposit: {
      zeroToHundredK: false,
      hundredToTwoHundredK: false,
      twoHundredKPlus: false,
    },
    supplier: {
      enterprise: false,
      keyCarRental: false,
    },
  });

  const handleClearAll = () => {
    setFilters({
      location: false,
      carSpecs: {
        airConditioning: false,
        fourDoors: false,
      },
      electricCars: {
        fullyElectric: false,
        hybrid: false,
        plugInHybrid: false,
      },
      transmission: false,
      carCategory: {
        small: false,
        medium: false,
        large: false,
        premium: false,
        suvs: false,
      },
      deposit: {
        zeroToHundredK: false,
        hundredToTwoHundredK: false,
        twoHundredKPlus: false,
      },
      supplier: {
        enterprise: false,
        keyCarRental: false,
      },
    });
    setLocation({
      start: "",
      end: "",
    });
  };

  const handleChange = (section, field) => {
    if (typeof field === "string") {
      setFilters((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: !prev[section][field],
        },
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
    }
  };

  return (
    <div className="">
      <div className={`p-4 w-full border rounded-lg shadow-lg ${styles}`}>
        <div className="flex justify-between items-center mb-3">
          <span className="font-bold text-[20px]">Filter</span>
          <button
            className="text-blue-500 text-[14px] font-medium"
            onClick={handleClearAll}
          >
            Clear all filters
          </button>
        </div>
        <hr className="w-full mb-3" />
        <div className="mb-4">
          <div className="font-medium mb-2 text-[16px] text-black">
            Location
          </div>
          <div className="relative mt-2">
            <label className="block text-[14px] font-medium text-gray-700">
              Start Location (Makkah Ziyarats)
            </label>
            <input
              type="text"
              value={searchTermStart}
              onChange={handleSearchStart}
              onKeyDown={handleKeyDownStart}
              placeholder="Enter start location"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              ref={startInputRef}
            />
            {filteredStartZiyarats.length > 0 && (
              <ul className="absolute z-10 mt-1 w-full border border-gray-300 rounded-md shadow-sm bg-white">
                {filteredStartZiyarats.map((ziyarat, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectStart(ziyarat)}
                    className={`px-3 py-2 cursor-pointer hover:bg-gray-200 ${
                      activeSuggestion === index ? "bg-gray-200" : ""
                    }`}
                  >
                    {ziyarat}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="relative mt-2">
            <label className="block text-[14px] font-medium text-gray-700">
              End Location (Madinah Ziyarats)
            </label>
            <input
              type="text"
              value={searchTermEnd}
              onChange={handleSearchEnd}
              onKeyDown={handleKeyDownEnd}
              placeholder="Enter end location"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              ref={endInputRef}
            />
            {filteredEndZiyarats.length > 0 && (
              <ul className="absolute z-10 mt-1 w-full border border-gray-300 rounded-md shadow-sm bg-white">
                {filteredEndZiyarats.map((ziyarat, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectEnd(ziyarat)}
                    className={`px-3 py-2 cursor-pointer hover:bg-gray-200 ${
                      activeSuggestion === index ? "bg-gray-200" : ""
                    }`}
                  >
                    {ziyarat}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <hr className="w-full mb-3" />
        <div className="mb-4">
          <div className="font-medium mb-2">Car specs</div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="air-conditioning"
                checked={filters.carSpecs.airConditioning}
                onChange={() => handleChange("carSpecs", "airConditioning")}
                className="rounded"
              />
              <label htmlFor="air-conditioning" className="ml-2">
                Air Conditioning
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="4-doors"
                checked={filters.carSpecs.fourDoors}
                onChange={() => handleChange("carSpecs", "fourDoors")}
                className="rounded"
              />
              <label htmlFor="4-doors" className="ml-2">
                4+ doors
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
        </div>
        <hr className="w-full mb-3" />
        <div className="mb-4">
          <div className="font-medium mb-2">Electric cars</div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="fully-electric"
                checked={filters.electricCars.fullyElectric}
                onChange={() => handleChange("electricCars", "fullyElectric")}
                className="rounded"
              />
              <label htmlFor="fully-electric" className="ml-2">
                Fully electric
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="hybrid"
                checked={filters.electricCars.hybrid}
                onChange={() => handleChange("electricCars", "hybrid")}
                className="rounded"
              />
              <label htmlFor="hybrid" className="ml-2">
                Hybrid
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="plug-in-hybrid"
                checked={filters.electricCars.plugInHybrid}
                onChange={() => handleChange("electricCars", "plugInHybrid")}
                className="rounded"
              />
              <label htmlFor="plug-in-hybrid" className="ml-2">
                Plug-in hybrid
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
        </div>
        <hr className="w-full mb-3" />
        <div className="mb-4">
          <div className="font-medium mb-2">Transmission</div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="automatic"
                checked={filters.transmission}
                onChange={() => handleChange("transmission")}
                className="rounded"
              />
              <label htmlFor="automatic" className="ml-2">
                Automatic
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
        </div>
        <hr className="w-full mb-3" />
        <div className="mb-4">
          <div className="font-medium mb-2">Car category</div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="small"
                checked={filters.carCategory.small}
                onChange={() => handleChange("carCategory", "small")}
                className="rounded"
              />
              <label htmlFor="small" className="ml-2">
                Small
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="medium"
                checked={filters.carCategory.medium}
                onChange={() => handleChange("carCategory", "medium")}
                className="rounded"
              />
              <label htmlFor="medium" className="ml-2">
                Medium
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="large"
                checked={filters.carCategory.large}
                onChange={() => handleChange("carCategory", "large")}
                className="rounded"
              />
              <label htmlFor="large" className="ml-2">
                Large
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="premium"
                checked={filters.carCategory.premium}
                onChange={() => handleChange("carCategory", "premium")}
                className="rounded"
              />
              <label htmlFor="premium" className="ml-2">
                Premium
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
          <div className="flex justify-between">
            <div>
              <input
                type="checkbox"
                id="suvs"
                checked={filters.carCategory.suvs}
                onChange={() => handleChange("carCategory", "suvs")}
                className="rounded"
              />
              <label htmlFor="suvs" className="ml-2">
                SUVs
              </label>
            </div>
            <span className="text-gray-500"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSidebar;