import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const Card = ({ title, description, imageUrl, link, styles,imagestyles }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link);
    //   navigate("/link");
  };

  return (
    <div
      className={`lg:p-0 relative rounded-lg transform cursor-pointer`}
      onClick={handleClick}
    >
      <div className="relative z-50">
        <img
          className={`w-full object-cover  ${imagestyles} h-[300px]`}
          src={imageUrl}
          alt={title}
        />
      </div>
      <div className={`text-left py-2 md:relative top-40 md:top-0 w-full ${styles}`}>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-sm font-semibold pb-2.5">{description}</p>
        {/* <hr /> */}
        {/* <div className="justify-between flex items-center p-2">
          <h2 className="text-sm font-semibold text-[#484848]">Read More</h2>
          <FaArrowRight className="text-[10px]" />
        </div> */}
      </div>
    </div>
  );
};

export default Card;
