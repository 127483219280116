import React from "react";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
// import explore from "../../../assets/explore.png";

const CommunityPoliciesPage = () => {
  return (
    <div className="">
      <BookingNavbar />
      <div className="w-[90%] mx-auto mt-10 text-justify">
        <h1 className="text-[20px] font-kd mb-4 font-bold">
          Our Community Policies
        </h1>
        {/* Respect and Consideration */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Respect and Consideration
          </h2>
          <p>
            Respect is the cornerstone of our community. We expect all members
            to treat each other with kindness, empathy, and consideration. This
            includes respecting each other's privacy, personal space, and
            cultural differences. Discriminatory behavior, harassment, or any
            form of hate speech will not be tolerated.
          </p>
        </section>

        {/* Communication and Transparency */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Communication and Transparency
          </h2>
          <p>
            Clear and transparent communication is vital for building trust
            within our community. We encourage open dialogue between hosts,
            guests, and our support team. If you encounter any issues or have
            questions, don't hesitate to reach out. Likewise, we expect all
            members to communicate honestly and provide accurate information
            about their listings, services, and experiences.
          </p>
        </section>

        {/* Safety and Security */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Safety and Security
          </h2>
          <p>
            The safety and security of our community are paramount. We have
            implemented measures to ensure the safety of our users, including
            verification processes, secure payment methods, and stringent
            quality standards for listings and experiences. However, it's
            essential for all members to exercise caution and be vigilant during
            their interactions. Report any suspicious or unsafe behavior to our
            support team immediately.
          </p>
        </section>

        {/* Compliance with Laws and Regulations */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Compliance with Laws and Regulations
          </h2>
          <p>
            We expect all members to comply with local laws, regulations, and
            our platform's policies. This includes adhering to zoning laws, tax
            requirements, and health and safety standards. It's essential to
            familiarize yourself with the rules and regulations governing your
            activities on our platform and to act accordingly.
          </p>
        </section>

        {/* Accountability and Responsibility */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Accountability and Responsibility
          </h2>
          <p>
            Every member of our community plays a role in maintaining its
            integrity and reputation. We encourage accountability and
            responsibility for one's actions. If you make a mistake or encounter
            a problem, own up to it and take steps to rectify the situation. By
            holding ourselves accountable, we contribute to a positive and
            trustworthy community environment.
          </p>
        </section>

        {/* Feedback and Improvement */}
        <section className="mb-8">
          <h2 className="text-[20px] font-kd mb-4 font-bold">
            Feedback and Improvement
          </h2>
          <p>
            Feedback is a valuable tool for continuous improvement. We welcome
            constructive feedback from our community members to help us enhance
            our platform and services. Whether it's a suggestion for
            improvement, a compliment, or a complaint, your feedback matters to
            us. Together, we can work towards making our community a better
            place for everyone.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CommunityPoliciesPage;
