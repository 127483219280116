import React from "react";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
// import umrah from "../../../assets/manage.jpg";

const ManageAccount = () => {
  return (
    <div className="bg-gray-100">
      <BookingNavbar/>
      <div className="w-[90%] mx-auto md:p-10 p-3.5 mt-5 md:mt-0">
        <div className="">
          <div className="mx-auto">
            <img
              src={"/images/HelpPage/manage.jpg"}
              alt=""
              className="md:w-1/2 w-full flex rounded-md"
            />
            <section className="mb-8 mt-5">
              <h1 className="text-[20px] font-kd font-bold text-[#484848] opacity-80 mb-6">
                Account Dashboard
              </h1>
              <p className="text-gray-600 mb-4">
                Your dashboard is the central hub for managing your account.
                Navigate to the dashboard by logging in and clicking on the
                dashboard link.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-[20px] font-kd mb-4 font-semibold">
                Update Profile
              </h2>
              <p className="text-gray-600 mb-4">
                Keep your profile information up to date. You can change your
                business details, contact information, and more from the profile
                section.
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Go to the "Profile" section.</li>
                <li>
                  Update your business name, logo, and contact information.
                </li>
                <li>Save changes to ensure your profile is always current.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-[20px] font-kd font-bold mb-4">
                Change Password
              </h2>
              <p className="text-gray-600 mb-4">
                For security reasons, periodically update your password. Go to
                the settings section to change your password.
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Navigate to the "Settings" section.</li>
                <li>
                  Enter your current password, followed by the new password.
                </li>
                <li>Confirm the new password and save changes.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-[20px] font-kd mb-4 font-bold">
                Payment Settings
              </h2>
              <p className="text-gray-600 mb-4">
                Manage your payment settings and view transaction history in the
                payment section of your dashboard.
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Go to the "Payments" section.</li>
                <li>Update your bank details or preferred payment method.</li>
                <li>Review transaction history and manage payouts.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-[20px] font-kd mb-4 font-bold">
                Notification Preferences
              </h2>
              <p className="text-gray-600 mb-4">
                Set your notification preferences to stay updated with important
                alerts and messages from customers and the platform.
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Navigate to the "Notifications" section.</li>
                <li>
                  Choose your preferred notification methods (email, SMS, etc.).
                </li>
                <li>
                  Customize the types of notifications you wish to receive.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-[20px] font-kd mb-4 font-bold">
                Logout
              </h2>
              <p className="text-gray-600 mb-4">
                Always logout from your account when you are done, especially if
                you are using a shared or public computer.
              </p>
              <ul className="list-disc list-inside text-gray-600">
                <li>
                  Click on the "Logout" button in the top-right corner of the
                  dashboard.
                </li>
                <li>Confirm that you want to logout when prompted.</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
