import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/footer";
import SideBar from "./SideBar";
import {
  createBookingRequestbyTransactionNumber,
  createBookingRequestbyTransactionPhoto,
} from "../../../utility/Api";
import { NumericFormat } from "react-number-format";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import { CurrencyContext } from "../../../utility/CurrencyContext";
import Loader from "../../../components/loader";
const BankingDetail = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

  const {
    bookingResponse,
    totalAdults,
    totalChild,
    totalInfants,
    roomType,
    totalCost,
  } = location.state || {};

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [transferID, setTransferID] = useState("");
  const [errors, setErrors] = useState({});
  const [previewFile, setPreviewFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const session_token =
    JSON.parse(localStorage.getItem("Login-User-Profile"))?.session_token ||
    JSON.parse(localStorage.getItem("New-User-Profile"))?.session_token;
  const user_name = JSON.parse(
    localStorage.getItem("Login-User-Profile")
  )?.name;

  const handleTransferInputChange = (event) => {
    setTransferID(event.target.value);
    validateTransferID(event.target.value);
  };

  const handleFileChange = (e) => {
    const inputSelectedFile = e.target.files[0];
    setSelectedFiles(inputSelectedFile);
    setPreviewFile(URL.createObjectURL(inputSelectedFile));
    validateFile(inputSelectedFile);
  };

  const validateTransferID = (value) => {
    if (!value && !selectedFiles) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        transferID: "Please enter a transfer ID or upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        transferID: null,
      }));
    }
  };

  const validateFile = (file) => {
    if (!file && !transferID) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: "Please enter a transfer ID or upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    validateTransferID(transferID);
    validateFile(selectedFiles);

    if (errors.transferID || errors.file) {
      setIsLoading(false);
      return;
    }

    try {
      if (selectedFiles) {
        const formData = new FormData();
        formData.append("session_token", session_token);
        formData.append("booking_number", bookingResponse.booking_number);
        formData.append("transaction_photo", selectedFiles);
        formData.append("transaction_amount", bookingResponse.total_price);
        await createBookingRequestbyTransactionPhoto(formData);
        navigate("/allbookingsinfo", {
          state: { transaction_image: selectedFiles },
        });
      } else if (transferID) {
        await createBookingRequestbyTransactionNumber(
          session_token,
          bookingResponse.booking_number,
          transferID,
          bookingResponse.total_price
        );
        localStorage.setItem("Transaction-ID", JSON.stringify(transferID));
        navigate("/allbookingsinfo", { state: { transaction_id: transferID } });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          transferID:
            "Please enter a transfer ID or upload a transaction photo.",
        }));
      }
    } catch (error) {
      console.error("Error creating booking request:", error);
    }
    setIsLoading(false);
  };

  const sanitizedBookingResponse = {
    ...bookingResponse,
    package_name: bookingResponse?.package_name || "",
    package_description: bookingResponse?.package_description || "",
    mecca_nights: Number(bookingResponse?.mecca_nights) || 0,
    madinah_nights: Number(bookingResponse?.madinah_nights) || 0,
    package_cost: bookingResponse?.package_cost || 0,
    start_date: bookingResponse?.start_date || "",
    end_date: bookingResponse?.end_date || "",
    is_package_open_for_other_date:
      bookingResponse?.is_package_open_for_other_date || false,
    booking_number: bookingResponse?.booking_number || "",
    adults: totalAdults || 0,
    child: totalChild || 0,
    infants: totalInfants || 0,
    room_type: roomType || "",
    total_cost: totalCost || 0,
  };

  const convertedCost = exchangeRates[selectedCurrency]
    ? (sanitizedBookingResponse.total_cost / exchangeRates["PKR"]) *
      exchangeRates[selectedCurrency]
    : sanitizedBookingResponse.total_cost;

  return (
    <div className="text-[#4b465c] border-[#B2ABAB]">
      <BookingNavbar />
      <div className="space-y-5 font-kd w-[85%] mx-auto py-10">
        <div className="md:flex w-full justify-between">
          <div className="xl:w-full justify-end">
            <div className="md:max-w-[75%] bg-white space-y-5">
              <h1 className="text-sm md:text-xl font-bold opacity-80">
                Thank You {user_name}!
              </h1>
              <div>
                <div className="space-y-2 text-sm opacity-80">
                  <p>Your order has been submitted.</p>
                  <p>
                    Your order number is{" "}
                    {sanitizedBookingResponse.booking_number}.
                  </p>
                  <p>
                    Total due amount is{" "}
                    <NumericFormat
                      className="font-bold text-[16px]"
                      value={convertedCost}
                      displayType={"text"}
                      thousandSeparator
                      prefix={`${selectedCurrency} `}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="pt-10">
                    Here are the bank account details where you can make the
                    payment:
                  </p>
                  <div className="flex gap-4 border-[1px] p-2 rounded-md">
                    <img src="/images/bank.svg" alt="Bank" className="h-12" />
                    <div className="text-xs">
                      <p>
                        <span className="font-bold">Account Title:</span> HUZ
                        Solutions (Pvt) Limited.
                      </p>
                      <p>
                        <span className="font-bold">Account #:</span>{" "}
                        092349328Z234
                      </p>
                      <p>
                        <span className="font-bold">IBFA:</span>{" "}
                        03409349034903439490
                      </p>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label>Enter your transfer ID:</label>
                    <input
                      type="text"
                      value={transferID}
                      onChange={handleTransferInputChange}
                      onBlur={() => validateTransferID(transferID)}
                      className="w-full p-1.5 text-sm rounded-md border-[#B2ABAB]"
                    />
                  </div>
                  <div className="w-full flex items-center">
                    <hr className="w-full" /> Or <hr className="w-full" />
                  </div>
                  <label htmlFor="fileInput">
                    <div className="h-24 w-full border border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center cursor-pointer relative">
                      <img
                        src="/images/uploadlogo.png"
                        alt="Upload"
                        className="h-6 w-6 text-gray-500 mb-2"
                      />
                      <p className="text-gray-600 font-light">
                        Drop files here or click to upload
                      </p>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                  </label>
                  {previewFile && (
                    <img src={previewFile} alt="Preview" className="w-[20%]" />
                  )}
                  {errors.file && (
                    <p className="text-red-500 text-xs">{errors.file}</p>
                  )}
                  <div className="space-y-4 mt-4">
                    <div className="mx-auto my-5">
                      <button
                        className={`w-full text-sm py-2 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
                          isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[40%] md:w-1/2">
            <SideBar
              pkg={{
                package_name: sanitizedBookingResponse.package_name,
                package_description:
                  sanitizedBookingResponse.package_description,
                mecca_nights: sanitizedBookingResponse.mecca_nights,
                madinah_nights: sanitizedBookingResponse.madinah_nights,
                package_cost: sanitizedBookingResponse.package_cost,
                start_date: sanitizedBookingResponse.start_date,
                end_date: sanitizedBookingResponse.end_date,
                is_package_open_for_other_date:
                  sanitizedBookingResponse.is_package_open_for_other_date,
              }}
              checkInDate={sanitizedBookingResponse.start_date}
              checkOutDate={sanitizedBookingResponse.end_date}
              totalAdults={sanitizedBookingResponse.adults}
              totalChildren={sanitizedBookingResponse.child}
              totalInfants={sanitizedBookingResponse.infants}
              roomType={sanitizedBookingResponse.room_type}
              totalCost={sanitizedBookingResponse.total_cost}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BankingDetail;
