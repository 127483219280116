import React from "react";
import mail from "../../../../assets/booking/mail.svg";
import phone from "../../../../assets/booking/phone.svg";
import user from "../../../../assets/booking/user.svg";

const getStatusColor = (status) => {
  switch (status) {
    case "Rejected":
      return "bg-[#FDECEA] text-[#F04438] font-semibold";
    case "Active":
      return "bg-[#c8eddb] text-[#019267] font-semibold";
    case "Pending":
      return "bg-[#fff0e1] text-[#FF9F43] font-semibold";
    case "Completed":
      return "bg-[#f1f1f2] text-[#A8AAAE] font-semibold";
    case "Close":
      return "bg-[#d6eee7] text-[#00936C] font-semibold";
    default:
      return "bg-gray-200 text-gray-800 font-semibold";
  }
};

const Sidebar = ({ booking }) => {
  if (!booking) {
    return <div>Loading...</div>;
  }

  const { booking_status, company_detail = {} } = booking;

  const {
    company_name,
    company_bio,
    company_logo,
    contact_name,
    contact_number,
  } = company_detail;

  const { REACT_APP_API_BASE_URL } = process.env;

  return (
    <div className="w-full text-[#484848] pr-4 pb-4 pl-1 pt-1 bg-white shadow-md rounded-lg">
      <div
        className={`${getStatusColor(
          booking_status
        )} w-24 px-4 py-1.5 text-center text-sm rounded-md mb-4`}
      >
        {booking_status}
      </div>
      <div className="px-4 flex flex-col md:flex-row md:justify-between lg:flex-col items-center">
        <div className="w-24 h-24 bg-gray-300 rounded-full mb-4 flex justify-center items-center overflow-hidden">
          <img
            src={`${REACT_APP_API_BASE_URL}${company_logo}`}
            alt="Company Logo"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col mb-4 mt-4 gap-y-2 lg:w-full">
          <h2 className="font-semibold text-sm">Company Details</h2>
          <h2 className="text-sm flex items-center">
            <img src={user} alt="Company Logo" className="w-4 h-4 mr-2" />
            <span className="font-thin">{company_name}</span>
          </h2>
          <p className="text-sm flex items-center">
            <img src={phone} alt="Phone Icon" className="w-4 h-4 mr-2" />
            <a href={`tel:${contact_number}`}>{contact_number}</a>
          </p>
          <p className="text-sm flex items-center">
            <img src={user} alt="User Icon" className="w-4 h-4 mr-2" />
            {contact_name}
          </p>
          <p className="text-sm flex items-center">
            <img src={mail} alt="Mail Icon" className="w-4 h-4 mr-2" />
            {company_bio}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
