import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getCityWisePackagesCount } from "../../../../utility/Api";
import Card from "./Card";
import "../swiper.css";

import karachiImage from "../../../../assets/karachi.jpg";
import lahoreImage from "../../../../assets/lahore.jpg";
import islamabadImage from "../../../../assets/islamabad.jpg";
import peshawarImage from "../../../../assets/Peshawar.jpg";
import quettaImage from "../../../../assets/quetta.jpg";
import sialkotImage from "../../../../assets/sialkot.jpg";
import multanImage from "../../../../assets/Multan.jpg";
import faisalabadImage from "../../../../assets/faisalabad.jpg";
import gwadar from "../../../../assets/gwadar.jpg";
import turbatImage from "../../../../assets/Turbat.webp";

const imageMapping = {
  Karachi: karachiImage,
  Lahore: lahoreImage,
  Islamabad: islamabadImage,
  Peshawar: peshawarImage,
  Quetta: quettaImage,
  Sialkot: sialkotImage,
  Multan: multanImage,
  Faisalabad: faisalabadImage,
  Gwadar: gwadar,
  Turbat: turbatImage,
};

const cities = [
  "Karachi",
  "Lahore",
  "Islamabad",
  "Peshawar",
  "Quetta",
  "Sialkot",
  "Multan",
  "Faisalabad",
  "Gwadar",
  "Turbat",
];

const ExploreMore = () => {
  const [swiper, setSwiper] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [apiData, setApiData] = useState([]);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCityWisePackagesCount();
        setApiData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (swiper && swiper.navigation) {
      swiper.params.navigation.prevEl = prevButtonRef.current;
      swiper.params.navigation.nextEl = nextButtonRef.current;
      swiper.navigation.update();
    }
  }, [swiper]);

  const prevSlide = () => swiper?.slidePrev();
  const nextSlide = () => swiper?.slideNext();

  const getImageForCity = (city) => imageMapping[city] || "https://example.com/default.jpg";

  const getCityProperties = (city) => {
    const cityData = apiData.find((c) => c.flight_from === city);
    return cityData ? cityData.package_count : 0;
  };

  const breakpoints = {

    2500: { slidesPerView: 13, spaceBetween: 20 },
    2400: { slidesPerView: 12.5, spaceBetween: 20 },
    2300: { slidesPerView: 12, spaceBetween: 20 },
    2200: { slidesPerView: 11.5, spaceBetween: 20 },
    2100: { slidesPerView: 11, spaceBetween: 20 },
    2000: { slidesPerView: 10.5, spaceBetween: 20 },
    1900: { slidesPerView: 10, spaceBetween: 20 },
    1800: { slidesPerView: 9.5, spaceBetween: 20 },
    1700: { slidesPerView: 9, spaceBetween: 20 },
    1600: { slidesPerView: 8.5, spaceBetween: 20 },
    1500: { slidesPerView: 8, spaceBetween: 20 },
    1400: { slidesPerView: 7.5, spaceBetween: 20 },
    1300: { slidesPerView: 7, spaceBetween: 20 },
    1200: { slidesPerView: 6.6, spaceBetween: 20 },
    1100: { slidesPerView: 6, spaceBetween: 20 },
    1000: { slidesPerView: 5.5, spaceBetween: 20 },
    900: { slidesPerView: 5, spaceBetween: 20 },
    800: { slidesPerView: 4.5, spaceBetween: 20 },
    700: { slidesPerView: 4, spaceBetween: 20 },
    600: { slidesPerView: 3, spaceBetween: 20 },
    500: { slidesPerView: 2, spaceBetween: 20 },
    400: { slidesPerView: 2, spaceBetween: 20 },
    300: { slidesPerView: 1, spaceBetween: 20 }

  };

  return (
    <div className="explore-more">
      <div className="w-[85%] mx-auto">
        <div className="header mb-4">
          <h2 className="text-lg sm:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-[#484848] mb-1 font-cairo">
            Explore Packages City Wise
          </h2>
          <p className="text-xs sm:text-sm xl:text-base 2xl:text-lg font-medium text-[#484848] mb-5 font-cairo">
            Discover the Best Your City Has to Offer - Book Your Perfect Experience Today!
          </p>
        </div>
        <div className="relative">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={{ prevEl: prevButtonRef.current, nextEl: nextButtonRef.current }}
            modules={[Navigation, Pagination]}
            className=""
            breakpoints={breakpoints}
            onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
            onInit={(swiperInstance) => setSwiper(swiperInstance)}
            onSlideChange={({ activeIndex }) => setCurrentIndex(activeIndex)}
          >
            {cities.map((city) => (
              <SwiperSlide key={city} className="cursor-pointer">
                <Card
                  image={getImageForCity(city)}
                  navigation={() => navigate("/umrah")}
                  heading={city}
                  para={getCityProperties(city)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="custom-navigation">
            <div
              className="swiper-button-prev custom1 swiper-container hidden"
              ref={prevButtonRef}
              onClick={prevSlide}
            />
            <div
              className="swiper-button-next custom2 swiper-container hidden"
              ref={nextButtonRef}
              onClick={nextSlide}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;