import React from 'react';

import HeaderComponent from "../../components/Header.js";
import Footer from "../../components/footer.js";

import { AiOutlineInfoCircle, AiOutlineGift, AiOutlineEye, AiOutlineDollarCircle } from 'react-icons/ai';

// Assuming the image is located in the public/images/AccountManagement/RewardsAndWallet/Wallet.png
const walletIconPath = process.env.PUBLIC_URL + '/images/AccountManagement/RewardsAndWallet/Wallet.png';
const bg = process.env.PUBLIC_URL + '/images/AccountManagement/RewardsAndWallet/bg.jpg';

const RewardsWallet = () => {
  return (
    <section>
      <HeaderComponent/>
      <div className="w-full mb-14">
        <div
          className="bg-cover bg-center bg-no-repeat p-6 text-center text-white relative"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="absolute inset-0 bg-[#00936C] opacity-75"></div> {/* Background overlay */}
          <div className="relative">
            <h1 className="text-3xl font-bold">Rewards & Wallet</h1>
            <p className="text-lg">Save money on your next adventure with Booking.com</p>
          </div>
        </div>
        <div className="w-[85%] mx-auto">
          <div className="max-w-[70%] mx-auto">
            <div className="lg:w-auto mx-auto mt-4 bg-white border p-4 shadow-lg rounded-lg flex flex-col lg:flex-row justify-around items-center">
              <div className="flex items-center w-full lg:w-[49%]">
                <img src={walletIconPath} alt="Wallet" className="w-16 h-16 md:w-24 md:h-24" />
                <div className="ml-4">
                  <h2 className="text-lg md:text-xl font-bold">Wallet balance</h2>
                  <p className="text-gray-600 text-sm">Includes all spendable rewards</p>
                  <p className="text-xl font-bold mt-2">€ 0</p>
                </div>
              </div>
              <div className="border h-[1px] lg:h-[100px] mx-10 w-full lg:w-[1px] my-4 lg:my-0"></div>
              <div className="flex flex-col font-light w-full lg:w-[49%] items-start">
                <div className="flex justify-between text-sm w-full">
                  <div className="flex items-center">
                    <p className="text-gray-600 mr-1">Credits</p>
                    <AiOutlineInfoCircle fontSize="small" className="text-gray-600" />
                  </div>
                  <p className="font-normal">€ 0</p>
                </div>
                <div className="flex justify-between items-center text-sm w-full mt-2">
                  <div className="flex items-center">
                    <p className="text-gray-600 mr-1">Vouchers (0)</p>
                    <AiOutlineInfoCircle fontSize="small" className="text-gray-600" />
                  </div>
                  <p className="font-normal">€ 0</p>
                </div>
                <a href="#" className="text-[#00936C] text-sm hover:underline mt-2">Browse Rewards & Wallet activity</a>
              </div>
            </div>

          </div>
          <div className="mt-8 md:mt-12 lg:mt-20 text-start justify-between flex flex-col lg:flex-row border-b pb-8">
            <p>Got a coupon code? <a href="#" className="text-[#00936C]">Add coupon into Wallet</a></p>
            <div className="mt-4 lg:mt-0">
              <a href="#" className="text-[#00936C]">Need help? Visit FAQs</a>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">What's Rewards & Wallet?</h2>
            <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
              <div className="flex items-start">
                <AiOutlineGift className="w-8 h-8 lg:w-10 lg:h-10 text-[#00936C]" />
                <div className="ml-4">
                  <h3 className="text-sm font-normal mb-3">Book and earn travel rewards</h3>
                  <p className="text-gray-600 font-light text-sm">Credits, vouchers, you name it! These are all spendable on your next Booking.com trip.</p>
                </div>
              </div>
              <div className="flex items-start">
                <AiOutlineEye className="w-8 h-8 lg:w-10 lg:h-10 text-[#00936C]" />
                <div className="ml-4">
                  <h3 className="text-sm font-normal mb-3">Track everything at a glance</h3>
                  <p className="text-gray-600 font-light text-sm">Your Wallet keeps all rewards safe, while updating you of your earnings and spendings.</p>
                </div>
              </div>
              <div className="flex items-start">
                <AiOutlineDollarCircle className="w-8 h-8 lg:w-10 lg:h-10 text-[#00936C]" />
                <div className="ml-4">
                  <h3 className="text-sm font-normal mb-3">Pay with Wallet to save money</h3>
                  <p className="text-gray-600 font-light text-sm">If a booking accepts any rewards in your Wallet, it'll appear during payment for spending.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </section>

  );
};

export default RewardsWallet;
