import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../../../../../assets/makkahPageIcon.svg";
import Footer from "../../../../../../components/footer";
import { AlulaOldTownImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const points = [
  "Traditional Mudbrick Architecture:",
  "Narrow Alleyways",
  "Ancient Fortifications:",
  "Historical Mosques",
  "Cultural Heritage Sites",
];

const sliderimages = [
  AlulaOldTownImages.image1,
  AlulaOldTownImages.image2,
  AlulaOldTownImages.image3,
  AlulaOldTownImages.image4,
  AlulaOldTownImages.image5,
];

const AlulaOldTown = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${AlulaOldTownImages.image1})` }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>
        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">
            AlUla Old Town: A Journey Through Time
          </h1>
        </div>
      </div>

      <div className="bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center">
        <h1 className="w-[90%] mx-auto">
          Step into AlUla Old Town, where history and tradition weave through
          narrow alleys and ancient mudbrick houses, inviting you to explore a
          living museum of Saudi Arabia's rich heritage!
        </h1>
      </div>

      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">
            Reference in Islamic History
          </h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                AlUla Old Town has played a significant role in the history of
                the Arabian Peninsula. As a key stop on the ancient incense and
                pilgrimage routes, it was a thriving hub of trade and culture.
                The town's strategic location made it an important meeting point
                for caravans traveling to and from the Arabian interior,
                facilitating the exchange of goods, ideas, and culture. AlUla's
                historical mosques and religious sites further highlight its
                importance in the Islamic world, serving as a place of worship
                and community for centuries.
              </p>
              <div className="py-2.5 lg:hidden xl:block">
                <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
                <p className="font-medium text-[15px] pt-4">
                  The architectural charm of AlUla Old Town lies in its
                  traditional mudbrick houses, narrow streets, and ancient
                  fortifications. The buildings, constructed from locally
                  sourced materials, blend seamlessly with the desert landscape,
                  showcasing a timeless elegance. The old town's design reflects
                  the ingenuity of its inhabitants, who built structures that
                  provided insulation against the harsh desert climate. Walking
                  through AlUla Old Town feels like stepping back in time, with
                  each alleyway and courtyard revealing a new story of the
                  town's storied past.
                </p>
              </div>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={AlulaOldTownImages.image4}
                alt="Abu Bakar Masjid"
                className="w-full rounded-md"
              />
            </div>
          </div>
          <div className="py-2.5 hidden lg:block xl:hidden">
            <h1 className="text-[25px] font-bold">Architectural Beauty</h1>
            <p className="font-medium text-[15px] pt-4">
              The architectural charm of AlUla Old Town lies in its traditional
              mudbrick houses, narrow streets, and ancient fortifications. The
              buildings, constructed from locally sourced materials, blend
              seamlessly with the desert landscape, showcasing a timeless
              elegance. The old town's design reflects the ingenuity of its
              inhabitants, who built structures that provided insulation against
              the harsh desert climate. Walking through AlUla Old Town feels
              like stepping back in time, with each alleyway and courtyard
              revealing a new story of the town's storied past.
            </p>
          </div>
        </div>

        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">
            Significance of AlUla Old Town
          </h1>
          <div className="pt-3">
            <div className="font-cairo font-medium text-[15px] space-y-2">
              <p>
                AlUla Old Town holds immense cultural and historical
                significance. As one of the oldest continuously inhabited
                settlements in the Arabian Peninsula, it offers a unique window
                into the region's past. The town's well-preserved architecture
                and urban layout provide invaluable insights into the lifestyle,
                trade, and community dynamics of ancient Arabian societies.
                AlUla Old Town's role as a cultural and trade center underscores
                its importance in the broader history of the Arabian Peninsula.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-10 gap-4">
              {[
                {
                  title: "Community Hub:",
                  description:
                    " A vibrant center of trade and social interaction for centuries.",
                },
                {
                  title: "Religious Services:",
                  description:
                    "Historic mosques that have served as places of worship and community gatherings.",
                },
                {
                  title: "Architectural Marvel:",
                  description:
                    "Traditional mudbrick houses and fortifications showcasing ancient building techniques.",
                },
                {
                  title: "Spiritual Sanctuary:",
                  description:
                    "Sites of religious and cultural significance that have been central to the town's identity.",
                },
              ].map((item, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg">
                  <span className="font-bold block text-[25px]">
                    {item.title}
                  </span>
                  <p className="font-normal text-[15px] pt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-10">
          <h1 className="font-bold block text-[25px]">
            Activities at AlUla Old Town
          </h1>
          <div className="space-y-1.5">
            <p className="font-normal text-[15px] pt-2">
              Guided Historical Tours: Learn about the town's history and
              significance with knowledgeable guides. Cultural Workshops:
              Participate in workshops that showcase traditional crafts,
              cooking, and customs. <br />
              Local Markets: Visit the bustling souks to experience the sights,
              sounds, and flavors of AlUla. <br />
              Architectural Exploration: Wander through the narrow streets and
              marvel at the well-preserved mudbrick architecture. Photography:
              Capture the timeless beauty of the ancient buildings and scenic
              desert backdrop. <br />
              Cultural Events: Engage with local traditions through festivals
              and events that celebrate AlUla's heritage. <br />
            </p>
            <div className="md:flex space-y-4 md:space-y-0 gap-5 text-center pt-5">
              <img
                src={AlulaOldTownImages.image3}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 rounded-lg md:h-[300px]"
              />
              <img
                src={AlulaOldTownImages.image4}
                alt="Activities at Abu Bakar Masjid"
                className="md:w-1/2 md:h-[300px] rounded-lg"
              />
            </div>
          </div>
        </div>

        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10">
          Alula Old Town
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className="font-medium text-[15px]">
              Abu Bakar Masjid is a symbol of spiritual devotion and
              architectural excellence in Jeddah. It continues to inspire and
              serve the Muslim community through its religious, educational, and
              social initiatives.
            </p>
            <div className="space-y-1.5 py-1.5">
              <h4 className="font-medium text-[15px]">
                Key Features of Alula Old Town:
              </h4>
              <ul className="font-medium text-[15px]">
                {points.map((point, index) => (
                  <li key={index} className="flex gap-2 py-2 px-2">
                    <img src={icon} alt="icon" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              <p className="font-medium text-[15px]">
                Natural Landscape and Archaeological Significance of AlUla Old
                Town Natural Landscape Nestled amidst stunning desert scenery
                and unique rock formations. Archaeological Significance
                Continuous excavations uncover the town's rich historical
                heritage.
              </p>
            </div>
          </div>
          <img
            src={AlulaOldTownImages.image6}
            alt="Abu Bakar Masjid"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {sliderimages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg h-[300px] w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AlulaOldTown;
