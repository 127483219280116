import React from "react";
import BookingNavbar from "../../../../../components/Navbar/BookingNavBar";
import ViewDeal from "./ViewDeal";
import Footer from "../../../../../components/footer";

const Booking = () => {
  return (
    <div>
      <BookingNavbar />
      <ViewDeal />
      <Footer />
    </div>
  );
};

export default Booking;
