export const MakkahPlaces = [
  {
    imageUrl: "/images/muhammad3.svg",
    title: "Prophet (S.A.W) House",
    description:
      "The history of the Prophet Muhammad's (peace be upon him) home...",
    link: "/articles/makkah/prophet-house",
    large: true,
  },
];

export const MadinahPlacesHolySites = [
  {
    imageUrl: "/images/Wadijin5.svg",
    title: "Wadi e Jinn in Islam",
    description:
      "Calling Jinn their brothers, the Messenger of Allah instructed everyone to not perform...",
    link: "/articles/madinah/wadi-jin",
    large: true,
  },

  {
    imageUrl: "/images/uhud2.svg",
    title: "Mountain Uhud",
    description:
      "The Battle of Uhud, a significant engagement between the early Muslims and...",
    link: "/articles/madinah/uhud-mountain",
    large: true,
  },
  {
    imageUrl: "/images/ghar3.svg",
    title: "Al-Ghars Well",
    description:
      "According to tradition, Bilal Ibn Rabah used to fetch water from this well for Prophet...",
    link: "/articles/madinah/bir-ghars",
    large: true,
  },
  {
    imageUrl: "/images/abubakar3.svg",
    title: "Masjid Abu-Bakr",
    description:
      "It was built-up for the first time in the Princedom of Omar Bin Abdul Aziz in...",
    link: "/articles/madinah/masjid-abu-bakr",
    large: true,
  },
  {
    imageUrl: "/images/ejabah2.svg",
    title: "Al-Ijabah Mosque",
    description:
      "The significance of Masjid Ijabah is that it is the location where the Prophet...",
    link: "/articles/madinah/masjid-ijabah",
    large: true,
  },

  {
    imageUrl: "/images/MasjidGhamama3.svg",
    title: "Mosque of Al-Ghamama",
    description:
      "After the Battle of Uhud, which took place in 625 CE, the Prophet Muhammad and his...",
    link: "/articles/madinah/masjid-al-ghammamah",
    large: true,
  },
];

export const MadinaPlacesAttraction = [
  {
    imageUrl: "/images/masjidjummah4.svg",
    title: "Masjid al-Jummah",
    description:
      "The history of Masjid al-Jummah dates back to the early years of Islam when the Prophet...",
    link: "/articles/madinah/masjid-jummah",
    large: true,
  },
  {
    imageUrl: "/images/miqat2.svg",
    title: "Dhul Hulaifah Miqat Mosque",
    description:
      "Miqat mosque holds a special place because the Prophet (P.B.U.H) defined it as the...",
    link: "/articles/madinah/masjid-miqat",
    large: true,
  },
  {
    imageUrl: "/images/MasjidMustarah3.svg",
    title: "Masjid al-Mustarah",
    description:
      "Mustarah translates to the place for resting in Arabic. Renovated during the reign of King...",
    link: "/articles/madinah/masjid-mustarah",
    large: true,
  },

  {
    imageUrl: "/images/qibla1.png",
    title: "Masjid al-Qiblatayn",
    description:
      "The mosque, believed to be one of the earliest in Medina, was constructed by Sawad ibn...",
    link: "/articles/madinah/masjid-qiblatayn",
    large: true,
  },
  {
    imageUrl: "/images/MasjidQuba2.svg",
    title: "Masjid Quba",
    description:
      "Masjid Quba holds great historical significance in Islam as it is the first mosque built by...",
    link: "/articles/madinah/masjid-quba",
    large: true,
  },
  {
    imageUrl: "/images/salman2.svg",
    title: "The Garden Of Salman Al Farsi",
    description:
      "Salman the Persian (رضي الله عنه) came to the Prophet (ﷺ) to seek his counsel and his...",
    link: "/articles/madinah/garden-of-salman-farsi",
    large: true,
  },
];

export const MakkahPlacesHolySites = [
  {
    imageUrl: "/images/Makkah/Kabba/kabba.jpg",
    title: "Kaaba",
    description:
      "The Kaaba is a sacred structure located at the center of the Grand Mosque (Masjid al-Haram) in Mecca, Saudi Arabia. It is the most revered site in Islam,",
    link: "/articles/makkah/holysites/kabba",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/SafaMarwa/safamarwa1.jpg",
    title: "Safa Marwa and Sa'i",
    description:
      "Safa Marwa and Sa'i are essential rituals in Hajj and Umrah, symbolizing Hagar's search for water for her son, Ishmael. Pilgrims walk seven times between the hills of Safa and Marwa within the Masjid al-Haram in Mecca, reflecting on faith, endurance, and divine providence. This practice commemorates Hagar's perseverance and is a powerful act of devotion and spiritual reflection for Muslims....",
    link: "/articles/makkah/holysites/safa-marwa",
    large: true,
  },

  {
    imageUrl: "/images/Makkah/Mina/mina1.jpg",
    title: "Mina the City Of Tents",
    description:
      'Mina, the "City of Tents" in Saudi Arabia, is a sacred site where millions of Muslims unite during Hajj perform the symbolic stoning of the devil....',
    link: "/articles/makkah/holysites/mina",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/Arafah/arafahheader.jpg",
    title: "Arafah",
    description:
      "Discover the spiritual heart of Saudi Arabia at Arafah, the pivotal site of the Hajj pilgrimage. Each year, millions of Muslims gather here to stand in prayer and reflection, seeking forgiveness and blessings. Dive into the profound significance and transformative experience of Arafah on our blog—join us to uncover why this sacred site captivates hearts worldwide...",
    link: "/articles/makkah/holysites/arafah",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/Ghar-e-Hira/gharHira.svg",
    title: "Cave of Hira",
    description:
      "History A site where the Prophet Muhammad (peace be upon him) received the first revelations of the..",
    link: "/articles/makkah/gar-e-hira",
    large: true,
  },

  {
    imageUrl: "/images/Makkah/Muzdalifah/muzdalifah2.webp",
    title: "Muzdalifah",
    description:
      "Allah called it al- Mash'ar al-Haram in the Holy Qur’an: (But when you depart from 'Arafat, remember Allah at al- Mash'ar al-Haram). It is a stage of the Hajj journey. It is located between Mina and Arafah. The pilgrims stay overnight there after they depart Arafah. They join and shorten Maghrib and Isha prayers and collect pebbles for Jamarat, in Mina. Pilgrims stay there for part of the night or until the dawn of the next day, the day of Eid, to move to Mina...",
    link: "/articles/makkah/holysites/muzdalifah",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/Namirah/namirah1.jpg",
    title: "Exploring Namirah Mosque",
    description:
      "Discover the historical and architectural grandeur of Namirah",
    link: "/articles/makkah/holysites/namirah-mosque",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/Ghar-e-Sur/gharsur.svg",
    title: "Thawr Cave",
    description:
      "Jabal Thawr is a mountain in Saudi Arabia that is located in the lower part of Makkah south of the Misfalah district. The place is popular among people belonging to the Islamic religion as it houses the..",
    link: "/articles/makkah/ghar-e-soor",
    large: true,
  },
];

export const MakkahPlacesAttractions = [
  {
    imageUrl: "/images/Makkah/MasjidAlKhayf/alkaif4.webp",
    title: "Masjid al-Khayf",
    description:
      "Masjid al-Khayf (Arabic: مسجد الخيف) is located near the smallest Jamarat at the base of the mountain in the south of Mina and is considered the most important mosque in this area. It is here that the Prophet ﷺ and 70 other prophets before him had performed salah.",
    link: "/articles/makkah/holysites/masjid-al-Khayf",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/AlHudaybiyah/huda2.svg",
    title: "Treaty of al-Hudaybiya",
    description:
      "The Treaty of al-Hudaybiya, a significant event during the lifetime of the Islamic...",
    link: "/articles/makkah/al-hudaybiyah",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/ClockTower/makkahclocktower1.jpg",
    title: "Makkah Clock Tower",
    description:
      "The Makkah Clock Tower, also known as Abraj Al Bait, is a prominent skyscraper complex located in Mecca, Saudi Arabia",
    link: "/articles/makkah/holysites/makkah-clock-tower",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/MakkahMuseum/makkahmuseum.jpg",
    title: "Mecca Museum",
    description:
      "The Makkah Clock Tower, also known as Abraj Al Bait, is a prominent skyscraper complex located in Mecca, Saudi Arabia",
    link: "/articles/makkah/holysites/makkah-museum",
    large: true,
  },
  {
    imageUrl: "/images/Makkah/MasjidJinn/MasjidJinn.webp",
    title: "Mosque of the Jinn",
    description:
      "Jinn mosque (Azerbaijani: Cin məscidi, Persian: مسجد جن) is a historical mosque of the XIV century.",
    link: "/articles/makkah/holysites/masjid-jinn",
    large: true,
  },
];

export const TaifPlacesHolyPlaces = [
  {
    imageUrl: "/images/Taif/holyplace/abdullah.jpg",
    title: "Masjid Abdullah Bin Al Abbas",
    description:
      "History A site where the Prophet Muhammad (peace be upon him) received the first revelations of the..",
    link: "/articles/taif/holysites/masjid-abdullah-bin-abbas",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/bibihalimah.jpg",
    title: "House of Halima Sadia",
    description:
      "The House of Halimah Sadia exudes timeless elegance and modern sophistication. Its blend of traditional architecture and contemporary design..",
    link: "/articles/taif/holysites/halima-sadia-house",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/madhoun-house.jpg",
    title: "Masjid al-Qantara",
    description:
      "Al Madhoun House is a stunning blend of heritage and modernity. Its architecture showcases intricate traditional designs while incorporating contemporary elements for a luxurious feel. Sunlit interiors reveal rich textures and elegant decor.",
    link: "/articles/taif/holysites/masjid-al-qantara",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/mountain.jpeg",
    title: "Al-Hada Mountain",
    description:
      " Although not a traditional holy site, Al-Hada Mountain is considered sacred by many locals due to its stunning natural beauty and its significance in the region.",
    link: "/articles/taif/holysites/al-hada-mountain",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/al-shafa.jpg",
    title: "Al-Shafa Mountains",
    description:
      "The Al-Shafa Mountains, located near Taif in Saudi Arabia, are a breathtaking natural wonder. Known for their cool climate and stunning vistas, they are a haven ",
    link: "/articles/taif/holysites/shafa-mountain",
    large: true,
  },
];

export const TaifPlacesAttractions = [
  {
    imageUrl: "/images/Taif/holyplace/rudafPark.jpeg",
    title: "Arruddaf Park",
    description:
      "Rudaf Park, located in Taif, Saudi Arabia, is a picturesque oasis of natural beauty and recreation. The park features expansive green lawns, vibrant flower gardens",
    link: "/articles/taif/attractions/arruddaf-park",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/shubrapalace.jpeg",
    title: "Shubra Palace",
    description:
      "Shubra Palace, situated in Riyadh, Saudi Arabia, is a splendid blend of history and architecture. This historic palace showcases traditional Saudi design with intricate woodwork ",
    link: "/articles/taif/attractions/shubra-palace",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/RoseFields.jpeg",
    title: "Taif Rose Fields",
    description:
      "The Taif Rose Fields in Saudi Arabia are a breathtaking sight, renowned for their beauty and fragrance. Every spring, these fields burst into bloom with delicate pink roses, filling the air with their sweet scent. Nestled amidst the majesti",
    link: "/articles/taif/attractions/taif-rose-fields",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/saiysadpark.jpeg",
    title: "Saiysad National Park",
    description:
      "Saiysad National Park, located near Taif in Saudi Arabia, is a pristine natural reserve known for its rugged beauty and diverse wildlife. The park encompasses rolling hills, rocky outcrops, and dense vegetation, creating a habitat for various ",
    link: "/articles/taif/attractions/saiysad-park",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/taifzoo.jpeg",
    title: "Taif Zoo",
    description:
      "Taif Zoo, located in Taif, Saudi Arabia, is a popular attraction offering visitors a chance to see a diverse range of animals from around the world. Set amidst picturesque surroundings, the zoo provides habitats for various species,",
    link: "/articles/taif/attractions/taif-zoo",
    large: true,
  },
  {
    imageUrl: "/images/Taif/holyplace/KindFahad.jpeg",
    title: "King Fahd Garden",
    description:
      "King Fahd Garden, situated in Taif, Saudi Arabia, is a splendid botanical oasis spanning vast acres of lush greenery and colorful blooms. This expansive park offers a serene retreat with shaded pathways, tranquil lakes, and meticulously landscaped gardens.",
    link: "/articles/taif/attractions/king-fahad",
    large: true,
  },
];

export const JeddahPlacesHolySites = [
  {
    imageUrl: "/images/Jeddah/AlRehmanMosque/AlRehman1.jpg",
    title: "Al Rahmah Mosque",
    description:
      "Located on the Red Sea coast in Jeddah, Al Rahmah Mosque is known for its unique floating design and stunning views. It serves as a place of worship and reflection for local residents and visitors alike.",
    link: "/articles/jeddah/holysites/al-rehman-mosque",
    large: true,
  },
  {
    imageUrl: "/images/Jeddah/KhadijahMosque/KhadijahMosque1.jpg",
    title: "Khadija Baghlaf Mosque",
    description:
      "A historic mosque in Jeddah's Al-Balad district, named after Khadija Baghlaf, a prominent local figure. It features traditional Islamic architecture and serves as a community hub for prayers and gatherings",
    link: "/articles/jeddah/holysites/khadijah-mosque",
    large: true,
  },

  {
    imageUrl: "/images/Jeddah/HassanMosque/HassanMosque1.webp",
    title: "Hassan Enany Mosque",
    description:
      " Situated in Jeddah, the Hassan Enany Mosque is celebrated for its intricate woodwork and exquisite craftsmanship, showcasing traditional Saudi Arabian architectural styles.",
    link: "/articles/jeddah/holysites/hassan-mosque",
    large: true,
  },

  {
    imageUrl: "/images/Jeddah/KhalidMosque/KhalidMosque1.jpg",
    title: "King Khalid Grand Mosque",
    description:
      "One of Jeddah's largest and most impressive mosques, King Khalid Grand Mosque features modern Islamic architecture and hosts thousands of worshippers during daily prayers and special occasions.",
    link: "/articles/jeddah/holysites/khalid-mosque",
    large: true,
  },
  {
    imageUrl: "/images/Jeddah/TurkeyMosque/TurkeyMosque1.jpg",
    title: "Imam Turki Bin Abd. Mosque",
    description:
      "A prominent mosque in Riyadh known for its expansive grounds and elegant architecture, named after Imam Turki bin Abdullah, a respected religious figure in Saudi Arabia.",
    link: "/articles/jeddah/holysites/turkey-mosque",
    large: true,
  },

  {
    imageUrl: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque1.jpg",
    title: "Abu Bakar Masjid",
    description:
      "A mosque named after Abu Bakr, the first Caliph of Islam, known for its serene atmosphere and traditional architecture. It serves as a place of worship and community gatherings in Jeddah",
    link: "/articles/jeddah/holysites/abu-bakar-mosque",
    large: true,
  },
];

export const JeddahPlacesAttractions = [
  {
    imageUrl: "/images/jeddah/naseefHouse.jpg",
    title: "Nassif House Museum",
    description:
      "Nassif House Museum, located in Jeddah, Saudi Arabia, offers visitors an immersive journey into the country's cultural and historical legacy.",
    link: "/articles/jeddah/attractions/nasif-house-museum",
    large: true,
  },
  {
    imageUrl: "/images/jeddah/globe.avif",
    title: "The Globe Roundabout Jeddah",
    description:
      "The Globe Roundabout in Jeddah, Saudi Arabia, stands as a striking symbol of the city's blend of modernity and tradition. Featuring a massive, intricately designed globe at its center, this landmark represents Jeddah's global connections and its role as a gateway to the world",
    link: "/articles/jeddah/attractions/globe-round-about",
    large: true,
  },
  {
    imageUrl: "/images/jeddah/souq.jpg",
    title: "Souq Al Alawi Jeddah",
    description:
      "Souq Al Alawi, located in the heart of Jeddah's historic Al-Balad district, is one of the oldest and most vibrant marketplaces in Saudi Arabia.",
    link: "/articles/jeddah/attractions/souq-al-alawi",
    large: true,
  },
  {
    imageUrl: "/images/jeddah/taybatmuseum.jpg",
    title: "Taybat City Museum",
    description:
      "Al Taybat International City Museum of Science and Information in Jeddah, Saudi Arabia, offers a fascinating journey through the region's rich heritage and advancements.",
    link: "/articles/jeddah/attractions/taybat-museum",
    large: true,
  },
  {
    imageUrl: "/images/jeddah/flagpole.jpg",
    title: "Jeddah Flagpole",
    description:
      "The Jeddah Flagpole, soaring at an impressive height of 171 meters, is one of the tallest flagpoles in the world. Located in King Abdullah Square,",
    link: "/articles/jeddah/attractions/flag-pole",
    large: true,
  },
  {
    imageUrl: "/images/jeddah/alBalad.jpg",
    title: "Al Balad: Jeddah's",
    description:
      "The Jeddah Flagpole, soaring at an impressive height of 171 meters, is one of the tallest flagpoles in the world. Located in King Abdullah Square,",
    link: "/articles/jeddah/attractions/al-balad",
    large: true,
  },
];

export const AlulaPlacesAttractions = [
  {
    imageUrl: "/images/alula/hegra-mountain/hegra1.jpeg",
    title: "Hegra (Madain Salih)",
    description:
      "Hegra, also known as Madain Salih, is a breathtaking archaeological site and Saudi Arabia's first UNESCO World Heritage site. Discover its stunning rock-cut tombs and intricate inscriptions that tell the story of the Nabatean civilization.",
    link: "/articles/alula/hegra",
    large: true,
  },
  {
    imageUrl: "/images/alula/old-town/image1.webp",
    title: "AlUla Old Town",
    description:
      "AlUla Old Town invites you to wander through its narrow alleyways and ancient mudbrick houses. This historic settlement offers a glimpse into centuries of trade, culture, and community in the heart of the desert.",
    link: "/articles/alula/alula-old-town",
    large: true,
  },
  {
    imageUrl: "/images/alula/jabal-ikmah/jabal-ikmah1.jpg",
    title: "Jabal Ikmah",
    description:
      "History Carved in Stone: Jabal Ikmah is an open-air library of ancient inscriptions. This majestic mountain, adorned with petroglyphs and rock art, reveals the stories and secrets of civilizations long past.",
    link: "/articles/alula/jabal-ikmah",
    large: true,
  },
  {
    imageUrl: "/images/alula/dadan/image1.avif",
    title: "Dadan (Dedan)",
    description:
      "Lost Kingdom Unearthed: Dadan, or Dedan, is an ancient city that was once a powerful trading hub. Explore its impressive ruins and discover the secrets of the Lihyanite and Dedanite kingdoms that flourished in this oasis.",
    link: "/articles/alula/dadan",
    large: true,
  },
  {
    imageUrl: "/images/alula/railwaystation/image1.webp",
    title: "Hijaz Railway Station",
    description:
      "Echoes of a Bygone Era: The Hijaz Railway Station in AlUla whispers tales of a once-vital lifeline. Step into history at this iconic station, where the remnants of a railway that once connected civilizations await exploration.",
    link: "/articles/alula/hijaz-railway-station",
    large: true,
  },
];

export const MinaImagesdata = {
  image1: "/images/Makkah/Mina/mina1.jpg",
  image2: "/images/Makkah/Mina/mina2.jpg",
  image3: "/images/Makkah/Mina/mina3.jpg",
  image4: "/images/Makkah/Mina/mina4.jpg",
  image5: "/images/Makkah/Mina/mina5.jpg",
  image6: "/images/Makkah/Mina/minastone.jpg",
};

export const arafahImagesdata = {
  image1: "/images/Makkah/Arafah/arafah1.jpg",
  image2: "/images/Makkah/Arafah/arafah2.webp",
  image3: "/images/Makkah/Arafah/arafah3.jpg",
  image4: "/images/Makkah/Arafah/arafa4.jpeg",
  image5: "/images/Makkah/Arafah/arafah5.jpg",
};

export const safamarwa = {
  image1: "/images/Makkah/SafaMarwa/safamarwa1.jpg",
  image2: "/images/Makkah/SafaMarwa/safamarwa2.jpg",
  image3: "/images/Makkah/SafaMarwa/safamarwa3.jpg",
  image4: "/images/Makkah/SafaMarwa/safamarwa4.jpg",
  image5: "/images/Makkah/SafaMarwa/safamarwa5.jpg",
};
export const MuzdalifahImages = {
  image1: "/images/Makkah/Muzdalifah/muzdalifah1.jpg",
  image2: "/images/Makkah/Muzdalifah/muzdalifah2.webp",
  image3: "/images/Makkah/Muzdalifah/muzdalifah3.webp",
  image4: "/images/Makkah/Muzdalifah/muzdalifah4.webp",
  image5: "/images/Makkah/Muzdalifah/muzdalifah5.jpg",
};

export const NamirahImages = {
  image1: "/images/Makkah/Namirah/namirah1.jpg",
  image2: "/images/Makkah/Namirah/namirah2.jpg",
  image3: "/images/Makkah/Namirah/namirah3.jpg",
  image4: "/images/Makkah/Namirah/namirah4.jpeg",
};
export const GharEsurImages = {
  image1: "/images/Makkah/Ghar-e-Sur/Gharesoor.webp",
  image2: "/images/Makkah/Ghar-e-Sur/Gharesoor2.jpg",
  image3: "/images/Makkah/Ghar-e-Sur/Gharesoor3.jpg",
};
export const MasjidalKhayfimages = {
  image1: "/images/Makkah/MasjidAlKhayf/alkaif1.webp",
  image2: "/images/Makkah/MasjidAlKhayf/alkaif2.webp",
  image3: "/images/Makkah/MasjidAlKhayf/alkaif3.webp",
  image4: "/images/Makkah/MasjidAlKhayf/alkaif4.webp",
};

export const ClockTowerImages = {
  image1: "/images/Makkah/ClockTower/makkahclocktower1.jpg",
  image2: "/images/Makkah/ClockTower/makkahclocktower2.jpg",
  image3: "/images/Makkah/ClockTower/makkahclocktower3.jpg",
  image4: "/images/Makkah/ClockTower/makkahclocktower4.jpg",
  image5: "/images/Makkah/ClockTower/makkahclocktower5.jpg",
};

export const MuseumImages = {
  image1: "/images/Makkah/MakkahMuseum/makkahmuseum.jpg",
  image2: "/images/Makkah/MakkahMuseum/makkahmuseum2.jpg",
  image3: "/images/Makkah/MakkahMuseum/makkahmuseum3.webp",
  image4: "/images/Makkah/MakkahMuseum/makkahmuseum4.jpg",
  image5: "/images/Makkah/MakkahMuseum/makkahmuseum5.jpg",
};

export const kabba = {
  image1: "/images/Makkah/Kabba/kabba.jpg",
  image2: "/images/Makkah/Kabba/kabba1.jpg",
  image4: "/images/Makkah/Kabba/kabba3.jpg",
  image6: "/images/Makkah/Kabba/kabba5.png",
  image7: "/images/Makkah/Kabba/kabba6.jpeg",
  image8: "/images/Makkah/Kabba/kabba7.jpg",
  image9: "/images/Makkah/Kabba/kabba8.jpg",
  image10: "/images/Makkah/Kabba/kabba9.jpeg",
  image11: "/images/Makkah/Kabba/kabba10.jpg",
  image12: "/images/Makkah/Kabba/kabba11.jpg",
  image13: "/images/Makkah/Kabba/kabba12.jpg",
};

export const masjidQiblatayn = {
  image1: "/images/qibla.svg",
  image2: "/images/qibla1.png",
  image3: "/images/qibla2.svg",
  image4: "/images/qibla3.svg",
  image5: "/images/qibla4.jpg",
  image6: "/images/qibla5.jpg",
  image7: "/images/qibla6.jpg",
  image8: "/images/qibla7.jpg",
  image9: "/images/qibla8.png",
};

// jeddah images

export const AlRehmanMosqueImages = {
  image1: "/images/Jeddah/AlRehmanMosque/AlRehman1.jpg",
  image2: "/images/Jeddah/AlRehmanMosque/AlRehman2.webp",
  image3: "/images/Jeddah/AlRehmanMosque/AlRehman3.jpg",
  image4: "/images/Jeddah/AlRehmanMosque/AlRehman4.jpeg",
  image5: "/images/Jeddah/AlRehmanMosque/AlRehman5.jpg",
  image6: "/images/Jeddah/AlRehmanMosque/AlRehman6.jpeg",
  image7: "/images/Jeddah/AlRehmanMosque/AlRehman7.webp",
  image8: "/images/Jeddah/AlRehmanMosque/AlRehman8.jpeg",
};
export const KhadijahMosqueImages = {
  image1: "/images/Jeddah/KhadijahMosque/KhadijahMosque1.jpg",
  image2: "/images/Jeddah/KhadijahMosque/KhadijahMosque2.webp",
  image3: "/images/Jeddah/KhadijahMosque/KhadijahMosque3.jpg",
  image4: "/images/Jeddah/KhadijahMosque/KhadijahMosque4.jpg",
  image5: "/images/Jeddah/KhadijahMosque/KhadijahMosque5.webp",
  image6: "/images/Jeddah/KhadijahMosque/KhadijahMosque6.webp",
  image7: "/images/Jeddah/KhadijahMosque/KhadijahMosque7.jpg",
};
export const HassanMosqueImages = {
  image1: "/images/Jeddah/HassanMosque/HassanMosque1.webp",
  image2: "/images/Jeddah/HassanMosque/HassanMosque2.jpg",
  image3: "/images/Jeddah/HassanMosque/HassanMosque3.jpg",
  image4: "/images/Jeddah/HassanMosque/HassanMosque4.jpg",
  image5: "/images/Jeddah/HassanMosque/HassanMosque5.jpg",
  image6: "/images/Jeddah/HassanMosque/HassanMosque6.jpg",
  image7: "/images/Jeddah/HassanMosque/HassanMosque7.jpg",
  image8: "/images/Jeddah/HassanMosque/HassanMosque8.jpg",
};
export const KhalidMosqueImages = {
  image1: "/images/Jeddah/KhalidMosque/KhalidMosque1.jpg",
  image2: "/images/Jeddah/KhalidMosque/KhalidMosque2.jpg",
  image3: "/images/Jeddah/KhalidMosque/KhalidMosque3.jpg",
  image4: "/images/Jeddah/KhalidMosque/KhalidMosque4.jpg",
  image5: "/images/Jeddah/KhalidMosque/KhalidMosque5.jpeg",
  image6: "/images/Jeddah/KhalidMosque/KhalidMosque6.jpg",
  image7: "/images/Jeddah/KhalidMosque/KhalidMosque7.jpg",
  image8: "/images/Jeddah/KhalidMosque/KhalidMosque8.jpg",
};
export const TurkeyMosqueImages = {
  image1: "/images/Jeddah/TurkeyMosque/TurkeyMosque1.jpg",
  image2: "/images/Jeddah/TurkeyMosque/TurkeyMosque2.jpeg",
  image3: "/images/Jeddah/TurkeyMosque/TurkeyMosque3.jpg",
  image4: "/images/Jeddah/TurkeyMosque/TurkeyMosque4.webp",
  image5: "/images/Jeddah/TurkeyMosque/TurkeyMosque5.png",
  image6: "/images/Jeddah/TurkeyMosque/TurkeyMosque6.jpg",
  image7: "/images/Jeddah/TurkeyMosque/TurkeyMosque7.jpg",
  image8: "/images/Jeddah/TurkeyMosque/TurkeyMosque8.webp",
};

export const AbuBakarMosqueImages = {
  image1: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque1.jpg",
  image2: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque2.jpg",
  image3: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque3.jpg",
  image4: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque4.jpg",
  image5: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque5.jpg",
  image6: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque6.jpg",
  image7: "/images/Jeddah/AbuBakarMosque/AbuBakarMosque7.webp",
};

export const HegraAlulaImages = {
  image1: "/images/alula/hegra-mountain/hegra1.jpeg",
  image2: "/images/alula/hegra-mountain/image1.jpeg",
  image3: "/images/alula/hegra-mountain/image2.jpeg",
  image4: "/images/alula/hegra-mountain/image3.jpeg",
  image5: "/images/alula/hegra-mountain/image4.jpeg",
  image6: "/images/alula/hegra-mountain/image5.jpeg",
  image7: "/images/alula/hegra-mountain/image6.avif",
};

export const AlulaOldTownImages = {
  image1: "/images/alula/old-town/image1.webp",
  image2: "/images/alula/old-town/image2.jpeg",
  image3: "/images/alula/old-town/image3.avif",
  image4: "/images/alula/old-town/image4.jpg",
  image5: "/images/alula/old-town/image5.jpg",
  image6: "/images/alula/old-town/image6.jpeg",
};

export const JabalIkmahImages = {
  image1: "/images/alula/jabal-ikmah/image1.jpeg",
  image2: "/images/alula/jabal-ikmah/image2.jpeg",
  image3: "/images/alula/jabal-ikmah/image3.jpeg",
  image4: "/images/alula/jabal-ikmah/image4.jpeg",
  image5: "/images/alula/jabal-ikmah/image5.jpeg",
  image6: "/images/alula/jabal-ikmah/jabal-ikmah1.jpg",
};

export const dadanImages = {
  image1: "/images/alula/dadan/image2.jpeg",
  image2: "/images/alula/dadan/image3.jpeg",
  image3: "/images/alula/dadan/image4.jpeg",
  image4: "/images/alula/dadan/image5.jpeg",
  image5: "/images/alula/dadan/image6.jpeg",
  image6: "/images/alula/dadan/image7.jpeg",
};

export const HijazRailwayStationImages = {
  image1: "/images/alula/railwaystation/image2.jpeg",
  image2: "/images/alula/railwaystation/image3.jpeg",
  image3: "/images/alula/railwaystation/image4.jpeg",
  image4: "/images/alula/railwaystation/image5.jpeg",
  image5: "/images/alula/railwaystation/image6.jpeg",
  image6: "/images/alula/railwaystation/image7.jpeg",
};
