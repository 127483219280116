import React, { useEffect } from "react";
import Banner from "./Banner";
import Card from "../Card";
import JeddahBanner from "./JeddahBanner";
import HeaderFooter from "../HeaderFooter";
import {
  JeddahPlacesHolySites,
  JeddahPlacesAttractions,
} from "../../../../placesdata";

const Jeddah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const sections = [
    { title: "Holy Sites", data: JeddahPlacesHolySites },
    { title: "Attractions", data: JeddahPlacesAttractions },
  ];
  return (
    <div>
      <HeaderFooter>
        <Banner />
        <JeddahBanner />
        <Card sections={sections} />
      </HeaderFooter>
    </div>
  );
};

export default Jeddah;
