import React, { useState } from 'react';
import HeaderComponent from "../../components/Header.js";
import Footer from "../../components/footer.js";
import Card from './Card';

const SavedListComponent = () => {
  const [showNextTripDropdown, setShowNextTripDropdown] = useState(false);
  const [showShareListInput, setShowShareListInput] = useState(false);
  const [showCreateListForm, setShowCreateListForm] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [lists, setLists] = useState([
    {
      name: 'My next trip',
      items: [
        {
          package_name: 'Sample Package 1',
          package_cost: 1000,
          start_date: '2024-08-01',
          end_date: '2024-08-10',
          hotel_info_detail: [
            { hotel_name: 'Hotel 1', hotel_city: 'Mecca' },
          ],
          company_detail: { company_name: 'Company 1' },
          huz_token: 'token1',
          rating_count: { average_stars: 4.5 }
        },
        {
          package_name: 'Sample Package 2',
          package_cost: 1500,
          start_date: '2024-09-01',
          end_date: '2024-09-10',
          hotel_info_detail: [
            { hotel_name: 'Hotel 2', hotel_city: 'Madina' },
          ],
          company_detail: { company_name: 'Company 2' },
          huz_token: 'token2',
          rating_count: { average_stars: 4.7 }
        }
      ]
    },
    {
      name: 'ht',
      items: [
        {
          package_name: 'Sample Package 3',
          package_cost: 2000,
          start_date: '2024-10-01',
          end_date: '2024-10-10',
          hotel_info_detail: [
            { hotel_name: 'Hotel 3', hotel_city: 'Mecca' },
          ],
          company_detail: { company_name: 'Company 3' },
          huz_token: 'token3',
          rating_count: { average_stars: 4.2 }
        },
        {
          package_name: 'Sample Package 4',
          package_cost: 2500,
          start_date: '2024-11-01',
          end_date: '2024-11-10',
          hotel_info_detail: [
            { hotel_name: 'Hotel 4', hotel_city: 'Madina' },
          ],
          company_detail: { company_name: 'Company 4' },
          huz_token: 'token4',
          rating_count: { average_stars: 4.8 }
        }
      ]
    },
  ]);
  const [selectedList, setSelectedList] = useState(null);

  const handleButtonClick = (action) => {
    if (action === 'My next trip') {
      setShowNextTripDropdown(!showNextTripDropdown);
      setShowShareListInput(false);
      setShowCreateListForm(false);
    } else if (action === 'Share the list') {
      setShowShareListInput(!showShareListInput);
      setShowNextTripDropdown(false);
      setShowCreateListForm(false);
    } else if (action === 'Create a list') {
      setShowCreateListForm(!showCreateListForm);
      setShowNextTripDropdown(false);
      setShowShareListInput(false);
    } else if (action === 'Start searching') {
      console.log('Redirecting to search page...');
    }
  };

  const handleCreateList = () => {
    if (newListName.trim()) {
      setLists([...lists, { name: newListName, items: [] }]);
      setNewListName('');
      setShowCreateListForm(false);
    }
  };

  const handleCopyLink = () => {
    const copyText = document.getElementById('shareLink');
    copyText.select();
    document.execCommand('copy');
    console.log('Link copied to clipboard');
  };

  const handleSelectList = (list) => {
    setSelectedList(list);
    setShowNextTripDropdown(false);
  };

  return (
    <section>
      <HeaderComponent />
      <div className="w-full min-h-screen flex flex-col items-center bg-gray-100 py-10">
        <div className="flex flex-wrap justify-center items-center mb-10 relative">
          <p className="mr-4 text-lg font-semibold">Saved</p>
          <div className="relative">
            <button
              className="border border-[#00936C] text-[#00936C] px-4 py-2 rounded-lg mr-2 mb-2 lg:mb-0"
              onClick={() => handleButtonClick('My next trip')}
            >
              My next trip
            </button>
            {showNextTripDropdown && (
              <div className="absolute left-0 mt-2 w-56 bg-white border rounded-lg shadow-lg z-10">
                <div className="p-4">
                  {lists.map((list, index) => (
                    <div key={index} onClick={() => handleSelectList(list)}>
                      <p className="cursor-pointer">{list.name} <span className="text-gray-500">({list.items.length})</span></p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              className="bg-[#00936C] text-white px-4 py-2 rounded-lg mr-2 mb-2 lg:mb-0"
              onClick={() => handleButtonClick('Share the list')}
            >
              Share the list
            </button>
            {showShareListInput && (
              <div className="absolute left-0 mt-2 w-56 bg-white border rounded-lg shadow-lg z-10">
                <div className="p-4">
                  <p>Copy the link to share this list:</p>
                  <input
                    type="text"
                    value="list.html?wl=SHg6fGs3HZdR0aeGnwp7zV8RwgY"
                    readOnly
                    id="shareLink"
                    className="w-full p-2 border rounded-lg mt-2"
                  />
                  <button
                    className="bg-[#00936C] text-white px-4 py-2 rounded-lg mt-2 w-full"
                    onClick={handleCopyLink}
                  >
                    Copy Link
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <button
              className="bg-[#00936C] text-white px-4 py-2 rounded-lg"
              onClick={() => handleButtonClick('Create a list')}
            >
              Create a list
            </button>
            {showCreateListForm && (
              <div className="absolute left-0 mt-2 w-56 bg-white border rounded-lg shadow-lg z-10">
                <div className="p-4">
                  <p>Create a list</p>
                  <input
                    type="text"
                    placeholder="Name your new list"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    className="w-full p-2 border rounded-lg mt-2"
                  />
                  <button
                    className="bg-[#00936C] text-white px-4 py-2 rounded-lg mt-2 w-full"
                    onClick={handleCreateList}
                  >
                    Create
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {selectedList && (
          <div className="w-3/4 ">
            <h3 className="text-xl font-semibold mb-4">{selectedList.name}</h3>
            <div className="flex flex-col space-y-4">
              {selectedList.items.map((item, index) => (
                <Card key={index} {...item} />
              ))}
            </div>
          </div>
        )}

        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mt-6 mb-4 text-center">
            Here are 3 simple steps to help you begin:
          </h2>
          <ol className="text-lg mb-6 text-center">
            <li>1. Search for a place to stay</li>
            <li>2. Tap the heart icon when you find a property you like</li>
            <li>3. You'll find all you've saved here</li>
          </ol>
          <button
            className="bg-[#00936C] text-white px-6 py-3 rounded-lg"
            onClick={() => handleButtonClick('Start searching')}
          >
            Start searching
          </button>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default SavedListComponent;
