import { useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../../../../../components/footer";
import { MuseumImages } from "../../../../../../placesdata";
import BlogHeader from "../../../../ArticlesPage/BlogHeader";

const MakkahMuseum = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return (
    <div className="text-[#4b465c] font-cairo">
      <BlogHeader />
      <div
        className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${MuseumImages.image3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

        <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
          <h1 className="font-kd font-bold text-4xl">Mecca Museum</h1>
        </div>
      </div>
      <div className=" bg-[#F2F2F2] mx-auto p-6 text-xl md:text-2xl font-bold text-center ">
        <h1 className="w-[90%] mx-auto">
          Al-Zaher Palace Museum in Makkah, Saudi Arabia, is a historical museum
          that exhibits the history of Makkah.
        </h1>
      </div>
      <div className="w-[85%] mx-auto py-10">
        <div>
          <h1 className="text-[25px] font-bold pb-1">A Glimpse Into History</h1>
          <div className="flex flex-col lg:flex-row gap-4 mt-4 ">
            {" "}
            <div className="lg:w-[55%] space-y-4 font-medium text-[15px]">
              <p>
                The Mecca Museum traces its origins to the early days of Islam
                when the city of Mecca served as the spiritual epicenter of the
                Muslim world.
              </p>
              <p>
                {" "}
                Originally established to preserve the relics and artifacts
                associated with the Islamic faith, the museum's roots can be
                traced back to the early Islamic caliphates, which sought to
                safeguard the rich cultural and religious heritage of the
                burgeoning Muslim community.
              </p>
              <p>
                Over the centuries, as Mecca continued to draw millions of
                pilgrims each year, the collection grew, incorporating
                invaluable manuscripts, historical documents, and everyday
                objects that illustrated the daily lives and spiritual practices
                of Muslims across different eras and regions.
              </p>
              <p className="lg:hidden xl:block">
                As Mecca continued to draw millions of pilgrims each year, the
                museum’s collection grew exponentially. The influx of visitors
                from diverse regions and backgrounds brought with them unique
                artifacts and manuscripts that enriched the museum’s holdings.
                These contributions helped to create a comprehensive repository
                of Islamic heritage, encompassing a wide range of historical
                periods and geographic regions.
              </p>
            </div>
            <div className="lg:w-[45%]">
              <img
                src={MuseumImages.image5}
                alt="Jabal al-Nour and the Cave of Hira"
                className="w-full rounded-lg"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden">
            As Mecca continued to draw millions of pilgrims each year, the
            museum’s collection grew exponentially. The influx of visitors from
            diverse regions and backgrounds brought with them unique artifacts
            and manuscripts that enriched the museum’s holdings. These
            contributions helped to create a comprehensive repository of Islamic
            heritage, encompassing a wide range of historical periods and
            geographic regions.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="text-[25px] font-bold pb-1">Exploring the Exhibits</h1>
          <div className="pt-3">
            <p className=" font-medium text-[15px]">
              As visitors step through the gates of the Mecca Museum, they are
              greeted by a breathtaking array of exhibits that span centuries of
              Islamic history. From ancient manuscripts and Quranic scriptures
              to intricately crafted calligraphy and artifacts of religious
              significance, each display offers a window into the rich cultural
              heritage of Islam. One of the highlights of the museum is its
              collection of artifacts related to the Hajj pilgrimage – the
              sacred journey that brings millions of Muslims to Mecca each year.
              Visitors can marvel at replicas of the Kaaba, the sacred
              cube-shaped structure at the center of the Grand Mosque, as well
              as artifacts from the historic sites associated with the
              pilgrimage.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 pt-10">
              <div className="bg-gray-100 p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  A Spiritual Journey
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  Beyond its historical significance, the Mecca Museum serves as
                  a place of spiritual reflection and contemplation. As visitors
                  wander through its halls, they are enveloped in an atmosphere
                  of reverence and awe, surrounded by reminders of the divine
                  presence that permeates the city of Mecca.
                  <br />
                  For many pilgrims, a visit to the Mecca Museum is an integral
                  part of their spiritual journey. Here, amidst the artifacts
                  and relics of the Islamic faith, they find solace and
                  inspiration, reaffirming their connection to Allah and the
                  teachings of Islam.
                </p>
              </div>
              <div className="border-y-2 border-[#E6F4F0] p-6">
                <span className="font-bold block text-[25px]">
                  {" "}
                  Preserving Tradition for Future Generations
                </span>
                <p className=" font-normal text-[15px] pt-2">
                  As custodians of Islamic heritage, the Mecca Museum plays a
                  vital role in preserving and safeguarding the treasures of the
                  past for future generations. Through its exhibits and
                  educational programs, the museum seeks to promote a deeper
                  understanding and appreciation of Islamic culture and
                  civilization. From schoolchildren to scholars, visitors of all
                  ages and backgrounds are invited to explore the wonders of the
                  Mecca Museum and discover the enduring legacy of Islam.
                  Whether they come as tourists or pilgrims, each visitor leaves
                  with a deeper appreciation of the rich history and spiritual
                  significance of Mecca – a city that continues to inspire and
                  captivate the hearts of believers around the world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-bold text-[24px] md:text-[25px] pb-1 pt-10 ">
          Meaning of Mecca Museum
        </h3>
        <div className="lg:flex md:block block gap-5 pt-5">
          <div className="block lg:w-[55%]">
            <p className=" font-medium text-[15px]">
              The Mecca Museum embodies the essence of Islamic heritage, drawing
              parallels between the sacred city of Mecca and its profound
              significance in the Muslim world. Through its exhibits and
              educational initiatives, it serves as a beacon of cultural
              preservation and enlightenment, inviting visitors to delve into
              the rich tapestry of Islamic history and spirituality.
            </p>
            <div className="space-y-1.5 py-2">
              <h4 className="font-medium text-[25px]">Points of reflection</h4>
              <div className="space-y-1.5 py-2">
                <p className="font-medium text-[15px]">
                  This is a place where the pilgrim makes a firm resolution that
                  from this day forward all they will do will be for the sake of
                  Allah and to acquire benefit in the Akhirah. This was the
                  emphasis of the Prophet (ﷺ) at this very place. Our mind and
                  hearts should ponder and reflect: Is our concern the Akhirah
                  or the Dunya?
                </p>
              </div>
            </div>
          </div>
          <img
            src={MuseumImages.image3}
            alt="Cave of Hira"
            className="w-full lg:w-[45%] rounded-lg mt-10 lg:mt-0"
          />
        </div>

        <div className="slider-container relative py-20">
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            slidesPerView={1} // Default for mobile
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            loop={true}
            navigation={false} // Disable built-in navigation
            modules={[Navigation]}
          >
            <SwiperSlide>
              <img
                src={MuseumImages.image4}
                alt="1"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuseumImages.image2}
                alt="2"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuseumImages.image3}
                alt="3"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={MuseumImages.image4}
                alt="4"
                className="rounded-lg h-[300px] w-full"
              />
            </SwiperSlide>
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
          >
            <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
          </button>
        </div>
        {/* <FeaturedBlogs /> */}
      </div>
      <Footer />
    </div>
  );
};

export default MakkahMuseum;
