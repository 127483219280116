import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoBookingsCard from "../../../components/NoBookings";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/loader";
import { fetchBookingsByUser } from "../../../utility/Api";
import Footer from "../../../components/footer";
import { NumericFormat } from "react-number-format";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import { CurrencyContext } from "../../../utility/CurrencyContext";

const PackageCard = React.forwardRef(({ pkg }, ref) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);
  const { state: locationState } = useLocation();
  const {
    huz_token,
    partner_session_token,
    bookingFormData: initialFormData,
  } = locationState || {};
  const handleViewDetails = () => {
    const bookingDetails = {
      totalAdults: pkg.adults,
      totalChild: pkg.child,
      totalInfants: pkg.infants,
      roomType: pkg.room_type,
      totalCost: pkg.total_price,
      startDate: pkg.start_date,
      endDate: pkg.end_date,
      totalLengthOfStay:
        parseInt(pkg.mecca_nights) + parseInt(pkg.madinah_nights),
      bookingNumber: pkg.booking_number,
      bookingResponse: pkg,
    };

    if (pkg.booking_status === "Passport_Validation") {
      let targetUrl = "/error";
      if (pkg.payment_type === "Bank") {
        targetUrl = "/bankingDetail";
      } else if (pkg.payment_type === "Voucher") {
        targetUrl = "/voucherDetail";
      }

      navigate(targetUrl, {
        state: bookingDetails,
      });
    } else if (pkg.booking_status === "Initialize") {
      let targetUrl = "/passportDetails";

      navigate(targetUrl, {
        state: {
          huz_token,
          partner_session_token,
          totalAdults: pkg.adults,
          totalChild: pkg.child,
          totalInfants: pkg.infants,
          roomType: pkg.room_type,
          totalCost: pkg.total_price,
          bookingResponse: pkg, // Pass the full booking response
          booking_number: pkg.booking_number, // Pass booking number
          user_session_token: pkg.user_session_token, // Pass session token
          payment_type: pkg.payment_type, // Adding the payment method state
          returnDate: pkg.end_date, // Pass the return date (end date of the package)
        },
      });
    } else {
      navigate(`/booking_details/${pkg.booking_number}`, {
        state: {
          ...bookingDetails,
          sessionToken: pkg.user_session_token,
        },
      });
    }
  };

  const convertedCost = exchangeRates[selectedCurrency]
    ? (pkg.total_price / exchangeRates["PKR"]) * exchangeRates[selectedCurrency]
    : pkg.total_price;

  const statusStyle =
    pkg.booking_status === "Objection" ? { color: "#CB5353" } : {};

  return (
    <div>
      <div className="w-[90%] text-[#4b465c] font-kd mt-6 mx-auto p-6 bg-white md:shadow-custom-box rounded-b-none rounded-lg border-b-8 border-b-[#00936C] border-t-[1px] border-x-[1px] border-x-[#DCDCDC] border-t-[#DCDCDC] overflow-hidden md:my-10 relative">
        <div className="hidden md:flex">
          <div className="xl:w-[30%] md:w-[35%] m-3 mt-0 border-r-[5px]">
            <h3 className="xl:text-xl md:text-lg opacity-90 font-semibold text-[#4B465C]">
              {pkg.package_name}
            </h3>
            <p className="xl:text-[15px] md:text-[13px] font-bold opacity-80 text-[#4b465c]">
              <NumericFormat
                value={convertedCost}
                displayType={"text"}
                thousandSeparator
                prefix={`${selectedCurrency} `}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            <div className="xl:mt-8 lg:mt-6 md:mt-3 space-y-1 font-kd text-[#4b465c] text-[14px] opacity-80 xl:w-[80%]">
              <p>
                Mecca Nights {pkg.mecca_nights} <span> &nbsp; - &nbsp;</span>{" "}
                Madinah Nights {pkg.madinah_nights}{" "}
              </p>
              <p>
                {pkg.is_visa_included === "True" && "Visa"}
                {pkg.is_visa_included === "True" && "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_insurance_included === "True" && "Insurance"}
                {pkg.is_insurance_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_airport_reception_included === "True" &&
                  "Airport Reception"}{" "}
                {pkg.is_airport_reception_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_breakfast_included === "True" && "Breakfast"}
                {pkg.is_breakfast_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_lunch_included === "True" && "Lunch"}
                {pkg.is_lunch_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_dinner_included === "True" && "Dinner"}
                {pkg.is_dinner_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
              </p>
            </div>
          </div>

          <div className="w-[70%] p-3 pt-0 lg:pl-6 xl:pl-10">
            <div className="flex md:justify-between lg:gap-x-6 xl:gap-x-10 items-center mb-4 md:mb-2 lg:mb-4">
              <div>
                <span className="block text-[13px] opacity-50 font-medium">
                  Adults & Child
                </span>
                <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                  {pkg.adults} - {pkg.child}
                </p>
              </div>
              <div>
                <span className="block opacity-50 text-[13px] font-medium">
                  Total Cost
                </span>
                <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                  {" "}
                  <NumericFormat
                    value={convertedCost}
                    displayType={"text"}
                    thousandSeparator
                    prefix={`${selectedCurrency} `}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />{" "}
                </p>
              </div>
              <div className="block md:hidden lg:block">
                <span className="block text-[13px] opacity-50 font-medium">
                  Start date & End date
                </span>
                <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                  {new Date(pkg.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  to{" "}
                  {new Date(pkg.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
              </div>
              <div
                className="block bg-green-50 border-[1px] border-[#00936c] lg:mt-5 rounded-[3px] px-3 text-[13px] font-semibold text-[#00936C]"
                style={statusStyle}
              >
                {pkg.booking_status}
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="hidden md:block lg:hidden">
                <span className="block text-[13px] opacity-50 font-medium">
                  Start date & End date
                </span>
                <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                  {new Date(pkg.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  to{" "}
                  {new Date(pkg.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
              </div>
              <button
                onClick={handleViewDetails}
                className="hidden md:block lg:hidden mt-2 border-[1px] border-[#00936c] font-semibold text-[13px] px-2 bg-green-50 text-[#00936C] text-sm rounded hover:bg-green-100"
              >
                View Details
              </button>
            </div>

            <div className="mt-4">
              <span className="block opacity-50 font-medium text-[13px]">
                Special Request
              </span>
              <p className="opacity-80 font-semibold lg:text-[13px] xl:text-[15px]">
                {pkg.special_request}
              </p>
              <button
                onClick={handleViewDetails}
                className="block md:hidden lg:block mt-2 border-[1px] border-[#00936c] font-semibold text-[13px] px-2 bg-green-50 text-[#00936C] text-sm rounded hover:bg-green-100"
              >
                View Details
              </button>
            </div>
          </div>
        </div>

        <div className="block md:hidden ">
          <div className="">
            <div className="">
              <div className="flex justify-between items-center">
                <h3 className="text-lg opacity-90 font-semibold text-[#4B465C]">
                  {pkg.package_name}
                </h3>
                <div
                  className="block bg-green-50 border-[1px] border-[#00936c] rounded-[3px] px-3 text-[13px] font-semibold text-[#00936C]"
                  style={statusStyle}
                >
                  {pkg.booking_status}
                </div>
              </div>
              <p className="text-[13px] font-bold opacity-80 text-[#4b465c]">
                <NumericFormat
                  value={convertedCost}
                  displayType={"text"}
                  thousandSeparator
                  prefix={`${selectedCurrency} `}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="">
              <div className="flex justify-between items-center my-4 ">
                <div className="block md:hidden lg:block">
                  <span className="block text-[13px] opacity-50 font-medium">
                    Start date & End date
                  </span>
                  <p className="opacity-80 font-semibold text-[13px] ">
                    {new Date(pkg.start_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    to{" "}
                    {new Date(pkg.end_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
                <div>
                  <span className="block text-[13px] opacity-50 font-medium">
                    Adults & Child
                  </span>
                  <p className="opacity-80 font-semibold text-[13px] ">
                    {pkg.adults} - {pkg.child}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <button
                  onClick={handleViewDetails}
                  className="block md:hidden lg:block mt-2 border-[1px] border-[#00936c] font-semibold text-[13px] px-2 bg-green-50 text-[#00936C] text-sm rounded hover:bg-green-100"
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const PackagesList = () => {
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = JSON.parse(
          localStorage.getItem("Login-User-Profile")
        );
        if (userProfile && userProfile.session_token) {
          const sessionToken = userProfile.session_token;
          const data = await fetchBookingsByUser(sessionToken);
          setPackages(data);
        } else {
          throw new Error("No session token found");
        }
      } catch (err) {
        if (err.message === "Booking detail not found") {
          setPackages([]);
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <BookingNavbar />
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (packages.length === 0) {
    return (
      <div>
        <BookingNavbar /> <NoBookingsCard /> <hr className="w-full" />
        <Footer />
      </div>
    );
  }

  const totalPages = Math.ceil(packages.length / itemsPerPage);

  const currentPackages = packages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="packages-list">
      <BookingNavbar />
      <div>
        <img src="/images/Booking/BannerImage.png" alt="" className="w-full" />
      </div>
      <div className="pb-6">
        <h1 className="xl:text-[28px] lg:text-[24px] font-bold text-[#484848] w-[90%] mx-auto pt-14">
          My Bookings{" "}
        </h1>
        {currentPackages.map((pkg) => (
          <PackageCard key={pkg.booking_id} pkg={pkg} />
        ))}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <Footer />
    </div>
  );
};

export default PackagesList;
