import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { CurrencyContext } from "../../../../utility/CurrencyContext";

const PackageCard = React.forwardRef(
  (
    {
      package_name,
      package_cost,
      start_date,
      end_date,
      hotel_info_detail,
      company_detail,
      pkg,
      rating_count,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);

    const handleClick = () => {
      navigate(`/tranport/package/deal`);
    };
    console.log("pkg", pkg);
    const isPerHour =
      pkg.common_1.includes("Per") || pkg.common_2.includes("Per");
    const convertedCost = exchangeRates[selectedCurrency]
      ? (pkg.cost / exchangeRates["PKR"]) * exchangeRates[selectedCurrency]
      : pkg.cost;
    return (
      <div className="">
        <div className="">
          <div className="w-full bg-white shadow-md rounded-lg overflow-hidden border border-gray-200 p-4 flex flex-col md:flex-row">
            <div className="md:w-[25%] w-full mb-3 md:mb-0">
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${pkg.vehicle_photos}`}
                alt={`${pkg.name_and_model}`}
                className="w-full h-[200px] md:h-[225px] object-cover rounded-lg"
              />
            </div>
            <div className="w-full md:pl-4">
              <div className="flex items-center justify-between">
                {isPerHour ? (
                  <span className="bg-[#e6f4f0] text-[#00936c] text-[11px] font-semibold mr-2 px-2.5 py-0.5 rounded">
                    {pkg.common_2}
                  </span>
                ) : (
                  <span className="bg-[#e6f4f0] text-[#00936c] text-[11px] font-semibold mr-2 px-2.5 py-0.5 rounded">
                    Fix Route
                  </span>
                )}
              </div>
              <div className="flex gap-1 mt-4">
                <h3 className="text-[18px] font-bold text-gray-900 font-cairo ">
                  {pkg.name_and_model}
                </h3>
              </div>
              <div className="mt-2 flex flex-wrap gap-x-9 md:gap-y-1 gap-y-2.5 md:w-[300px] font-kd">
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="size-[14px] -mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16.5 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0M18 6A6 6 0 1 0 6 6a6 6 0 0 0 12 0M3 23.25a9 9 0 1 1 18 0 .75.75 0 0 0 1.5 0c0-5.799-4.701-10.5-10.5-10.5S1.5 17.451 1.5 23.25a.75.75 0 0 0 1.5 0"></path>
                    </svg>
                  </span>
                  {pkg.sitting_capacity} seats
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.25 14.25v-4.5l-1.374.416 3 4.5c.412.617 1.374.326 1.374-.416v-4.5a.75.75 0 0 0-1.5 0v4.5l1.374-.416-3-4.5c-.412-.617-1.374-.326-1.374.416v4.5a.75.75 0 0 0 1.5 0m3 6a3.75 3.75 0 0 0-3.75-3.75.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75a3.75 3.75 0 0 0 3.75-3.75m-1.5 0a2.25 2.25 0 0 1-2.25 2.25l.75.75v-6l-.75.75a2.25 2.25 0 0 1 2.25 2.25M18.5 4.5H20A2.25 2.25 0 0 0 20 0h-1.5a.75.75 0 0 0-.75.75v6a.75.75 0 0 0 1.5 0v-6l-.75.75H20A.75.75 0 0 1 20 3h-1.5a.75.75 0 0 0 0 1.5M4.25 6.75v4.5A2.25 2.25 0 0 0 6.5 13.5H8a.75.75 0 0 1 .75.75v4.5A2.25 2.25 0 0 0 11 21h3a.75.75 0 0 0 0-1.5h-3a.75.75 0 0 1-.75-.75v-4.5A2.25 2.25 0 0 0 8 12H6.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-1.5 0m3-3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0m1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0"></path>
                    </svg>
                  </span>
                  Automatic
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M22.5 14.249v4.5a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25v-9a2.25 2.25 0 0 1 2.25-2.25h16.5a2.25 2.25 0 0 1 2.25 2.25zm1.5 0v-4.5a3.75 3.75 0 0 0-3.75-3.75H3.75A3.75 3.75 0 0 0 0 9.749v9a3.75 3.75 0 0 0 3.75 3.75h16.5a3.75 3.75 0 0 0 3.75-3.75zm-18-7.5v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m10.5 0v15a.75.75 0 0 0 1.5 0v-15a.75.75 0 0 0-1.5 0m0 0v-3a2.25 2.25 0 0 0-2.25-2.25h-4.5a2.25 2.25 0 0 0-2.25 2.25v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v3a.75.75 0 0 0 1.5 0"></path>
                    </svg>
                  </span>
                  1 Large bag
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15 14.75H9a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5m.75 3.25a.76.76 0 0 0-.75-.75H9a.75.75 0 0 0 0 1.5h6a.76.76 0 0 0 .75-.75m3-6.5v9a2.25 2.25 0 0 1-2.25 2.25h-.75v.5a.75.75 0 0 1-1.5 0v-.5h-4.5v.5a.75.75 0 0 1-1.5 0v-.5H7.5a2.25 2.25 0 0 1-2.25-2.25v-9A2.25 2.25 0 0 1 7.5 9.25h1.75v-8C9.25.56 9.81 0 10.5 0h3c.69 0 1.25.56 1.25 1.25v8h1.75a2.25 2.25 0 0 1 2.25 2.25m-8-2.25h2.5V1.5h-2.5zm6.5 2.25a.76.76 0 0 0-.75-.75h-9a.76.76 0 0 0-.75.75v9a.76.76 0 0 0 .75.75h9a.76.76 0 0 0 .75-.75z"></path>
                    </svg>
                  </span>
                  1 Small bag
                </div>
                <div className="flex gap-1 items-center text-[14px] text-gray-600">
                  <span className="-mt-1 size-[14px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M9.75 15.292v-.285a2.25 2.25 0 0 1 4.5 0v.285a.75.75 0 0 0 1.5 0v-.285a3.75 3.75 0 1 0-7.5 0v.285a.75.75 0 0 0 1.5 0M13.54 5.02l-2.25 6.75a.75.75 0 0 0 1.424.474l2.25-6.75a.75.75 0 1 0-1.424-.474M6.377 6.757a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5m12.75 3.75a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5m-1.496-3.75a1.125 1.125 0 1 0 1.119 1.131v-.006c0-.621-.504-1.125-1.125-1.125a.75.75 0 0 0 0 1.5.375.375 0 0 1-.375-.375V7.88a.375.375 0 1 1 .373.377.75.75 0 1 0 .008-1.5m-8.254-3a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5M21.88 17.541a16.5 16.5 0 0 0-19.76 0 .75.75 0 1 0 .898 1.202 15 15 0 0 1 17.964 0 .75.75 0 1 0 .898-1.202m.62-5.534c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5m1.5 0c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12m-19.123-1.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5"></path>
                    </svg>
                  </span>
                  600 km per rental
                </div>
              </div>
              <div className="md:flex md:items-center justify-between mt-1.5 font-kd">
                <div className="text-sm text-gray-600">
                  <a href="#" className="text-[#00936c] hover:underline flex">
                    {pkg.common_1}{" "}
                    {pkg.common_2 === "Per Hour" ? (
                      ""
                    ) : (
                      <p>&nbsp; to {pkg.common_2}</p>
                    )}
                  </a>{" "}
                  <span className="block text-xs">7.9 km from centre</span>
                </div>
                <div className="block items-start w-full md:w-auto">
                  <div className="md:-mt-10 mmd:ml-9 mml-24 w-full md:w-auto">
                    <div className="text-[11px] font-normal text-gray-900 mml-[45px] text-right">
                      Price for 3 days:
                    </div>
                    <div className="text-[21px] font-semibold text-gray-900 text-right">
                      <NumericFormat
                        className="font-bold text-base"
                        value={convertedCost}
                        displayType={"text"}
                        thousandSeparator
                        prefix={`${selectedCurrency} `}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  </div>
                  <div className="mt-3 text-center hidden md:block w-full">
                    <button
                      className="bg-[#00936cce] hover:bg-[#00936c] text-white text-sm px-12 text-center py-2 rounded  w-full md:w-auto"
                      onClick={handleClick}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PackageCard;
