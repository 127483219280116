import React from "react";
import { FaFilePdf, FaFileWord, FaFileImage } from "react-icons/fa";

const getFileIcon = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return <FaFilePdf className="text-red-500" />;
    case "doc":
    case "docx":
      return <FaFileWord className="text-blue-500" />;
    case "png":
    case "jpg":
    case "jpeg":
      return <FaFileImage className="text-green-500" />;
    default:
      return <FaFileImage className="text-gray-500" />;
  }
};

const url = process.env.REACT_APP_API_BASE_URL;
const openDocument = (documentLink) => {
  window.open(`${url}${documentLink}`, "_blank");
};

const VisaDetails = ({ documents = [] }) => (
  <div className="space-y-2 p-2">
    <div className="flex justify-between items-center">
      <h2 className="text-base font-medium text-gray-500">
        Shared Visa Detail
      </h2>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-2 bg-gray-50 rounded">
      {documents.map((doc) => (
        <div
          key={doc.document_id}
          className="flex justify-between items-center p-3 bg-white border border-gray-200 rounded shadow-sm"
        >
          <div className="flex items-center space-x-2">
            {getFileIcon(doc.document_link)}
            <p
              className="text-sm text-gray-700 cursor-pointer"
              onClick={() => openDocument(doc.document_link)}
            >
              {doc.document_link.split("/").pop()}
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default VisaDetails;
