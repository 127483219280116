// src/components/Loader.js
import React from "react";
import "./loader.css"; // Create this file for custom styling

const LoaderForPages = () => {
  return (
    <div id="loaderPages">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderForPages;
