import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../../homepage/Header/header";
import PackagesPage from "./PackagesPage";
import Footer from "../../../../components/footer";
import { getPackagesForHome, fetchPackages } from "../../../../utility/Api";
import LoaderForPages from "../../../../components/loaderForPages";

const Umrah = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackagesData = async () => {
      setIsLoading(true);
  
      try {
        // Check if navigated from homepage
        if (location.state?.fromHomepage) {
          // If sessionStorage does not have "refreshed", call fetchPackages
          if (!sessionStorage.getItem("refreshed")) {
            console.log("Fetching filtered packages...");
            const { start_date, flight_from, page, page_size } = location.state;
  
            // Fetch filtered packages
            const result = await fetchPackages({
              package_type: "Umrah",
              start_date,
              flight_from,
              page,
              page_size,
            });
  
            console.log("Filtered Packages Result:", result);
            setPackages(result.results);
  
            // Set refreshed flag
            sessionStorage.setItem("refreshed", "true");
          } else {
            console.log("Fetching all packages (reload detected)...");
            const result = await getPackagesForHome();
            setPackages(result.data.results);
          }
        } else {
          // If not from homepage, fetch all packages
          console.log("Fetching all packages (direct visit)...");
          const result = await getPackagesForHome();
          setPackages(result.data.results);
        }
      } catch (err) {
        console.error("Error fetching packages:", err);
        setError("An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchPackagesData();
  }, [location.state]);
  


  return (
    <div>
      <HeaderComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <main>
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <LoaderForPages />
          </div>
        ) : (
          <PackagesPage
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            packages={packages}
          />
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Umrah;
