import React from "react";

const WeatherCard = ({ date, time, temperature, high, low }) => (
  <div className="bg-white bg-opacity-75 p-4 rounded-lg shadow-lg text-gray-800 flex flex-col items-center">
    <div className="flex justify-between w-full mb-4">
      <div className="text-sm text-gray-600 flex flex-col items-end">
        <span>{date}</span>
        <span>{time}</span>
      </div>
    </div>
    <div className="text-5xl font-bold mb-2">{temperature}°C</div>
    <div className="text-lg">
      <span className="mr-2">{high}°</span> / <span>{low}°</span>
    </div>
  </div>
);

export default WeatherCard;
