import React, { useEffect } from "react";

const Page = () => {
  // useEffect(() => {
  //   // Define the function inside useEffect
  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };
  //   // Call the function
  //   scrollToTop();
  // }, []);

  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Discover the Enchanting Oasis of Bir Ghars: A Desert Jewel Awaiting
          Your Adventure
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Ancient Origins & Historical Significance of Bir Ghars
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-10">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] leading-[25px]">
                Ghars Well is a significant historical and cultural site, deeply
                embedded in the lore and traditions of the region it resides in.
                This article explores the multifaceted aspects of Ghars Well,
                including its historical significance, cultural importance,
                geographical context, and the modern-day implications of this
                ancient water source. Ghars Well, also known as Ain Ghars, is a
                site of immense historical importance. Its origins trace back to
                ancient times, with references found in various historical texts
                and oral traditions. The well has served as a critical water
                source for numerous civilizations that have inhabited or
                traversed the region. The exact date of Ghars Well's
                construction is shrouded in mystery, but archaeological evidence
                suggests that it dates back several millennia.
              </p>
              <p className="text-[#484848] mb-4 font-cairo lg:hidden xl:block font-medium text-[15px] leading-[25px]">
                The well is believed to have been established by early settlers
                who recognized the necessity of a reliable water source in the
                arid landscape. Over the centuries, it has witnessed the rise
                and fall of various empires and has been a silent witness to the
                passage of time. Several historical texts mention Ghars Well,
                indicating its longstanding presence and importance. For
                instance, early travelers and explorers documented the well in
                their writings, often describing it as a crucial stop for
                caravans and traders. These accounts highlight the well's role
                in sustaining trade routes and facilitating cultural exchanges
                between different regions.
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                src="/images/Madinah/birghars/birghars.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
          <p className="text-[#484848] mb-4 font-cairo hidden lg:flex xl:hidden font-medium text-[15px] leading-[25px]">
            The well is believed to have been established by early settlers who
            recognized the necessity of a reliable water source in the arid
            landscape. Over the centuries, it has witnessed the rise and fall of
            various empires and has been a silent witness to the passage of
            time. Several historical texts mention Ghars Well, indicating its
            longstanding presence and importance. For instance, early travelers
            and explorers documented the well in their writings, often
            describing it as a crucial stop for caravans and traders. These
            accounts highlight the well's role in sustaining trade routes and
            facilitating cultural exchanges between different regions.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Religious Significance Bir Ghars
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
              The al-Ghars Well, also known as Bir al-Ghars ( بئر غرس), holds
              significant historical importance in the city of Medina, Saudi
              Arabia, as it is believed to have connections to the life of
              Prophet Muhammad. Situated in the Bat'haan valley in the al-Awali
              district, approximately four kilometers south of Masjid Nabawi,
              and about 1,200 meters east of Masjid al-Jummah and Masjid
              al-Quba, this ancient watering hole has been a site of reverence
              for centuries. According to tradition, Bilal Ibn Rabah used to
              fetch water from this well for Prophet Muhammad. It is said that
              the well was originally dug by Malik bin al-Nahhat, the
              grandfather of Sa'ad bin Khaythamah bin al-Haris, who later owned
              it when he hosted the Prophet during his Hijrah journey from Mecca
              to Medina. Ibn Najjar notes that the well was located about half a
              mile north of Masjid al-Quba and had fallen into disrepair due to
              flooding, eventually becoming filled with stagnant green water.
              However, in the year 1300 CE, it was briefly restored before being
              abandoned again. In the 15th century, during the time of Samhudi,
              the well was bought by an influential individual who restored it
              and constructed a garden and a small mosque around it. A staircase
              was also built to facilitate access to the water during periods of
              low levels. The name "Ghars" is believed to be derived from the
              term "Gharas al-Shajar," meaning a planted tree or sapling.
            </p>
          </div>
        </div>
        <div className="mt-4 gap-5 lg:mt-0">
          <img
            src="/images/Madinah/birghars/birghars2.jpg"
            alt="Wadi-e-Jinn"
            className="rounded-lg shadow-lg w-full"
          />
        </div>
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Bir Ghars in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Bir Ghars holds profound religious importance in Islam. It is
              mentioned in various hadiths (traditions of the Prophet Muhammad),
              underscoring its spiritual value. The Prophet Muhammad is known to
              have praised the purity of the water from Bir Ghars and instructed
              that his ablution water after his death should be taken from this
              well. This instruction is recorded in several authentic Islamic
              texts, highlighting the well's sanctity and the high regard in
              which it was held by the Prophet himself. As such, Bir Ghars is
              not merely a historical site but a place imbued with deep
              spiritual resonance, serving as a testament to the life and
              practices of the Prophet Muhammad.
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-4">
            How to Explore Bir Ghars
          </h2>
          <ul className="list-none space-y-2 text-[#484848] font-cairo font-medium text-[17px]">
            <li>
              - Visit the Well: Start your visit by seeing the well itself, a
              historic site with deep religious significance.
            </li>
            <li>
              - Learn the History: Read up on the history of Bir Ghars, its
              connection to Prophet Muhammad, and its role in Islamic
              traditions.
            </li>
            <li>
              - Guided Tours: Consider joining a guided tour to get in-depth
              knowledge about the site from experienced guides.
            </li>
            <li>
              - Photography: Capture the beauty and historic essence of Bir
              Ghars with your camera.
            </li>
            <li>
              - Reflect and Pray: Use the serene environment of Bir Ghars for
              personal reflection and prayer.
            </li>
            <li>
              - Visit Nearby Sites: Explore other significant sites in the area
              such as Masjid Quba and Masjid Nabawi.
            </li>
            <li>
              - Stay Hydrated: Make sure to carry enough water, especially if
              visiting during the hot months.
            </li>
            <li>
              - Respect the Site: Maintain the sanctity of the site by
              respecting local customs and regulations.
            </li>
            <li>
              - Read Historical Plaques: Take time to read the plaques and
              markers that provide information about the site's significance.
            </li>
            <li>
              - Best Time to Visit: Plan your visit during cooler parts of the
              day or during the milder seasons to avoid extreme heat.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Page;
