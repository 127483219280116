import React from "react";

const AlulaPlaces = () => (
  <div className="w-[85%] mx-auto py-5 pt-10">
    <h1 className="text-cairo font-semibold md:text-[25px] text-[30px] text-[#484848] py-2.5">
      Alula Holy Sites
    </h1>
    <p className="text-[16px] text-[#484848] text-left leading-[20px] font-kd">
      Nestled in the heart of the Arabian Peninsula, AlUla City in Saudi Arabia
      is a breathtaking blend of ancient heritage and natural splendor. This
      extraordinary destination boasts stunning landscapes of sandstone cliffs
      and lush oases, alongside archaeological marvels like the Nabatean tombs
      of Hegra, Saudi Arabia's first UNESCO World Heritage Site. AlUla is a
      gateway to the past, where visitors can explore the remnants of ancient
      civilizations while enjoying the serene beauty of its desert scenery.
      Whether you're an adventure seeker, a history enthusiast, or a lover of
      nature, AlUla promises an unforgettable journey through time and culture.
    </p>
  </div>
);

export default AlulaPlaces;
