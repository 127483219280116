// PackagesPage.js
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import LoaderForPages from "../../../../components/loaderForPages";
import { getTransportPackages } from "../../../../utility/Api";
import PackageSidebar from "./Filter";
import PackageCard from "./PackageCard";
import SortDropdown from "./SortDropdown";

const PackagesPage = ({ isModalOpen, setIsModalOpen }) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const result = await getTransportPackages();
        if (result.error) {
          setError(result.error);
        } else if (result.data && result.data.results) {
          setPackages(result.data.results);
        } else {
          setError("Unexpected API response format.");
        }
      } catch (error) {
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);
  const [filters, setFilters] = useState({
    nameAndModel: "",
    packageType: "",
    costRange: [0, 500000],
    availability: "",
    fromMecca: "",
    fromMadina: "",
  });

  // Handle changes in the filters
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  // Handle slider change
  const handleSliderChange = (value) => {
    setFilters((prev) => ({ ...prev, costRange: value }));
  };

  // Apply filters to package data
  const filteredPackages = packages.filter((pkg) => {
    const nameAndModel =
      filters.nameAndModel === "" ||
      pkg.name_and_model
        .toLowerCase()
        .includes(filters.nameAndModel.toLowerCase());
    const matchesType =
      filters.packageType === "" ||
      pkg.package_type.includes(filters.packageType);
    // const sittingCapacity =
    //   filters.sittingCapacity === "" ||
    //   pkg.sitting_capacity.includes(filters.sittingCapacity);
    const matchesMinCost = pkg.cost >= filters.costRange[0];
    const matchesMaxCost = pkg.cost <= filters.costRange[1];
    const availability = !filters.availability || pkg.availability;
    const fromMecca =
      filters.fromMecca === "" || pkg.common_1 === filters.fromMeeca;
    const fromMadina =
      filters.fromMadina === "" || pkg.common_2 === filters.fromMadina;
    return (
      nameAndModel &&
      matchesType &&
      // sittingCapacity &&
      matchesMinCost &&
      matchesMaxCost &&
      availability &&
      fromMecca &&
      fromMadina
    );
  });
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoaderForPages />
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-4 mx-auto w-[85%] py-10">
          {/* Filter Button for Mobile */}
          <button
            className="lg:hidden bg-[#00936c] text-white py-2 px-4 rounded-lg mb-4"
            onClick={() => setIsModalOpen(true)}
          >
            Filter
          </button>

          {/* Filter Card for Desktop Only */}
          <div className="hidden lg:block">
            {renderFilters(handleFilterChange, handleSliderChange, filters)}
          </div>

          {/* Packages Display */}
          <div className="block w-full ">
            <div className="mb-5">
              <SortDropdown />
            </div>
            <div className="flex-grow grid-cols-1 space-y-6">
              {filteredPackages.length > 0 ? (
                <div className="flex-grow grid-cols-1 space-y-6">
                  {filteredPackages.map((pkg) => (
                    <PackageCard key={pkg.huz_token} pkg={pkg} />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-[70vh] text-center text-[#4b465c] md:text-[24px] xl:text-[32px] font-[600] font-kd">
                  <img
                    src="/images/error.svg"
                    alt="No Data"
                    className="w-20 lg:w-28 xl:w-32"
                  />
                  <p>There is no data yet, please try again later.</p>
                </div>
              )}
            </div>
          </div>

          {/* Modal for Mobile Filter */}
          {isModalOpen && (
            <div className="fixed  inset-0 bg-black bg-opacity-50 flex lg:hidden justify-center items-center z-50">
              <div className="bg-white w-[90%] max-w-md max-h-screen overflow-y-auto rounded-lg relative">
                <button
                  className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                  onClick={() => setIsModalOpen(false)}
                >
                  <RxCross1 />
                </button>
                <div className="mt-3">
                  {renderFilters(
                    handleFilterChange,
                    handleSliderChange,
                    filters
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Function to render filters UI
const renderFilters = () => {
  // const renderFilters = (handleFilterChange, handleSliderChange, filters) => {
  return (
    <>
      <div className="xl:w-[354px] lg:w-[354px] w-full">
        <PackageSidebar />
      </div>

      {/* Package Name
      <div className="mb-4 ">
        <label className="block mb-2 font-semibold text-sm">
          Transport Name:
        </label>
        <input
          type="text"
          placeholder="Search"
          value={filters.nameAndModel}
          onChange={(e) => handleFilterChange("nameAndModel", e.target.value)}
          className="w-full p-2 text-sm border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4 ">
        <label className="block mb-2 font-semibold text-sm">
          Sitting Capacity:
        </label>
        <input
          type="text"
          placeholder="Search"
          className="w-full p-2 text-sm border border-gray-300 rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">Select type:</label>
        <select
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
          onChange={(e) => handleFilterChange("packageType", e.target.value)}
        >
          <option value="">All</option>
          <option value="SUV">SUV</option>
          <option value="Sedan">Sedan</option>
        </select>
      </div>

      <div className="mb-6">
        <label className="block mb-2 font-semibold text-sm">
          Your Budget (per package):
        </label>
        <div className="px-2 mb-3 text-sm text-gray-600 ">
          PKR {filters.costRange[0]} - PKR {filters.costRange[1]}
        </div>
        <Slider
          range
          min={0}
          max={500000}
          value={filters.costRange}
          onChange={handleSliderChange}
          trackStyle={[{ backgroundColor: "#007bff" }]}
          handleStyle={[{ borderColor: "#007bff" }, { borderColor: "#007bff" }]}
        />
      </div> */}
    </>
  );
};

export default PackagesPage;
