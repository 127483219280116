import React, { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { TbCheckbox } from "react-icons/tb";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MasjidJinn } from "../../../../../../data.js";

const Distance = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const facts = [
    "Masjid Al-Jinn commemorates the event where a group of jinn listened to the Prophet Muhammad recite the Quran and subsequently embraced Islam. This event is mentioned in Surah Al-Jinn (72:1-15) of the Quran.",
    "The mosque is located approximately 1.5 kilometers (about 0.9 miles) from the Kaaba in Mecca, near the Al-Ma’la cemetery.",
    "The name 'Masjid Al-Jinn' translates to 'Mosque of the Jinn,' reflecting its connection to the supernatural beings in Islamic belief who accepted Islam after hearing the Quran.",
    "Compared to other Islamic sites, Masjid Al-Jinn has relatively simple architecture, but it holds great historical and spiritual significance for Muslims visiting Mecca.",
  ];

  return (
    <div className="w-[90%] mx-auto pb-10">
      <h1 className="font-cairo bg-[#cce9e2]  p-4 rounded-2xl md:text-[25px] text-[15px] font-semibold text-center justify-center flex my-10">
        Masjid Al-Jinn is approximately 1.5 kilometers (about 0.9 miles) from
        the Kaaba in Mecca.
      </h1>
      <div className="space-y-">
        <h1 className="font-cairo md:text-[25px] text-[15px] font-bold text-[#484848]">
          History
        </h1>
        <div className="flex gap-10">
          <p className="font-cairo w-1/2 md:text-[16px] text-[10px] font-normal leading-[32px]">
            Masjid Al-Jinn, also known as the Mosque of the Jinn, holds a unique
            place in Islamic history and tradition. It is named after an event
            where a group of jinn (supernatural beings in Islamic belief)
            encountered the Prophet Muhammad while he was reciting the Quran.
            According to Islamic tradition, this event is detailed in Surah
            Al-Jinn (72:1-15) of the Quran, which describes how the jinn were
            impressed by the recitation and subsequently accepted Islam. The
            historical event is believed to have taken place when the Prophet
            Muhammad was returning from Ta'if to Mecca. He stopped in a valley
            near Mecca, where he began to recite verses from the Quran during
            the night.
            <p className="lg:hidden xl:block">
              A group of jinn overheard the recitation and were so moved by its
              beauty and truth that they approached the Prophet and declared
              their faith in the message of Islam. To commemorate this
              significant encounter, a mosque was built on the site where it is
              believed to have taken place. Masjid Al-Jinn is located
              approximately 1.5 kilometers (about 0.9 miles) from the Kaaba in
              Mecca, near the Al-Ma’la cemetery.
            </p>
          </p>
          <div className="w-1/2 text-center">
            <img
              src={MasjidJinn.masjidjinn1.image}
              alt="Jabal al-Nour and the Cave of Hira"
              className="w-full h-auto"
            />
          </div>
        </div>
        <p className="hidden lg:flex xl:hidden">
          A group of jinn overheard the recitation and were so moved by its
          beauty and truth that they approached the Prophet and declared their
          faith in the message of Islam. To commemorate this significant
          encounter, a mosque was built on the site where it is believed to have
          taken place. Masjid Al-Jinn is located approximately 1.5 kilometers
          (about 0.9 miles) from the Kaaba in Mecca, near the Al-Ma’la cemetery.
        </p>
      </div>
      <div className="py-6">
        <div className="w-full mx-auto">
          <h2 className="text-[25px] font-bold font-cairo text-left text-gray-800 mb-5">
            4 Facts about the Cave of Hira
          </h2>
          <ul className="space-y-3">
            {facts.map((fact, index) => (
              <li key={index} className="flex items-start gap-2 rounded-lg">
                <TbCheckbox className="text-[15px] text-gray-700 mt-1" />
                <p className="text-gray-700 text-lg font-cairo leading-relaxed">
                  {fact}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="py-5">
        <h1 className="font-cairo text-[28px] md:text-[25px] font-bold text-gray-800">
          Resign for what peruse the people visit Masjid Jinn
        </h1>
        <h2 className="font-cairo font-normal text-[18px] md:text-[15px] py-4 text-gray-600">
          People visit Masjid Jinn for several reasons, primarily of spiritual
          significance:
        </h2>
      </div>
      <div className="2xl:flex xl:flex lg:flex md:block block gap-14">
        <img
          src={MasjidJinn.masjidjinn3.image}
          alt="Cave of Hira"
          className="w-full md:w-1/2 lg:w-1/3 rounded-lg"
        />
        <div className="block">
          <h3 className="font-cairo font-bold text-[24px] md:text-[25px] pb-2 text-gray-800">
            Pilgrimage:
          </h3>
          <p className="font-cairo font-normal text-[10px] md:text-[16px] leading-relaxed text-gray-600">
            Many Muslims visit Masjid Jinn as part of their pilgrimage to Mecca,
            known as Hajj or Umrah. They consider it a sacred site and an
            essential stop during their journey to the Kaaba and other holy
            places in Mecca.
          </p>
          <p className="font-cairo font-normal text-[10px] md:text-[16px] leading-relaxed text-gray-600">
            Masjid Jinn remains a sacred site for Muslims worldwide, who visit
            it as part of their pilgrimage to Mecca. Pilgrims often visit the
            mosque, reflecting on the profound spiritual experiences associated
            with the site, including the event where a group of jinn embraced
            Islam after hearing the Quran recited by the Prophet Muhammad. The
            mosque serves as a symbol of the universality of Islam and the
            importance of faith and divine revelation in the Islamic tradition.
          </p>
        </div>
      </div>
      <div className="bg-[#cce9e2] p-4 rounded text-left mx-auto my-10">
        <p className="text-[#484848] font-semibold font-cairo text-[18px]">
          Embark on a transformative pilgrimage to the sacred sanctuary of
          Ghar-e-Hira, nestled within the rugged folds of Mount Al-Noor, where
          the timeless whispers of revelation echo through the depths of the
          ancient cave, inviting seekers to delve into the profound mysteries of
          existence and emerge enlightened, their souls ablaze with the divine
          knowledge of truth.
        </p>
      </div>

      <div className="slider-container relative">
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={3}
          loop={true}
          navigation={false} // Disable built-in navigation
          modules={[Navigation]}
        >
          <SwiperSlide>
            <img
              src={MasjidJinn.history.image}
              alt="2"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidJinn.why.image}
              alt="3"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidJinn.masjidjinn1.image}
              alt="4"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={MasjidJinn.masjidjinn3.image}
              alt="6"
              className="rounded-lg w-full h-[340px]"
            />
          </SwiperSlide>
        </Swiper>

        {/* Custom Navigation Buttons */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 -left-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowBack className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 -right-3 transform -translate-y-1/2 z-10"
        >
          <IoIosArrowForward className="bg-white rounded-full text-[37px] p-1 shadow-lg text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default Distance;
