import React from "react";
import BookingNavbar from "../../../../components/Navbar/BookingNavBar";
import Footer from "../../../../components/footer";

const HelpWithHosting = () => {
  return (
    <div className="">
      <BookingNavbar />
      <div className="bg-gray-100 min-h-screen">
        <div className="w-[90%] mx-auto py-8">
          <header className="text-center mb-8">
            <h1 className="text-[20px] font-kd font-bold">Help with Hosting</h1>
            <p className="text-lg text-gray-600">
              Welcome to our platform for hosting your products or services
              related to Hajj and Umrah!
            </p>
          </header>

          <section className="rounded-lg p-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Getting Started
            </h2>
            <p className="text-gray-700 mb-6">
              Embarking on your hosting journey is an exciting step, and we're
              here to guide you through every aspect of it. <br /> From setting
              up your vendor account to optimizing your listings, we have all
              the tools and information you need to get started.
            </p>

            <img
              src="https://i0.wp.com/muslimgirl.com/wp-content/uploads/2024/04/pexels-drmkhawarnazir-18996602-scaled.jpg?fit=2560%2C1920&ssl=1"
              alt="Getting Started"
              className="rounded-lg mb-6 w-[800px] h-[400px] object-cover"
            />

            <h2 className="text-[20px] font-kd mb-4 font-bold">
              All the Ways to Host
            </h2>
            <ul className="list-disc list-inside mb-6">
              <li>
                <strong className="font-semibold">
                  Listing Your Products/Services:
                </strong>{" "}
                Learn how to create compelling listings that attract customers
                and maximize your earning potential.
              </li>
              <li>
                <strong className="font-semibold">Superhost Assistance:</strong>{" "}
                Benefit from one-on-one guidance from our top vendors through
                our Ask a Superhost program.
              </li>
              <li>
                <strong className="font-semibold">Preparing to Host:</strong>{" "}
                Find tips and best practices for ensuring a seamless hosting
                experience and earning positive reviews from customers.
              </li>
            </ul>

            <img
              src={"/images/HelpPage/makkah2.jpg"}
              alt="All the Ways to Host"
              className="rounded-lg mb-6  w-[800px] h-[400px]"
            />

            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Managing Your Listings
            </h2>
            <p className="text-gray-700 mb-6">
              Once your listings are live, it's important to stay on top of
              managing them effectively. From handling reservations to making
              changes <br /> and responding to guest inquiries, we'll help you
              navigate every aspect of hosting.
            </p>

            <img
              src={
                "https://i0.wp.com/muslimgirl.com/wp-content/uploads/2024/04/pexels-drmkhawarnazir-18996602-scaled.jpg?fit=2560%2C1920&ssl=1"
              }
              alt="Managing Your Listings"
              className="rounded-lg mb-6  w-[800px] h-[400px]"
            />

            {/* Add more sections as needed */}

            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Need Further Assistance?
            </h2>
            <p className="text-gray-700 mb-6">
              If you have any questions, concerns, or need additional support,
              don't hesitate to reach out to our customer support team. We're
              here to help you succeed and make the most of your hosting
              experience on our platform.
            </p>

            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Did this Information Help?
            </h2>
            <p className="text-gray-700 mb-6">
              Let us know if you found this information helpful by providing
              feedback. Your input helps us improve our services and better
              support our vendors.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpWithHosting;
