import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          "Rising Above: Al Shafa Mountain, Saudi Arabia's Natural Mountain"
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Geographical Location of Al Shafa Mountain
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full text-[#484848] mb-4 font-cairo font-medium text-[15px]">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Al Shafa Mountain is situated near the city of Taif in the
                western region of Saudi Arabia, approximately 2,500 meters above
                sea level. Known for its cool climate and scenic beauty, Al
                Shafa offers a refreshing contrast to the arid landscapes
                typical of the Arabian Peninsula. The mountain range is
                characterized by its rugged terrain, lush greenery, and diverse
                flora, including juniper trees and wildflowers that bloom
                vividly during springtime. The location of Al Shafa Mountain
                provides breathtaking panoramic views of the surrounding valleys
                and plains, extending towards the Red Sea coast. Its cooler
                temperatures make it a favored destination for locals and
                visitors seeking respite from the heat of lower altitudes,
                particularly during the summer months. The mountain is also
                renowned for its historical significance, as it has been a
                retreat for generations, offering a peaceful environment for
                relaxation and reflection amidst nature's splendor.
              </p>
              <p>
                Al Shafa Mountain is renowned for its cool climate, especially
                compared to the lower altitudes of the region. The higher
                elevation provides a refreshing escape from the heat, making it
                a popular destination during the summer months. The mountain's
                terrain is rugged, with steep slopes and rocky outcrops
                interspersed with patches of lush greenery. The area is
                particularly known for its diverse flora, including juniper
                trees and a variety of wildflowers that bloom vividly in the
                spring.
              </p>
              <p className="lg:hidden xl:block">
                Scenic Beauty and Panoramic Views The location of Al Shafa
                Mountain offers breathtaking panoramic views of the surrounding
                valleys and plains. From various vantage points on the mountain,
                visitors can see expansive landscapes extending towards the Red
                Sea coast. The scenic beauty of the area is enhanced by the
                contrast between the green foliage of the mountain and the more
                barren regions below.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/shafaMountains/image2.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="hidden lg:flex xl:hidden text-[#484848] mb-4 font-cairo font-medium text-[15px]">
            Scenic Beauty and Panoramic Views The location of Al Shafa Mountain
            offers breathtaking panoramic views of the surrounding valleys and
            plains. From various vantage points on the mountain, visitors can
            see expansive landscapes extending towards the Red Sea coast. The
            scenic beauty of the area is enhanced by the contrast between the
            green foliage of the mountain and the more barren regions below.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Shafa Mountain in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              In Islamic tradition, mountains hold significant spiritual and
              symbolic importance. While specific references to Al Shafa
              Mountain in religious texts may not be detailed, the general
              reverence for mountains in Islam stems from their depiction as
              signs of Allah's creation and power. Mountains are often mentioned
              in the Quran as stabilizers of the Earth's crust and as symbols of
              strength and steadfastness, reflecting themes of faith and
              endurance. In Islam, Shafa Mountain (Jabal al-Shafa) holds
              significant spiritual and historical importance, primarily
              associated with the Hajj pilgrimage to Mecca. Located near Mecca,
              it is part of the sacred journey for pilgrims performing the Hajj,
              specifically during the days of Hajj rituals. According to Islamic
              tradition, the mountain holds a special place as it is mentioned
              in the Quran and Hadith (sayings of Prophet Muhammad). Pilgrims
              ascend Jabal al-Shafa as part of the Hajj rituals, following the
              footsteps of Prophet Ibrahim (Abraham) and his family. The
              mountain is where Hajra (Hagar), the wife of Prophet Ibrahim,
              climbed to seek water for her son Isma'il (Ishmael), resulting in
              the discovery of the Zamzam well, a vital source of water revered
              by Muslims worldwide. Moreover, the significance of Jabal al-Shafa
              extends beyond its historical role in the Hajj. It serves as a
              symbol of resilience, faith, and the divine blessings associated
              with the acts of devotion performed during the pilgrimage.
              Pilgrims visiting Mecca often include a visit to Jabal al-Shafa to
              commemorate the trials and blessings of Prophet Ibrahim's family
              and to reflect on the spiritual lessons embedded in their journey
              of faith.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/shafaMountains/image4.jpg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to Explore Shafa Mountain
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                - Enjoy Scenic Views: Take time to appreciate the stunning
                panoramic views of the valleys and surrounding landscapes from
                different viewpoints along the mountain trails.
              </li>
              <li>
                - Hiking Trails: Discover various hiking trails that cater to
                different skill levels, allowing you to experience the
                mountain's diverse flora and fauna up close.
              </li>
              <li>
                - Plan Your Route: Start by deciding which areas of Al Shafa
                Mountain you want to explore, considering your interests and
                fitness level. There are various trails and viewpoints to choose
                from.
              </li>
              <li>
                - Pack Essentials: Bring essentials such as water, snacks,
                sunscreen, and appropriate clothing for the weather, as
                temperatures can vary and sun exposure can be intense.
              </li>
              <li>
                - Picnicking and Relaxation: Take advantage of designated picnic
                areas and viewpoints along the mountain roads for a relaxing day
                amidst nature.
              </li>
              <li>
                - Explore Nature: Observe the diverse flora and fauna that
                thrive in the mountain's cooler climate, including juniper
                trees, wildflowers, and possibly wildlife like birds and small
                mammals.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
