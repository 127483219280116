import React from "react";

const HeroSection = ({ herobg, heading }) => {
  return (
    <div
      className="w-full h-[300px] bg-cover bg-center bg-no-repeat relative"
      style={{
        backgroundImage: `url(${herobg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="bg-[#00936C] opacity-20 w-full h-[300px] z-10"></div>

      <div className="absolute bottom-0 left-0 right-0 p-6 w-[85%] lg:pb-10 mx-auto text-white z-20">
        <h1 className="font-kd font-bold text-4xl">{heading}</h1>
      </div>
    </div>
  );
};

export default HeroSection;
