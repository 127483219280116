import React from "react";
import { RxCross2 } from "react-icons/rx";

const Card = ({ location, onClose }) => (
  <div className="fixed top-10 left-10 h-[450px] bg-gray-800 text-white w-[331px] rounded-lg shadow-lg overflow-y-auto">
    <button className="absolute top-2 right-2 text-xl" onClick={onClose}>
      <RxCross2 />
    </button>
    <img
      src={location.image}
      alt={location.name}
      className="rounded-t-lg w-full"
    />
    <div className="absolute top-2 left-2 flex items-center justify-center w-8 h-8 bg-black text-yellow-400 rounded-full">
      {location.id}
    </div>
    <div className="p-4">
      <h2 className="text-lg font-bold mt-2">{location.name}</h2>
      <p className="mt-2 text-sm">{location.description}</p>
      <button className="mt-4 text-yellow-400 underline">FIND OUT MORE</button>
    </div>
  </div>
);

export default Card;
