import React from "react";

const MakkahHolySites = () => (
  <div className="w-[85%] mx-auto py-5 pt-12">
    <h1 className="text-cairo font-semibold md:text-[25px] text-[30px] text-[#484848] py-2.5">
      Makkah Holy Sites
    </h1>
    <p className="text-[16px] text-[#484848] text-left leading-[20px] font-kd">
      Makkah, the heart of Islam, is where the sacred Masjid Al Haram and the
      Kaaba, the qiblah for millions of Muslims, stand. Every Muslim dreams of
      visiting Makkah to perform Hajj or Umrah. This city is not just a place
      but a cornerstone of Islamic faith—it is the birthplace of Prophet
      Muhammad (peace be upon him), the seal of the prophets. From here, the
      divine message of Islam spread across the globe after the revelation of
      the Holy Quran. Nestled in western Saudi Arabia, Makkah has flourished
      under the care of Saudi kings, receiving unparalleled attention to enhance
      the pilgrimage experience. The iconic Masjid Al Haram has seen numerous
      expansions, and extensive developments ensure every pilgrim enjoys the
      utmost comfort and spiritual solace.
    </p>
  </div>
);

export default MakkahHolySites;
