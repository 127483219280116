import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          "Unveiling the Legacy: Masjid Abdullah ibn Abbas Beckons With Rich
          History and Serenity"
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            The Geographical Location of Masjid Abdullah ibn Abbas,
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Masjid Abdullah ibn Abbas, also known as Masjid Aisha, is
                situated near Mina in Mecca, Saudi Arabia. It holds historical
                and religious significance primarily because it is associated
                with Abdullah ibn Abbas, a prominent companion of Prophet
                Muhammad (peace be upon him). Abdullah ibn Abbas was the cousin
                of Prophet Muhammad and is renowned for his knowledge and
                understanding of the Quran and Islamic teachings. He is often
                referred to as the "Tarjuman al-Quran" (Interpreter of the
                Quran) due to his deep insights into its meanings and
                interpretations. It is said that he spent much of his time in
                this mosque teaching the Quran and explaining its verses to the
                companions and early Muslims. The mosque itself is not large in
                size compared to other significant mosques in Mecca, but its
                historical importance makes it a revered site for pilgrims and
                visitors. The location near Mina is particularly significant
                during the annual Hajj pilgrimage when millions of Muslims
                gather in the area for rituals, including the stoning of the
                pillars representing Satan (Rami al-Jamarat).
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/abdullahMasjid/image2.jpeg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
          <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] mr-10">
            Visitors to Masjid Abdullah ibn Abbas can experience a sense of
            connection to early Islamic history and the teachings of Abdullah
            ibn Abbas. The mosque serves as a reminder of the dedication of
            early Muslims to learning and spreading the message of Islam during
            the time of Prophet Muhammad.
          </p>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Masjid Abdullah ibn Abbas in Islam
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Masjid Abdullah ibn Abbas, located near Mina in Mecca, Saudi
              Arabia, holds profound significance in Islamic history. Named
              after Abdullah ibn Abbas, a notable companion of Prophet Muhammad
              (peace be upon him), the mosque stands as a testament to his
              scholarly contributions and deep understanding of the Quran. Known
              as the "Interpreter of the Quran," Abdullah ibn Abbas played a
              pivotal role in educating early Muslims about Islam's teachings
              and Quranic interpretations. Today, the mosque serves as a
              spiritual landmark where pilgrims visit during Hajj to reflect on
              his legacy and the importance of knowledge in Islamic tradition.
              Its humble architecture and historical resonance make it a place
              of reverence and learning for Muslims from around the world.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/abdullahMasjid/image3.jpeg"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <p className="text-center text-[#484848] font-cairo text-[15px] font-semibold mt-4">
              "And I did not create the jinn and mankind except to worship Me"
              <br />
              <span className="italic font-bold">
                Surah Adz Dzaariyaat [verse 56]
              </span>
            </p>
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            How to explore Wadi al Jinn?
          </h2>
          <div className="mt-4">
            <ul className="list-none space-y-2 text-[#484848] mb-4 font-cairo font-medium text-[17px]">
              <li>
                -Visit During Hajj or Umrah: Plan a visit to Masjid Abdullah ibn
                Abbas during the Hajj pilgrimage or Umrah to experience the
                spiritual atmosphere and learn about its historical background.
              </li>
              <li>
                - Guided Tours: Take advantage of guided tours offered in Mecca
                that include historical sites like Masjid Abdullah ibn Abbas.
                Guides can provide insights into its significance and the life
                of Abdullah ibn Abbas.
              </li>
              <li>
                - Study the Life of Abdullah ibn Abbas: Research the life and
                contributions of Abdullah ibn Abbas in Islamic history and
                Quranic interpretation to better appreciate the importance of
                the mosque named after him.
              </li>
              <li>
                - Attend Lectures or Talks: Check if there are lectures or talks
                held at the mosque or nearby venues discussing the teachings and
                interpretations of Abdullah ibn Abbas and their relevance today.
              </li>
              <li>
                - Read Historical Accounts: Explore books, articles, or online
                resources that delve into the history and significance of Masjid
                Abdullah ibn Abbas, providing deeper context to its role in
                early Islamic education.
              </li>
              <li>
                - Reflect and Pray: Spend time in prayer and reflection at the
                mosque, considering its role as a place of learning and
                spiritual guidance in Islam's early days.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
