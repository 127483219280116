import React, { useEffect, useState } from "react";
import HeaderFooter from "../HeaderFooter";
import AlulaPlaces from "./AlulaPlaces";
import Banner from "./Banner";
import Card from "../Card";
import Cards from "./Card";
import { AlulaPlacesAttractions } from "../../../../placesdata";
import Map from "./Map";

const locations = [
  {
    id: 1,
    name: "Jabal Ikmah",
    description:
      "Hegra, also known as Madain Salih, is a breathtaking archaeological site and Saudi Arabia's first UNESCO World Heritage site. Discover its stunning rock-cut tombs and intricate inscriptions that tell the story of the Nabatean civilization.",
    image: "/images/alula/hegra-mountain/hegra1.jpeg",
    coordinates: { top: "47%", left: "38%" },
  },
  {
    id: 2,
    name: "Hegra",
    description:
      "History Carved in Stone: Jabal Ikmah is an open-air library of ancient inscriptions. This majestic mountain, adorned with petroglyphs and rock art, reveals the stories and secrets of civilizations long past.",
    image: "/images/alula/jabal-ikmah/jabal-ikmah1.jpg",
    coordinates: { top: "26%", left: "83%" },
  },
  {
    id: 3,
    name: "AlUla Old Town",
    description:
      "AlUla Old Town invites you to wander through its narrow alleyways and ancient mudbrick houses. This historic settlement offers a glimpse into centuries of trade, culture, and community in the heart of the desert.",
    image: "/images/alula/old-town/image1.webp",
    coordinates: { top: "71%", left: "20%" },
  },
  {
    id: 4,
    name: "Dadan",
    description:
      "Lost Kingdom Unearthed: Dadan, or Dedan, is an ancient city that was once a powerful trading hub. Explore its impressive ruins and discover the secrets of the Lihyanite and Dedanite kingdoms that flourished in this oasis.",
    image: "/images/alula/dadan/image1.avif",
    coordinates: { top: "74%", left: "39%" },
  },
  {
    id: 5,
    name: "AlUla Oasis",
    description:
      "The AlUla valley is an oasis, an island of habitable land amidst Saudi Arabia’s vast seas of sand. Here, underground waters were used as fertile land where plants and animals could thrive. Humans settled and developed agriculture, growing wheat, barley, and palm dates in a haven that supported communities and offered succor to travelers.",
    image: "/images/alula/alulaoasis/oasis.jpg",
    coordinates: { top: "42%", left: "52%" },
  },
];

const Alula = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationClick = (id) => {
    setSelectedLocation(locations.find((loc) => loc.id === id));
  };

  return (
    <HeaderFooter>
      <Banner />
      <AlulaPlaces />
      <Card
        sections={[{ title: "Places to go", data: AlulaPlacesAttractions }]}
      />
      <div className="relative bg-[#2b3d47] text-white hidden md:block">
        <Map onLocationClick={handleLocationClick} locations={locations} />
        {selectedLocation && (
          <Cards
            location={selectedLocation}
            onClose={() => setSelectedLocation(null)}
          />
        )}
      </div>
    </HeaderFooter>
  );
};

export default Alula;
