import React, { useEffect } from "react";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import Subscription from "../../../components/subscription";

const UmrahGuide = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <BookingNavbar />

      <header className="relative bg-blue-600 text-white">
        <div className="absolute inset-0 opacity-75">
          <img
            src="/images/UmrahGuide/uhud4.webp"
            alt="Kaaba"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative p-8 flex flex-col items-start justify-center h-64">
          <h1 className="text-4xl font-bold mb-4">
            Umrah Guide: Step by step guide to performing Umrah
          </h1>
          <div className="flex space-x-4">
            <a href="#" className="text-white">
              <FaFacebookSquare className="text-2xl" />
            </a>
            <a href="#" className="text-white">
              <FaTwitterSquare className="text-2xl" />
            </a>
            <a href="#" className="text-white">
              <FaLinkedin className="text-2xl" />
            </a>
          </div>
        </div>
      </header>
      <div className="w-[85%] mx-auto pt-10">
        <div className=" px-4">
          <p className="text-[16px] font-kd">
            Umrah begins with making your intention and assuming{" "}
            <strong>Ihram</strong>. After this, you enter the{" "}
            <strong>Masjid al-Haram</strong> and perform <strong>Tawaf</strong>,
            which involves circling the Kaaba seven times.
          </p>
          <p className="text-[16px] font-kd">
            Following Tawaf, perform two Rak’ah at the{" "}
            <strong>Maqam Ibrahim</strong> and drink from the{" "}
            <strong>Zamzam</strong> well.
          </p>
          <p className="text-[16px] font-kd">
            The next step is <strong>Sa’i</strong>, walking or running between
            the hills of <strong>Safa</strong> and <strong>Marwa</strong>,
            completing seven circuits.
          </p>
          <p className="text-[16px] font-kd">
            To conclude Umrah, men are required to shave their heads, while
            women should cut a fingertip's length of hair, symbolizing the end
            of the state of <strong>Ihram</strong>.
          </p>
        </div>
        <div className=" px-4 py-8">
          <h1 className="text-[20px] font-kd mb-4 font-bold">
            What are the steps of performing Umrah?
          </h1>

          <h2 className="text-[18px] font-kd mb-2 font-bold">
            Assuming Ihram and making niyyah
          </h2>

          <ol className="list-decimal pl-4 text-[16px] font-kd">
            <li>
              Before assuming Ihram, it's highly recommended Sunnah to perform
              Ghusl. It’s also recommended to clip your nails and remove excess
              body hair. As you'll be traveling on an international flight, this
              should be performed before leaving your home. Men may also wish to
              apply Attar/perfume to their heads/beards, ensuring not to get any
              on the Ihram garments.
            </li>
            <li>
              Change into your Ihram. Men should wear two white sheets: One is
              wrapped around the waist, while the other is used to cover the top
              half of the body. Sandals may be worn that do not cover the ankle,
              and it is not permissible to wear a hat or turban. Women are to
              wear their normal dress, but should not wear gloves or cover their
              face.
            </li>
            <li>
              Pray Salah al-Ihram. It's Sunnah to perform two Rakahs of Salah
              before entering into the state of Ihram. This can be performed
              after getting changed at the airport or in-flight before crossing
              the Miqat, providing there are prayer facilities on-board. Observe
              the prayer with the intention of performing two Rakahs Nafl for
              Ihram. It is recommended to recite Surah al-Kafirun (109) in the
              first Rakah and Surah al-Ikhlas (112) in the second, although
              other Surahs may be read.
            </li>
            <li>
              Make the intention for Umrah. For those flying from the UK/
              abroad, the Miqat is passed 20-30 minutes before landing. This is
              the point at which one must make the intention for Umrah, by
              reciting the following:
              <div className="italic">
                <p className="text-[16px] font-kd">
                  لَبَّيْكَ اللَّهُمَّ بِعُمْرَة
                </p>
                <p className="text-[14px] font-kd">
                  Translation: Here I am O Allah making Umrah.
                </p>
              </div>
            </li>
            <li>
              Recite the Talbiyah from this point onwards. Men should recite
              this in a loud voice without disturbing others, while women should
              recite this softly.
              <div className="italic">
                <p className="text-[16px] font-kd">
                  لَبَّيْكَ اللَّهُمَّ لَبَّيْكَ، لَبَّيْكَ لاَ شَرِيكَ لَكَ
                  لَبَّيْكَ
                </p>
                <p className="text-[14px] font-kd">
                  Translation: At Your service, Allah, at Your service. At Your
                  service, You have no partner, at Your service. Truly all
                  praise, favour and sovereignty are Yours. You have no partner.
                </p>
              </div>
            </li>
          </ol>
        </div>
        <div className=" px-4 py-8">
          <h1 className="text-[20px] font-kd mb-4 font-bold">
            Entering the Masjid al-Haram
          </h1>

          <ol className="list-decimal pl-4 text-[16px] font-kd">
            <li className="mb-4">
              It’s recommended to enter the Masjid from Baab Bani Shaibah (or
              known as Baab Salam) and the Sunnah is to enter the Masjid with
              the right foot forward and it is recommended to recite the
              following dua:
              <div className="italic my-2">
                <p className="text-[16px] font-kd">
                  اَللَّهُمَّ هَذَا حَرَمُكَ وَأَمْنُكَ فَحَرِّمْنِي عَلَى
                  النَّارِ، وَأَمِنِّي مِنْ عَذَابِكَ يَوْمَ تَبْعَثُ عِبَادَكَ،
                  وَاجْعَلْنِي مِنْ أَوْلِيَائِكَ وَأَهْلِ طَاعَتِكَ.
                </p>
                <p className="text-[14px] font-kd">
                  Transliteration: Allāhumma hādhā ḥaramuka wa amnuka fa
                  ḥarrimnī ‘ala-n-Nār, wa amminnī min ‘adhābika yawma tab’athu
                  ‘ibādak, wa j‘alnī min awliyā’ika wa ahli ṭā’atik.
                </p>
                <p className="text-[14px] font-kd">
                  Translation: Allah, this is Your sanctuary and security, so
                  make me unlawful to Hell-Fire, make me safe from Your
                  punishment on the day You resurrect Your servants, and make me
                  one of Your friends and one of the people who obey You.
                </p>
              </div>
            </li>
            <li className="mb-4">
              Upon seeing the Holy Ka’aba, raise your hands and make any dua
              with sincerity, as this is one of the places that dua’s are
              accepted. It is recommended to recite the following du’as upon
              seeing the Holy Ka’aba:
              <div className="italic my-2">
                <p className="text-[16px] font-kd">
                  اَللَّهُمَّ زِدْ أَهْلَ هَذَا الْبَيْتَ تَشْرِيفاً
                  وَتَعْظِيماً وَتَكْرِيماً وَمَهَابَةً، وَزِدْ مَنْ شَرَّفَهُ
                  وَكَرَّمَهُ مِمَّنْ حَجَّهُ أَوْ اعْتَمَرَهُ تَشْرِيفاً
                  وَتَكْرِيماً وَتَعْظِيماً وَبِرّاً.
                </p>
                <p className="text-[14px] font-kd">
                  Transliteration: Allāhumma zid hādhā-l-Bayta tashrīfan wa
                  ta’ẓīman wa takrīman wa mahābah, wa zid man sharrafahu wa
                  karramahu mimman ḥajjahu awi-’tamarahu tashrīfan wa takrīman
                  wa ta’ẓīman wa birrā.
                </p>
                <p className="text-[14px] font-kd">
                  Translation: Allah, increase this House in honour, esteem,
                  respect and reverence. And increase those who honour and
                  respect it—of those who perform Hajj or ‘Umrah—in honour,
                  respect, esteem and piety.
                </p>
                <p className="text-[16px] font-kd">
                  اَللَّهُمَّ أَنْتَ السَّلاَمُ وَمِنْكَ السَّلَامُ، حَيِّنَا
                  رَبَّنَا بِالسَّلاَمِ
                </p>
                <p className="text-[14px] font-kd">
                  Transliteration: Allāhumma Anta-s-Salāmu wa minka-s-salām,
                  ḥayyinā Rabbanā bi-s-salām.
                </p>
                <p className="text-[14px] font-kd">
                  Translation: O Allah, You are Peace and from You is peace.
                  Make us live, Lord, in peace.
                </p>
              </div>
            </li>
            <li>
              Do Dhikr, make Dua and send Salawat upon the Prophet (Peace be
              upon Him) in abundance.
            </li>
          </ol>
        </div>
        <div className=" px-4 py-8">
          <h1 className="text-[20px] font-kd mb-4 font-bold">
            Performing Tawaf
          </h1>

          <ol className="list-decimal pl-4 text-[16px] font-kd">
            <li className="mb-4">
              One must be in a state of Wudu before performing Tawaf.
            </li>
            <li className="mb-4">
              Make the intention for performing the Tawaf for the sake of Allah,
              either verbally or silently.
            </li>
            <li className="mb-4">
              If possible, try to touch the Hajar al-Aswad (Black Stone) face,
              while reciting 'Allahu Akbar,' kiss it and place your forehead
              upon it (Istilam) – this is to be repeated at the beginning of
              each circuit of tawaf.
            </li>
            <li className="mb-4">
              If you are unable to touch the Hajar al-Aswad, perform a symbolic
              Istilam from afar by directly facing the Hajar al-Aswad and
              raising your hands up to your earlobes (as you would do when
              starting Salah). Ensure your palms are also facing it, as though
              your face and hands are on the Hajar al-Aswad and say 'Allahu
              Akbar'.
            </li>
            <li className="mb-4">
              Begin your Tawaf at the Black Stone, circling the Ka'aba, with the
              Ka'aba on your left. Seven circuits are to be completed in total.
            </li>
            <li className="mb-4">
              In the first three circuits, men should perform Raml, which is the
              practice of walking briskly, lifting the legs forcefully and
              sticking out the chest. However, only perform this Sunnah if
              you’re sure you won't harm or inconvenience others.
            </li>
            <li className="mb-4">
              Each time one passes the Yemeni corner of the Ka'aba (the corner
              before the corner of the Hajar al-Aswad), try to touch it with
              your hand if possible.
            </li>
            <li className="mb-4">
              Recite the following supplication between the Yemeni corner and
              the Hajar al-Aswad:
              <div className="italic my-2">
                <p className="text-[16px] font-kd">
                  رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ
                  حَسَنَةً وَقِنَا عَذَابَ النَّارِ
                </p>
                <p className="text-[14px] font-kd">
                  Transliteration: Rabbanā ātinā fi-d-dunyā ḥasanatan wa
                  fi-l-ākhirati ḥasanatan wa-qinā ‘adhāb an-nār.
                </p>
                <p className="text-[14px] font-kd">
                  Translation: O Our Lord, grant us the good of this world, the
                  good of the Hereafter, and save us from the punishment of the
                  fire.
                </p>
              </div>
            </li>
            <li className="mb-4">
              The Tawaf should be performed in a continuous manner with no
              interruptions between circuits. However, if a prayer is due to
              start, you must join the congregation and resume your Tawaf from
              the position that you stopped. The circuit need not be repeated.
              These rules also apply if you need to repeat your Wudu.
            </li>
            <li className="mb-4">
              Make sincere dua while performing Tawaf. If you are able to, cling
              to the area between the Hajar al-Aswad and make dua here.
            </li>
          </ol>
        </div>
        <div className=" px-4 py-8">
          <h1 className="text-[20px] font-kd mb-4 font-bold">After Tawaf</h1>

          <ol start="18" className="list-decimal pl-4 text-[16px] font-kd">
            <li className="mb-4">
              After performing Tawaf, proceed to the Maqam Ibrahim and recite
              the following verse (men should cover both of their shoulders at
              this point):
              <div className="italic my-2">
                <p className="text-[16px] font-kd">
                  وَاتَّخِذُوا مِنْ مَقَامِ إِبْرَاهِيمَ مُصَلًّى
                </p>
                <p className="text-[14px] font-kd">
                  Transliteration: Wattaḵiḏū min maqāmi ʾibrāhīma muṣallā.
                </p>
                <p className="text-[14px] font-kd">
                  Translation: And take the Maqam Ibrahim as a place of Salah.
                </p>
              </div>
            </li>
            <li className="mb-4">
              Perform two Rak'ah behind the Maqam Ibrahim and the Ka'aba. It is
              Sunnah to recite Surah Kafirun in the first Rak'ah, and Surah
              Ikhlas in the second Rak'ah.
            </li>
            <li className="mb-4">
              Drink Zamzam water which is available around the Tawaf area. Make
              an intention for what you are drinking Zamzam water for. For
              example, to be cured from an illness.
            </li>
            <li className="mb-4">
              After you have finished drinking Zamzam water, you may proceed (if
              you wish to, or after you have finished the rituals of the Umrah)
              to the Multazam which is the area between Hajar al-Aswad and the
              door of the Ka'aba if you are able to reach it. Raise your hands
              above your head, cling to the wall and press your chest and cheeks
              against it. This is yet another station where supplications are
              accepted.
            </li>
            <li className="mb-4">
              If you can't reach the Multazam due to the crowds, you may face it
              and supplicate from a distance.
            </li>
            <li className="mb-4">
              It is Sunnah to return to the Hajar al-Aswad from the Maqam
              Ibrahim and perform Istilam again if possible.
            </li>
          </ol>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h3 className="text-[20px] font-kd mb-4 font-bold">Sa’i</h3>
            <p className="text-[16px] font-kd mb-4">
              24. The next stage of performing Umrah involves walking to Safa
              which is located inside Masjid al-Haram in line with the Hajar
              al-Aswad, and to recite the following verse:
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>
                <em>إِنَّ الصَّفَا وَالْمَرْوَةَ مِن شَعَائِرِ اللَّهِ</em>
              </strong>
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Transliteration:</strong>
              <br />
              Inna aṣ-ṣafā wa l-marwata min sha’ā’iri l-lāh(i).
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Translation:</strong>
              <br />
              Indeed, Safa and Marwa are from the Signs of Allah.
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">Followed by:</p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>
                <em>أَبْدَأُ بِمَا بَدَأَ اللَّهُ بِهِ</em>
              </strong>
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Transliteration:</strong>
              <br />
              Abda’u bimā bad’a l-lāhu bih(i).
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Translation:</strong>
              <br />I begin with that which Allah has begun with.
            </p>
            <p className="text-[16px] font-kd mb-4">
              25. Upon reaching the hill of Safa, face the direction of the
              Kaaba and raise your hands in supplication. Your view of the Kaaba
              may be obscured so make an educated guess based on its location
              and face this direction. You may say Takbeer (Allahu Akbar),
              Tahleel (la ilaha illa Allah) and send Salawat upon the Prophet
              (PBUH).
            </p>
            <p className="text-[16px] font-kd mb-4">
              It is Sunnah to recite the following Dua:
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>
                <em>
                  اللَّهُ أَكْبَرُ، اللَّهُ أَكْبَرُ، اللَّهُ أَكْبَرُ، لَا
                  إِلٰهَ إِلَّا اللَّهُ، وَاللَّهُ أَكْبَرُ، اللَّهُ أَكْبَرُ،
                  وَلِلَّهِ الْحَمْدُ
                </em>
              </strong>
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Transliteration:</strong>
              <br />
              Allāhu akbar, Allāhu akbar, Allāhu akbar, lā ilāha illallāh,
              Allāhu akbar, Allāhu akbar, wa lillāhil-ḥamd.
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Translation:</strong>
              <br />
              Allah is the greatest; Allah is the greatest; Allah is the
              greatest, and to Allah belongs all praise.
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>
                <em>
                  لَا إِلٰهَ إِلَّا اللَّهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ
                  الْمُلْكُ وَ لَهُ الْحَمْدُ وَهُوَ عَلٰى كُلِّ شَيْءٍ قَدِيرٍ،
                  لَا إِلٰهَ إِلَّا اللَّهُ وَحْدَهُ، أَنْجَزَ وَعْدَهُ،
                  وَنَصَرَ عَبْدَهُ، وَهَزَمَ الْأَحْزَابَ وَحْدَهُ
                </em>
              </strong>
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Transliteration:</strong>
              <br />
              Lā ilāha illallāh waḥdahu lā sharīka lah, lahul-mulku wa
              lahul-ḥamdu wa huwa ‘alā kulli shay’in qadīr, lā ilāha illallāh
              waḥdahu, anjaza wa’dahu, wa naṣara ‘abdahu, wa hazamal-aḥzāba
              waḥdah.
            </p>
            <p className="text-[16px] font-kd mb-4 text-center">
              <strong>Translation:</strong>
              <br />
              There is no deity except Allah alone. He fulfilled His promise,
              supported His slave and defeated the confederates alone.
            </p>
            <p className="text-[16px] font-kd mb-4">
              After reciting this Dua, walk towards the hill of Marwa. Recite
              the Duas a total of three times, making your own supplications in
              between each time, as was the Sunnah of the Prophet (PBUH).
            </p>
            <p className="text-[16px] font-kd mb-4">
              26. Walk towards Marwa, and men should run between the fluorescent
              green lights. Repeat the Duas upon reaching Marwa, and continue
              walking/running and repeat the same supplications as when
              ascending Safa. This completes one circuit.
            </p>
            <p className="text-[16px] font-kd mb-4">
              27. Return back to Safa and repeat step 25 - this completes two
              circuits.
            </p>
            <p className="text-[16px] font-kd mb-4">
              28. Complete seven circuits between Safa and Marwa in total,
              ending the last one at Marwa.
            </p>
            <p className="text-[16px] font-kd mb-4">
              29. Cutting hair: Men should shave their hair, while women should
              cut the length of one fingertip in order to break from the state
              of Ihram.
            </p>
            <p className="text-[16px] font-kd mb-4">
              30. Your Umrah is now complete – Mubarak!
            </p>
          </section>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              How do you perform Umrah for the first time?
            </h2>
            <p className="text-[16px] font-kd mb-4">
              If you’re performing Umrah for the first time, you will complete
              the steps of Umrah as described above. There are no differences in
              how to perform Umrah whether it’s your first time, or if you’ve
              performed Umrah in the past.
            </p>
            <p className="text-[16px] font-kd mb-4">
              However, if you’re performing Umrah for the first time, you may be
              a little unsure of where to start with preparing for your trip and
              what to expect.
            </p>
            <p className="text-[16px] font-kd mb-4">
              We’ve put together a checklist on how to prepare and what to pack.
            </p>
            <p className="text-[16px] font-kd mb-4">
              You can also find everything you need to know about how to book
              your Umrah trip{" "}
              <a
                href="https://www.islamic-relief.org.uk/resources/knowledge-base/umrah/how-to-book-your-umrah-trip/"
                className="text-blue-600 underline"
              >
                here
              </a>
              , as well as the rules of performing umrah{" "}
              <a
                href="https://www.islamic-relief.org.uk/resources/knowledge-base/umrah/rules-of-performing-umrah/"
                className="text-blue-600 underline"
              >
                here
              </a>
              .
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              How to perform Umrah for ladies?
            </h2>
            <p className="text-[16px] font-kd mb-4">
              The steps of performing Umrah are almost the same as for men, as
              listed above. However, there are some differences for women when
              it comes to performing Umrah, such as whether a woman can travel
              without a mahram to perform Umrah, if she is able to perform Umrah
              while menstruating, and what to wear once assuming Ihram.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Below are some subtle differences in how women will perform Umrah
              compared to men:
            </p>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px] font-kd">
              <li>
                Men will repeat the Talbiyah loudly, while women should recite
                this quietly.
              </li>
              <li>
                In the first three rounds of Tawaf, the Sunnah for men is to
                run, which is called Raml. However, women do not follow this
                practice.
              </li>
              <li>
                During Sa’i, men should run between the fluorescent green
                lights, while women should walk throughout.
              </li>
              <li>
                Islamically, women should travel to Umrah accompanied by a
                mahram.
              </li>
              <li>
                In order to break from the state of Ihram, men shave their
                heads, while women cut the length of a fingertip.
              </li>
            </ul>
            <p className="text-[16px] font-kd mb-4">
              Women performing Umrah must be in a state of purity (not
              menstruating). Therefore, it’s important to plan the dates of your
              Umrah accordingly.
            </p>
            <p className="text-[16px] font-kd mb-4">
              In order to assume Ihram, women do not have to wear specific
              clothing as is the case for men. However, women must also ensure
              they wear light and dress modestly, ensuring that everything
              except their face, hands and feet are covered.
            </p>
            <p className="text-[16px] font-kd mb-4">
              In addition, women must refrain from wearing perfume, tight-fitted
              clothing, gloves, make-up or henna during the state of Ihram.
            </p>
            <p className="text-[16px] font-kd mb-4">
              If a woman is in her Iddah period (three months after being
              widowed or divorced), she is not allowed to perform Umrah.
            </p>
            <p className="text-[16px] font-kd mb-4">
              In order to obtain a Saudi Tourist Visa (which can be used to
              perform Umrah), women are no longer required to be accompanied by
              a Mahram (close male relative). They are now able to perform Umrah
              accompanied by close women, such as their mothers, sisters, aunts
              or close friends.
            </p>
            <p className="text-[16px] font-kd mb-4">
              However, from an Islamic perspective, if a woman wishes to perform
              Hajj or Umrah, she must travel with a Mahram according to the
              majority of scholars.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Some scholars believe that women who wish to perform Umrah or Hajj
              without a Mahram may do so if she is in the trusted company of a
              group or if the travelling routes are safe.
            </p>
            <p className="text-[16px] font-kd mb-4">
              However, you are encouraged to consult your Madhab (school of
              thought), teacher to help inform your decision.
            </p>
          </section>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              How many hours is Umrah performed?
            </h2>
            <p className="text-[16px] font-kd mb-4">
              It usually takes between 3-6 hours to perform Umrah. The time it
              takes varies depending on how busy it is, also your health, age
              and stamina levels.
            </p>
            <p className="text-[16px] font-kd mb-4">
              It usually takes longer to perform Umrah for the first time as you
              are less familiar with the steps involved.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Although Umrah can be performed in less than one day, from when
              one enters the state of Ihram, until the cutting/trimming of hair
              once Umrah is complete, most tour operators offer Umrah packages
              for the duration of 7 days, to 30 days at the maximum.
            </p>
            <p className="text-[16px] font-kd mb-4">
              How long you wish to spend on your Umrah trip depends on your own
              personal circumstances and how you wish to spend your time on your
              trip to Umrah.
            </p>
            <p className="text-[16px] font-kd mb-4">
              However, for most families, the experience of travelling to Umrah
              also includes spending time in the Haram Sharif as well as
              spending some time in Madinah and visiting Islamic historical
              sites.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              The conditions of performing Umrah
            </h2>
            <p className="text-[16px] font-kd mb-4">
              In order to be eligible to perform Umrah, one must adhere to
              certain conditions. These include:
            </p>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px] font-kd">
              <li>
                <strong>Islam:</strong> It is necessary to be a Muslim.
              </li>
              <li>
                <strong>Maturity:</strong> You must have reached puberty
                (Baligh). You can perform Umrah before this age, but it is only
                accepted after the age of puberty.
              </li>
              <li>
                <strong>Sanity:</strong> You should be mentally sound and in
                control of your mental faculties.
              </li>
              <li>
                <strong>Financially able:</strong> You must have enough funds to
                cover the expenses of travel, accommodation, and other
                requirements during your Umrah. You should also be able to
                support your dependents while you are away. Being debt-free is
                recommended; any arrangements for debt repayment should be made
                beforehand.
              </li>
              <li>
                <strong>Physically able:</strong> You must be physically capable
                of travelling to Makkah and performing the rituals of Umrah,
                either on foot or in a wheelchair.
              </li>
              <li>
                <strong>Transportation:</strong> You should have access to
                transportation and be able to afford the costs of travelling to
                Makkah for Umrah. It is also important that the journey is safe,
                without any foreseeable danger.
              </li>
              <li>
                <strong>Visa rules:</strong> Certain conditions must be met to
                obtain an Umrah Visa from the UK, such as holding a valid
                passport for at least six months. Find out more here.
              </li>
            </ul>
            <p className="text-[16px] font-kd mb-4">
              These conditions ensure that individuals are ready and capable of
              fulfilling the rites of Umrah.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Performing Umrah on behalf of relatives
            </h2>
            <p className="text-[16px] font-kd mb-4">
              You can perform Umrah on behalf of relatives. However, you can
              only do so if the relative is deceased, chronically unwell, or
              physically unable to perform Umrah. This means that they are too
              physically weak to travel to Umrah and take care of themselves
              throughout the journey.
            </p>
            <p className="text-[16px] font-kd mb-4">
              In order to perform Umrah on behalf of a relative, one must make
              the intention of performing Umrah for them when entering the state
              of Ihram. They must say their relative's name when making the
              intention of Umrah. They then complete the Umrah rites as they
              would for themselves, intending to perform the Umrah on behalf of
              their relative.
            </p>
            <p className="text-[16px] font-kd mb-4">
              It's important to note that you can only perform Umrah on behalf
              of relatives at one time. If you are intending to perform Umrah on
              behalf of multiple relatives, you will need to perform Umrah
              multiple times.
            </p>
            <p className="text-[16px] font-kd mb-4">
              It’s permissible to perform Umrah on behalf of a relative who has
              passed away. When you are performing Umrah on behalf of someone
              else, start by saying: “Labbayk Allahumma Umrah on behalf of
              (insert name of relative).”
            </p>
          </section>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              What should be avoided during Umrah?
            </h2>
            <p className="text-[16px] font-kd mb-4">
              The following should be avoided during Umrah, specifically after
              assuming the state of Ihram:
            </p>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px] font-kd">
              <li>
                Applying perfume and perfumed cosmetics for men and women.
              </li>
              <li>
                Women covering their face, wearing tight-fitted clothing or
                wearing hand gloves.
              </li>
              <li>
                Men covering their head with a cap, or wearing stitched
                clothing.
              </li>
              <li>Trimming nails.</li>
              <li>Cutting hair.</li>
              <li>
                Getting married is not permissible according to most scholars,
                but the Hanafis allow it although it is not recommended to do so
                according to them too.
              </li>
              <li>Engaging in sexual activity.</li>
              <li>Hunting an animal.</li>
              <li>Cutting down a tree.</li>
              <li>Killing an insect (unless it poses a threat to you).</li>
              <li>Smoking or taking drugs.</li>
              <li>Engaging in a quarrel or fight.</li>
              <li>Swearing and backbiting.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Islamic sites to visit when going for Umrah
            </h2>
            <p className="text-[16px] font-kd mb-4">
              Many Umrah package providers include arrangements for an Islamic
              historical tour/visit to Islamic landmarks (Ziyarah). You can also
              arrange to visit these sites if you’re travelling without a
              package provider. We’ve put together a list of the most noteworthy
              sites to visit near Makkah and Madinah outside of the Haramain
              Sharif, when you go for Umrah:
            </p>
            <h3 className="text-[18px] font-kd mb-4 font-bold">Near Makkah:</h3>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px] font-kd">
              <li>
                <strong>Jabal al-Noor:</strong> The “Mountain of Light” hosts
                Ghar-e-Hira – the cave in which the Prophet Muhammad (PBUH)
                would go to for seclusion, contemplation and meditation. It is
                also the place where the first words of the Holy Qur’an were
                revealed to the Prophet Muhammad (PBUH) through Angel Jibreel.
              </li>
              <li>
                <strong>Mount Arafat:</strong> Mount Arafat is a holy site of
                Hajj and considered to be a blessed place to visit.
              </li>
              <li>
                <strong>Maktaba Makkah-al-Mukarramah:</strong> This is the holy
                birthplace of Prophet Muhammad (PBUH), which is located in Sheeb
                Banu Hashim in Makkah. Previously a mountain, this place has
                been converted into a national library.
              </li>
              <li>
                <strong>Jannat-al-Baqi:</strong> This is the most significant
                cemetery for Muslims, and is the resting place of many ancestors
                and relatives of Prophet Muhammad (PBUH). It is located to the
                north of the Masjid al-Haram and is around 15 minutes walk.
              </li>
              <li>
                <strong>Masjid-e-Ayesha:</strong> The second-largest mosque in
                Makkah, Masjid-e-Aisha is one of the oldest establishments in
                the city. It is also a site where pilgrims can assume Ihram.
              </li>
            </ul>
            <h3 className="text-[18px] font-kd mb-4 font-bold">
              Near Madinah:
            </h3>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px] font-kd">
              <li>
                <strong>Masjid al-Quba:</strong> Situated on the outer area of
                Madina.
              </li>
              <li>
                <strong>Masjid al-Qiblatain:</strong> Known to be the first
                mosque in the history of Islam and is the second-largest mosque
                in Madina.
              </li>
              <li>
                <strong>Mount Uhud:</strong> This is the site of the Battle of
                Uhud, and is a blessed and sanctified site. It represents the
                martyrs who gave their life for Islam.
              </li>
              <li>
                <strong>Dar Al-Madinah Museum:</strong> This museum hosts rare
                artefacts and archeological collections from the holy city.
              </li>
              <li>
                <strong>Masjid al-Ghamama:</strong> This beautiful mosque is the
                site in which Allah commanded Prophet Muhammad (PBUH) to change
                the direction of the Qibla from Jerusalem to Makkah.
              </li>
            </ul>
          </section>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Madina (history, facts, religious significance, travelling to
              Madina during Umrah trip)
            </h2>
            <p className="text-[16px] font-kd mb-4">
              Madina, also known as the Prophet's Mosque, is the second holiest
              city in Islam, housing one of the three Grand Mosques (Haram)
              where rewards for good deeds are amplified tenfold (as is in
              Makkah).
            </p>
            <p className="text-[16px] font-kd mb-4">
              The Prophet Muhammad (PBUH) migrated to Madina from Makkah in 622
              AD. It is also known as the Hijrah.
            </p>
            <p className="text-[16px] font-kd mb-4">
              The Prophet Muhammad (PBUH) is buried within the holy mosque of
              the Prophet Muhammad (PBUH), which was established by the Prophet
              (PBUH) himself.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Prophet Muhammad (PBUH) himself has said that one must visit his
              mosque and the city of Madina during their Umrah travels as there
              are countless rewards for these blessed actions.
            </p>
            <p className="text-[16px] font-kd mb-4">
              The Prophet (PBUH) has also mentioned the rewards for visiting
              Madina.
            </p>
            <p className="text-[16px] font-kd mb-4">
              A visitor of the Prophet’s Mosque (PBUH), Madina, should make the
              intention and pray two Rak’ah, seeking Allah’s forgiveness and
              mercy. You can also visit the grave of the Prophet (PBUH) and
              offer your Salaam.
            </p>
            <p className="text-[16px] font-kd mb-4">
              The following Hadith demonstrates the benefits and rewards of
              visiting Madina:
            </p>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “The person who offers 40 prayers consecutively in my mosque,
                without missing a prayer in between, will secure immunity from
                the fire of Hell and other torments and also from hypocrisy.”
              </p>
              <footer className="text-right">Hadith | Musnad Ahmad</footer>
            </blockquote>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “The prayer in my Masjid [Masjid An-Nabawi] is better than one
                thousand [1000] prayers in any other masjid with the exception
                of Masjid Al-Haram, and a prayer in Masjid Al-Haram is better
                than one hundred thousand [100,000] prayers.”
              </p>
              <footer className="text-right">Hadith | Ibn Majah</footer>
            </blockquote>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “I have made Madinah a sanctuary between its two [Harrat]
                mountains.”
              </p>
              <footer className="text-right">Hadith | Bukhari & Muslim</footer>
            </blockquote>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “There are angels guarding the entrances (or roads) of Madina;
                neither plague nor Ad-Dajjal will be able to enter it.”
              </p>
              <footer className="text-right">Hadith | Bukhari & Muslim</footer>
            </blockquote>

            <p className="text-[16px] font-kd mb-4">
              As an enlightened city, millions of Muslims visit Madinah every
              year in order to attain purification, blessings and rewards.
              Almost everyone performing Umrah will choose to visit Madina as
              part of their trip.
            </p>
          </section>
        </div>
        <div className=" px-4 py-8">
          <section className="mb-8">
            <h2 className="text-[20px] font-kd mb-4 font-bold">
              Makkah (history, facts, religious significance)
            </h2>
            <p className="text-[16px] font-kd mb-4">
              Makkah is the holy birthplace of the Prophet Muhammad (PBUH), who
              was born on 12th Rabi al-Awwal in the year 570 CE.
            </p>
            <p className="text-[16px] font-kd mb-4">
              It is also the city of the Holy Ka’aba, which is the religious
              centre (Qibla) for Muslims across the world to face for their five
              daily prayers. As the site for Hajj, it is an obligation and one
              of the five pillars of Islam to visit Makkah at least once in
              every Muslim’s lifetime.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Makkah is also the city in which the Prophet Muhammad (PBUH)
              revealed his first revelations of the Holy Qur’an in the Cave of
              Hira located on Mount Nur.
            </p>
            <p className="text-[16px] font-kd mb-4">
              Makkah is home to the Masjid al-Haram, the Great Mosque and Holy
              Site, which was built by Prophet Ibrahim (AS) and Prophet Ismail
              (AS).
            </p>
            <p className="text-[16px] font-kd mb-4">
              Steeped in religious and historical significance, Makkah is
              renowned as a blessed site for Muslims throughout the world, and
              it is a great honour for Muslims to visit this holy city.
            </p>
            <p className="text-[16px] font-kd mb-4">
              The following Hadith describes the significance of Makkah:
            </p>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “Allah has made this city a sanctuary from the day that He
                created the heavens and the earth. By Allah’s declaration, it is
                sanctified until the Day of Judgement.”
              </p>
              <footer className="text-right">Hadith | Sahih Muslim</footer>
            </blockquote>

            <p className="text-[16px] font-kd mb-4">
              Prophet Muhammad (PBUH) said:
            </p>

            <blockquote className="bg-[#e6f4f0] text-[#484848] p-4 rounded-lg mb-4">
              <p className="text-[16px] font-kd mb-4 italic">
                “The prayer in my Masjid [Masjid An-Nabawi] is better than one
                thousand [1000] prayers in any other masjid with the exception
                of Masjid Al-Haram, and a prayer in Masjid Al-Haram is better
                than one hundred thousand [100,000] prayers.”
              </p>
              <footer className="text-right">Hadith | Sahih Muslim</footer>
            </blockquote>
          </section>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default UmrahGuide;
