import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import Card from "./Card";
import Subscription from "../../../components/subscription";
import Footer from "../../../components/footer";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";

const Career = () => {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <div>
      <BookingNavbar />
      <div className="md:p-14 py-5 md:space-y-5 space-y-1 bg-[#E5F1EE] text-[#4B465C]">
        <div className="w-[85%] mx-auto">
          <h1 className="md:text-4xl text-md leading-relaxed font-bold text-left md:text-center">
            Join Our World-class team of <br className="hidden md:block" />{" "}
            Creators & dreamers
          </h1>
        </div>
        <div className="w-[85%] mx-auto">
          <p className="md:text-lg text-[12px] leading-relaxed font-thin text-left md:text-center">
            At Findred Limited, we believe in staying one step ahead of cyber
            <br className="hidden md:block" /> attackers, keeping our customers'
            assets safe and secure.
          </p>
        </div>
      </div>
      <div className="w-[85%] mx-auto text-[#4B465C] mt-2">
        <div className="flex justify-end">
          <form className="w-full max-w-xs">
            <div className="relative border-b-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <RiSearchLine className="w-4 h-4" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-sm outline-none border-none"
                placeholder="Search Jobs"
                onChange={handleSearch}
                required
              />
            </div>
          </form>
        </div>
        <Card searchQuery={searchQuery} />
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Career;
