import React from "react";
import { MasjidMiqat } from "../../../../../../data";

const Page = () => {
  return (
    <div className="">
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo md:text-[25px] text-[15px]">
          Miqat mosque or Masjid Meeqat is located on the western side of
          Al-Aqeeq
          <br />
          Valley. It is around 10 kilometers away from Masjid an-Nabawi.
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        <div className="mt-12">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                {" "}
                History of Miqat Dhul Hulaifah Mosque
              </h1>
              <p
                className="font-cairo font-normal leading-[32px]"
                style={{ marginTop: "10px" }}
              >
                The Mīqāt Dhu al-Ḥulayfah Mosque, also known as the Masjid
                ash-Shajarah Upon reaching the outskirts of Mecca, at a place
                called Hudaibiyah, negotiations commenced between the Muslims
                and the Quraysh. The talks were tense and prolonged, involving
                several back-and-forth communications. Eventually, a compromise
                was reached, resulting in the Treaty of Hudaibiyah.
                Masjid-u-Shajarah (mosque of the tree) is one of several Miqats
                (place where ihram is put on) for Muslims on pilgrimage to Mecca
                for umrah or hajj. Abyar-e Ali is the name of a place where
                Masjid-u-Shajarah is situated, 7 km outside Medina in Dhul
                Hulaifah.
                <br></br>
                The Miqat Mosque Complex was designed by the architect
                Abdel-Wahed El-Wakil. It was built between 80 and 90 A.H. when
                Umar bin Abdul Aziz was governor of Medina. After the initial
                construction, the mosque has been renovated several times. The
                latest renovation happened during the time of King Fahd bin
                Abdul Aziz, who expanded the area in order to equip the mosque
                with the necessary facilities.
              </p>
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:ml-8">
              <img
                src={MasjidMiqat.why.image}
                alt="Wadi-e Jinn"
                className="w-full h-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        <div className="mt-12">
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div>
                <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                  {" "}
                  Importance in Islam:
                </h1>
                <p
                  className="font-cairo font-normal leading-[32px]"
                  style={{ marginTop: "10px" }}
                >
                  Miqat mosque holds a special place because the Prophet
                  (P.B.U.H) defined it as the miqat for the people of Medina. As
                  pilgrims arrive for umrah or hajj, they stop by the mosque in
                  order to enter into ihram and make the intention of performing
                  umrah before they leave for Mecca. People inside Mecca, who
                  have to perform umrah, travel to the mosque especially so they
                  can enter into ihram.
                </p>
              </div>
              <div>
                <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                  {" "}
                  Structure:
                </h1>
                <p
                  className="font-cairo font-normal leading-[32px]"
                  style={{ marginTop: "10px" }}
                >
                  Built in the shape of a square, the mosque covers an area of
                  6000 m². There are two sets of galleries in the mosque that
                  are separated by a yard. These galleries are shaped like
                  arches ending with long domes. The minaret of the mosque is
                  square at the bottom and round at the top, distinguishing it
                  from other mosques in the area. A green central garden with
                  lots of greenery and trees is located beside the mosque. There
                  is a separate area for pilgrims where they can do ablution and
                  change into ihrams. The mosque is located on the highway so
                  that it is convenient for the pilgrims to reach it.
                </p>
              </div>
            </div>

            <div className="mt-4 md:mt-0">
              <img
                src={MasjidMiqat.pilgrimge.image}
                alt="Wadi-e Jinn"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        <div className="mt-12">
          <div className="flex flex-col flex-col-reverse md:flex-row ">
            <div className=" md:w-3/4 mt-4 md:mt-0 ">
              <img
                src={MasjidMiqat.pilgrimge2.image}
                alt="Wadi-e Jinn"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-100 md:ml-8">
              <h1 className="font-cairo font-bold text-[24px] md:text-[25px] pb-1 text-gray-800">
                {" "}
                Key Points of Miqat
              </h1>
              <div className="space-y-2 py-1.5">
                <h4 className="font-bold">The 5 Miqat Points:</h4>
                <div className="grid grid-cols-1 gap-2.5 md:grid-cols-3">
                  <p className="border-2 border-[#484848] text-[#484848] font-cairo font-normal text-[13.5px] leading-relaxed rounded-full py-2 px-2">
                    1. Dhul Hulayfah (also known as Abyar Ali)
                  </p>
                  <p className="border-2 border-[#484848] text-[#484848] font-cairo font-normal text-[13.5px] leading-relaxed rounded-full py-2 px-2">
                    2. Al-Juhfah (also known as Rabigh)
                  </p>
                  <p className="border-2 border-[#484848] text-[#484848] font-cairo font-normal text-[13.5px] leading-relaxed rounded-full py-2 px-2">
                    3. Qarn al-Manazil (also known as al-Sayl)
                  </p>
                  <p className="border-2 border-[#484848] text-[#484848] font-cairo font-normal text-[13.5px] leading-relaxed rounded-full py-2 px-2">
                    4. Dhat Irq
                  </p>
                  <p className="border-2 border-[#484848] text-[#484848] font-cairo font-normal text-[13.5px] leading-relaxed rounded-full py-2 px-2">
                    5. Yalamlam (also known as al-Sadiah)
                  </p>
                </div>
                <p className="font-cairo font-normal text-[16px] leading-relaxed text-gray-600">
                  An additional Miqat was affixed during the reign of the second
                  Caliph, Sayyiduna Umar Ibn al-Khattab (RA) I after the towns
                  of Basra and Kufa had been captured. Abdullah ibn Umar (RA)
                  narrates:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
