import React, { useEffect } from "react";
import HeroSection from "../../../../../../components/HeroSection/HeroSection";
import { pageOneContent } from "../../../../../../data";
import Distance from "./Distance";
import HeaderFooter from "../../../../ArticlesPage/HeaderFooter";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Scroll to top when the component mounts
    scrollToTop();

    // Scroll to top when the page is reloaded or navigated from another page
    window.addEventListener("beforeunload", scrollToTop);

    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);
  return (
    <div className="">
      <HeaderFooter>
        <HeroSection
          herobg={pageOneContent.hero.image}
          heading={pageOneContent.hero.heading}
          paragraph={pageOneContent.hero.description}
        />
        <Distance />
      </HeaderFooter>
    </div>
  );
};

export default Home;
