import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import Select from "react-select";
import countryList from "react-select-country-list";
import { checkPassportValidity } from "../../../utility/Api";
import dayjs from "dayjs";

const PassportDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Destructuring the state received from RegistrationForm
  const {
    huz_token,
    partner_session_token,
    totalAdults,
    totalChild,
    totalInfants,
    roomType,
    totalCost,
    bookingResponse,
    booking_number,
    user_session_token,
    payment_type,
    returnDate, // Now receiving the return date
  } = location.state || {};

  // Initialize state for storing passport details
  const [passports, setPassports] = useState(
    Array(totalAdults + totalChild + totalInfants).fill({
      passportNumber: "",
      countryIssued: "",
      expiryDate: { day: "", month: "", year: "" },
    })
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState(
    Array(totalAdults + totalChild + totalInfants).fill("")
  ); // Array to hold error messages for each traveler
  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleInputChange = (index, field, value) => {
    const updatedPassports = [...passports];
    updatedPassports[index] = {
      ...updatedPassports[index],
      [field]: value,
    };
    setPassports(updatedPassports);
  };

  const handleExpiryDateChange = (index, field, value) => {
    const updatedPassports = [...passports];
    updatedPassports[index].expiryDate = {
      ...updatedPassports[index].expiryDate,
      [field]: value,
    };
    setPassports(updatedPassports);
  };

  const handleCountryChange = (index, value) => {
    const updatedPassports = [...passports];
    updatedPassports[index].countryIssued = value.label;
    setPassports(updatedPassports);
  };

  // Function to format the date to YYYY-MM-DD
  const formatExpiryDate = (expiryDate) => {
    const { day, month, year } = expiryDate;
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // Function to calculate if expiry date is at least 6 months from the return date
  const isExpiryDateValid = (expiryDate) => {
    const passportExpiryDate = dayjs(formatExpiryDate(expiryDate));
    const returnDatePlus6Months = dayjs(returnDate).add(6, "month");
    return passportExpiryDate.isAfter(returnDatePlus6Months);
  };

  // Validate all travelers and set individual errors if any
  const validateAllTravelers = () => {
    const updatedErrorMessages = [...errorMessages];
    let isValid = true;

    passports.forEach((passport, index) => {
      if (!passport.passportNumber) {
        updatedErrorMessages[index] = `Passport number for traveler ${
          index + 1
        } cannot be empty.`;
        isValid = false;
      } else if (!passport.countryIssued) {
        updatedErrorMessages[index] = `Country issued for traveler ${
          index + 1
        } cannot be empty.`;
        isValid = false;
      } else if (!isExpiryDateValid(passport.expiryDate)) {
        updatedErrorMessages[index] = `Passport for traveler ${
          index + 1
        } (number: ${
          passport.passportNumber
        }) must be valid for at least 6 months after the return date.`;
        isValid = false;
      } else {
        updatedErrorMessages[index] = ""; // Clear error if valid
      }
    });

    setErrorMessages(updatedErrorMessages);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate all travelers before proceeding
    if (!validateAllTravelers()) {
      setIsSubmitting(false);
      return; // Stop execution if any validation fails
    }

    try {
      // Step 2: If all data is valid, proceed to call the API for each traveler
      for (let passport of passports) {
        const payload = {
          session_token: user_session_token,
          booking_number: booking_number,
          passport_number: passport.passportNumber,
          passport_country: passport.countryIssued,
          expiry_date: formatExpiryDate(passport.expiryDate),
        };

        const response = await checkPassportValidity(payload);

        if (response.error) {
          console.error(
            `Error validating passport: ${passport.passportNumber}`,
            response.message
          );
          setIsSubmitting(false);
          return; // Stop further execution if there's an error
        }
      }

      // All passports successfully submitted, navigate to the next page
      const navigateTo =
        payment_type === 1
          ? "/bankingDetail"
          : payment_type === 3
          ? "/voucherDetail"
          : "/bankingDetail";

      navigate(navigateTo, {
        state: {
          huz_token,
          partner_session_token,
          totalAdults,
          totalChild,
          totalInfants,
          roomType,
          totalCost,
          bookingResponse,
          passports,
        },
      });
    } catch (error) {
      console.error("An error occurred while submitting passports:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPassportFields = (index) => (
    <div
      key={index}
      className="passport-details-div bg-white shadow-lg rounded-lg p-6 mb-6 transform transition-all duration-300 hover:scale-105"
    >
      <h3 className="text-xl font-semibold mb-4">
        Traveler {index + 1} Passport Details
      </h3>

      {/* Display error message for this specific traveler */}
      {errorMessages[index] && (
        <p className="text-red-500 font-semibold mb-4">
          {errorMessages[index]}
        </p>
      )}

      <div className="form-group mb-4">
        <label className="block mb-2 font-medium text-gray-700">
          Passport Number
        </label>
        <input
          type="text"
          placeholder="Please enter 9 Digit Passport number"
          value={passports[index].passportNumber}
          onChange={(e) =>
            handleInputChange(index, "passportNumber", e.target.value)
          }
          className="w-full border border-gray-300 rounded-md p-3 text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
        />
      </div>
      <div className="form-group mb-4">
        <label className="block mb-2 font-medium text-gray-700">
          Country Issued
        </label>
        <Select
          options={countryOptions}
          value={countryOptions.find(
            (option) => option.label === passports[index].countryIssued
          )}
          onChange={(value) => handleCountryChange(index, value)}
          className="w-full border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              height: "36px",
              fontSize: "0.875rem",
              minHeight: "36px",
              borderColor: "#E5E7EB",
              boxShadow: "none",
              "&:hover": { borderColor: "#A7A7A7" },
            }),
            option: (provided, state) => ({
              ...provided,
              fontSize: "0.875rem",
              backgroundColor: state.isSelected
                ? "#00936C"
                : state.isFocused
                ? "#d1d1d1"
                : "white",
              color: state.isSelected ? "white" : "#111827",
              padding: "8px",
            }),
          }}
        />
      </div>
      <div className="form-group mb-4">
        <label className="block mb-2 font-medium text-gray-700">
          Passport Expiry Date
        </label>
        <div className="flex space-x-2">
          <input
            type="number"
            placeholder="Day"
            value={passports[index].expiryDate.day}
            onChange={(e) =>
              handleExpiryDateChange(index, "day", e.target.value)
            }
            className="w-1/3 border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
          />
          <input
            type="number"
            placeholder="Month"
            value={passports[index].expiryDate.month}
            onChange={(e) =>
              handleExpiryDateChange(index, "month", e.target.value)
            }
            className="w-1/3 border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
          />
          <input
            type="number"
            placeholder="Year"
            value={passports[index].expiryDate.year}
            onChange={(e) =>
              handleExpiryDateChange(index, "year", e.target.value)
            }
            className="w-1/3 border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <BookingNavbar />
      <div className="container mx-auto py-10">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">
          Traveler Passport Information
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {Array.from({ length: totalAdults + totalChild + totalInfants }).map(
            (_, index) => renderPassportFields(index)
          )}

          <button
            type="submit"
            className={`w-full bg-[#00936C] text-white py-3 rounded-md shadow-lg font-semibold hover:bg-green-800 transition-transform transform hover:scale-105 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Continue to Payment"}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default PassportDetails;
