import React from "react";

const MainPage = () => {
  return (
    <div>
      <div className="bg-[#F2F2F2]">
        <p className="text-center text-[#484848] font-bold py-4 font-cairo text-[25px]">
          Shubra Palace is a royal palace in Taif, Saudi Arabia. It was one of
          the royal residences until 1995 when it was transformed into a museum.
        </p>
      </div>
      <div className="w-[85%] mx-auto">
        {/* upper section named location */}
        <div className="pt-14">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Cultural and Recreational Significance
          </h2>
          <div className="flex flex-col lg:flex-row mt-4 gap-4">
            <div className="md:w-full">
              <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px]">
                Shubra Palace, located in Riyadh, Saudi Arabia, is a historical
                gem that reflects the kingdom's rich cultural heritage and
                architectural grandeur. Built during the reign of King
                Abdulaziz, the founder of modern Saudi Arabia, the palace is
                renowned for its traditional Islamic design and lavish
                interiors.
                <br />
                Architectural Splendor and Design
                <br />
                Shubra Palace is celebrated for its intricate woodwork, ornate
                carvings, and elegant Islamic motifs that adorn its facades and
                interiors. The palace's architecture showcases a blend of Saudi
                Arabian and Ottoman influences, featuring spacious halls,
                majestic domes, and finely crafted archways that reflect the
                craftsmanship of the era.
                {/*  */}
                The building was originally constructed in 1858 as a two-storey
                house It was rebuilt by Ali Pasha, former sharif of Mecca, and
                completed in 1905. It was named after a palace built in Cairo,
                Egypt. Following the capture of the city by Saudis the Shubra
                Palace was used by King Abdulaziz as summer residence. Two of
                his sons, Prince Talal and Prince Nawwaf, were born there. It is
                where King Abdulaziz died in 1953. King Faisal used the Shubra
                Palace as a summer residence. The palace was also used as the
                office of Crown Prince Sultan. In 1995 the palace was made a
                heritage museum.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mt-0">
              <img
                src="/images/Taif/holyplace/shubrapalace/image3.jpg"
                alt="Wadi-e-Jinn"
                className="rounded-lg shadow-lg w-[523px]"
              />
            </div>
          </div>
        </div>
        {/* location section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Historical Significance
          </h2>
          <div className="mt-4">
            <p className="text-[#484848] mb-4 font-cairo font-medium text-[15px] ">
              Beyond its architectural beauty, Shubra Palace holds historical
              significance as a residence for members of the Saudi royal family
              and visiting dignitaries over the decades. It served as a venue
              for important state meetings, cultural gatherings, and diplomatic
              events, embodying the kingdom's diplomatic and cultural outreach.
              <br />
              Cultural and Artistic Legacy
              <br />
              The palace is not only a symbol of royal prestige but also a hub
              of cultural preservation and artistic expression. It houses a rich
              collection of artifacts, including traditional Saudi furnishings,
              ancient manuscripts, and artworks that depict the kingdom's
              history and cultural evolution. Visitors can explore these
              exhibits to gain insights into Saudi Arabian heritage and artistic
              traditions.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <img
              src="/images/Taif/holyplace/shubrapalace/image2.webp"
              //   src="https://via.placeholder.com/600x300"
              alt="Islamic Calligraphy"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* islam section code ending */}
        <div className="pt-7">
          <h2 className="text-[#484848] font-cairo font-bold text-[25px] pb-1">
            Ecological Conservation and Sustainability
          </h2>
          <div className="mt-4">
            Shubra Palace in Saudi Arabia exemplifies a commitment to ecological
            conservation and sustainability, ensuring its recreational value and
            environmental significance. The management of Shubra Palace
            prioritizes sustainable practices such as water conservation,
            preservation of native flora, and protection of wildlife. Visitors
            are encouraged to respect and contribute to maintaining the palace's
            natural ecosystem, ensuring its beauty endures for future
            generations to appreciate.
            <br />
            Beyond its ecological role, Shubra Palace stands as a symbol of
            Saudi Arabia's cultural charm and dedication to enhancing community
            well-being through its serene surroundings. Whether visitors seek
            relaxation, recreation, or cultural exploration, the palace offers a
            sanctuary where one can reconnect with nature, find solace, and
            create lasting memories with loved ones. It embodies the warmth and
            tranquility of Saudi hospitality, inviting all to experience its
            cultural richness firsthand.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
