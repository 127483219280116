import React from "react";
import BlogHeader from "./BlogHeader";
import Footer from "../../../components/footer";

const HeaderFooter = ({ children }) => {
  return (
    <div>
      <BlogHeader />
      <main>{children}</main>
      <div className="mt-10">
      <Footer />
      </div>
    </div>
  );
};

export default HeaderFooter;
