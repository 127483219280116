import React, { useEffect } from "react";
import imagefour from "../../../assets/howhuz/bannerfour.png";
import imageone from "../../../assets/howhuz/bannerone.png";
import imagethree from "../../../assets/howhuz/bannerthree.png";
import arrow from "../../../assets/howhuz/bannertwo.svg"; // Ensure you have the arrow image
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import offersimage from "../../../assets/howhuz/offers.png";
import stepImage1 from "../../../assets/howhuz/trending.png";
import stepImage2 from "../../../assets/howhuz/detailpage.png";
import stepImage3 from "../../../assets/howhuz/locationdetail.png";

const HowHuzWorks = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      <BookingNavbar />
      <divx>
        <div className="bg-purple-700 text-white font-kd py-16 space-y-7 flex flex-col items-center">
          <h2 className="md:text-3xl text-[20px] font-bold mb-8">
            And it's only three steps away
          </h2>
          <div className="md:flex justify-between items-center w-[85%] mx-auto relative">
            <div className="flex flex-col items-center text-center md:w-1/3 px-4">
              <img src={imageone} alt="Step 1" className="h-16 mb-4" />
              <h3 className="text-[20px] font-kd font-semibold">
                Sign up & load funds
              </h3>
              <p className="text-[15px] font-kd mt-2">
                Verify your company and load funds to your Spendesk wallet from
                your existing bank account.
              </p>
            </div>
            <div className="absolute xl:left-[24%] left-[22%] transform xl:-translate-y-2/3 lg:-translate-y-[121%]">
              <img src={arrow} alt="Arrow" className="h-10 hidden md:block" />
            </div>
            <div className="flex flex-col items-center text-center md:w-1/3 px-4">
              <img src={imagefour} alt="Step 2" className="h-16 mb-4 " />
              <h3 className="text-[20px] font-kd font-semibold">
                Set your spending rules
              </h3>
              <p className="text-[15px] font-kd mt-2">
                Define teams, approval workflows, spending policies, and card
                limits that work for you.
              </p>
            </div>
            <div className="absolute xl:right-[24%] lg:right-[21%] right-[24%] top-[47] transform xl:-translate-y-1/2 lg:-translate-y-[121%]">
              <img src={arrow} alt="Arrow" className="h-10 hidden md:block" />
            </div>
            <div className="flex flex-col items-center text-center md:w-1/3 px-4">
              <img src={imagethree} alt="Step 3" className="h-16 mb-4" />
              <h3 className="text-xl font-semibold">Invite your team</h3>
              <p className="text-[15px] font-kd mt-2">
                Employees can request funds, pay securely, and submit receipts
                in a snap with the Spendesk app.
              </p>
            </div>
          </div>
        </div>
        <section id="about" className="py-12 bg-gray-100">
          <div className="w-[85%] mx-auto">
            <h2 className="text-[20px] font-kd font-bold mb-6">About Huz</h2>
            <p className="text-[15px] font-kd text-[#484848] mb-8">
              Huz is dedicated to providing a seamless and fulfilling experience
              for those embarking on the sacred journeys of Hajj, Umrah, and
              Ziyarat. We connect you with trusted vendors and agencies offering
              comprehensive packages tailored to meet your needs, ensuring a
              memorable and stress-free pilgrimage.
            </p>
            <p className="text-[15px] font-kd text-[#484848] mb-8">
              Our platform offers exclusive benefits, expert guidance, and a
              secure booking process, making Huz your ideal partner for
              spiritual journeys. Whether you are planning your first pilgrimage
              or are a seasoned traveler, Huz simplifies the process, allowing
              you to focus on the spiritual aspects of your journey.
            </p>
            <p className="text-[15px] font-kd text-[#484848] mb-8">
              At Huz, we believe in transparency and quality. Our carefully
              curated packages include detailed itineraries, high-quality
              accommodations, and reliable transportation, ensuring your comfort
              and peace of mind. Our team of experienced consultants is always
              ready to assist you with any queries and provide personalized
              recommendations.
            </p>
            <p className="text-[15px] font-kd text-[#484848] mb-8">
              Join Huz today and embark on a spiritual journey that is both
              enriching and hassle-free. Discover the ease and convenience of
              booking your Hajj, Umrah, and Ziyarat packages through a platform
              designed with your needs in mind.
            </p>
          </div>
        </section>
        <section id="offers" className="py-12">
          <div className="w-[85%] mx-auto space-y-7">
            <h2 className="text-[20px] font-kd font-bold mb-6">
              Exclusive Offers on Select Packages
            </h2>
            <p className="text-[15px] text-[#484848]">
              At Huz, we are committed to providing you with the best possible
              experience for your sacred journey. We understand the importance
              of making your pilgrimage both spiritually enriching and
              financially accessible. That's why we offer exclusive discounts
              and benefits on select Hajj, Umrah, and Ziyarat packages.
            </p>
            <p className="text-[15px] text-[#484848]">
              Our special offers include free personal and medical assistance,
              complimentary insurance, and various other promotions to ensure
              your journey is as comfortable and worry-free as possible. These
              offers are designed to give you peace of mind and additional
              support throughout your pilgrimage.
            </p>
            <p className="text-[15px] text-[#484848]">
              Keep an eye out for the "Special Offer" tags on our packages to
              take advantage of these amazing deals. Our dedicated team is
              always here to assist you in choosing the best package that meets
              your needs and preferences.
            </p>
            <p className="text-[15px] text-[#484848]">
              Discover the benefits of booking with Huz and make your spiritual
              journey even more memorable with our exclusive offers.
            </p>
            <img src={offersimage} alt="" className="" />
          </div>
        </section>
        <section
          id="trending-destinations-guide"
          className="py-12 w-[85%] mx-auto"
        >
          <div className="">
            <h2 className="text-[20px] font-kd font-bold mb-6">
              How to Use the Trending Destinations Section
            </h2>
            <p className="text-[15px] font-kd text-[#484848] mb-5">
              The Trending Destinations section helps you explore the most
              popular pilgrimage sites in Mecca, Madinah, and Taif. Follow these
              steps to navigate through the section and find detailed
              information about each destination.
            </p>

            <div className="font-kd">
              <h3 className="text-[20px] font-semibold mb-4">
                Step 1: Select a Destination
              </h3>
              <img
                src={stepImage1}
                alt="Step 1"
                className="mb-4 mx-auto rounded shadow-md"
              />
              <p className="text-[15px] text-gray-700">
                Begin by choosing a destination from the list of trending
                places. Click on the image of Mecca, Madinah, or Taif to view
                more details about that destination.
              </p>
            </div>
            <div className="font-kd mt-5">
              <h3 className="text-[20px] font-semibold mb-4">
                Step 2: Browse Through Places
              </h3>
              <img
                src={stepImage2}
                alt="Step 2"
                className="mb-4 mx-auto rounded shadow-md"
              />
              <p className="text-[15px] text-[#484848]">
                After selecting a destination, you'll be taken to a page
                displaying various places of interest in that area. These places
                are shown as slider cards, making it easy to browse through the
                options. Each card contains an image and a brief description of
                the place.
              </p>
            </div>

            <div className="font-kd mt-5">
              <h3 className="text-[20px] font-semibold mb-4">
                Step 3: View Place Details
              </h3>
              <img
                src={stepImage3}
                alt="Step 3"
                className="mb-4 mx-auto rounded shadow-md"
              />
              <p className="text-[15px] text-[#484848]">
                To learn more about a specific place, click on its card. This
                will open a detailed page where you can find comprehensive
                information about the site, including its history, significance,
                and visitor tips.
              </p>
            </div>

            <p className="text-[15px] font-kd text-[#484848]">
              Explore the Trending Destinations section to discover the most
              popular and sacred sites, making your pilgrimage planning easier
              and more informed.
            </p>
          </div>
        </section>
        <section id="city-wise-packages-guide" className="py-7 w-[85%] mx-auto">
          <div className="">
            <h2 className="text-[20px] font-kd font-bold mb-6">
              Explore Packages City Wise
            </h2>
            <p className="text-[15px] font-kd text-[#484848] mb-8">
              Discover the best packages each city has to offer. This section
              allows you to explore and book packages based on your preferred
              city. Whether you're looking for adventures in Karachi, historical
              tours in Lahore, or scenic views in Islamabad, we've got you
              covered.
            </p>

            <div className="mb-12">
              <h3 className="text-[20px] font-kd font-semibold mb-4">
                Step 1: Select Your City
              </h3>
              {/* <img src={cityImage1} alt="Step 1" className="mb-4 mx-auto rounded shadow-md"/> */}
              <p className="text-[15px] font-kd text-[#484848]">
                Begin by choosing your city of interest from the list. Click on
                the image of your desired city to view the available packages.
              </p>
            </div>

            <div className="mb-12">
              <h3 className="text-[20px] font-kd font-semibold mb-4">
                Step 2: Browse Available Packages
              </h3>
              {/* <img src={cityImage2} alt="Step 2" className="mb-4 mx-auto rounded shadow-md"/> */}
              <p className="text-[15px] font-kd text-[#484848]">
                After selecting a city, you'll be taken to a page displaying all
                the available packages in that city. These packages are
                displayed as cards with images, descriptions, and pricing
                details.
              </p>
            </div>

            <div className="mb-12">
              <h3 className="text-[20px] font-kd font-semibold mb-4">
                Step 3: View Package Details
              </h3>
              {/* <img src={cityImage3} alt="Step 3" className="mb-4 mx-auto rounded shadow-md"/> */}
              <p className="text-[15px] font-kd text-[#484848]">
                To learn more about a specific package, click on its card. This
                will open a detailed page where you can find comprehensive
                information about the package, including itinerary, inclusions,
                and booking options.
              </p>
            </div>

            <p className="text-[15px] font-kd text-[#484848]">
              Explore the city-wise packages section to find the perfect
              experience tailored to your preferences. Book your adventure today
              and enjoy the best each city has to offer.
            </p>
          </div>
        </section>
      </divx>
      <Footer />
    </div>
  );
};

export default HowHuzWorks;
